require('./includes/prototype');
require('./includes/AvsAn.js');

window.UIkit = require('uikit/dist/js/uikit.min.js');

window.axios = require('axios');

// add interceptor for 401 response
window.axios.interceptors.response.use(
	(response) => {return response}, 
	error => {
		// if Unauthenticated response
		if(error.status === 401) {
			// reload page.
			location.reload();
		}
		// normal error
		return Promise.reject(error);
	}
);

import {createApp} from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import mitt from 'mitt';
import VTooltip from 'v-tooltip';

import VueClipboard from 'vue3-clipboard';
import VueTelInput from 'vue-tel-input';

import moment from "moment";
//eslint-disable-next-line
import moment_timezone from "moment-timezone";

import VueMask from '@devindex/vue-mask';

// main app
const app = createApp(App)
	.use(store)
	.use(router)
	.use(VueClipboard,{
		autoSetContainer: true
	})
	.use(VTooltip)
	.use(VueMask)
	.use(VueTelInput,{mode : 'auto'});

// emitter
const emitter = mitt();
app.config.globalProperties.emitter = emitter;

// moment
app.config.globalProperties.$moment = moment;


// mount
app.mount('#app');