<template>
	<div class="tsa-box low-padding">
		<div class="tsa-section-title  tsa-small pull-left">
			Partners <ExportButton :export_route="exportRoute" :file_name="exportFilename" />
		</div>
		<DataTable 
			:searchable="false"
			:table_data="partners"
			:table_headers="table_headers"
		/>
	</div>
</template>

<script>
	import DataTable from '@/components/Table/DataTable.vue';
	import ExportButton from '@/components/SuperAdmin/ExportButton.vue';
	export default {
		name: "admin-partner-group-partners",
		components: {
			DataTable,
			ExportButton
		},
		props: {
			partners: {
				type: Array,
				default: () => []
			},
			group: {
				type: Object,
				default: () => {}
			},
		},

		data(){
			return {
				table_headers: {
					name: {
						display: "Name",
						render: (cell_data) => {
							if(cell_data.department == null){
								return cell_data.company.name;
							}else{
								return `${cell_data.company.name} | ${cell_data.department.name}`;
							}
						}
					},
					'document.name': {
						display: "Collaboration Process"
					},
					'type': {
						render: cell_data => {
							return (cell_data.department == null)? "Company" : "Department";
						}
					}
				},
			}
		},
		computed : {
			// use group name to create file name.
			exportFilename(){
				if(this.group.name != undefined){
					const slug = this.group.name.replace(' ','-').toLowerCase();
					return `${slug}-groupmembers.xlsx`;
				}
				return "";
			},
			// api route need to export members.
			exportRoute(){
				return `${process.env.VUE_APP_API_URL}admin/company/partner_groups/${this.group.id}/export_members`;
			},
		}
	}
</script>