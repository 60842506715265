<template>
	<div class="collaborate-parent">
		<!--div class="tsa-admin-header">
			{{company.name}} <span class="page-name">Process Details</span>
		</div-->
		<DocumentHeader />
		<div class="choose-child-process form-row">
			<select v-model="document_id">
				<option value="">Choose {{process_document.parent_option_name }}</option>
				<option
					v-for="(row,index) in process_document.doc_linkages"
					:key="index"
					:value="row.contact_document_id">{{ row.option_name }}</option>
			</select>
		</div>
	</div>
</template>

<script>
	import DocumentHeader from '@/components/ContactDocuments/DocumentHeader.vue';
	export default {
		name: 'caller-parent-document',
		props: {
			process_document: {
				type: Object,
				default: () => {}
			}
		},
		mounted(){
		},
		components: {
			DocumentHeader
		},
		data(){
			return {
				company: {},
				document_id : ""
			}
		},
		methods: {
		},
		watch: {
			process_document(){
				this.company = this.process_document.company;
			},
			document_id() {
				this.$emit('selected',this.document_id);
			}
		}
	}
</script>