<template>
	<div class="tsa-contact-document tsa-default">
		<a href="#" class="close-sample" @click.prevent="closeSample"><i class="fas fa-window-close"></i></a>
		<div class="doc-note">This is the form that {{ company.name }} will use when contacting you</div>
		<div class="tsa-contact-document tsa-default">
			<div class="uk-child-width-1-2@m uk-grid-small tsa-document-form" uk-grid>
				<div>
					<div class="form-section">&lt;Partner&gt; Contact <i class="fas fa-user"></i></div>
					<div class="form-row">
						<input type="text" name="first_name" placeholder="Enter Your First Name" disabled="disabled" />
					</div>
					<div class="form-row">
						<input type="text" name="last_name" placeholder="Enter Your Last Name" disabled="disabled" />
					</div>
					<div class="form-row">
						<input type="email" name="email" placeholder="Enter Your Email" disabled="disabled" />
					</div>
					<div class="form-row">
						<input type="text" name="phone_number" placeholder="Enter Your Phone" disabled="disabled" />
					</div>
					<div class="form-row">
						<input type="text" name="internal_case_number" :placeholder="`Enter Your <Partner> Internal Case #`" disabled="disabled" />
					</div>
				</div>
				<div>
					<!-- custom requirements -->
					<div class="form-section">Common Customer Contact</div>
					<div class="form-row">
						<input type="text" name="company" placeholder="Enter Customer Company" disabled="disabled" />
					</div>
					<div class="form-row">
						<input type="text" name="name" placeholder="Enter Customer First and Last Name" disabled="disabled" />
					</div>
					<div class="form-row">
						<input type="email" name="email" placeholder="Enter Customer Email" disabled="disabled" />
					</div>
					<div class="form-row">
						<TelephoneField
							:disabled="true"
							placeholder="Enter Phone" />
					</div>
					<div class="form-row">
						<input type="text" name="case" placeholder="Enter Customer Case# with <Partner>" disabled="disabled" />
					</div>
				</div>
			</div>
			<div class="uk-child-width-1-2@m uk-grid-small tsa-document-form" uk-grid>
				<div>
					<div class="form-section">Problem Information</div>
					<div class="form-row">
						<input type="text" name="problem_summary" placeholder="Enter Problem Summary" disabled="disabled" />
					</div>
					<div class="form-row">
						<textarea rows="6" name="problem_description" placeholder="Enter description of problem and include product versions, troubleshooting steps and error messages. Do not share any Customer Data such as IP addresses, login IDs or other sensitive information." disabled="disabled"></textarea>
					</div>
				</div>
				<div>
					<div class="form-section">&nbsp;</div>
					<div class="tsa-priority">
						<select disabled="disabled">
							<option value="">Select Priority</option>
							<option value='low'>Low</option>
							<option value='medium'>Medium</option>
							<option value='high'>High</option>
						</select>
						<button disabled="disabled">Submit</button>
					</div>
					<div class="priority-notes">
						<i class="fas fa-exclamation-circle"></i>
						<p>
							Display information based on Priority selected <br>
							(Example Response time or special instructions)
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import {mapState} from 'vuex';
	import TelephoneField from '@/components/Form/TelephoneField.vue';
	export default {
		name: "document-sample",
		components: {
			TelephoneField
		},
		data(){
			return {
				phone: ""
			}
		},
		methods: {
			closeSample(){
				this.$emit('close');
			},
			/**
			 * when input changes make sure letters cannot be entered
			 * remove dial code if it is in place.
			 * @param  {[type]} options.number  [description]
			 * @param  {[type]} options.isValid [description]
			 * @param  {[type]} options.country [description]
			 * @return {[type]}                 [description]
			 */
			onPhoneChange(number,event_data){
				if(event_data.valid || event_data.valid == undefined){
					this.form_data.phone = event_data.formatted;
				}else{
					this.form_data.phone = event_data.nationalNumber;
				}
			},
		},
		computed: {
			...mapState({
				partner_group: state => state.partner_group,
				company: state => state.company
			})
		}
	}
</script>