<template>
	<div>
		<!--div class="tsa-admin-header">
			{{company.name}} <span class="page-name">Process Details</span>
		</div-->
		<DocumentHeader />
		<div class="static-process-document" style="margin-top: 20px" v-html="process_document.static_document" />
	</div>
</template>

<script>
	import DocumentHeader from '@/components/ContactDocuments/DocumentHeader.vue';
	export default {
		name: 'super-admin-static-document',
		props: {
			process_document: {
				type: Object,
				default: () => {}
			}
		},
		mounted(){
			setTimeout(() => this.checkForCaseExchangeButton(),0);
		},
		components: {
			DocumentHeader
		},
		data(){
			return {
				company: {},
			}
		},
		methods: {
			/**
			 * check static document for case exchange button
			 * if found add click behavior to load case exchange
			 * @return {[type]} [description]
			 */
			checkForCaseExchangeButton(){
				const logCaseButton = document.getElementById("caseExchangeButton");
				if(logCaseButton != null){
					logCaseButton.addEventListener('click', event => {
						event.preventDefault();
						this.loadCaseExchange();
					});
				}
			},
			/**
			 * made request to log user in to hubcase and get redirect url
			 * @return {[type]} [description]
			 */
			loadCaseExchange(){
				this.emitter.emit('loader',true);
				//eslint-disable-next-line
				axios.get(`${process.env.VUE_APP_API_URL}caller/case_exchange/cases`)
				.then(response => this.handlCaseExchangeResponse(response.data))
				//eslint-disable-next-line
				.catch(error => console.log(error));
			},
			/**
			 * if succesfull response redirect user
			 * otherwise show error message
			 * @param  {[type]} response [description]
			 * @return {[type]}          [description]
			 */
			handlCaseExchangeResponse(response){
				this.emitter.emit('loader',false);
				if(response.success){
					window.open(response.url,"_blank");
				}else{
					this.emitter.emit('alert',{
						type: 'warning',
						message: response.message
					});
				}
			}
		},
		watch: {
			process_document(){
				this.company = this.process_document.company;
				this.checkForCaseExchangeButton();
			}
		}
	}
</script>