<template>
	<div class="cases-list">
		<h1>Page Not Found</h1>
		<p>The page you are trying to access cannot be found. <a href="" @click.prevent="goHome">Click Here</a> to go back home.</p>
	</div>
</template>

<script>
import {mapState} from 'vuex';
export default {
	name: 'page-not-found',
	components: {
	},
	data(){
		return {
		}
	},
	mounted(){
	},
	updated(){
		
	},
	methods:{
		goHome(){
			if(this.isLoggedIn){
				if(this.user.role == "super_admin"){
					this.$router.push({name: 'super_admin.dashboard'});
				}else{
					this.$router.push({name: 'dashboard'});
				}
			}else{
				let redirect = "";
				if(this.app_slug != null){
					redirect = process.env.VUE_APP_COMPANY_URL.replace('{slug}',this.app_slug);
				}else{
					redirect = process.env.VUE_APP_URL;
				}

				window.location.href = redirect;
				location.reload();
			}
		},
	},
	computed:{
		isLoggedIn(){
			return (this.user.id != undefined);
		},
		...mapState({
				app_slug: state => state.app_slug,
				user: state => state.user,
		})
	}
}
</script>
