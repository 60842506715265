<template>
	<!-- registration settings -->
	<div class="tsa-registration-settings tsa-box low-padding">
		<div class="tsa-section-title">Registration Page Settings:</div>
		<div class="tsa-data">
			<div class="tsa-data-label">Onboarding Link:</div>
			<div class="tsa-data-value">{{ registrationLink }}</div>
		</div>
		<div class="tsa-data">
			<div class="tsa-data-label">Host Email:</div>
			<div class="tsa-data-value">{{ partner_group.host_email }}</div>
		</div>
		<div class="tsa-data">
			<div class="tsa-data-label">Group Page Banner:</div>
			<div class="tsa-data-value">
				<img :src="groupBannerImage" />
			</div>
		</div>
		<div class="tsa-data">
			<div class="tsa-data-label">One Year Price:</div>
			<div class="tsa-data-value">${{ priceOneYear }}</div>
		</div>
		<div class="tsa-data">
			<div class="tsa-data-label">Three Year Price:</div>
			<div class="tsa-data-value">${{ priceThreeYears }}</div>
		</div>
		<div class="tsa-data">
			<div class="tsa-data-label">Program Summary:</div>
			<div class="tsa-data-value" v-html="partner_group.instructions"></div>
		</div>
		<div class="tsa-data">
			<div class="tsa-data-label">Links Section:</div>
			<div class="tsa-data-value" v-html="partner_group.links"></div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "group-registration-details",
		components: {

		},
		props: {
			partner_group: {
				type: Object,
				default: () => {}
			}
		},
		methods: {

		},
		computed: {
			registrationLink(){
				return process.env.VUE_APP_COMPANY_URL.replace("{slug}",this.partner_group.vhost);
			},
			groupBannerImage(){
				if(this.partner_group.vhost == undefined) return "";
				return `${process.env.VUE_APP_API_URL}resources/group_banner/${this.partner_group.vhost}`;
			},
			priceOneYear(){
				if(this.partner_group.price == undefined) return "";
				return parseFloat(this.partner_group.price).toMoney();
			},
			priceThreeYears(){
				if(this.partner_group.price_three_years == undefined) return "";
				return parseFloat(this.partner_group.price_three_years).toMoney();
			}
		}
	}
</script>