<template>
	<div>
		<Breadcrumb :routes="breadcrumb" />
		<div class="tsa-submit-case">
			<RequestSent v-show="request_sent" />
			<div v-show="!request_sent">
				<Default
					v-if="managedDocument"
					:sample_submission="true"
					@submitted="caseSubmitted" />
				<ManagerRequest
					v-if="managerDocument"
					@submitted="caseSubmitted"
				/>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapState} from 'vuex';
	import Default from "@/components/ContactDocuments/Default.vue";
	import RequestSent from "@/components/ContactDocuments/RequestSent.vue";
	import Breadcrumb from '@/components/Layout/Breadcrumb.vue';
	import ManagerRequest from "@/components/Manager/ManagerRequest";

	export default {
		name: "admin-inbound-process-test",
		components: {
			Default,
			RequestSent,
			ManagerRequest,
			Breadcrumb
		},
		data(){
			return {
				has_initialized: false,
				request_sent: false
			}
		},
		mounted(){
			this.initialize();
		},
		updated(){
			if(!this.has_initialized) this.initialize();
		},
		/**
		* remove bus event listeners before component is destroyed.
		* @return {[type]} [description]
		*/
		beforeUnmount(){
			this.emitter.off('token_ready');
		},
		methods: {
			initialize(){
				this.has_initialized = true;
				
				this.emitter.on('token_ready', () => this.loadDocumentData());
				if(this.jwt_token.access_token != undefined) this.loadDocumentData();
			},
			loadDocumentData(){
				this.emitter.emit('loader',true);
				const document_id = this.$route.params.id;
				//eslint-disable-next-line
				axios.get(`${process.env.VUE_APP_API_URL}admin/company/documents/${document_id}/sample`)
				.then(response => this.handleLoadDocumentData(response.data))
				//eslint-disable-next-line
				.catch(error => console.log(error));
			},
			handleLoadDocumentData(response){
				this.emitter.emit('loader',false);
				this.$store.dispatch('caseData',{
					document: response.document,
					messages: response.messages
				});
			},
			caseSubmitted(response){
				this.request_sent = true;
				this.emitter.emit('alert',response.message);
			},
		},
		computed:{
			breadcrumb(){
				return [
					{display: "Process" ,route: {name: "admin.inbound_process"}},
					{display: `${this.document.name} Process Details`, route: {name:'admin.inbound_process.manage',params: {document_id : this.$route.params.id }}},
					"Sample Case Submission"
				];
			},
			documentName(){
				return this.document.name;
			},
			managedDocument(){
				if(this.document.version != undefined){
					if(this.document.version.document_type == "ticket" || this.document.version.document_type == "solution_support" || this.document.version.document_type == "json") return true;
				}
				return !this.managerDocument;
			},
			managerDocument(){
				if(this.document.version != undefined){
					if(this.document.version.document_type == "manager") {return true;}
				}
				return false;
			},
			...mapState({
				company: state => state.company,
				document: state => state.contact_document,
				jwt_token: state => state.token
			})
		}
	}
</script>