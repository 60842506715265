<template>
	<div class="tsa-partner-groups-agreements tsa-partner-onboarding">
		<DefaultModal
			title="Payment Information"
			@close="closePaymentModal"
			:is_open="show_payment_form">
				<PaymentForm
					@proccessed="continueToNextStep" />	
		</DefaultModal>
		<GroupHeader />
		<div class="onboarding-instructions" v-if="this.onboarding_session.company != undefined">
			<HelpHint :flag="headerFlag" :content="helpContent" /> <span class="page-name">You are a {{ memberType }} Member</span>
		</div>
		<div class="uk-child-width-1-2@m uk-grid-medium" uk-grid>
			<div>
				<div class="tsa-box low-padding" style="min-height: 190px;">
					<div class="tsa-section-title">
						<div v-if="newMember">Accept Legal Agreements <HelpHint flag="limited_accept_legal_agreements" /></div>
						<div v-if="!newMember">Accept Partner Addendum <HelpHint flag="limited_accept_partner_addendum" /></div>
					</div>
					<div class='form-row' v-if="defaultAgreements">
						<input type="checkbox" v-model="code_of_conduct" /> <a href="https://www.tsanet.org/wp-content/uploads/2018/01/code_final.pdf" target="_blank">Code of Conduct - Baseline agreement for all TSANet Members</a>
					</div>
					<div class='form-row' v-if="defaultAgreements">
						<input type="checkbox" v-model="database_license" /> <a href="https://www.tsanet.org/wp-content/uploads/2018/01/DBLicense_final.pdf" target="_blank">Database License - For use of the TSANet Connect system</a>
					</div>
					<div class='form-row' v-if="hasAddendum">
						<input type="checkbox" v-model="addendum" /> <a :href="addendumRoute">Partner Program Addendum - Details for working with this Member</a>
					</div>
					<div class="no-action-required" v-if="!hasAddendum && !defaultAgreements">
						<div class="title">No Action Required</div>
						<p>This group uses your existing {{ memberType }} Addendum.</p>
					</div>
					<div v-if="memberType == 'Basic' || memberType == 'Standard'" class="member-note">
						<strong>Note:</strong> Your Basic Membership does not provide 7*24 collaboration with other members.
					</div>
					<div class="uk-child-width-1-2 uk-grid-small" uk-grid v-show="onboarding.user.first_name == undefined" style="margin-top: 20px;">
						<div :class="formRowClass('first_name')">
							<input type="text" placeholder="Enter Your First Name" v-model="form_data.first_name" />
							<span class="error-block">{{ errors.first_name }}</span>
						</div>
						<div :class="formRowClass('last_name')">
							<input type="text" placeholder="Enter Your Last Name" v-model="form_data.last_name" />
							<span class="error-block">{{ errors.last_name }}</span>
						</div>
					</div>
				</div>
			</div>
			<div>
				<div class="tsa-box low-padding">
					<div class="tsa-section-title">
						Payment <HelpHint flag="limited_payment" />
					</div>
					<div class="no-action-required" v-if="freeMembership">
						<div class="title">No Payment Required</div>
						<p>{{ company.name }} is paying for your Limited Membership. That is a a savings of $500 per year for your company.</p>
					</div>
					<div class="payment-options" v-if="!freeMembership">
						<!-- contract length -->
						<div class="payment-option">
							<div class="option-title">Select 3 Year or 1 Year Option</div>
							<div class="uk-child-width-1-2@m uk-grid-medium" uk-grid>
								<div>
									<div class="form-row checkbox">
										<div>
											<input type="radio" value="three_years" name="contract_length" v-model="contract_length" /> ${{ threeYearPrice }} for 3 Years (10% Discount)
										</div>
									</div>
								</div>
								<div>
									<div class="form-row checkbox">
										<div>
											<input type="radio" value="one_year" name="contract_length" v-model="contract_length" /> ${{ oneYearPrice }} for 1 Year
										</div>
									</div>
								</div>
							</div>
						</div>
						<hr>
						<!-- payment type -->
						<div class="payment-option">
							<div class="option-title">Select Payment Method</div>
							<div class="uk-child-width-1-3@m uk-grid-medium" uk-grid>
								<!-- credit card -->
								<div>
									<div class="form-row checkbox">
										<div>
											<input type="radio" value="credit_card" name="payment_type" v-model="payment_type" /> Credit Card
										</div>
									</div>
								</div>
								<!-- invoice -->
								<div>
									<div class="form-row checkbox">
										<div>
											<input type="radio" value="invoice" name="invoice" v-model="payment_type" /> Send Invoice
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- note -->
						<div class="tsa-note">
							<span class="main">Note: </span> Account will be activated when payment is received.
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="tsa-register-footer">
			<div class='tsa-progress-wrapper' v-if="!isLimited">
				<Progress section="agreements" />
			</div>
			<button class="tsa-btn" @click.prevent="checkAgreementSelected">{{ nextButtonText }}</button>
		</div>
	</div>
</template>
<script>
	import HelpHint from '@/components/Layout/HelpHint.vue';
	import GroupHeader from '@/components/PartnerGroup/Header.vue';
	import Progress from '@/components/PartnerGroup/Progress.vue';
	import PaymentForm from '@/components/Checkout/PaymentForm.vue';
	import {mapState} from 'vuex';
	import DefaultModal from '@/components/Layout/DefaultModal.vue';
	import RegistrationSaver from "../../Mixins/RegistrationSaver.vue";
	import _ from 'lodash';
	export default {
		name : 'onboarding-agreements',
		components: {
			HelpHint,
			GroupHeader,
			Progress,
			PaymentForm,
			DefaultModal
		},
		mixins:[
			RegistrationSaver
		],
		data(){
			return {
				form_data: {
					contract_length: "one_year",
					payment_type: "credit_card",
					first_name : "",
					last_name : ""
				},
				show_payment_form: false,
				errors : {}
			}
		},
		mounted(){
			if(!this.partner_group || !this.partner_group.id){
				this.loadRegistrationData();
			}
		},
		methods: {
			checkAgreementSelected(){
				// if no addendum ignore
				if(!this.hasAddendum) this.addendum = true;
				if(!this.defaultAgreements){
					this.code_of_conduct = true;
					this.database_license = true;
				}

				if(this.database_license &&
					this.code_of_conduct &&
					this.addendum  ){
					this.emitter.emit('loader',true);
					// passess validation create process document.
					let data = _.cloneDeep(this.onboarding);
					data.session_token = this.session_token;
					if(this.onboarding.user.first_name == undefined){
						data.user.first_name = this.form_data.first_name;
						data.user.last_name = this.form_data.last_name;
					}
					//eslint-disable-next-line
					axios.post(`${process.env.VUE_APP_API_URL}onboarding/${this.partner_group.vhost}/agreements`,data)
							.then(response => this.onSaveAgreements(response.data))
							//eslint-disable-next-line
						    .catch(error => this.handleErrors(error.response.data.errors));
				}else{
					this.emitter.emit('alert',{
						type: 'warning',
						message: "You must review and accept all agreements before continuing."
					});
				}
			},
			handleErrors (errors){
				// clear all errors
				this.errors = {};
				for(var e in errors){
					this.errors[e] = errors[e][0];
				}
				this.emitter.emit('alert',{
					type: 'warning',
					message: "There was an error with your submission. Please check your entry and try again."
				});
			},
			onSaveAgreements(response){
				this.emitter.emit('loader',false);
				if(response.valid){
					if(this.payment_type == "credit_card" && !this.freeMembership){
						this.show_payment_form = true;
					}else{
						this.continueToNextStep();
					}
				}
			},
			registerLimitedMember(){
				this.emitter.emit('loader',true);
				const data = {
					company_id : this.onboarding_session.company.id,
					session_token: this.session_token
				};
				//eslint-disable-next-line
				axios.post(`${process.env.VUE_APP_API_URL}onboarding/${this.partner_group.vhost}/register/limited`,data)
				.then(response => this.handleLimitedRegister(response.data))
				//eslint-disable-next-line
	    		.catch(error => console.log('error'));
			},
			/**
			 * if everything saved
			 * reset onboarding data and go to success.
			 * @param  {[type]} response [description]
			 * @return {[type]}          [description]
			 */
			handleLimitedRegister(response){
				this.emitter.emit('loader',false);
				if(response.valid){
					this.$store.dispatch('resetOnboarding');
					this.$router.push({name:'onboarding.success'});
				}
			},
			/**
			 * 
			 * @return {[type]} [description]
			 */
			continueToNextStep(){
				this.show_payment_form = false;
				if(this.newMember){
					this.$router.push({name: 'onboarding.process'});
				}else{
					// not limited user continue to next step
					if(!this.isLimited){
						this.$router.push({name: 'onboarding.choose-process'});
					}else{
						this.registerLimitedMember();
					}
				}
			},
			closePaymentModal(){
				this.show_payment_form = false;
			},
			/**
			 * 'form-row' add 'has-error' class if field has error
			 * @param  {[type]} field [description]
			 * @return {[type]}       [description]
			 */
			formRowClass(field){
				let string = "form-row";
				if(this.errors[field] != undefined ) string += " has-error";
				return string;
			},
		},
		computed: {
			/**
			 * no price for year and three year membership
			 * @return {[type]} [description]
			 */
			freeMembership(){
				return this.partner_group.price == 0 && this.partner_group.price_three_years == 0;
			},
			hasAddendum(){
				if(this.partner_group.community == undefined) return false;
				// group has addendum
				if(this.partner_group.community.addendum != null){
					// new member
					if(this.newMember){
						return true;
					}else{
						// existing member.
						if(!this.isLimited){
							return this.partner_group.force_addendum;
						}else{
							return true;
						}
					}
				}
				return false;
			},
			/**
			 * check if user has to agree to default agreements
			 * @return {[type]} [description]
			 */
			defaultAgreements() {
				if(this.newMember) return true;
				// if existing members
				return false;
			},
			newMember(){
				return this.onboarding_session.company == null;
			},
			memberType(){
				if(this.newMember) return "";
				return this.onboarding_session.company.membership_type.ucfirst();
			},
			nextButtonText(){
				return (this.isLimited)? "Submit" : "Next";
			},
			database_license: {
				get() { return this.$store.state.onboarding.agreements.database_license},
				set(value){ 
					this.$store.commit('updateOnboardingAgreement',{
					field: 'database_license',
					value: value
					});
				}
			},
			code_of_conduct: {
				get() { return this.$store.state.onboarding.agreements.code_of_conduct},
				set(value){ 
					this.$store.commit('updateOnboardingAgreement',{
					field: 'code_of_conduct',
					value: value
					});
				}
			},
			addendum: {
				get() { return this.$store.state.onboarding.agreements.addendum},
				set(value){ 
					this.$store.commit('updateOnboardingAgreement',{
					field: 'addendum',
					value: value
					});
				}
			},
			contract_length: {
				get() { return this.$store.state.onboarding.payment.contract_length},
				set(value){ 
					this.$store.commit('updateOnboardingPayment',{
					field: 'contract_length',
					value: value
					});
				}
			},
			payment_type: {
				get() { return this.$store.state.onboarding.payment.payment_type},
				set(value){ 
					this.$store.commit('updateOnboardingPayment',{
					field: 'payment_type',
					value: value
					});
				}
			},
			headerFlag(){
				return (this.memberType == "Limited")? 'limited_agreements_member_type' : 'agreements_member_type';
			},
			isLimited(){
				return (this.memberType == "Limited");
			},
			addendumRoute(){
				return `${process.env.VUE_APP_API_URL}onboarding/${this.partner_group.vhost}/addendum`;
			},
			oneYearPrice(){
				if(this.partner_group.price == undefined) return "";
				return this.partner_group.price;
			},
			threeYearPrice(){
				if(this.partner_group.price_three_years == undefined) return "";
				return this.partner_group.price_three_years;
			},
			helpContent(){
				if(this.onboarding_session.company.id == undefined) return {};
				return {
					level: this.onboarding_session.company.membership_type.ucfirst()
				};
			},
			...mapState({
				company: state => state.company,
				partner_group: state => state.partner_group,
				session_token: state => state.onboarding_session_token,
				onboarding: state => state.onboarding,
				onboarding_session: state => state.onboarding_session
			})
		}
	}
</script>