<template>
	<div :class="containerClass">
		<div class="tsa-modal-window tsa-internal-notes">
			<i class="fas fa-times tsa-close" @click="closeModal"></i>
			<div class="tsa-section-title">Internal Notes</div>
			<p class="page-instructions">Instructions for <strong>Your</strong> engineers to use when contacting this Member.</p>
			<div v-if="!isCaller">
				<div :class="formRowClass('note')">
					<wysiwyg-editor v-model="notes"></wysiwyg-editor>
					<span class="error-block">{{ errors.note }}</span>
				</div>
				<div class="form-row">
					<button class="tsa-btn" @click.prevent="createNote">Save Note</button>
				</div>
			</div>
			<div v-else v-html="notes" class="caller-internal-note"></div>
		</div>
	</div>
</template>

<script>
	import {mapState} from 'vuex';
	import WysiwygEditor from "@/components/Form/WysiwygEditor.vue";

	export default {
		name: 'internal-notes-modal',
		props:{
			is_open: {
				type: Boolean,
				default: false
			}
		},
		components: {
			WysiwygEditor
		},
		data(){
			return {
				notes: "",
				note: {},
				errors: {},
				note_to_delete: {},
			}
		},
		mounted(){
			this.cloneNote();
		},
		methods:{
			closeModal(){
				this.$emit('close');
			},
			/**
			 * send updated notes to api
			 * @return {[type]} [description]
			 */
			createNote(){
				this.emitter.emit('loader',true);
				const data = {
					location: "contact_document",
					document_id: this.contact_document.id,
					note: this.notes
				}
				// eslint-disable-next-line
				axios.post(`${process.env.VUE_APP_API_URL}caller/notes/store`,data)
					.then(response => this.handleCreateNotes(response.data))
					//.catch(error => console.log(error))
					.catch(error => this.handleErrors(error.response.data.errors));
			},
			/**
			 * close window.
			 * @return {[type]} [description]
			 */
			handleCreateNotes(response){
				this.emitter.emit('loader',false);
				// update notes in store.
				if(response.error != undefined){
					this.emitter.emit('alert',{message: response.error, type: "warning"});
				}else{
					this.$store.dispatch('newDocumentNote',response.note);
					this.closeModal();
				}
			},
			/**
			 * handle validation errors.
			 * @param  {[type]} errors [description]
			 * @return {[type]}        [description]
			 */
			handleErrors (errors){
				// hide loader
				this.emitter.emit('loader',false);
				// clear all errors
				this.errors = {};
				for(var e in errors){
					this.errors[e] = errors[e][0];
				}
			},
			/**
			 * 'form-row' add 'has-error' class if field has error
			 * @param  {[type]} field [description]
			 * @return {[type]}       [description]
			 */
			formRowClass(field){
				let string = "form-row";
				if(this.errors[field] != undefined ) string += " has-error";
				return string;
			},
			cloneNote(){
				if(this.document_notes != null){
					this.note = this.document_notes;
					this.notes = this.note.note;
				}
			},
			clearNotes(){
				this.node = {};
				this.notes = "";
			},
		},
		computed:{
			containerClass(){
				let classList = "tsa-modal";
				if(this.is_open) classList += " show";
				return classList;
			},
			isCaller(){
				return this.user.role == "caller";
			},
			...mapState({
				contact_document: state => state.contact_document,
				document_notes: state => state.document_notes,
				user: state => state.user
			})
		},
		watch:{
			document_notes(){
				this.cloneNote();
			}
		}
	}
</script>