<template>
	<div class="tsa-key-contact">
		<i class="fas fa-user"></i>
		<span class="tsa-key-contact-name">{{ contact.first_name }} {{ contact.last_name }}</span> &mdash; {{ contact.city }}, {{ contact.country.country_code }} / Department
	</div>
</template>

<script>

export default {
	name: 'tsa-key-contacts',
	props:{
		contact:{
			type: Object,
			default: () => {}
		}
	},
	components: {
	}
}
</script>
