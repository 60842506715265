<template>
	<div :class="containerClass">
		<div class="tsa-modal-window tsa-forgot-password">
			<i class="fas fa-times tsa-close" @click="closeModal"></i>	
			<h2>Forgot Password</h2>
			<p>Please provide the email address associated with your account. You will receive an email with instructions on how to reset your password.</p>
			<form @submit.prevent="sendResetLink">
				<div :class="formRowClass('email')">
					<input type="email" placeholder="Enter Email Address" v-model="form_data.email" />
					<span class="error-block">{{ errors.email }}</span>
				</div>
				<div class="form-row">
					<button @click.prevent="sendResetLink" class='tsa-btn pull-right'>Send Reset Link</button>
				</div>
			</form>
			<slot></slot>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'forgot-password',
		props:{
			is_open: {
				type: Boolean,
				default: false
			}
		},
		data(){
			return {
				form_data:{
					email: ""
				},
				errors: {}
			}
		},
		components: {

		},
		methods:{
			closeModal(){
				this.$emit('close');
			},
			sendResetLink(){
				this.emitter.emit('loader',true);
				//eslint-disable-next-line
				axios.post(`${process.env.VUE_APP_API_URL}auth/forgot_password`,this.form_data)
					.then(response => this.handleSendResetLink(response.data))
					//eslint-disable-next-line
				    .catch(error => this.handleErrors(error.response.data.errors));
			},
			handleSendResetLink(response){
				this.emitter.emit('loader',false);
				if(response.success){
					this.emitter.emit('alert',response.success);
					this.$emit('close');
				}else{
					this.emitter.emit('alert',{message: response.error, type: "warning"});
				}
			},
			/**
			 * hide loader and add errors to erros array so they're displayed
			 * @param  {[type]} errors [description]
			 * @return {[type]}        [description]
			 */
			handleErrors (errors){
				this.emitter.emit('loader',false);
				// clear all errors
				this.errors = {};
				for(var e in errors){
					this.errors[e] = errors[e][0];
				}
			},
			/**
			 * 'form-row' add 'has-error' class if field has error
			 * @param  {[type]} field [description]
			 * @return {[type]}       [description]
			 */
			formRowClass(field){
				let string = "form-row";
				if(this.errors[field] != undefined ) string += " has-error";
				return string;
			}
		},
		computed:{
			containerClass(){
				let classList = "tsa-modal";
				if(this.is_open) classList += " show";
				return classList;
			},
		}
	}
</script>