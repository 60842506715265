<template>
	<div class="manage-users">
		<div class="tsa-admin-header">
			User Details &mdash; <span class="page-name">{{ headerDisplay }}</span>
		</div>
		<Breadcrumb :routes="breadcrumb" />
		<UserForm
			:company="company"
			:user="user"
			:communities="communities"
			:access="access"
			@updated="onUserUpdated" />
	</div>
</template>

<script>
// import any required components
import {mapState} from 'vuex';
import UserForm from '@/components/Admin/UserForm.vue';
import Breadcrumb from '@/components/Layout/Breadcrumb.vue';

// export component
export default {
	name: "admin-manage-user",
	// properties that the component will receive from parent
	props: {

	},
	// components to use in template
	components:{
		UserForm,
		Breadcrumb
	},
	// component data
	data() {
		return {
			user: {},
			form_data: {

			},
			access: [],
			communities: [],
		};
	},
	mounted(){
		this.initialize();
	},
	updated(){
		if(!this.has_initialized) this.initialize();
	},
	/**
     * remove bus event listeners before component is destroyed.
     * @return {[type]} [description]
     */
    beforeUnmount(){
		this.emitter.off('token_ready');
    },
	methods: {
		initialize(){
			this.has_initialized = true;
			
			this.emitter.on('token_ready', () => this.loadProfileFormData());
			if(this.jwt_token.access_token != undefined) this.loadProfileFormData();
		},
		/**
		 * load current user data
		 * use param from route
		 * @return {[type]} [description]
		 */
		loadUser(){
			this.emitter.emit('loader',true);
			//eslint-disable-next-line
			axios.get(`${process.env.VUE_APP_API_URL}admin/company/users/${this.$route.params.id}`)
			.then(response => this.handleGetUser(response.data))
			//eslint-disable-next-line
			.catch(error => console.log(error));
		},
		handleGetUser(response){
			this.emitter.emit('loader',false);
			if(response.error != undefined){
				this.emitter.emit('alert',{message: response.error,type:'warning'});
				this.$router.push({name : 'admin.users'});
			}else{
				this.user = response.user;
				this.access = response.user.group_access;
				this.communities = response.communities;
			}
		},
		loadProfileFormData(){
			// eslint-disable-next-line
			axios.get(`${process.env.VUE_APP_API_URL}users/profile_form_data`)
			.then(response => this.onProfileFormData(response.data))
			// eslint-disable-next-line
			.catch(error => console.log(error));
		},
		/**
		 * save form data in store
		 * @return {[type]} [description]
		 */
		onProfileFormData(response){
			if(this.$route.params.id != 0) this.loadUser();
			this.$store.dispatch('profileFormData',response);
		},
		onUserUpdated(response){
			let user = (response.user == undefined)? response : response.user;
			// update store.
			if(user.id == this.current_user.id) this.$store.dispatch('user',{'user' : user});
			// redirect
			if(response.save_and_new){
				// redirect to new user.
				this.$router.push({name: "admin.user.manage", params: {id: 0}});
			}else{
				// back to users list
				this.$router.push({name: 'admin.users'});
			}
		}
	},
	computed:{
		headerDisplay(){
			return (parseInt(this.$route.params.id) === 0)? "New User" : `${this.user.first_name} ${ this.user.last_name }`;
		},
		breadcrumb(){
			return [
				{display: "Users" ,route: {name: "admin.users"}},
				this.headerDisplay
			];
		},
		...mapState({
			company: state => state.company,
			jwt_token : state => state.token,
			current_user: state => state.user
		})
	}
}
</script>