<template>
	<div class="tsa-search-partner limited-dashboard">
		<div class="tsa-box">
			<div class="uk-container">
				<div uk-grid class="uk-child-width-1-2@m uk-grid-medium">
					<div>
						<div class="tsa-section-title">Collaborate with Your Partners</div>
						<MyPartners />
					</div>
					<div>
						<div class="tsa-section-title">Add Partners</div>
						<SearchPartnerBox />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import SearchPartnerBox from '@/components/Dashboard/SearchPartnerBox.vue';
	import MyPartners from '@/components/Limited/MyPartners.vue';
	export default {
		name: 'search-partner',
		data(){
			return {
				member: {},
			}
		},
		components:{
			SearchPartnerBox,
			MyPartners,
		},
		methods:{
		}
	}
</script>