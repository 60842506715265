<template>
	<router-link :to="`/admin/users/${cell_data.id}`">
		<i class="fas fa-eye"></i>
	</router-link>
</template>

<script>
	export default {
		name: "admin-partner-group-delete-user",
		components:{

		},
		props:{
			cell_data:{
				type: Object,
				default: () => {}
			}
		},
		methods:{
			/**
			 * confirm that user wants to delete document.
			 * @return {[type]} [description]
			 */
			removeAccess(){
				this.emitter.emit('confirm',{
					message: `Are you sure you want to remove access for ${this.cell_data.user.first_name} ${this.cell_data.user.last_name}?`,
					flag: `confirm_delete_group_user`,
					data: this.cell_data.user_id
				});
			}
		}
	}
</script>