<template>
	<div>
		<div ref="editor" v-html="baseContent"></div>
	</div>
</template>

<script>

export default {
	name: "QuillEditor",

	props: {
		modelValue: String,
		placeholder:{
			type: String,
			default: ''
		}
	},
	components: {},
	data() {
		return {
			quill:null,
			updated: false,
			baseContent:""
		}
	},
	mounted() {
		if(this.modelValue && this.modelValue !== ''){
			this.baseContent = this.modelValue;
		}
		setTimeout(() => this.buildQuill(), 1);
	},
	beforeUnmount() {
		if(this.quill){
			this.quill.disable();
			this.quill.off('*');
			this.quill = null;
		}
	},
	methods: {
		buildQuill(){
			// library imported from CDN in public/index.html .. but we're making sure.
			// eslint-disable-next-line no-undef
			if(Quill && this.$refs.editor){
				// eslint-disable-next-line no-undef
				this.quill = new Quill(this.$refs.editor, {
					theme: 'snow',
					placeholder: this.placeholder,
					modules:{
						toolbar: [
							['bold','italic','underline','strike','link'],
							[{ 'list': 'ordered'}, { 'list': 'bullet' }],
						]
					}
				});
				this.quill.on('text-change', () => this.changeListener());
			}
		},
		changeListener(){
			this.$emit('change');
		},
		getContent(){
			return this.quill.getSemanticHTML();
		}
	},
	watch: {
	}
}
</script>