<template>
	<div class="manage-document">
		<Breadcrumb :routes="breadcrumb" />
		<div>
			<div class="tsa-admin-header">
				{{company.name}} <span class="page-name">Process Details</span>
				<button class="tsa-btn tsa-btn-secondary" @click="saveDocument">Save</button>
			</div>
			<div uk-grid class="uk-child-width-1-2@m uk-grid-small">
				<div>
					<div :class="formRowClass('status')" class="with-label">
						<label>Status:</label>
						<select v-model="form_data.status">
							<option value='approved'>Approved</option>
							<option value='pending'>Pending</option>
							<option value='rejected'>Rejected</option>
						</select>
						<span class="error-block">{{ errors.status }}</span>
					</div>
					<div :class="formRowClass('parent_option_name')" class="with-label">
						<label>Variant:</label>
						<input type="text" placeholder="Enter Variant Name" v-model="form_data.parent_option_name" />
						<span class="error-block">{{ errors.parent_option_name }}</span>
					</div>
				</div>
				<div>
					<div :class="formRowClass('title')" class="with-label">
						<label>Process Name:</label>
						<input type="text" placeholder="Enter Title" v-model="form_data.title" />
						<span class="error-block">{{ errors.title }}</span>
					</div>
				</div>
			</div>
		</div>
		<div class="tsa-admin-header" style="margin-top: 20px;"><span class="page-name">Admin Note</span></div>
		<div class="form-row"  style="margin-bottom: 20px;">
			<wysiwyg-editor v-model="form_data.admin_note"></wysiwyg-editor>
		</div>
		<div class="tsa-admin-header" style="margin-top:20px;">
			<span class="page-name">Child Processes</span>
			<button class="tsa-btn" @click="addRow">Add Row</button>
		</div>
		<div>
			<ParentChildRow
				v-for="(row_data,index) in form_data.linkages"
				:key="index"
				:documents="documents"
				:errors="errors"
				:index="index"
				@update="onRowUpdate"
				@delete="onDeleteRow"
				:row_data="row_data" />
		</div>
		
	</div>
</template>

<script>
	import {mapState} from 'vuex';
	import Breadcrumb from '@/components/Layout/Breadcrumb.vue';
	import ParentChildRow from '@/components/SuperAdmin/InboundProcess/ParentChildRow.vue';
	import _ from 'lodash';
	import WysiwygEditor from "@/components/Form/WysiwygEditor.vue";

	export default {
		name : 'parent-document-view',
		props: {
			process_document: {
				type: Object,
				default: () => {}
			}
		},
		components: {
			Breadcrumb,
			ParentChildRow,
			WysiwygEditor
		},
		data(){
			return {
				company: {},
				mode: "manage",
				errors: {},
				form_data: {
					title: "",
					status: "approved",
					document_type: "parent",
					parent_option_name : "",
					linkages : [
						{
							id : null,
							option_name : "",
							contact_document_id : "",
							deleted : false
						}
					],
					admin_note : ""
				},
				documents : [],
			}
		},
		mounted(){
			this.initialized();
		},
		updated(){
			this.initialized();
		},
		/**
		* remove bus event listeners before component is destroyed.
		* @return {[type]} [description]
		*/
		beforeUnmount(){
			this.emitter.off('token_ready');
		},
		methods: {
			initialized(){
				if(this.is_initialized) return;
				this.is_initialized = true;
				if(this.process_document.id != undefined) this.cloneDocument();
				this.emitter.on('token_ready', () => this.getFormData());
				if(this.jwt_token.access_token != undefined) this.getFormData();
			},
			/**
			 * get current company data
			 * @return {[type]} [description]
			 */
			getFormData(){
				const company_id = this.$route.params.company_id;
				//eslint-disable-next-line
				axios.get(`${process.env.VUE_APP_API_URL}super_admin/companies/${company_id}/contact_documents/form_data/parent`)
				.then(response => this.handleFormData(response.data))
				//eslint-disable-next-line
				.catch(error => console.log(error));
			},
			handleFormData(response){
				this.company = response.company;
				this.documents = response.documents;
			},
			saveDocument(){
				this.emitter.emit('loader',true);
				const company_id = this.$route.params.company_id,
				document_id = this.$route.params.document_id,
				url = `${process.env.VUE_APP_API_URL}super_admin/companies/${company_id}/contact_documents/${document_id}`;
				//eslint-disable-next-line
				axios.post(url,this.form_data)
					.then(response => this.handleSaveDocument(response.data))
					//eslint-disable-next-line
					.catch(error => this.handleErrors(error.response.data.errors));
			},
			/**
			 * show errors or redirect to group details.
			 * @param  {[type]} response [description]
			 * @return {[type]}          [description]
			 */
			handleSaveDocument(response){
				this.emitter.emit('loader',false);
				if(response.error != undefined){
					this.emitter.emit('alert',{
						type: 'error',
						message: response.error
					});
					return;
				}
				// redirect to details
				this.$router.push({name:"super_admin.company.detail",params:{company_id: this.$route.params.company_id}});
			},
			handleErrors (errors){
				this.emitter.emit('loader',false);
				// clear all errors
				this.errors = {};
				for(var e in errors){
					this.errors[e] = errors[e][0];
				}
			},
			/**
			 * 'form-row' add 'has-error' class if field has error
			 * @param  {[type]} field [description]
			 * @return {[type]}       [description]
			 */
			formRowClass(field){
				let string = "form-row";
				if(this.errors[field] != undefined ) string += " has-error";
				return string;
			},
			cloneDocument(){
				this.form_data.status = this.process_document.version.status;
				this.form_data.title = this.process_document.name;
				this.form_data.parent_option_name = this.process_document.parent_option_name;
				if(this.process_document.doc_linkages != undefined){
					let linkages = [];
					this.process_document.doc_linkages.forEach(row => {
						row.deleted = false;
						linkages.push(row);
					});
					this.form_data.linkages = linkages;
				}
				this.form_data.admin_note = (this.process_document.version.admin_note == null)? "" :  this.process_document.version.admin_note;
			},
			/**
			 * save data in correct index.
			 * @param  {[type]} data [description]
			 * @return {[type]}      [description]
			 */
			onRowUpdate(data){
				this.form_data.linkages[data.index].option_name = data.option_name;
				this.form_data.linkages[data.index].contact_document_id = data.contact_document_id;
			},
			/**
			 * remove row from array list
			 * @param  {[type]} index [description]
			 * @return {[type]}       [description]
			 */
			onDeleteRow(index){
				//this.linkages.splice(index,1);
				let clone = _.cloneDeep(this.form_data.linkages);
				clone[index].deleted = true;
				this.form_data.linkages = clone;
			},
			/**
			 * add new empty item top array.
			 */
			addRow(){
				this.form_data.linkages.push({
					id : null,
					option_name : "",
					contact_document_id : "",
					deleted : false
				});
			}
		},
		watch: {
			process_document(){
				this.cloneDocument();
			},
		},
		computed : {
			breadcrumb(){
				return [
					{display: "Companies" ,route: {name: "super_admin.companies"}},
					{display: this.company.name, route: {name: "super_admin.company.detail",params: {company_id : this.company.id}}},
					"Process Details"
				];
			},
			...mapState({
				jwt_token: state => state.token
			})
		}
	}
</script>