<template>
	<div class="tsa-dashboard">
		<MissingPartner
			:is_open="missing_partner"
			@close="onCloseMissingPartner" />
		<InviteMember
			:member="member"
			:is_open="invite_member"
			@close="onCloseInvite" />
		<!-- premium basic search partners and favorites -->
		<SearchPartner v-if="!isLimited" />
		<!-- limited partners and search partner -->
		<LimitedPartners v-if="isLimited" />
		<CompanyNotes v-if="!isLimited" />
		<active-cases></active-cases>
		<div><TsaNews /></div>
		<div class="tsa-bottom-content uk-child-width-1-2@m uk-grid-small" uk-grid>
			<div>
				
			</div>
			
		</div>
	</div>
</template>
<script>
	import SearchPartner from '@/components/Dashboard/SearchPartner.vue';
	import CompanyNotes from '@/components/Dashboard/CompanyNotes.vue';
	import TsaNews from '@/components/Dashboard/TsaNews.vue';
	import InviteMember from '@/components/Dashboard/InviteMember.vue';
	import MissingPartner from '@/components/Dashboard/MissingPartner.vue';
	import ActiveCases from '@/components/Dashboard/ActiveCases.vue';
	// limited member components
	import LimitedPartners from '@/components/Limited/LimitedPartners.vue';


	import {mapState} from 'vuex';

	export default {
		name: 'dashboard',
		components:{
			SearchPartner,
			CompanyNotes,
			TsaNews,
			InviteMember,
			MissingPartner,
			LimitedPartners,
			ActiveCases
		},
		data(){
			return {
				member: {},
				// show invite member window
				invite_member: false,
				// show missing partner window
				missing_partner: false,
				is_initialized: false
			}
		},
		mounted(){
			//this.loadDashboardData();
			this.initialize();
		},
		updated(){
			this.initialize();
		},
		/**
		* remove bus event listeners before component is destroyed.
		* @return {[type]} [description]
		*/
		beforeUnmount(){
			this.emitter.off('invite_member');
			this.emitter.off('missing_partner');
			this.emitter.off('token_ready');
		},
		methods:{
			initialize(){
				if(this.is_initialized) return;
				this.is_initialized = true;
				this.emitter.on('invite_member', member => this.onInviteMember(member));
				this.emitter.on('missing_partner', () => this.onMissingPartner());
				this.emitter.on('token_ready', () => this.loadDashboardData());
				if(this.jwt_token.access_token != undefined) this.loadDashboardData();
			},
			/**
			 * get company directory
			 * @return {[type]} [description]
			 */
			loadDashboardData(){
				this.$store.commit('companyDirectoryAvailable',false);
				// eslint-disable-next-line
				axios.get(`${process.env.VUE_APP_API_URL}caller/company_directory`)
				.then(response => this.companyDirectoryResponse(response.data))
				// eslint-disable-next-line
				.catch(error => console.log(error));
			},
			/**
			 * company directory loaded
			 * @param  {[type]} response [description]
			 * @return {[type]}          [description]
			 */
			companyDirectoryResponse(response){
				this.$store.commit('companyDirectoryAvailable',true);
				this.$store.dispatch('companyDirectory',response.directory);
				// get favorites
				this.loadFavorites();
			},
			/**
			 * get list of favorite contact documents.
			 * @return {[type]} [description]
			 */
			loadFavorites(){
				//eslint-disable-next-line
				axios.get(`${process.env.VUE_APP_API_URL}caller/favorites`)
				.then(response => this.handleLoadFavorites(response.data))
				//eslint-disable-next-line
				.catch(error => console.log(error));
			},
			/**
			 * save favorites to store.
			 * @return {[type]} [description]
			 */
			handleLoadFavorites(response){
				this.$store.dispatch('favorites',response);
			},
			/**
			 * show invite member screen
			 * @param  {[type]} member [description]
			 * @return {[type]}        [description]
			 */
			onInviteMember(member){
				this.member = member;
				this.invite_member = true;
			},
			/**
			 * close invite member modal.
			 * @return {[type]} [description]
			 */
			onCloseInvite(){
				this.invite_member = false;
			},
			/**
			 * close missing partner modal
			 * @return {[type]} [description]
			 */
			onCloseMissingPartner(){
				this.missing_partner = false;
			},
			// show missing partner 
			onMissingPartner(){
				this.missing_partner = true;
			},
		},
		computed:{
			isLimited(){
				return this.company.membership_type == 'limited';
			},
			...mapState({
				jwt_token : state => state.token,
				company: state => state.company,
			})
		}
	}
</script>