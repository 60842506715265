<template>
    <div class="alert-modal main-modal" v-bind:class="modalClass">
        <div class="modal-window">
            <div class="modal-header">
                <div class="pull-right close" @click="onOk"><i class="fa fa-times"></i></div>
                <slot>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat
                </slot>
            </div>
            <div class="modal-body">
                <div class="icon" v-bind:class="iconContainerClass">
                    <i class="fas" v-bind:class="iconClass"></i>
                </div>
            </div>
            <div class='footer'>
                <a href="" @click.prevent="onOk" ref="close_btn">{{ button_text }}</a>
            </div>
        </div>
    </div>
</template>

<script>
    // import any required components

    // export component
    export default {
        // properties that the component will receive from parent
        props: {
            button_text:{
                type:String,
                default:"OK",
            },
            is_open:{
                type:Boolean,
                default:false,
            },
            icon:{
                type:String,
                default:""
            },
            type:{
                type:String,
                default:"success"
            }
        },
        // components to use in template
        components:{

        },
        // component data
        data() {
            return {};
        },
        // component was mounted
        mounted(){
            
        },
        // any methods needed for this component
        methods: {
            onOk(){
                this.$emit('ok');
            },
        },
        // computed properties
        computed:{
            modalClass(){
                return this.type + ((this.is_open)? " open" : "");
            },
            iconClass(){
                if(this.icon.length > 0) return "fa-" + this.icon;
                let string = "";
                switch(this.type){
                    case "warning":
                    case "error":
                        string = "fa-exclamation-circle";
                    break;
                    case "success":
                        string = "fa-check-circle";
                    break;
                    case "delete":
                        string = "fa-trash";
                    break;
                }
                return string;
            },
            iconContainerClass(){
                return (this.is_open)? "pulse_in" : "";
            }
        },
        // property watchers
        watch:{
            is_open(){
                if(this.is_open){
                    var self = this;
                    setTimeout(function() {
                        self.$refs.close_btn.focus();
                    },100);
                    
                }
            }
        }
    }
</script>