<template>
	<div :class="formRowClass">
		<input
			v-if="requirement.requirement.type == 'integer'"
			type="number"
			:placeholder="placeholder"
			v-model="field_data" />
		<input
			v-if="requirement.requirement.type == 'email'"
			type="email"
			:placeholder="placeholder"
			v-model="field_data" />
		<input
			v-if="requirement.requirement.type == 'url'"
			type="text"
			:placeholder="placeholder"
			v-model="field_data" />
		<textarea
			v-if="requirement.requirement.type == 'text'"
			:placeholder="placeholder"
			v-model="field_data" />
		<span class="error-block">{{ error }}</span>
	</div>
</template>
<script>
	export default {
		name: 'requirement-field',
		components: {
		},
		props:{
			requirement: {
				type: Object,
				default: () => {}
			},
			error: {
				type: String,
				default: ""
			},
			disabled: {
				type: Boolean,
				default: false
			}
		},
		data(){
			return {
				field_data: ""
			}
		},
		methods: {
		},
		computed: {
			formRowClass(){
				let string = "form-row";
				if(this.error.length > 0) string += " has-error";
				return string;
			},
			placeholder(){
				let ph = `Enter ${this.requirement.requirement.label}`;
				if(!this.requirement.required) ph += " (Optional)";
				return ph;
			}
		},
		watch:{
			field_data(){
				this.$emit('change',{
					'requirement_id' : this.requirement.requirement_id,
					'data' : this.field_data
				});
			}
		}
	}
</script>