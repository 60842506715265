<template>
	<div :class="containerClass">
		<div class="tsa-modal-window tsa-group-documents">
			<i class="fas fa-times tsa-close" @click="closeModal"></i>
			<div class="tsa-section-title">Group Documents</div>
			<p>Below are documents and links related to this group.</p>
			<div class="tsa-list">
				<DataTable 
					:searchable="false"
					:table_data="resources"
					:table_headers="table_headers"
				/>
			</div>
			<!--div class="upload-new">
				<div class="tsa-section-title">Upload a New Document</div>
				<div class="uk-child-width-1-2@m uk-grid-small" uk-grid>
					<div class="form-row">
						<input type="text" v-model="form_data.name" placeholder="Enter Document Name" />
					</div>
					<div class="form-row">
						<FileUpload />
					</div>
				</div>
				<div class="form-row">
					<button @click="sendFile">Upload</button>
				</div>
			</div-->
		</div>
	</div>
</template>

<script>
	import {mapState} from 'vuex';
	//import FileUpload from '@/components/Form/FileUpload.vue';
	import DataTable from '@/components/Table/DataTable.vue';
	import DownloadDocument from '@/components/Admin/PartnerGroups/DownloadDocument.vue';

	export default {
		name: 'group-documents-modal',
		props:{
			is_open: {
				type: Boolean,
				default: false
			}
		},
		data(){
			return {
				form_data: {
					name: ""
				},
				table_headers: {
					name: {
						display: "Name",
						component: DownloadDocument
					},
					document_type: {
						display: "Type",
					},
				},
			}
		},
		components: {
			//FileUpload,
			DataTable
		},
		mounted(){
		},
		methods:{
			closeModal(){
				this.$emit('close');
			},
			sendFile(){
				
			}
		},
		computed:{
			resources(){
				if(this.contact_document.files == undefined) return [];
				return this.contact_document.files;	
			},
			containerClass(){
				let classList = "tsa-modal";
				if(this.is_open) classList += " show";
				return classList;
			},
			...mapState({
				contact_document: state => state.contact_document
			})
		},
		watch:{
		}
	}
</script>