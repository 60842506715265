<template>
	<div class="tsa-company-notes">
		<ManageNote
			@close="closeModal"
			:is_open="modal_open"
		/>
		<a
			v-if="(user.type == 'admin' || user.role =='business_manager' || user.role == 'program_manager')  && user.company_id == company.id"
			href="#" @click.prevent="manageNote"><i class="fas fa-edit"></i></a>
		Company Note:
		<div v-html="companyNote" />
	</div>	
</template>

<script>
	import {mapState} from 'vuex';
	import ManageNote from '@/components/Dashboard/ManageNote';
	export default {
		name: '',
		components: {
			ManageNote
		},
		methods: {
			manageNote(){
				this.modal_open = true;
			},
			closeModal(){
				this.modal_open = false;
			}
		},
		data(){
			return {
				modal_open: false
			}
		},
		computed:{
			companyNote(){
				return (this.note == null)? "N/A" : this.note.note;
			},
			...mapState({
				company: state => state.company,
				note: state => state.company.note,
				user: state => state.user
			})
		}
	}
</script>