<template>
	<span>
		<a href="#"
			@click.prevent="deleteDocument"
			v-if="cell_data.company_id == company.id"
		><i class="fas fa-trash-alt danger" ></i></a>
	</span>
</template>

<script>
	import {mapState} from 'vuex';
	
	export default {
		name: "admin-partner-group-delete-document",
		components:{

		},
		props:{
			cell_data:{
				type: Object,
				default: () => {}
			}
		},
		methods:{
			/**
			 * confirm that user wants to delete document.
			 * @return {[type]} [description]
			 */
			deleteDocument(){
				this.emitter.emit('confirm',{
					message: `Are you sure you want to delete ${this.cell_data.document_name} from this partner group?`,
					flag: `confirm_delete_document`,
					data: this.cell_data.id
				});
			}
		},
		computed: {
			...mapState({
				company : state => state.company
			})
		}
	}
</script>