<template>
	<div class="tsa-partner-groups-agreements tsa-partner-onboarding">
		<GroupHeader />
		<div class="onboarding-instructions" v-if="this.onboarding_session.company != undefined">
			<HelpHint :flag="headerFlag"  :content="helpContent" /> <span class="page-name">You are a {{ memberType }} Member</span>
		</div>
		<div class="uk-child-width-1-2@m uk-grid-medium" uk-grid>
			<!-- agreements window -->
			<div>
				<div class="tsa-box low-padding" style="min-height: 190px;">
					<div class="tsa-section-title">
						<div v-if="newMember">Accept Legal Agreements <HelpHint flag="limited_accept_legal_agreements" /></div>
						<div v-if="!newMember">Accept Partner Addendum <HelpHint flag="limited_accept_partner_addendum" /></div>
					</div>
					<div class='form-row' v-if="defaultAgreements">
						<input type="checkbox" v-model="code_of_conduct" /> <a href="https://www.tsanet.org/wp-content/uploads/2018/01/code_final.pdf" target="_blank">Code of Conduct - Baseline agreement for all TSANet Members</a>
					</div>
					<div class='form-row' v-if="defaultAgreements">
						<input type="checkbox" v-model="database_license" /> <a href="https://www.tsanet.org/wp-content/uploads/2018/01/DBLicense_final.pdf" target="_blank">Database License - For use of the TSANet Connect system</a>
					</div>
					<div class='form-row' v-if="hasAddendum">
						<input type="checkbox" v-model="addendum" /> <a :href="addendumRoute">Partner Program Addendum - Details for working with this Member</a>
					</div>
					<div class="no-action-required" v-if="!hasAddendum && !defaultAgreements">
						<div class="title">No Action Required</div>
						<p>This group uses your existing {{ memberType }} Addendum.</p>
					</div>
					<div v-if="memberType == 'Basic' || memberType == 'Standard'" class="member-note">
						<strong>Note:</strong> Your Basic Membership does not provide 7*24 collaboration with other members.
					</div>
					<div class="uk-child-width-1-2 uk-grid-small" uk-grid v-show="onboarding.user.first_name == undefined" style="margin-top: 20px;">
						<div :class="formRowClass('first_name')">
							<input type="text" placeholder="Enter Your First Name" v-model="form_data.first_name" />
							<span class="error-block">{{ errors.first_name }}</span>
						</div>
						<div :class="formRowClass('last_name')">
							<input type="text" placeholder="Enter Your Last Name" v-model="form_data.last_name" />
							<span class="error-block">{{ errors.last_name }}</span>
						</div>
					</div>
				</div>
			</div>
			<!-- choose support process -->
			<div>
				<div class="tsa-box low-padding">
					<div class="tsa-section-title">
						Select Support Process <HelpHint flag="limited_select_support_process" />
					</div>
					<div :class="formRowClass('document_id')">
						<select v-model="form_data.document_id">
							<option value="">Choose One</option>
							<option
								v-for="document in documents"
								:key="document.id"
								:value="document.id">{{ document.name }}</option>
							<option value="new">Create a new one for me</option>
						</select>
						<span class="error-block">{{ errors.document_id }}</span>
					</div>
					<div class="tsa-section-title">
						Confirm User Access <HelpHint flag="limited_user_access" />
					</div>
					<div class='form-row'>
						<select v-model="form_data.user_access">
							<option value="open">Open to All Users</option>
							<option value="restricted">Restricted</option>
						</select>
					</div>
				</div>
			</div>
		</div>
		<div class="tsa-register-footer">
			<button class="tsa-btn" @click.prevent="checkAgreementSelected">Submit</button>
		</div>
	</div>
</template>
<script>
	import HelpHint from '@/components/Layout/HelpHint.vue';
	import GroupHeader from '@/components/PartnerGroup/Header.vue';
	import {mapState} from 'vuex';
	import RegistrationSaver from "../../Mixins/RegistrationSaver.vue";
	import _ from 'lodash';
	export default {
		name : 'onboarding-agreements',
		components: {
			HelpHint,
			GroupHeader,
		},
		mixins:[
			RegistrationSaver
		],
		data(){
			return {
				form_data: {
					document_id: "",
					user_access: "open",
					first_name : "",
					last_name : ""
				},
				show_payment_form: false,
				documents: [],
				errors: {}
			}
		},
		mounted(){
			if(!this.partner_group || !this.partner_group.id){
				this.loadRegistrationData();
			}
			if(this.partner_group.id != undefined) this.loadDocuments();
		},
		methods: {
			/**
			 * check if all agreements have been checked.
			 * if yes, save selected process and user access
			 * @return {[type]} [description]
			 */
			checkAgreementSelected(){
				// if no addendum ignore
				if(!this.hasAddendum) this.addendum = true;
				if(!this.defaultAgreements){
					this.code_of_conduct = true;
					this.database_license = true;
				}

				if(this.database_license &&
					this.code_of_conduct &&
					this.addendum  ){
					this.emitter.emit('loader',true);
					// passess validation create process document.
					let data = _.clone(this.form_data);
					data.agreements = this.onboarding.agreements;
					data.session_token = this.session_token;
					data.user = this.onboarding.user;

					if(data.user.id == undefined){
						data.user.first_name = this.form_data.first_name;
						data.user.last_name = this.form_data.last_name;
					}

					//eslint-disable-next-line
					axios.post(`${process.env.VUE_APP_API_URL}onboarding/${this.partner_group.vhost}/register/existing`,data)
					.then(response => this.handlePostResponse(response.data))
					//eslint-disable-next-line
				    .catch(error => this.handleErrors(error.response.data.errors));
				}else{
					this.emitter.emit('alert',{
						type: 'warning',
						message: "You must review and accept all agreements before continuing."
					});
				}
			},
			/**
			 * if everything saved
			 * reset onboarding data and go to success.
			 * @param  {[type]} response [description]
			 * @return {[type]}          [description]
			 */
			handlePostResponse(response){
				this.emitter.emit('loader',false);
				if(response.valid){
					this.$store.dispatch('resetOnboarding');
					this.$router.push({name:'onboarding.success'});
				}
			},
			/**
			 * 
			 * @return {[type]} [description]
			 */
			continueToNextStep(){
				if(this.newMember){
					this.$router.push({name: 'onboarding.process'});
				}else{
					this.$router.push({name: 'onboarding.choose-process'});
				}
			},
			closePaymentModal(){
				this.show_payment_form = false;
			},
			/**
			 * get process documents so user can select which to use
			 * @return {[type]} [description]
			 */
			loadDocuments(){
				this.emitter.emit('loader',true);
				//eslint-disable-next-line
				axios.get(`${process.env.VUE_APP_API_URL}onboarding/${this.partner_group.vhost}/documents/${this.onboarding_session.company.id}`)
				.then(response => this.handleGetDocuments(response.data))
				//eslint-disable-next-line
				.catch(error => console.log(error));
			},
			handleGetDocuments(response){
				this.emitter.emit('loader',false);
				this.documents = response;
			},
			/**
			 * 'form-row' add 'has-error' class if field has error
			 * @param  {[type]} field [description]
			 * @return {[type]}       [description]
			 */
			formRowClass(field){
				let string = "form-row";
				if(this.errors[field] != undefined ) string += " has-error";
				return string;
			},
			handleErrors (errors){
				this.emitter.emit('loader',false);
				// clear all errors
				this.errors = {};
				for(var e in errors){
					this.errors[e] = errors[e][0];
				}
			},
		},
		computed: {
			/**
			 * no price for year and three year membership
			 * @return {[type]} [description]
			 */
			freeMembership(){
				return this.partner_group.price == 0 && this.partner_group.price_three_years == 0;
			},
			hasAddendum(){
				if(this.partner_group.community == undefined) return false;
				// group has addendum
				if(this.partner_group.community.addendum != null){
					// new member
					if(this.newMember){
						return true;
					}else{
						// existing member.
						return this.partner_group.force_addendum;
					}
				}
				return false;
			},
			/**
			 * check if user has to agree to default agreements
			 * @return {[type]} [description]
			 */
			defaultAgreements() {
				if(this.newMember) return true;
				// if existing members
				return false;
			},
			newMember(){
				return this.onboarding_session.company == null;
			},
			memberType(){
				if(this.newMember) return "";
				return this.onboarding_session.company.membership_type.ucfirst();
			},
			database_license: {
				get() { return this.$store.state.onboarding.agreements.database_license},
				set(value){ 
					this.$store.commit('updateOnboardingAgreement',{
					field: 'database_license',
					value: value
					});
				}
			},
			code_of_conduct: {
				get() { return this.$store.state.onboarding.agreements.code_of_conduct},
				set(value){ 
					this.$store.commit('updateOnboardingAgreement',{
					field: 'code_of_conduct',
					value: value
					});
				}
			},
			addendum: {
				get() { return this.$store.state.onboarding.agreements.addendum},
				set(value){ 
					this.$store.commit('updateOnboardingAgreement',{
					field: 'addendum',
					value: value
					});
				}
			},
			contract_length: {
				get() { return this.$store.state.onboarding.payment.contract_length},
				set(value){ 
					this.$store.commit('updateOnboardingPayment',{
					field: 'contract_length',
					value: value
					});
				}
			},
			payment_type: {
				get() { return this.$store.state.onboarding.payment.payment_type},
				set(value){ 
					this.$store.commit('updateOnboardingPayment',{
					field: 'payment_type',
					value: value
					});
				}
			},
			headerFlag(){
				return (this.memberType == "Limited")? 'limited_agreements_member_type' : 'agreements_member_type';
			},
			addendumRoute(){
				return `${process.env.VUE_APP_API_URL}onboarding/${this.partner_group.vhost}/addendum`;
			},
			oneYearPrice(){
				if(this.partner_group.price == undefined) return "";
				return this.partner_group.price;
			},
			threeYearPrice(){
				if(this.partner_group.price_three_years == undefined) return "";
				return this.partner_group.price_three_years;
			},
			helpContent(){
				if(this.onboarding_session.company.id == undefined) return {};
				return {
					level: this.onboarding_session.company.membership_type.ucfirst()
				};
			},
			...mapState({
				company: state => state.company,
				partner_group: state => state.partner_group,
				session_token: state => state.onboarding_session_token,
				onboarding: state => state.onboarding,
				onboarding_session: state => state.onboarding_session
			})
		}
	}
</script>