<template>
	<div class="tsa-onboarding-success tsa-partner-onboarding">
		<GroupHeader />
		<div class="onboarding-instructions">
			<span class="page-name">Thank You &mdash;</span> You have finished the onboarding process.
		</div>
		<div class="thankyou-text">
			Thank you for registering with TSANet. Your request has been sent and we will send an email to you with the status of your request.
			Please add <a href="mailto:membership@tsanet.org">membership@tsanet.org</a> to your address list.
        </div>
		<button class="tsa-btn" style="margin-top: 40px;" @click.prevent="goToCorporate" >Learn More About TSANet</button>
	</div>
</template>

<script>
	import GroupHeader from '@/components/PartnerGroup/Header.vue';
	import RegistrationSaver from "../../Mixins/RegistrationSaver.vue";
	import {mapState} from 'vuex';

	export default {
		name: 'partner-onboarding-success',
		components: {
			GroupHeader
		},
		mixins:[
			RegistrationSaver
		],
		mounted() {
			//it's over, clear the data
			this.clearRegistrationData();
		},
		methods: {
			goToCorporate(){
				window.location.href ="http://www.tsanet.org";
			}
		},
		computed: {
			...mapState({
				partner_group: state => state.partner_group,
				session_token: state => state.onboarding_session_token,
				onboarding: state => state.onboarding
			})
		}
	}
</script>