<template>
	<div class="tsa-managers">
		<div class="tsa-section-title">Your TSANet Managers</div>
		<p><span class="tsa-manager">{{ manager.first_name }} {{manager.last_name}}</span> is your TSANet Business Manager. Key User contacts are listed below:</p>
		<KeyContacts />
	</div>
</template>

<script>

import KeyContacts from '@/components/Home/KeyContacts.vue';
import {mapState} from 'vuex';

export default {
	name: 'tsa-managers',
	components: {
		KeyContacts
	},
	computed:{
		...mapState({
			manager: state => state.manager,
		})
	}
}
</script>
