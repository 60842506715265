<template>
	<div class="tsa-add-users tsa-box low-padding">
		<div class="tsa-section-title tsa-small">Add Users to Group</div>
		<form method="post" @submit.prevent="searchUsers">
			<div class="tsa-search-box">
				<input type="text" v-model="form_data.search_term" placeholder="Find a user. Type email address" />
				<button @click.prevent="searchUsers"><i class="fas fa-search"></i></button>
				<i class="fas fa-sync fa-spin fa-3x fa-fw loader-icon" v-show="searching"></i>
			</div>
			<div class="tsa-search-results">
				<div class="result-row" v-for="user in search_results"
					:key="user.id">
					<div>
						<span>{{ user.first_name }} {{ user.last_name }}</span><br>
						{{ userLocation(user) }}
					</div>
					<button @click="addUser(user)" class="tsa-btn tsa-plain" v-if="user.group_access.length == 0">Add User</button>
					<div v-if="user.group_access.length > 0" class="in-group"><i class="fas fa-check-square"></i></div>
				</div>
				<div class="result-row" v-if="noResults">No Users Found</div>
			</div>
		</form>
	</div>
</template>
<script>
	import _ from 'lodash';
	export default {
		name: "partner-group-add-users",
		components: {

		},
		props: {
			group: {
				type: Object,
				default: () => {}
			}
		},
		data(){
			return {
				form_data: {
					search_term: ""
				},
				search_results: [],
				has_results: false,
				searching: false,
				new_user_index: null
			}
		},
		methods: {
			/**
			 * make request to find users.
			 * if search field is blank reset
			 * @return {[type]} [description]
			 */
			searchUsers(){
				this.has_results = false;
				// if no search term.
				if(this.form_data.search_term.length == 0){
					this.search_results = [];
					return;
				}
				this.searching = true;
				//eslint-disable-next-line
				axios.post(`${process.env.VUE_APP_API_URL}admin/company/partner_groups/${this.group.id}/users/search`,this.form_data)
				.then(response => this.handleSearchUsers(response.data))
				//eslint-disable-next-line
				.catch(error => console.log(error));
			},
			/**
			 * save search results locally
			 * set variables to handle view changes.
			 * @param  {[type]} response [description]
			 * @return {[type]}          [description]
			 */
			handleSearchUsers(response){
				this.has_results = true;
				this.searching = false;
				this.search_results = response;
				
			},
			/**
			 * display city and country
			 * @param  {[type]} user [description]
			 * @return {[type]}      [description]
			 */
			userLocation(user){
				if(user.city != null && user.city != "" && user.country){
					return `${user.city}, ${user.country.name}`;
				}else if(user.city != null && user.city != ""){
					return `${user.city}`;
				}else if(user.country){
					return user.country.name;
				} else {
					return "N/A";
				}
			},
			/**
			 * send request to add a user to the group.
			 * @param {[type]} user [description]
			 */
			addUser(user){
				this.emitter.emit('loader',true);
				this.new_user_index = this.search_results.indexOf(user);
				//eslint-disable-next-line
				axios.get(`${process.env.VUE_APP_API_URL}admin/partner_groups/${this.group.id}/users/add/${user.id}`)
				.then(response => this.handleAddUser(response.data))
				//eslint-disable-next-line
				.catch(error => console.log(error));
			},
			handleAddUser(response){
				this.emitter.emit('loader',false);
				if(response.error != undefined){
					this.emitter.emit('alert',{message: response.error, type: "warning"});
				}else{
					// set as added.
					let results = _.cloneDeep(this.search_results);
					results[this.new_user_index].group_access = [response];
					this.search_results = results;
					this.new_user = null;
					// let parent component a new user has added.
					this.$emit('new_user');
				}
			}
		},
		computed: {
			/**
			 * if no results and a query mas made.
			 * @return {[type]} [description]
			 */
			noResults(){
				return this.search_results.length == 0 && this.has_results;
			}
		},
		watch: {
			'form_data.search_term' : function(){
				if(this.form_data.search_term.length >= 2){
					this.searchUsers();
				}
			}
		}
	}
</script>