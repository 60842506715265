<template>
	<div :class="containerClass">
		<div class="tsa-modal-window tsa-invite-member">
			<div class="tsa-section-title">Invite a Member</div>
			<i class="fas fa-times tsa-close" @click="closeModal"></i>
			<p>
				<span class="member-name">{{ memberName }}</span>  is a TSANet member but does not share a relationship with you. TSANet will contact {{ memberName }} and request them to join into a mutual relationship on your behalf.
			</p>
			<div class="uk-child-width-1-2@m uk-grid-small" uk-grid>
				<div>
					<div :class="formRowClass('comments')">
						<textarea rows="6" placeholder="Short comment on why you need to work with them.  Provide any additional information you feel would help in your relationship request. Example General Problems encountered, etc." v-model="form_data.comments"></textarea>
						<span class="error-block">{{ errors.comments }}</span>
					</div>
				</div>
				<div><button class='tsa-btn' @click.prevent="sendInvite">Send Request</button></div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'invite-member',
		props:{
			member: {
				type: Object,
				default: () => {}
			},
			is_open: {
				type: Boolean,
				default: false
			}
		},
		data(){
			return {
				form_data: {
					comments: ""
				},
				errors: {}
			}
		},
		components: {

		},
		methods:{
			/**
			 * let parent component know we need to close the modal.
			 * @return {[type]} [description]
			 */
			closeModal(){
				this.$emit('close');
			},
			/**
			 * send request to invite member to join tsa net.
			 * @return {[type]} [description]
			 */
			sendInvite(){
				this.emitter.emit('loader',true);
				const data = {
					company_id: this.member.id,
					comments: this.form_data.comments
				};
				// eslint-disable-next-line
				axios.post(`${process.env.VUE_APP_API_URL}collaborate_invite`,data)
					.then(response => this.handleSendInvite(response.data))
					// eslint-disable-next-line
				    .catch(error => this.handleErrors(error.response.data.errors));
			},
			/**
			 * let user know invite was sent.
			 * @return {[type]} [description]
			 */
			handleSendInvite(response){
				this.emitter.emit('loader',false);
				this.errors = {};
				if(response.success){
					// clear comments
					this.comments = "";
					this.emitter.emit('alert',response.message);
					this.closeModal();
				}else{
					this.emitter.emit('alert',{message: response.message, type: "warning"});
				}
			},
			/**
			 * hide loader and add errors to erros array so they're displayed
			 * @param  {[type]} errors [description]
			 * @return {[type]}        [description]
			 */
			handleErrors (errors){
				this.emitter.emit('loader',false);
				// clear all errors
				this.errors = {};
				for(var e in errors){
					this.errors[e] = errors[e][0];
				}
			},
			/**
			 * 'form-row' add 'has-error' class if field has error
			 * @param  {[type]} field [description]
			 * @return {[type]}       [description]
			 */
			formRowClass(field){
				let string = "form-row";
				if(this.errors[field] != undefined ) string += " has-error";
				return string;
			},
		},
		computed:{
			containerClass(){
				let classList = "tsa-modal";
				if(this.is_open) classList += " show";
				return classList;
			},
			memberName(){
				if(this.member.name == undefined) return "";
				return this.member.name;
			}
		}
	}
</script>