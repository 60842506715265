<template>
	<div class="tsa-box">
		<div class="tsa-section-title text-center">Sign in to your company page</div>
		<SearchBox
			placeholder="Search for your company..."
			:endpoint="searchEndPoint"
			@selected="onCompanySelected" />
		<div class="text-center tsa-large-note">Not able to find your company page? <a href="mailto:membership@tsanet.org">Contact Us</a></div>
	</div>
</template>

<script>
	import {mapState} from 'vuex';
	import SearchBox from '@/components/Layout/SearchBox.vue';
	export default {
		name: "landing-page-search",
		components: {
			SearchBox
		},
		methods: {
			/**
			 * once the company is selected redirect to landing page.
			 * @param  {[type]} company [description]
			 * @return {[type]}         [description]
			 */
			onCompanySelected(company){
				let redirect = process.env.VUE_APP_COMPANY_URL.replace('{slug}',company.slug);
				window.location.href = redirect;
				//location.reload();
			}
		},
		computed: {
			/**
			 * api end point to search for company landing pages.
			 * @return {[type]} [description]
			 */
			searchEndPoint(){
				return `${process.env.VUE_APP_API_URL}caller/landing_page`;
			},
			...mapState({
				landing_pages: state => state.landing_pages
			})
		}
	}
</script>