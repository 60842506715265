<template>
    <div class="form-row tsa-custom-select" :class="{'has-error' : error.length > 0}">
        <select v-model="select_value" :placeholder="placeholder" :disabled="disabled">
            <option value="">{{ requirement.requirement.label}}</option>
            <option v-for="(option,index) in select_options" :key="index" :value="option">{{option}}</option>
        </select>
        <span class="error-block"><strong>{{ error }}</strong></span>
    </div>
</template>

<script>
    export default {
        name: "TSANetGroupSelect",
        props: {
            requirement: {
				type: Object,
				default: () => {}
			},
            error: {
                type: String,
                default: ""
            },
			value: {
				type: Object,
				default: () => {}
			},
            placeholder: {
                type: String,
                default: "Choose One"
            },
            disabled: {
                type: Boolean,
                default: false
            }
        },
        data(){
            return {
				select_value: "",
                text_value: "",
                select_options: [],
                groups: {}
			}
        },
        mounted(){
            this.handleOptions();
        },
        methods: {
            handleOptions(){
                if(Array.isArray(this.requirement.options)){
                    this.select_options = this.requirement.options;
                }else{
                    this.select_options = this.requirement.options.split("\n");
                }
                // get groups from additional settings
                if(Array.isArray(this.requirement.additional_settings)){
                    this.groups = this.requirement.additional_settings;
                }else{
                    this.groups = this.requirement.additional_settings.split("\n");
                }
                setTimeout(() => this.handleVisibleGroup(),100);
            },
            /**
             * show/hide fields based on selected option
             * @return {[type]} [description]
             */
            handleVisibleGroup(){
                let selected = null;
                for(let i in this.select_options){
                    if(this.select_value == this.select_options[i]){
                        selected = i;
                        break;
                    }
                }

                let groups = {};

                // no selected
                if(selected === null){
                    // hide all groups
                    for(let i in this.groups){
                        const group = this.groups[i];
                        groups[group] = false;
                    }
                }else{
                    for(let i in this.groups){
                        const group = this.groups[i];
                        // selected item
                        if(i == selected){
                            groups[group] = true;
                        }else{
                            // hide items
                            groups[group] = false;
                        }
                    }
                }
                this.$store.commit('setDocumentActiveGroups',groups);
            }
        },
        watch:{
            /**
             * only send select option if not other
             * @return {[type]} [description]
             */
            select_value(){
                if(this.select_value != "other"){
                    this.text_value = "";
                }
                this.$emit('change',{
                    'requirement_id' : this.requirement.requirement_id,
                    'data' : this.select_value
                });
                this.handleVisibleGroup();
			},
            /**
             * if the textfield changed and they are using the other field.
             * @return {[type]} [description]
             */
            text_value(){
                if(this.select_value == "other"){
                    // sent value
                    this.$emit('change',{
                        'requirement_id' : this.requirement.requirement_id,
                        'data' : this.text_value
                    });
                }
            }
		}
    }
</script>