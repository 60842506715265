<template>
	<div>
		<Modal
			title="Add New Partner"
			:is_open="modal.open"
			@close="closeModal">
				<div class="modal-inner">
					<!-- company -->
					<div :class="formRowClass('company_id')" class="with-label">
						<label>Partner:</label>
						<select v-model="form_data.company_id" @change="loadDocuments">
							<option value="">Choose Partner</option>
							<option 
								v-for="company in companies"
								:key="company.id"
								:value="company.id">{{company.name}}</option>
						</select>
						<span class="error-block">{{ errors.company_id }}</span>
					</div>
					<!-- department -->
					<div 
						:class="formRowClass('department_id')"
						v-if="hasDepartments"
						class="with-label">
						<label>Department:</label>
						<select v-model="form_data.department_id" :disabled="departmentDisabled">
							<option value="">Choose Department</option>
							<option 
								v-for="department in departments"
								:key="department.id"
								:value="department.id">{{department.name}}</option>
						</select>
						<span class="error-block">{{ errors.department_id }}</span>
					</div>
					<!-- document -->
					<div :class="formRowClass('document_id')" class="with-label">
						<label>Process Document:</label>
						<select v-model="form_data.document_id" :disabled="documentDisabled">
							<option value="">{{ documentEmptyOption }}</option>
							<option 
								v-for="pdoc in documents"
								:key="pdoc.id"
								:value="pdoc.id">{{pdoc.name}}</option>
						</select>
						<span class="error-block">{{ errors.document_id }}</span>
					</div>
				</div>
				<template v-slot:footer>
					<div>
						<a href="" class="tsa-cancel" @click.prevent="closeModal">Cancel</a>
						<button class="tsa-btn" @click.prevent="addPartner">Add Partner</button>
					</div>
				</template>
		</Modal>
		<!-- partners list -->
		<div class="tsa-group-partner tsa-box low-padding">
			<div class="tsa-section-title">
				<button class='tsa-btn pull-right tsa-small' @click="showAddPartnerModal" v-if="!view_only"><i class="fas fa-plus"></i> Add New</button>
				Partners <ExportButton :export_route="exportRoute" :file_name="exportFileName" />
			</div>
			<DataTable
				:searchable="true"
				:table_headers="table_headers"
				:table_data="partners" />
		</div>
	</div>
</template>

<script>
	import DataTable from '@/components/Table/DataTable.vue';
	import RemovePartner from '@/components/SuperAdmin/Groups/Manage/RemovePartnerCell.vue';
	import Modal from '@/components/Layout/DefaultModal.vue';
	import ExportButton from '@/components/SuperAdmin/ExportButton.vue';

	import {mapState} from 'vuex';

	export default {
		name: "group-partners",
		components: {
			DataTable,
			Modal,
			ExportButton
		},
		props: {
			group: {
				type: Object,
				default: () => {}
			},
			view_only: {
				type: Boolean,
				default: false
			},
		},
		data(){
			return {
				table_headers: {
					name: {
						display: "Name",
						render: (cell_data) => {
							if(cell_data.department == null){
								return cell_data.company.name;
							}else{
								return `${cell_data.company.name} | ${cell_data.department.name}`;
							}
						}
					},
					'document.name': {
						display: "Collaboration Process"
					},
					'type': {
						render: cell_data => {
							return (cell_data.department == null)? "Company" : "Department";
						}
					},
					actions: {
						display: "",
						component: RemovePartner
					}
				},
				partners: [],
				modal:{
					open: false
				},
				form_data: {
					company_id: "",
					document_id: "",
					department_id: ""
				},
				errors: {

				},
				documents: [],
				companies: [],
				departments: []
			}
		},
		mounted(){
            this.initialized();
        },
        updated(){
            this.initialized();
        },
        /**
		 * remove bus event listeners before component is destroyed.
		 * @return {[type]} [description]
		 */
		beforeUnmount(){
			this.emitter.off('confirm_remove_partner');
			this.emitter.off('token_ready');
		},
        methods: {
            initialized(){
                if(this.is_initialized) return;
                this.is_initialized = true;
                this.emitter.on('confirm_remove_partner', data => this.removePartner(data))
                this.emitter.on('token_ready', () => this.getCompanies());
                if(this.jwt_token.access_token != undefined) this.getCompanies();
            },
            /**
             * get the list of all partners for this group.
             * @return {[type]} [description]
             */
            getPartners(){
				this.emitter.emit('loader',true);
				//eslint-disable-next-line
				axios.get(`${process.env.VUE_APP_API_URL}super_admin/groups/${this.$route.params.group_id}/partners`)
				.then(response => this.handleGetPartners(response.data))
				//eslint-disable-next-line
				.catch(error => console.log(error));
            },
            handleGetPartners(response){
				this.emitter.emit('loader',false);
				this.partners = response;
            },
            /**
             * get list of companies to choose a partner
             * @return {[type]} [description]
             */
            getCompanies(){
				this.emitter.emit('loader',true);
                //eslint-disable-next-line
                axios.get(`${process.env.VUE_APP_API_URL}super_admin/companies/list`)
                .then(response => this.handleGetCompanies(response.data))
                //eslint-disable-next-line
                .catch(error => console.log(error));
            },
            handleGetCompanies(response){
				this.emitter.emit('loader',false);
                this.companies = response;
                this.getPartners();
            },
            /**
             * load contact documents for selected company
             * @return {[type]} [description]
             */
            loadDocuments(){
				// no client selected ?
				if(this.company_id == ""){
					this.documents = [];
					return;
				}
				// clear departments
				this.departments = [];
				this.form_data.department_id = "";
				//eslint-disable-next-line
				axios.get(`${process.env.VUE_APP_API_URL}super_admin/companies/${this.form_data.company_id}/docs_and_depts`)
				.then(response => this.handleGetDocuments(response.data))
				//eslint-disable-next-line
				.catch(error => console.log(error));
            },
            handleGetDocuments(response){
				this.documents = response.documents;
				this.departments = response.departments;
			},
			/**
			 * show modal to add new partner
			 */
			addPartner(){
				this.emitter.emit('loader',true);
				//eslint-disable-next-line
				axios.post(`${process.env.VUE_APP_API_URL}super_admin/groups/${this.$route.params.group_id}/partners/add`,this.form_data)
					.then(response => this.handleAddNew(response.data))
					//eslint-disable-next-line
				    .catch(error => this.handleErrors(error.response.data.errors));
			},
			/**
			 * alert if error
			 * reload list.
			 * @param  {[type]} response [description]
			 * @return {[type]}          [description]
			 */
			handleAddNew(response){
				this.emitter.emit('loader',false);
				// error with submission
				if(response.error != undefined){
					this.emitter.emit('alert',{
						type:"warning",
						message: response.error
					});
				}else{
					this.form_data = {
						company_id: "",
						document_id: ""
					};
					this.closeModal();
					
					this.getPartners();
				}
			},
			handleErrors (errors){
				this.emitter.emit('loader',false);
				// clear all errors
				this.errors = {};
				for(var e in errors){
					this.errors[e] = errors[e][0];
				}
			},
			/**
			 * close add new partner modal
			 * @return {[type]} [description]
			 */
			closeModal(){
				this.modal.open = false;
				this.documents = [];
				//reset values
				this.form_data = {
					company_id: "",
					document_id: "",
					department_id: ""
				};
			},
			/**
			 * send request to add partner
			 */
			showAddPartnerModal(){
				this.modal.open = true;
			},
			/**
			 * 'form-row' add 'has-error' class if field has error
			 * @param  {[type]} field [description]
			 * @return {[type]}       [description]
			 */
			formRowClass(field){
				let string = "form-row";
				if(this.errors[field] != undefined ) string += " has-error";
				return string;
			},
			/**
			 * send api request to remove partner
			 * @param  {[type]} relation_id [description]
			 * @return {[type]}            [description]
			 */
			removePartner(relation_id){
				this.emitter.emit('loader',true);
				//eslint-disable-next-line
				axios.get(`${process.env.VUE_APP_API_URL}super_admin/groups/${this.$route.params.group_id}/partners/${relation_id}/remove`)
				.then(response => this.handleRemovePartner(response.data))
				//eslint-disable-next-line
				.catch(error => console.log(error));
			},
			handleRemovePartner(response){
				this.emitter.emit('loader',false);
				this.emitter.emit('alert',response.success);
				this.getPartners();
			}
		},
		computed: {
			/**
			 * check if the department dropdown should be disabled.
			 * @return {[type]} [description]
			 */
			departmentDisabled(){
				return this.form_data.company_id == "";
			},
			/**
			 * check if the document dropdown should be disabled
			 * if partner not selected yes
			 * @return {[type]} [description]
			 */
			documentDisabled(){
				// if no company selected
				if(this.form_data.company_id == "") return true;
				// if company has departments
				if(this.departments.length > 0){
					return this.form_data.department_id == "";
				}
				return false;
			},
			/**
			 * what label to show based on if a partner is selected or not.
			 * @return {[type]} [description]
			 */
			documentEmptyOption(){
				if(this.documentDisabled){
					return (this.departments.length > 0)? "Choose Department First" : "Choose Partner First";
				}else{
					return "Choose Process Document";
				}
			},
			/**
			 * check if the current partner has deparments
			 * @return {Boolean} [description]
			 */
			hasDepartments(){
				return this.departments.length > 0;
			},
			exportFileName(){
				if(this.group.id == undefined) return "";
				return `${this.group.name.slugify()}.group_partners.xlsx`;
			},
			exportRoute(){
				return `${process.env.VUE_APP_API_URL}super_admin/groups/${this.$route.params.group_id}/partners/export`;
			},
			...mapState({
				jwt_token: state => state.token
			})
		},
		watch: {
		}
	}
</script>