<template>
	<strong>Password must match at least 3 of following rules:</strong>
	<ul>
		<li>Minimum of 8 characters</li>
		<li>1 Uppercase letter</li>
		<li>1 Lowercase letter</li>
		<li>1 Numeric character</li>
		<li>1 Non-numeric character: ( !,$,# or %)</li>
	</ul>
</template>

<script>
	export default {
		name : 'password-requirements',
	}
</script>