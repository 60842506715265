	<template>
	<div class="tsa-case-response">
		<div class="tsa-section-title">Response:</div>
		<div v-if="caseResponse != null">
			<template v-if="case_entry.case_type == 'manager'">
				<div class="tsa-data" >
					<!-- company -->
					<div class="tsa-data-label">Company:</div>
					<div class="tsa-data-value">{{ case_entry.receiver.name }}</div>
				</div>
				<div class="tsa-data" >
					<!-- company -->
					<div class="tsa-data-label">Date:</div>
					<div class="tsa-data-value">{{ dateSubmitted }}</div>
				</div>
				<div class="tsa-data" >
					<!-- case number -->
					<div class="tsa-data-label">Internal Reference (Case/Project/Customer):</div>
					<div class="tsa-data-value">{{ caseResponse.case_number }}</div>
				</div>
				<div class="tsa-data" >
					<!-- name -->
					<div class="tsa-data-label">Name:</div>
					<div class="tsa-data-value">{{ caseResponse.engineer_name }}</div>
				</div>
				<div class="tsa-data" >
					<!-- email -->
					<div class="tsa-data-label">Email:</div>
					<div class="tsa-data-value">{{ caseResponse.engineer_email }}</div>
				</div>
				<div class="tsa-data" >
					<!-- email -->
					<div class="tsa-data-label">Phone:</div>
					<div class="tsa-data-value">{{ formattedPhone(caseResponse.engineer_phone) }}</div>
				</div>
				<div class="tsa-data" >
					<!-- next steps -->
					<div class="tsa-data-label">Description:</div>
					<limited-copy class="tsa-data-value" :copy="caseResponseDescription" ></limited-copy>
				</div>
			</template>
			<template v-else>
				<div class="tsa-data" >
					<!-- company -->
					<div class="tsa-data-label">Company:</div>
					<div class="tsa-data-value">{{ case_entry.receiver.name }}</div>
				</div>
				<div class="tsa-data" >
					<!-- company -->
					<div class="tsa-data-label">Date:</div>
					<div class="tsa-data-value">{{ dateSubmitted }}</div>
				</div>
				<div class="tsa-data" >
					<!-- case number -->
					<div class="tsa-data-label">Case #</div>
					<div class="tsa-data-value">{{ caseResponse.case_number }}</div>
				</div>
				<div class="tsa-data" >
					<!-- name -->
					<div class="tsa-data-label">Engineer Name:</div>
					<div class="tsa-data-value">{{ caseResponse.engineer_name }}</div>
				</div>
				<div class="tsa-data" >
					<!-- email -->
					<div class="tsa-data-label">Engineer Email:</div>
					<div class="tsa-data-value">{{ caseResponse.engineer_email }}</div>
				</div>
				<div class="tsa-data" >
					<!-- email -->
					<div class="tsa-data-label">Engineer Phone:</div>
					<div class="tsa-data-value">{{ formattedPhone(caseResponse.engineer_phone) }}</div>
				</div>
				<div class="tsa-data" >
					<!-- next steps -->
					<div class="tsa-data-label">Next Steps:</div>
					<limited-copy class="tsa-data-value" :copy="nextSteps" ></limited-copy>
				</div>
			</template>
		</div>
		<div class="no-response" v-if="caseResponse == null">
			<!-- sender message -->
			<p v-if="case_entry.company.id == user.company_id"><strong>{{ case_entry.receiver.name }}</strong> has not responded to this case. You will receive an email once an engineer from their team has done so.</p>
			<!-- receiver message -->
			<p v-if="case_entry.receiver.id == user.company_id">
				<strong>{{ case_entry.company.name }}</strong> is awaiting a response on this case. Please click the button to reply.
				<br><br>
				<router-link v-if="case_entry.case_type == 'manager'" :to="{name:'manager.response',params:{token:case_entry.token}}" class="tsa-btn">Respond to Case</router-link>
				<router-link v-else :to="{name:'case-response',params:{token:case_entry.token}}" class="tsa-btn">Respond to Case</router-link>
			</p>
		</div>
	</div>
</template>

<script>

	import {mapState} from 'vuex';
	import parsePhoneNumber from 'libphonenumber-js';
	import LimitedCopy from "@/components/Form/LimitedCopy.vue";
	export default {
		name: "case-reply",
		components: {
			LimitedCopy
		},
		// properties that the component will receive from parent
		props: {
			case_entry: {
				type: Object,
				default: () => {}
			}
		},
		data(){
			return{
				caseResponse: null,
			}
		},
		mounted() {
			if(this.case_entry){
				this.caseResponse = this.findResponse();
			}
		},
		methods:{
			formattedPhone(phone){
				if(!phone){ return "";}
				let phoneParts = phone.split(":");
				if(phoneParts.length > 1){
					let country = phoneParts[0];
					phoneParts.shift();
					let phNumber = parsePhoneNumber(phoneParts.join(":").trim(), country);
					if(phNumber.isValid()){
						return phNumber.country + ": " + phNumber.formatInternational();
					}
				}
				return phone;
			},
			findResponse(){
				if(this.case_entry.responses){
					for(let i = 0; this.case_entry.responses.length > i;++i){
						if(this.case_entry.responses[i].type == "approval"){
							return this.case_entry.responses[i];
						}
					}
				}
				return null;
			},
			updateCase(){
				this.$emit("updated");
			}
		},
		computed:{
			/**
			 * convert \n to <br>
			 * @return {[type]} [description]
			 */
			nextSteps(){
				if(this.caseResponse.next_steps == undefined) return "";
				return this.caseResponse.next_steps.nl2br();
			},
			/**
			 * format date and change to local timezone.
			 * @return {[type]} [description]
			 */
			dateSubmitted(){
				if(this.caseResponse.created_at == undefined) return "";
				return this.$moment(this.caseResponse.created_at)/*.local()*/.format("MM/DD/YY hh:mm a");
			},
			caseResponseDescription(){
				if(this.case_entry.manager_requests == undefined) {
					return "";
				}
				if(this.case_entry.manager_requests.length > 1){
					return this.case_entry.manager_requests[1].notes.nl2br();
				}
				return "";
			},
			...mapState({
				user: state => state.user,
				company: state => state.company
			})
		},
		watch:{
			case_entry(){
				this.caseResponse = this.findResponse();
			}
		},
		emits:["updated"]
	}
</script>