<template>
	<div>
		<GroupLogin v-if="groupLogin" />
		<LoginAndRegister  v-if="registrationLogin || normalLogin" />
		<SsoLogin v-if="ssoLogin" />
	</div>
</template>

<script>
	// @ is an alias to /src
	import GroupLogin from '@/components/Home/GroupLogin.vue';
	import SsoLogin from '@/components/Home/SsoLogin.vue';
	import LoginAndRegister from '@/components/Home/LoginAndRegister.vue';
	import {mapState} from 'vuex';

	export default {
		name: 'landing-home',
		components: {
			GroupLogin,
			LoginAndRegister,
			SsoLogin
		},
		props: {
			view_is_ready: {
				type: Boolean,
				default: false
			}
		},
		computed: {
			/**
			 * check if group login is available
			 * @return {[type]} [description]
			 */
			groupLogin(){
				if(!this.view_is_ready || this.company == undefined || this.app_slug == null) return false;
				return this.view_is_ready && this.company.login_method == "email_link";
			},
			registrationLogin(){
				if(!this.view_is_ready || this.company == undefined || this.app_slug == null) return false;
				return this.view_is_ready && this.company.login_method == "requires_registration";
			},
			ssoLogin(){
				if(!this.view_is_ready || this.company == undefined || this.app_slug == null) return false;
				return this.view_is_ready && this.company.login_method == "sso";
			},
			normalLogin(){
				if(!this.view_is_ready) return false;
				if(this.company.id == undefined  || this.app_slug == null) return true;
				return false;
			},
			...mapState({
				company: state => state.company,
				app_slug: state => state.app_slug,
				app_ready: state => state.app_ready
			})
		},
	}
</script>