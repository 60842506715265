<template>
	<div class="tsa-admin tsa-admin-inbound-process">
		<div class="tsa-admin-header">
			<span class="page-name">{{ company.name }} Inbound Process -</span> How members contact you
		</div>
		<div class="tsa-box low-padding">
			<div class="tsa-section-title">Inbound Process <HelpHint flag="inbound_process_list" /></div>
			<DataTable 
					:searchable="false"
					:table_data="documents"
					:table_headers="list_headers"
				/>
		</div>

	</div>
</template>

<script>
	import {mapState} from 'vuex';
	import HelpHint from '@/components/Layout/HelpHint.vue';
	import DataTable from '@/components/Table/DataTable.vue';

	export default {
		name: "admin-inbound-process",
		components: {
			HelpHint,
			DataTable
		},
		data(){
			return {
				has_initialized: false,
				documents: [],
				list_headers:{
					name: {
						display: "Name",
						route: "/admin/inbound_process/{id}",	
					},
					document_type: {
						display: "Type",
						render: data => {
							return  data.version.document_type.ucfirst();	
						}
					},
					departments: {
						render: data => {
							let depts = [];
							data.group_relations.forEach(row => {
								if(row.department != null) depts.push(row.department.name);
							});
							return (depts.length > 0)? depts.join(", ") : "";
						}
					},
					groups: {
						render: data => {
							let comms = [];
							data.group_relations.forEach(row => {
								comms.push(row.community.name);
							});
							return (comms.length > 0)? comms.join(", ") : "";
						}
					},
					/*communities: {
						display: "Communities",
						style: "width:30%"
					},
					*/
					escalation_manager: {
						display: "Escalation Manager",
						render: data => {
							if(data.version.manager_users.length == 0) return "";
							let managers = [];
							data.version.manager_users.forEach(manager => {
								managers.push(`${manager.first_name} ${manager.last_name}`);
							});
							return managers.join(", ");
						}
					}
				}
			}
		},
		mounted(){
			this.initialize();
		},
		updated(){
			if(!this.has_initialized) this.initialize();
		},
		/**
         * remove bus event listeners before component is destroyed.
         * @return {[type]} [description]
         */
        beforeUnmount(){
			this.emitter.off('token_ready');
        },
		methods: {
			initialize(){
				this.has_initialized = true;
				
				this.emitter.on('token_ready', () => this.loadDocuments());
				if(this.jwt_token.access_token != undefined) this.loadDocuments();
			},
			/**
			 * load contact documents for company
			 * @return {[type]} [description]
			 */
			loadDocuments(){
				//eslint-disable-next-line
				axios.get(`${process.env.VUE_APP_API_URL}admin/company/documents`)
				.then(response => this.handleLoadDocuments(response.data))
				//eslint-disable-next-line
				.catch(error => console.log(error));
			},
			handleLoadDocuments(response){
				this.documents = response;
			}
		},
		computed: {
			...mapState({
				company: state => state.company,
				jwt_token: state => state.token
			})
		}
	}
</script>