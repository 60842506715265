import { createStore } from 'vuex';
import _ from 'lodash';

// vue store
export default createStore({
	state: {
		token: {},
		company: {
			note: null,
			registration: null
		},
		manager: {},
		key_contacts: [],
		domain: "",
		company_directory: [],
		company_directory_available: false,
		contact_document: {
			version: {
				requirements: []
			},
			company: [],
			department: null
		},
		case_entry:{
			company:{},
			engineer:{},
			receiver:{},
			customer_data:[]
		},
		case_response: null,
		case_responses: null,
		case_details: "",
		case_details_plain: "",
		user:{},
		my_favorites:[],
		favorites_available: false,
		countries: [],
		priority_messages: {},
		current_page: "",
		show_admin_menu: false,
		document_notes: [],
		php_upload_max_filesize: "",
		// the full domain for the application
		app_domain: "",
		// the company slug the app is currently running under
		app_slug: null,
		// the app is ready.
		app_ready: "",
		partner_group: {},
		table_data_selected_items: [], // used to track items that have been selected in the a list,
		// data for limited member onboarding.
		onboarding_session_token: null,
		onboarding_session_step: null,
		onboarding_session: {
			company: null
		},
		onboarding: {
			user: {
				first_name: "",
				last_name: "",
				email: "",
				phone: "",
				phone_country_code : "US",
				password: "",
				password_confirmation: ""
			},
			company: {
				name: "",
				address: "",
				city: "",
				state: "",
				zipcode: "",
				country_id: "",
				url:""
			},
			document: {
				support_hours: "",
				product_supported: "",
				support_email: "",
				manager_email: "",
				manager_first_name: "",
				manager_last_name: "",
				support_phone: "",
				support_country_code: "US",
			},
			agreements: {
				database_license: false,
				code_of_conduct: false,
				addendum: false
			},
			payment: {
				contract_length: "three_years", // one_year
				payment_type: "credit_card", // paypal invoice
			}
		},
		/**
		 * track if logged in a user.
		 * @type {Boolean}
		 */
		login_as_user: false,
		/**
		 * what cases to display in escalation reporting filter.
		 * @type {String}
		 */
		escalation_filter: 'open',
		document_active_groups: {}
	},
	mutations: {
		companyDirectoryAvailable(state,payload){
			state.company_directory_available = payload;
		},
		setCompanyDirectory(state,payload){
			state.company_directory = payload;
		},
		setLandingPageData(state, payload){
			state.company = payload.company;
			state.manager = payload.manager;
			state.key_contacts = payload.key_contacts;
			state.countries = payload.countries;
			localStorage.setItem("tsa_level", payload.company.membership_type);
		},
		setPartnerGroup(state,payload){
			state.company = payload.company;
			state.partner_group = payload.group;
			state.countries = payload.countries;
		},
		setToken(state, payload){
			// save token in store
			state.token = payload;
			// save to local storage
			localStorage.setItem("tsa_token", JSON.stringify(payload));
			//eslint-disable-next-line
			axios.defaults.headers.common['Authorization'] = 'Bearer ' + payload.access_token;
		},
		overwriteCaseProcess(state,payload){
			state.contact_document = payload;
			state.document_notes = payload.notes;
		},
		setCaseData(state,payload){
			//state.company = payload.company;
			state.contact_document = payload.document;
			state.priority_messages = payload.messages;
			state.document_notes = payload.document.notes;
		},
		setDocumentActiveGroups(state,payload){
			state.document_active_groups = payload;
			//eslint-disable-next-line
			UIkit.update();
		},
		/**
		 * reset case data
		 * @param {[type]} state [description]
		 */
		setClearCaseData(state){
			state.contact_document = {
				version: {
					requirements: []
				},
				company: [],
				department: null
			};
			state.priority_messages = {};
			state.document_notes = [];
		},
		setUserData(state,payload){
			state.user = payload.user;
			if(payload.company != undefined){
				state.company = payload.company;
				state.app_slug = payload.company.slug;
				localStorage.setItem("tsa_level", payload.company.membership_type);
			}
			localStorage.setItem("tsa_role", payload.user.role);
		},
		setProfileDataFromCase(state,payload){
			let user = _.cloneDeep(state.user);
			user.first_name = payload.first_name;
			user.last_name = payload.last_name;
			user.email = payload.email;
			user.phone = payload.phone_number;
			user.phone_country_code = payload.phone_country_code;
			state.user = user;
		},
		removeUserData(state){
			state.company_directory = [];
			//if(state.user.role == "super_admin") state.app_slug = null;
			state.user = {};
			state.contact_document = {
				version: {}
			};
			state.show_admin_menu = false;
			state.company = {};
			state.token = {};
			state.login_as_user = false;
			localStorage.removeItem('tsa_token');
			localStorage.removeItem("tsa_role");
			localStorage.removeItem("tsa_level");
			localStorage.removeItem("tsa_admin_token");
			//state.app_slug = null;
		},
		setCaseDetails(state,payload){
			state.case_details = payload.details;
			state.case_details_plain = payload.details_plain;
		},
		setCaseEntry(state,payload){
			state.case_entry = payload;
		},
		setFavorites(state,payload){
			state.my_favorites = payload;
			state.favorites_available = true;
		},
		setIsFavorite(state,payload){
			let contactDocument = _.cloneDeep(state.contact_document);
			contactDocument.is_favorite = payload;
			state.contact_document = contactDocument;
		},
		setProfileFormData(state,payload){
			state.countries = payload.countries;
		},
		setCaseResponse(state,payload){
			state.case_response = payload;
		},
		setCaseResponses(state,payload){
			state.case_responses = payload;
		},
		setCurrentPage(state,payload){
			state.show_admin_menu = false;
			state.current_page = payload;
		},
		setToggleAdminMenu(state){
			state.show_admin_menu = !state.show_admin_menu;
		},
		setDocumentNotes(state,payload){
			state.document_notes = payload;
		},
		setNewDocumentNote(state,payload){
			state.document_notes = payload;
		},
		setNewCompanyNote(state,payload){
			state.company.note = payload;
		},
		/*deleteDocumentNote(state,payload){
			const index = state.document_notes.indexOf(payload);
			if(index !== -1){
				state.document_notes.splice(index,1);
			}
		},*/
		setAppUrl(state,host){
			const parts = host.split('.');
			state.app_domain = host;	
			if(parts.length > 1){
				const slug = parts[0];
				if(slug != "connect") state.app_slug = parts[0];
			}

			// app is ready
			state.app_ready = true;
		},
		setAppSlug(state,slug){
			state.app_slug = slug;
		},
		/**
		 * add item to the selected list.
		 * @param {[type]} state   [description]
		 * @param {[type]} payload [description]
		 */
		setTableSelect(state,payload){
			const index = state.table_data_selected_items.indexOf(payload);
			if(index == -1) state.table_data_selected_items.push(payload);
		},
		/**
		 * remove item from deselected list.
		 * @param {[type]} state   [description]
		 * @param {[type]} payload [description]
		 */
		setTableDeselect(state,payload){
			const index = state.table_data_selected_items.indexOf(payload);
			if(index != -1){
				state.table_data_selected_items.splice(index,1);
			}
		},

		updateOnboardingCompany(state,payload){
			state.onboarding.company[payload.field] = payload.value;
		},
		updateOnboardingUser(state,payload){
			state.onboarding.user[payload.field] = payload.value;
		},
		updateOnboardingDocument(state,payload){
			state.onboarding.document[payload.field] = payload.value;
		},
		updateOnboardingAgreement(state,payload){
			state.onboarding.agreements[payload.field] = payload.value;
		},
		updateOnboardingPayment(state,payload){
			state.onboarding.payment[payload.field] = payload.value;
		},
		setOnboardingSession(state,payload){
			state.onboarding_session = payload;
			state.onboarding_session_step = payload.step;
			state.onboarding_session_token = payload.token;
			localStorage.setItem("tsa_onboarding_session_step", payload.step);
			localStorage.setItem("tsa_onboarding_session_token", payload.token);
			
			if(payload.data != null){
				state.onboarding.user = payload.data.user;
				state.onboarding.company = payload.data.company;
				state.onboarding.document = payload.data.document;
				let empty = Object.keys(payload.data.agreements).length === 0 && payload.data.agreements.constructor === Object;
				if(!empty) state.onboarding.agreements = payload.data.agreements;
			}
		},
		resetOnboarding(state){
			state.onboarding_session_step = null;
			state.onboarding_session_token = null;
			localStorage.removeItem("tsa_onboarding_session_step");
			localStorage.removeItem("tsa_onboarding_session_token");
		},
		setLoginAsUser(state,payload){
			state.login_as_user = payload;
		},
		setTableSelectValues(state,payload){
			state.table_data_selected_items = payload;
		},
		setRegistrationSettings(state,payload){
			state.company.registration = payload;
		},
		setEscalationFilter(state, payload){
			state.escalation_filter = payload;
		},
		setSsoSettings(state,payload){
			let company = _.cloneDeep(state.company);
			company.login_method = 'sso';
			company.sso_idp_federation_url = payload.sso_idp_federation_url;
			company.sso_idp_logout_url = payload.sso_idp_logout_url;
			company.sso_idp_entity_url = payload.sso_idp_entity_url;
			company.sso_login_section = payload.sso_login_section;
			company.sso_registration_section = payload.sso_registration_section;
			company.sso_identity_source = payload.sso_identity_source;
			company.sso_identity_file = payload.sso_identity_file;

			state.company = company;
		}
	},
	actions: {
		companyDirectory({commit},data){
			commit('setCompanyDirectory',data);
		},
		landing({commit},data){
			commit('setLandingPageData',data);
		},
		partnerGroup({commit},data){
			commit('setPartnerGroup',data);
		},
		token({commit},data){
			commit('setToken',data);
		},
		caseData({commit},data){
			commit('setCaseData',data);
		},
		clearCaseData({commit}){
			commit('setClearCaseData');
		},
		user({commit},data){
			commit('setUserData',data);
		},
		logout({commit}){
			commit('removeUserData');
		},
		caseDetails({commit},data){
			commit('setCaseDetails', data);
		},
		caseEntry({commit},data){
			commit('setCaseEntry',data);
		},
		favorites({commit},data){
			commit('setFavorites',data);
		},
		isFavorite({commit},data){
			commit('setIsFavorite',data);
		},
		profileFormData({commit},data){
			commit('setProfileFormData',data);
		},
		caseResponse({commit},data){
			commit('setCaseResponse',data);
		},
		caseResponses({commit},data){
			commit('setCaseResponses',data);
		},
		currentPage({commit},data){
			commit('setCurrentPage',data);
		},
		toggleAdminMenu({commit}){
			commit('setToggleAdminMenu');
		},
		documentNotes({commit},data){
			commit('setDocumentNotes',data);
		},
		newDocumentNote({commit}, data){
			commit('setNewDocumentNote',data);
		},
		newCompanyNote({commit}, data){
			commit('setNewCompanyNote',data);
		},
		deleteDocumentNote({commit},data){
			commit('deleteDocumentNote',data);
		},
		appUrl({commit},data){
			commit('setAppUrl',data);
		},
		tableSelect({commit},data){
			commit('setTableSelect',data);
		},
		tableDeselect({commit},data){
			commit('setTableDeselect',data);
		},
		onboardingSession({commit},data){
			commit('setOnboardingSession',data);
		},
		restoreOnboarding({commit},data){
			// set session data.
			commit('setOnboardingSession',data.session);
			// set landing page info.
			commit('setPartnerGroup',data);
			// save app slug
			commit('setAppSlug',data.group.vhost);
		},
		resetOnboarding({commit}){
			commit('resetOnboarding');
		},
		loggedInAsUser({commit},data){
			commit('setLoginAsUser',data);
		},
		tableSelectValues({commit},data){
			commit('setTableSelectValues',data);
		},
		registrationSettings({commit},data){
			commit('setRegistrationSettings',data);
		},
	}
})
