<template>
	<div class="tsa-case-response">
		<!-- When first needs to respond -->
		<div class="tsa-response-fields" v-if="can_respond">
			<div class="response-header">Information requested for {{ case_entry.receiver.name }} Case {{ case_entry.internal_case_number }}</div>
			<div class="uk-child-width-1-2@m uk-grid-small tsa-document-form uk-grid" uk-grid="">
				<div v-if="case_response" class="uk-first-column">
					<div class="form-section">Requested Information</div>
					<div class="form-row data-row" v-html="case_response.next_steps.nl2br()"></div>
				</div>
				<div>
					<div class="form-section">Supply information</div>
					<div :class="formRowClass('additional_information')">
						<textarea placeholder="Requested information" rows="6" v-model="form_data.additional_information"></textarea>
						<span v-if="errors.additional_information" class="error-block">{{ errors.additional_information }}</span>
					</div>
					<div class="form-row">
						<button @click.prevent="submitResponse" class="tsa-btn tsa-btn-secondary">Submit</button>
					</div>
				</div>
			</div>
			<EngineerInfo />
		</div>
		<!-- This displays after response sent -->
		<div class="tsa-response-fields" v-if="!can_respond && !error_message">
			<div class="response-header">Information requested for {{ case_entry.receiver.name }} Case {{ case_entry.internal_case_number }}</div>
			<div class="uk-child-width-1-2@m uk-grid-small tsa-document-form uk-grid" uk-grid="">
				<div v-if="case_response" class="uk-first-column">
					<div class="form-section">Requested Information</div>
					<div class="form-row data-row" v-html="case_response.next_steps.nl2br()"></div>
				</div>
				<div>
					<div class="form-section">Supply information requested</div>
					<div :class="formRowClass('additional_information')" v-html="form_data.additional_information.nl2br()"></div>
				</div>
			</div>
			<EngineerInfo />
		</div>
		<div class="in-page-error" v-if="!can_respond && error_message">
			<i class="fas fa-exclamation-triangle"></i> {{ error_message }}</div>
	</div>
</template>
<script>
	import EngineerInfo from '@/components/CaseResponse/EngineerInfo.vue';
	import {mapState} from 'vuex';
	import _ from "lodash";
	export default {
		name: 'case-provide-information',
		components:{
			EngineerInfo
		},
		data(){
			return {
				can_respond: true,
				form_data:{
					additional_information:""
				},
				errors: {},
				error_message:null
			}
		},
		mounted(){
			this.getCaseInformation();
		},
		methods:{
			getCaseInformation(){
				const url = `${process.env.VUE_APP_API_URL}caller/case/${this.$route.params.token}`;
				// eslint-disable-next-line
				axios.get(url)
				.then(response => this.handleGetCaseInformation(response.data))
				// eslint-disable-next-line
				.catch(error => console.log(error));
			},
			handleGetCaseInformation(response){
				// if error
				if(response.error != undefined){
					this.error_message = response.error;
					this.can_respond = false;
				}else{
					// save case information in store
					this.$store.dispatch('caseEntry',response.case);
					this.$store.dispatch('caseResponse',response.response);
					this.$store.commit('setAppSlug',response.receiver.slug);
					for(let i = 0; response.allResponses.length > i;++i) {
						if (response.allResponses[i].type == "information-response") {
							//they've already responded.
							this.can_respond = false;
							this.form_data.additional_information = response.allResponses[i].next_steps;
						}
					}
				}
			},
			/**
			 * send case response to api
			 * @return {[type]} [description]
			 */
			submitResponse(){
				this.errors = {};
				this.emitter.emit('loader',true);
				let data = _.clone(this.form_data);
				data.status = "information-reply";
				// eslint-disable-next-line
				axios.post(`${process.env.VUE_APP_API_URL}caller/case/${this.case_entry.token}/respond`,data)
					.then(response => this.handleSubmitCase(response.data))
					// eslint-disable-next-line
					.catch(error => this.handleErrors(error.response.data.errors));
			},
			handleSubmitCase(response){
				console.log(response);
				this.emitter.emit('loader',false);
				this.emitter.emit('alert',"Your response has been sent.");
				this.can_respond = false;
				this.$forceUpdate();
			},
			handleErrors (errors){
				// clear all errors
				this.errors = {};
				for(var e in errors){
					this.errors[e] = errors[e][0];
				}
				this.emitter.emit('loader',false);
			},
			/**
			 * 'form-row' add 'has-error' class if field has error
			 * @param  {[type]} field [description]
			 * @return {[type]}       [description]
			 */
			formRowClass(field){
				let string = "form-row";
				if(this.errors[field] != undefined ) string += " has-error";
				return string;
			}
		},
		computed:{
			...mapState({
				case_entry: state => state.case_entry,
				jwt_token: state => state.token,
				case_response: state => state.case_response
			})
		}
	}
</script>