<template>
	<div>
		<ManageDocument
			v-if="!staticDocument && !parentDocument"
			:process_document="document" />
		<StaticDocument
			v-if="staticDocument"
			:process_document="document" />
		<ParentDocument
			v-if="parentDocument"
			:process_document="document" />
	</div>
</template>

<script>
	import {mapState} from 'vuex';
	import ManageDocument from './ManageDocument.vue';
	import StaticDocument from './StaticDocument.vue';
	import ParentDocument from './ParentDocument.vue';

	export default {
		name: 'super-admin-document',
		components:{
			ManageDocument,
			StaticDocument,
			ParentDocument
		},
		mounted(){
			this.initialized();
		},
		updated(){
			this.initialized();
		},
		data(){
			return {
				document: {}
			}
		},
		/**
		* remove bus event listeners before component is destroyed.
		* @return {[type]} [description]
		*/
		beforeUnmount(){
			this.emitter.off('token_ready');
		},
		methods: {
			initialized(){
				if(this.is_initialized) return;
				this.is_initialized = true;
				this.emitter.on('token_ready', () => this.getDocument());
				if(this.jwt_token.access_token != undefined) this.getDocument();
			},
			/**
			 * load document information from API
			 * @return {[type]} [description]
			 */
			getDocument(){
				if(this.$route.params.document_id == 0) return;
				//eslint-disable-next-line
				axios.get(`${process.env.VUE_APP_API_URL}super_admin/contact_documents/${this.$route.params.document_id}`)
				.then(response => this.handleGetDocument(response.data))
				//eslint-disable-next-line
				.catch(error => console.log(error));
			},
			handleGetDocument(response){
				if(response.error != undefined){
					this.emitter.emit('alert',{
						type: "error",
						message: response.error
					});
					return;
				}

				this.document = response;
			}
		},
		computed:{
			/*managedDocument(){
				if(this.$route.params.document_id == 0) return true;
				if(this.document.version != undefined){
					if(this.document.version.document_type == "ticket") return true;
				}
				return false;
			},*/
			staticDocument(){
				/*if(this.$route.params.document_id == 0) return false;
				if(this.document.version != undefined){
					if(this.document.version.document_type == "default") return true;
				}
				return false;*/
				return this.$route.params.type == "default";
			},
			parentDocument(){
				return this.$route.params.type == "parent";
			},
			...mapState({
				jwt_token: state => state.token
			})
		},
	}
</script>