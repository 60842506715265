<template>
	<div>
		<div class='form-section'>Change Password</div>
		<div class="with-label form-row" :class="formRowClass('password')">
			<label>Password</label>
			<input type="password" name="password" placeholder="Enter User Password" v-model="form_data.password" />
			<span class="error-block">{{ errors.password }}</span>
		</div>
		<div class="with-label form-row">
			<label>Confirm</label>
			<input type="password" name="password_confirmation" placeholder="Confirm Password" v-model="form_data.password_confirmation" />
			<span class="error-block">{{ errors.password_confirmation }}</span>
		</div>
		<div class="form-row">
			<button @click="changePassword" class="tsa-btn tsa-btn-secondary pull-right">Update Password</button>
		</div>
		<div class="form-row tsa-note small text-left" style="margin-top: 20px;">
			<PasswordRequirements />
		</div>
	</div>
</template>

<script>
	import PasswordRequirements from '@/components/Layout/PasswordRequirements.vue';
	export default {
		name: 'change-password',
		components: {
			PasswordRequirements
		},
		props: {
			user: {
				type: Object,
				default: () => {}
			}
		},
		data(){
			return {
				form_data: {
					password: "",
					password_confirmation: ""
				},
				errors: {}
			}
		},
		methods: {
			changePassword(){
				this.emitter.emit('loader',true);
				//eslint-disable-next-line
				axios.post(`${process.env.VUE_APP_API_URL}super_admin/users/${this.user.id}/password`,this.form_data)
				.then(response => this.handleUpdatePassword(response.data))
				//eslint-disable-next-line
				.catch(error => this.handleErrors(error.response.data.errors));

			},
			handleUpdatePassword(response){
				this.emitter.emit('loader',false);
				if(response.error != undefined){
					this.emitter.emit('alert',{
						type: "error",
						message: response.error
					});
					return;
				}
				this.emitter.emit('alert',response.message);
				this.$emit('updated');
			},
			/**
			 * 'form-row' add 'has-error' class if field has error
			 * @param  {[type]} field [description]
			 * @return {[type]}       [description]
			 */
			formRowClass(field){
				let string = "";
				if(this.errors[field] != undefined ) string += " has-error";
				return string;
			},
			handleErrors (errors){
				this.emitter.emit('loader',false);
				// clear all errors
				this.errors = {};
				for(var e in errors){
					this.errors[e] = errors[e][0];
				}
			}
		}
	}
</script>