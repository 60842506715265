<template>
	<div class="tsa-key-contacts">
		<KeyContact
			v-for="(contact,index) in key_contacts"
			:key="index"
			:contact="contact" />
	</div>
</template>

<script>

import KeyContact from '@/components/Home/KeyContact.vue';
import {mapState} from 'vuex';

export default {
	name: 'tsa-key-contacts',
	components: {
		KeyContact
	},
	computed:{
		...mapState({
			key_contacts: state => state.key_contacts
		})
	}
}
</script>
