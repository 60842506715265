<template>
	<div class="tsa-favorites" @click="toggleFavorites">
		<span class="placeholder">My Favorites ...</span>
		<i class="fas fa-angle-down"></i>
		<div class="tsa-list" ref="list">
			<i class="fas fa-times tsa-close"></i>
			<div class="tsa-inner" ref="listInner">
				<div class="tsa-rows" v-if="my_favorites.length > 0">
					<MyFavoriteRow
					v-for="(row,index) in my_favorites"
					:key="index"
					:favorite="row" />
				</div>
				<p class='tsa-instructions'>To add Favorites select the <i class="fas fa-star"></i> on the Process Form.</p>
			</div>
		</div>  
	</div>
</template>

<script>

	import {mapState} from 'vuex';
	import MyFavoriteRow from '@/components/Dashboard/MyFavoriteRow.vue';
	import {Interactive} from '@/includes/helpers';
	export default {
		name: 'my-favorite-list',
		components: {
			MyFavoriteRow
		},
		mounted(){
			this.initialize();

		},
		updated(){
			this.initialize();
		},
		data(){
			return {
				list_showing: false
			}
		},
		methods:{
			initialize(){
				window.addEventListener('resize',() => this.onWindowResize());
				this.onWindowResize();
			},
			/**
			 * when the window resizes re-calculate the height of the favorite window.
			 * @return {[type]} [description]
			 */
			onWindowResize(){
				const list = this.$refs.listInner;
				if(list == undefined) return;
				let height = Interactive.getRectSon(list).height;
				this.$refs.list.style.bottom = `-${height}px`;
			},
			/**
			 * hide/show favorite window.
			 * @return {[type]} [description]
			 */
			toggleFavorites(){
				// wait for refs to initialize.
				if(this.$refs.list == undefined) return;

				this.$refs.list.style.display = (this.list_showing)? "none" : "block";
				this.onWindowResize();
				this.list_showing = !this.list_showing;
			},
		},
		computed:{
			...mapState({
				my_favorites: state => state.my_favorites
			})
		},
	}
</script>