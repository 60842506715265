<template>
    <div class="alert-modal success" v-bind:class="modalClass">
        <div class="modal-window">
            <div class="modal-header">
                <div class="pull-right close" @click="onCancel"><i class="fa fa-times"></i></div>
                <slot>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat
                </slot>
            </div>
            <div class="modal-body">
                <div class="icon" v-bind:class="iconContainerClass">
                    <i class="far fa-question-circle"></i>
                </div>
            </div>
            <div class='footer'>
                <a href="" class="tsa-cancel" @click.prevent="onCancel">{{ cancel_text }}</a>
                <button @click="onConfirm" class="tsa-btn tsa-btn-secondary">{{ confirm_text }}</button>
            </div>
        </div>
    </div>
</template>

<script>
// import any required components

// export component
export default {
    // properties that the component will receive from parent
    props: {
        confirm_text:{
            type:String,
            default:"OK",
        },
        cancel_text:{
            type:String,
            default:"Cancel",
        },
        is_open:{
            type:Boolean,
            default:false,
        },
        icon:{
            type:String,
            default:""
        },
        type:{
            type:String,
            default:"success"
        },
    },
    // components to use in template
    components:{

    },
    // component data
    data() {
        return {};
    },
    // component was mounted
    mounted(){

    },
    // any methods needed for this component
    methods: {
        onCancel(){
            this.$emit('cancel');
        },
        onConfirm(){
            this.$emit('confirm');
        }
    },
    // computed properties
    computed:{
        modalClass(){
            return (this.is_open)? " open" : "";
        },
        iconClass(){
            if(this.icon.length > 0) return "fa-" + this.icon;
            let faclass = "";
            switch(this.type){
                case "warning":
                    faclass = "fa-exclamation-circle";
                break;
                case "success":
                    faclass = "fa-check-circle";
                break;
                case "delete":
                    faclass = "fa-trash";
                break;
            }
            return faclass;
        },
        iconContainerClass(){
            return (this.is_open)? "pulse_in" : "";
        }
    },
    // property watchers
    watch:{
    }
}
</script>