/**
 * middleware checks that user has admin rights.
 * must be business manager or program manager
 * @param  {[type]} options.next   [description]
 * @param  {[type]} options.router [description]
 * @return {[type]}                [description]
 */
export default function admin({next, router}) {
	const token = JSON.parse(localStorage.getItem('tsa_token'));
	let xhr = new XMLHttpRequest();
	let responseJSON = null;
	xhr.open("GET",`${process.env.VUE_APP_API_URL}auth/middleware`, false);
	xhr.setRequestHeader("Authorization", `Bearer ${token.access_token}`);
	xhr.setRequestHeader( 'Content-Type', 'application/json' );
	xhr.addEventListener( 'load',  function () {
		responseJSON = JSON.parse( xhr.responseText );
		//fun( xhr.responseJSON, xhr );
	});
	xhr.send();

	if(responseJSON.authorized === false){
		return router.push({ name: 'logout'});
	}
	
	const role = responseJSON.role;
	if (role != "business_manager" && role != "program_manager") {
		localStorage.setItem('tsa_alert',"You do not have access to see this page.");
		return router.push({ name: 'dashboard'});
	}

	return next();
}