<template>
    <div class="alert-modal success" v-bind:class="modalClass">
        <div class="modal-window">
            <div class="modal-header">
                <div class="pull-right close" @click="onClose" v-if="allow_close"><i class="fa fa-times"></i></div>
                {{ title }}
            </div>
            <div class="modal-body">
                <slot></slot>
            </div>
            <div class='footer'>
                <slot name="footer"></slot>
            </div>
        </div>
    </div>
</template>

<script>
// import any required components

// export component
export default {
    // properties that the component will receive from parent
    props: {
        /**
         * title of modal
         * @type {Object}
         */
        title:{
            type:String,
            default:"Modal Title",
        },
        /**
         * removes close button if neeed.
         * @type {Object}
         */
        allow_close: {
            type: Boolean,
            default: true
        },
        /**
         * if true modal will display
         * @type {Object}
         */
        is_open:{
            type:Boolean,
            default:false,
        },
        
    },
    // components to use in template
    components:{

    },
    // component data
    data() {
        return {};
    },
    // component was mounted
    mounted(){

    },
    // any methods needed for this component
    methods: {
        onClose(){
            this.$emit('close');
        },
    },
    // computed properties
    computed:{
        modalClass(){
            return (this.is_open)? " open" : "";
        },
    },
    // property watchers
    watch:{
    }
}
</script>