<template>
	<div class="tsa-partner-group-header" :style="bannerUrl">
		<div class="tsa-group-name" v-if="bannerUrl == ''">{{ group.name }}</div>
	</div>
</template>

<script>
	import {mapState} from 'vuex';

	export default {
		name: 'group-header',
		components: {

		},
		computed: {
			bannerUrl(){
				if(this.group.logo == null) return "";
				return `background-image: url(${process.env.VUE_APP_API_URL}resources/group_banner/${this.group.vhost})`;
			},
			...mapState({
				group: state => state.partner_group,
				company: state => state.company
			})
		}
	}
</script>