<template>
	<div class="tsa-users-list tsa-box low-padding">
		<div class="tsa-section-title tsa-small">Users with Access</div>
		<DataTable
			:utc_note="true"
			:table_headers="table_headers"
			:table_data="users"
			:searchable="false" />
	</div>
</template>
<script>
	import DataTable from '@/components/Table/DataTable.vue';
	import DeleteUser from '@/components/Admin/PartnerGroups/Users/DeleteUser.vue';

	export default {
		name: "partner-group-users-list",
		components: {
			DataTable
		},
		props:{
			users: {
				type: Array,
				default: () => []
			}
		},
		data(){
			return {
				table_headers: {
					'user.first_name': {
						display: "Name",
						render: (data) => {
							return `${data.user.first_name} ${data.user.last_name}` 
						}
					},
					'user.country.name': {
						display: "Location"
					},
					'user.role': {
						display: "Role",
						render: (data) => {
							return data.user.role.replace("_"," ").ucfirst();
						}
					},
					'user.last_login_date':{
						display: "Last Login",
						format: "date"
					},
					options: {
						sortable: false,
						component: DeleteUser
					}

				}
			}
		}
	}
</script>