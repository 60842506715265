<template>
	<div class="tsa-favorites" @click="toggleFavorites" :class="{disabled: !favorites_available}">
		<div class="loader"><i class="fas fa-spinner fa-spin"></i></div>
		<span class="placeholder">Your Partners ...</span>
		<i class="fas fa-angle-down"></i>
		<div class="tsa-list" ref="list">
			<i class="fas fa-times tsa-close"></i>
			<div class="tsa-inner" ref="listInner">
				<div class="tsa-rows" v-if="my_favorites.length > 0">
					<MyPartnerRow
					v-for="(row,index) in my_favorites"
					:key="index"
					:partner="row" />
				</div>
			</div>
		</div>  
	</div>
</template>

<script>

	import {mapState} from 'vuex';
	import MyPartnerRow from '@/components/Limited/MyPartnerRow.vue';
	import {Interactive} from '@/includes/helpers';
	export default {
		name: 'my-favorite-list',
		components: {
			MyPartnerRow
		},
		mounted(){
			this.initialize();

		},
		updated(){
			this.initialize();
		},
		data(){
			return {
				list_showing: false
			}
		},
		methods:{
			initialize(){
				window.addEventListener('resize',() => this.onWindowResize());
				this.onWindowResize();
			},
			/**
			 * when the window resizes re-calculate the height of the favorite window.
			 * @return {[type]} [description]
			 */
			onWindowResize(){
				//console.log(this.$refs.listInner);
				const list = this.$refs.listInner;
				if(list == undefined) return;
				let height = Interactive.getRectSon(list).height;
				this.$refs.list.style.bottom = `-${height}px`;
			},
			/**
			 * hide/show favorite window.
			 * @return {[type]} [description]
			 */
			toggleFavorites(){
				if(!this.favorites_available) return;
				if(this.$refs.list == undefined) return;
				this.$refs.list.style.display = (this.list_showing)? "none" : "block";
				this.onWindowResize();
				this.list_showing = !this.list_showing;
			},
		},
		computed:{
			...mapState({
				my_favorites: state => state.my_favorites,
				favorites_available: state => state.favorites_available
			})
		},
	}
</script>