<template>
	<div uk-grid class="uk-child-width-1-2 uk-grid-small process-child-row" v-if="row_data.deleted === false">
		<div>
			<div class="form-row" :class="{'has-error' : hasError('option_name')}" >
				<input type="text" placeholder="Enter Option Name" v-model="option_name" @keyup="onUpdate" />
				<span class="error-block">{{ errorMessage('option_name') }}</span>
			</div>
		</div>
		<div>
			<div class="form-row" :class="{'has-error' : hasError('contact_document_id')}" >
				<select v-model="contact_document_id" @change="onUpdate">
					<option value="">Choose Inbound Process</option>
					<option
						v-for="(doc,index) in documents"
						:key="index"
						:value="doc.id">{{ doc.name }}</option>
				</select>
				<span class="error-block" style="right: 58px;">{{ errorMessage('contact_document_id') }}</span>
				<i class="fas fa-trash-alt danger" @click.prevent="deleteRow"></i>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		name : "parent-child-row",
		components : {

		},
		props : {
			row_data : {
				type : Object,
				default : () => {}
			},
			documents : {
				type : Array,
				default : () => []
			},
			index : {
				type : Number,
				default : 0
			},
			errors : {
				type : Object,
				default : () => {}
			},
		},
		data(){
			return {
				contact_document_id : "",
				option_name : ""
			}
		},
		mounted(){
			this.cloneData();
		},
		methods : {
			deleteRow(){
				this.$emit('delete',this.index);
			},
			/**
			 * send changes to parent component
			 * @return {[type]} [description]
			 */
			onUpdate(){
				this.$emit('update',{
					index : this.index,
					option_name : this.option_name,
					contact_document_id : this.contact_document_id
				});
			},
			hasError(field){
				let string = "";
				if(this.errors[`linkages.${this.index}.${field}`] != undefined) string = "has-error";
				return string;
			},
			errorMessage(field){
				if(!this.hasError(field)) return "";
				let message = this.errors[`linkages.${this.index}.${field}`];
				return message.replace(`linkages.${this.index}.`,"");
			},
			cloneData(){
				this.contact_document_id = this.row_data.contact_document_id;
				this.option_name = this.row_data.option_name;
			}
		},
		watch : {
			row_data(){
				this.cloneData();
			}
		}
	}
</script>