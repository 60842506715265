<template>
	<div class="tsa-expandable-box tsa-box low-padding" ref="expandable">
		<div class="tsa-box-header" @click.prevent="toggleExpanded">
			<i class="fas fa-caret-right expand-icon"></i>
			<slot name="headerright"></slot>
			<div class='tsa-section-title'>{{ title }} <slot name="after_title"></slot></div>
		</div>
		<div class="tsa-expandable-body" ref="body">
			<div class="tsa-body-content" ref="content">
				<slot></slot>
			</div>
		</div>
	</div>
</template>
<script>
	import {Interactive} from '../../includes/helpers.js';
	export default {
		name: 'expandable-box',
		components: {

		},
		props: {
			title: {
				type: String,
				default: "The Title"
			},
			expanded: {
				type: Boolean,
				default: false
			}
		},
		data(){
			return {
				is_expanded: false
			}
		},
		methods: {
			/**
			 * expand collapse body.
			 * @return {[type]} [description]
			 */
			toggleExpanded(){
				this.is_expanded = !this.is_expanded;
			},
			/**
			 * animate toogle of expanded or collapse
			 * @return {[type]} [description]
			 */
			animatedExpanded(){
				// closed
				if(this.is_expanded){
					const rect = Interactive.getRectSon(this.$refs.content);
					this.$refs.expandable.classList.add('expanded');
					this.$refs.body.style.maxHeight = rect.height + "px";
					this.$refs.body.style.height = rect.height + "px";
				}else{
					this.$refs.expandable.classList.remove('expanded');
					this.$refs.body.style.maxHeight = 'auto';
					this.$refs.body.style.height = 0;
				}
			}
		},
		watch:{
			is_expanded(){
				this.animatedExpanded();
			},
			/**
			 * watch prop in case parent sends the request to toggle.
			 * @return {[type]} [description]
			 */
			expanded(){
				this.is_expanded = this.expanded;
			}
		}
	}
</script>