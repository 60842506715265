<script>
	import Expandable from '@/components/Layout/ExpandableBox.vue';
	import DataTable from '@/components/Table/DataTable.vue';

	export default {
		name: 'company-contact-documents',
		components: {
			Expandable,
			DataTable
		},
		props: {
			company: {
				type: Object,
				default: () => {}
			}
		},
		data(){
			return {
				table_headers:{
					'community.name': {
						display: "Name",
						route: "/super_admin/companies/{company_id}/groups/{id}/edit"
					},
					'department.name': {
						display: "Department",
					},
					type: {
						searchable: false,
						render(cell_data){
							return cell_data.community.type.replace('_', " ").ucfirst();
						}
					},
					'community.host.name': {
						display: "Host",
						searchable: false,
					},
					status: {
						render: cell_data => {
							const color = cell_data.is_active ? "green" : "red";
							return `<i class="fas fa-circle ${color}"></i>`;
						}
					}
				},
				table_data: []
			}
		},
		methods: {
			addNew(){
				this.$router.push({
					name: "super_admin.company.groups.edit",
					params: {
						company_id: this.company.id,
						relation_id: 0
					}
				});
			},
			getTableData(){
				//eslint-disable-next-line
				axios.get(`${process.env.VUE_APP_API_URL}super_admin/companies/${this.company.id}/groups`)
				.then(response => this.handleGetTableData(response.data))
				//eslint-disable-next-line
				.catch(error => console.log(error));
			},
			handleGetTableData(response){
				this.table_data = response;
			}
		},
		computed: {
			theTitle(){
				return `Groups (${this.table_data.length})`;
			}
		},
		watch: {
			company(){
				if(this.company.id != undefined) this.getTableData();
			}
		}
	}
</script>

<template>
	<div class="tsa-company-communities">
		<Expandable
			:title="theTitle">
			<template v-slot:headerright>
				<button
					class="tsa-btn tsa-outline pull-right"
					@click.prevent="addNew"
					><i class="fas fa-plus"></i> Add New</button>
			</template>
			<DataTable
				:table_headers="table_headers"
				:table_data="table_data"
				:searchable="false" />
		</Expandable>
	</div>
</template>