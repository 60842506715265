<template>
    <div v-if="stripePublic" class="form-row">
        <label>Card Information:</label>
		<div id="payment-form"></div>
        <span class="error-block" v-show="hasError">{{ error }}</span>
    </div>
	<div v-else>
		NO STRIPE KEY SET
	</div>
</template>

<script>
    // export component
	export default {
        // properties that the component will receive from parent
        props: {
            'error':{},
            'label':{
                type:String,
                default:""
            },
            'placeholder':{
                default:""
            },
            'field_id':{},
            'value':{
                default:""
            },
            'label_class':{
                default:'label'
            },
            'field_class':{
                default:'field-div'
            },
            'required':{
                type:Boolean,
                default:false
            },
            'disabled':{
                type:Boolean,
                default:false
            },
            cc_tabindex : {
                type: String,
                default: ""
            },
            type_tabindex : {
                type: String,
                default: ""
            }
        },
        // components to use in template
        components:{},
        // component data
        data() {
            return {
                /*'digits':"",
                'masked_value':"",
                visa_mask: "#### #### #### ####",
                amex_mask: "#### ###### #####",
                the_mask: "#### #### #### ####",
				targetLength: 16,
                card_types:{
                    'visa': "Visa",
                    'mc' : "MasterCard",
                    'amex' : "American Express"
                },
                card_type: "visa",*/
				//stripe: null,
				stripePublic:"",
				cardElement:null,
            };
        },
        // component was mounted
        mounted(){
			this.stripePublic = process.env.VUE_APP_STIPE_PUBLIC;
			let stripeScript = document.createElement("script");
			stripeScript.src = "https://js.stripe.com/v3/";
			document.head.appendChild(stripeScript);
			stripeScript.onload = function(){
				// eslint-disable-next-line no-undef
				this.stripe = Stripe(this.stripePublic);
				this.cardElement = this.stripe.elements().create("card");
				this.cardElement.mount("#payment-form");
				
				this.cardElement.on("change", (val) => this.cardChange(val))
			}.bind(this);
        },
        // any methods needed for this component
        methods: {
			getToken(){
				this.stripe.createToken(this.cardElement)
					.then((pack) => this.processToken(pack))
					.catch((err) => {console.warn(err)})
			},
			processToken(pack){
				if(pack && pack.token){
					this.$emit("token", {token: pack.token});
				}
			},
            hasError(){
                if(this.error == null) return false;
                return (this.error.length > 0);
            },
            handleValue(value){
                return value == null ? "" : value;
            },
			cardChange(val){
				//this.$emit('valid',{valid: val.complete });
				if(val && val.complete){
					this.getToken();
				}
			},
            emitChange(){

                this.digits = this.masked_value.replace(/\D/g,'');
                this.$emit('input',this.digits);
                const length = (this.card_type == 'amex')? 15 : 16;
                const is_valid =  this.digits.length >= length;
                this.$emit('valid',{valid: is_valid });

            },
            // allow only numbers to be entered
            onlyNumbers(event){
                event = (event) ? event : window.event;
                var charCode = (event.which) ? event.which : event.keyCode;
                if (charCode < 48 || charCode > 57){
                    event.preventDefault();
                }else{
                    this.checkMask();
                    return true;
                }
            },

            errorClass(){
                return (this.hasError())? "has-error" : "";
            },
            checkMask(){
                // if first digit 3 and not amex
                if(this.digits.substr(0,1) == "3"){ // amex
					this.card_type = "amex";
					this.targetLength = 15;
                    this.the_mask = this.amex_mask;
                }else if(this.digits.substr(0,1) == "5"){
					this.card_type = "mc";
					this.targetLength = 16;
                    this.the_mask = this.visa_mask;
                }else{
					this.card_type = "visa";
					this.targetLength = 16;
                    this.the_mask = this.visa_mask;
                }
                if(this.masked_value.length > this.targetLength){
                   this.masked_value =  this.masked_value.substring(0,this.targetLength);
                }
				this.$forceUpdate();
            }
        },
        // watch property changes
        watch:{
            card_type(){
                //this.checkMask();
            }
        },
        // computed properties
        computed:{
            handleClassList(){
                var classList = "form-group ";
                if(this.required) classList += "required ";
                classList += this.errorClass();
                return classList;
            },
            labelClass(){
                return "control-label " + this.label_class;
            },
            fieldDivClass(){
                return "form-field " + this.field_class;
            },
            placeHolder(){
                if(this.placeholder.length > 0)
                    return this.placeholder;
                else{
                    return "Enter " + this.label;
                }
            },
        }
    }
</script>