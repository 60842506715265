<template>
	<span class="delete-icon">
		<i class="fas fa-trash-alt danger" @click.prevent="deleteConfirm"></i>
	</span>
</template>

<script>
	export default {
		name: "company-delete-group",
		components:{

		},
		props:{
			cell_data:{
				type: Object,
				default: () => {}
			}
		},
		methods:{
			/**
			 * confirm that user wants to delete document.
			 * @return {[type]} [description]
			 */
			deleteConfirm(){
				this.emitter.emit('confirm_delete_group',this.cell_data);
			}
		}
	}
</script>