<template>
    <nav aria-label="Page navigation"  class="tsa-pagination" v-show="pages > 1" :class="isBottom">
        <ul class="tsa-pagination pull-right">
            <!-- first -->
            <li class="page-item" v-show="page > 1">
                <a class="page-link first" href="#" aria-label="Next" @click.prevent="goToFirstPage">
                    <span aria-hidden="true"><i class="fa fa-angle-double-left"></i></span>
                    <span class="sr-only">First</span>
                </a>
            </li>
            <!-- prev -->
            <li class="page-item" v-show="page != 1">
                <a class="page-link prev" href="#" aria-label="Previous" @click.prevent="goToPrevPage">
                    <span aria-hidden="true"><i class="fa fa-angle-left"></i></span>
                    <span class="sr-only">Previous</span>
                </a>
            </li>
            <li class="page-item current">
                Page {{page }} of {{ pages }}
            </li>
            <!-- next -->
            <li class="page-item" v-show="page != pages">
                <a class="page-link next" href="#" aria-label="Next" @click.prevent="goToNextPage">
                    <span aria-hidden="true"><i class="fa fa-angle-right"></i></span>
                    <span class="sr-only">Next</span>
                </a>
            </li>
            <!-- last -->
            <li class="page-item" v-show="page <= (pages - 1)">
                <a class="page-link last" href="#" aria-label="Next" @click.prevent="goToLastPage">
                    <span aria-hidden="true"><i class="fa fa-angle-double-right"></i></span>
                    <span class="sr-only">Last</span>
                </a>
            </li>
        </ul>
        <div class="clearfix"></div>
    </nav>
</template>

<script>
// import any required components

// export component
export default {
    // properties that the component will receive from parent
    props: {
        'count':{
            type:Number,
            default:0
        },
        'page':{
            type:Number,
            default:0
        },
        'per_page':{
            type:Number,
            default:0
        },
        bottom:{
            type:Boolean,
            default:false
        }
    },
// components to use in template
components:{

},
// component data
data() {
    return {
        'pages':1
    };
},
// component was mounted
mounted(){
    this.pages = Math.ceil(this.count/this.per_page);
},
// any methods needed for this component
methods: {
    goToPage(page){
        this.$emit('page',page);
    },
    isActive(page){
        return (page == this.page)? "active" : "";
    },
    goToNextPage(){
        var page = this.page + 1;
        this.goToPage(page);
    },
    goToPrevPage(){
        var page = this.page - 1;
        this.goToPage(page);
    },
    goToFirstPage(){
        this.goToPage(1);
    },
    goToLastPage(){
        this.goToPage(this.pages);
    }
},
// computed properties
computed:{
    isBottom(){
        return (this.bottom)? "bottom" : "";
    }
},
watch:{
    count:function(){
        this.pages = Math.ceil(this.count/this.per_page);
    }
},
}
</script>