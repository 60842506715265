\<template>
	<div class="company-internal-notes">
		<DataTable
				:searchable="false"
				:table_headers="table_headers"
				:row_class="rowClass"
				:table_data="notes" />
	</div>
</template>

<script>
	import DataTable from '@/components/Table/DataTable.vue';

	export default {
		name: 'document-internal-notes',
		components: {
			DataTable
		},
		props: {
		},
		data(){
			return {
				table_headers:{
					'document.company.name': {
						display: "Member",
					},
					'document.name' : {
						display: "Process"
					},
					notes: {
						display: "Note",
						render: cell_data => {
							if(cell_data.note == null) return "";
							return cell_data.note.replace(/<[^>]*>?/gm, '').truncate();
						}
					}
				},
				notes: [],
				initialized: false
			}
		},
		mounted(){
			this.getNotes();
		},
		methods: {
			getNotes(){
				//eslint-disable-next-line
				axios.get(`${process.env.VUE_APP_API_URL}admin/company/internal_notes`)
				.then(response => this.handleGetNotes(response.data))
				//eslint-disable-next-line
				.catch(error => console.log(error));
			},
			handleGetNotes(response){
				this.notes = response;
			},
			/**
			 * determine row class for document
			 * @return {[type]} [description]
			 */
			//eslint-disable-next-line
			rowClass(cell_data){
				return "document-notes-row"
			}
		},
		computed: {
		},
		watch: {
		}
	}
</script>