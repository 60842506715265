<template>
	<span class="tsa-hint">
		<HintModal
			:title="hint.title"
			:hint="hint.content"
			:is_open="show_hint"
			@ok="closeHint" />
		<i class="fas fa-info-circle" @click="showHint"></i>
	</span>
</template>

<script>
	import HintModal from '@/components/Layout/HintModal.vue';
	export default {
		name: "help-hint",
		components: {
			HintModal
		},
		props: {
			flag: {
				type: String,
				default: ""
			},
			content: {
				type: Object,
				default: () => {}
			}
		},
		data() {
			return {
				hint: {
					title: "",
					content: ""
				},
				show_hint: false
			}
		},
		methods: {
			showHint(){
				this.emitter.emit('loader',true);
				//eslint-disable-next-line
				axios.get(`${process.env.VUE_APP_API_URL}help_hints/${this.flag}`)
				.then(response => this.handleGetResponse(response.data))
				//eslint-disable-next-line
				.catch(error => console.log(error));
			},
			replaceContent(){
				for(let i in this.content){
					this.hint.title = this.hint.title.replace(`{content.${i}}`,this.content[i]);
					this.hint.content = this.hint.content.replace(`{content.${i}}`,this.content[i]);
				}
			},
			handleGetResponse(response){
				this.emitter.emit('loader',false);
				this.hint = response;
				setTimeout(()=> this.replaceContent(),0);
				this.show_hint = true;
			},
			closeHint(){
				this.show_hint = false;
				this.hint = {};
			}
		},
		watch: {
			content(){
				this.replaceContent();
			}
		}
	}
</script>
