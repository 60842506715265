<template>
    <div :class="containerClass">
        <input
        v-show="show_field"
        :id="requirement.requirement.code_label"
        type="text"
        ref='textfield'
        :placeholder="placeholder"
        :disabled="disabled"
        @input="emitChange" />
        <span class="error-block"><strong>{{ error }}</strong></span>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    export default {
        name: "TSANetString",
        props: {
            requirement: {
                type: Object,
                default: () => {}
                },
            value: {
                type: String,
                default: ""
                },
            error: {
                type: String,
                default: ""
            },
            disabled: {
                type: Boolean,
                default: false
            }
        },
        data(){
            return {
                show_field : true,
				startValue : null
            }
        },
        mounted(){
            if(this.requirement.requirement.code_label != null){
                this.emitter.on(`${this.requirement.requirement.code_label}_hide`, () => this.toogleVisibility(false));
                this.emitter.on(`${this.requirement.requirement.code_label}_show`, () => this.toogleVisibility(true));
            }
			this.$refs.textfield.value = this.value;
        },
        beforeUnmount(){
            if(this.requirement.requirement.code_label != null){
                this.emitter.off(`${this.requirement.requirement.code_label}_hide`);
                this.emitter.off(`${this.requirement.requirement.code_label}_show`);
            }
        },
        computed: {
            placeholder(){
                let label = this.requirement.requirement.label.replace('{{Send_Member}}',this.company.name);
                let ph = `Enter ${label}`;
                return ph;
            },
            containerClass(){
                let string = `form-group-${this.requirement.group} form-row tsa-custom-string`;
                if(this.error.length > 0) string += ' has-error';
                if(this.requirement.group != null){
                    if(this.document_active_groups[this.requirement.group] === false){
                        string += " hide";
                    }
                }
                return string;
            },
            ...mapState({
                document_active_groups: state => state.document_active_groups,
                // the company of the user that is logged in.
                company : state => state.company
            })
        },
        methods: {
            emitChange(){
                this.$emit('change',{
                    requirement_id : this.requirement.requirement_id,
                    data: this.$refs.textfield.value
                });
            },
            toogleVisibility(show){
                this.$emit('required',{
                    required : show,
                    field_id : this.requirement.requirement_id
                });
                this.show_field = show;
            }
        }
    }
</script>