<template>
	<span>
		<a href="#"
			@click.prevent="deleteDocument"
		><i class="fas fa-trash-alt danger" ></i></a>
	</span>
</template>

<script>
	
	export default {
		name: "admin-company-delete-process",
		components:{

		},
		props:{
			cell_data:{
				type: Object,
				default: () => {}
			}
		},
		methods:{
			/**
			 * confirm that user wants to delete document.
			 * @return {[type]} [description]
			 */
			deleteDocument(){
				this.emitter.emit('confirm',{
					message: `Are you sure you want to delete ${this.cell_data.name} from this company?`,
					flag: `confirm_delete_contact_document`,
					data: this.cell_data.document_id
				});
			}
		},
		computed: {
		}
	}
</script>