<template>
	<div class="tsa-add-document tsa-document-form">
		<div class="form-section">Provide the following information:</div>
		<div :class="formRowClass('name')">
			<input type="text" placeholder="Enter Document Name" v-model="form_data.name" />
			<span class="error-block">{{ errors.name }}</span>
		</div>
        <div :class="formRowClass('name')">
            <select v-model="form_data.document_type">
                <option value="file">Document/File</option>
                <option value="url">URL/Website</option>
            </select>
            <span class="error-block">{{ errors.document_type }}</span>
        </div>
        <!-- url -->
        <div :class="formRowClass('url')"  v-if="form_data.document_type == 'url'">
            <input type="text" placeholder="Enter Url Address" v-model="form_data.url" />
            <span class="error-block">{{ errors.name }}</span>
        </div>
		<!-- file upload -->
        <div :class="formRowClass('file')" class="tsa-custom-file" v-if="form_data.document_type == 'file'">
            <input type="file" ref="file_input" @change="onFileSelected($event)" :accept="fileTypesAccepted" />
            <label>{{ file_name }}</label>
            <span class="error-block">{{ errors.file }}</span>
        </div>
        <div class="form-row">
            <button class='tsa-btn' @click="uploadDocument">Upload</button>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
	export default {
		name: "upload-document",
        components: {
        },
		props: {
			group: {
                type: Object,
                default: () => {}
            },
            partners: {
                type: Array,
                default: () => {}
            }
		},
        data(){
            return {
                form_data:{
                    name: "",
                    has_file:false,
                    file:null,
                    document_type: "file",
                    url: ""
                },
                errors: {},
                file_types: ['.xls','.xlsx','.doc','.docx','.pdf','.jpg','.jpeg','.png'],
                file_name: "Choose File"
            }
        },
		methods: {
			onFileSelected(event){
                // set file path on label
                var fieldVal = this.$refs.file_input.value;
                // Change the node's value by removing the fake path (Chrome)
                fieldVal = fieldVal.replace("C:\\fakepath\\", "");
                this.file_name = fieldVal;
                this.form_data.has_file = true;
                this.form_data.file = event.target.files[0];
            },
            uploadDocument(){
                this.emitter.emit('loader',true);
                let formData = new FormData();
                formData.append("name",this.form_data.name);
                formData.append("company_id",this.form_data.company_id);
                formData.append("document_type",this.form_data.document_type);
                formData.append("url",this.form_data.url);

                if(this.form_data.has_file){
                    formData.append("file", this.form_data.file);
                }
                let url = `${process.env.VUE_APP_API_URL}admin/company/partner_groups/${this.group.id}/documents/upload`;
                //eslint-disable-next-line
                axios.post(url,formData)
                    .then(response => this.handleUploadResponse(response.data))
                    //eslint-disable-next-line
                    .catch(error => this.handleErrors(error.response));
            },
            handleUploadResponse(response){
                this.emitter.emit('loader',false);
                // error with submission
                if(response.error != undefined){
                    this.emitter.emit('alert',{
                        type:"warning",
                        message: response.error
                    });
                }else{
                    this.form_data = {
                        name: "",
                        has_file:false,
                        file:null,
                        document_type: "file",
                        url: ""
                    };
                    this.$emit('uploaded');
                }
            },
            /**
             * 'form-row' add 'has-error' class if field has error
             * @param  {[type]} field [description]
             * @return {[type]}       [description]
             */
            formRowClass(field){
                let string = "form-row";
                if(this.errors[field] != undefined ) string += " has-error";
                return string;
            },
            /**
             * parse to list of returned validation errors and add them to data object.
             * @param  object errors error object returned from Laravel
             * @return void
             */
            handleErrors (error){
                this.emitter.emit('loader',false);
                let message = "";
                // 413 Nginx error (file too large.)
                if(error.status == 413){
                    message = `Unable to upload your file. That max file size allowed is ${this.php_upload_max_filesize}`;
                }else{
                    // clear all errors
                    this.errors = {};
                    for(var e in error.data.errors){
                        this.errors[e] = error.data.errors[e][0];
                    }
                    message = "There was an error with your submission. Please check your entry and try again.";
                }
                this.$emit('alert',{
                    type:"warning",
                    message: message
                });
            },
		},
        computed: {
            fileTypesAccepted(){
                return this.file_types.join(',');
            },
            ...mapState({
                php_upload_max_filesize : state => state.php_upload_max_filesize
            })
        }
	}
</script>