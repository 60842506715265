<template>
	<div class="tsa-box engineer-info">
		<div class="tsa-note danger"><strong>Note!</strong> After Submitting your response contact the {{ case_entry.company.name }} Assigned Engineer to work the case.</div>
		<div class="uk-child-width-1-2@m uk-grid-small" uk-grid>
			<div>
				<div class="form-section">Case Information:</div>
				<p class='user-info'>
					{{ case_entry.company.name }} Case#: {{ case_entry.internal_case_number }}<br>
					Assigned Engineer: {{ case_entry.engineer.first_name}} {{ case_entry.engineer.last_name }}<br>
					Email: <a :href="`mailto:${case_entry.engineer.email}`">{{ case_entry.engineer.email }}</a><br>
					Phone: {{ engineerPhone }}
					<span v-for="(row,index) in case_entry.customer_data" :key="index">
						<span v-if="row.section == 'contact_section'">{{ row.field }}: {{ row.value}}<br></span>
					</span>
				</p>
				<div class="form-section">Problem:</div>
				<div class="customer-data">
					<div>Summary: {{ case_entry.summary }}</div>
					<div>Description:</div>
					<p v-html="caseDescription" class="tsa-break-word"></p>
					<div>Priority: {{ casePriority }} - P{{ casePriorityNumber }}</div>
					<div v-for="(row,index) in case_entry.customer_data" :key="index">
						<div v-if="row.section == 'problem_section'">{{ row.field }}: {{ row.value}}</div>
					</div>
				</div>
			</div>
			<div>
				<div class="form-section">{{ customerInfoTitle }}:</div>
				<div class="customer-data" v-if="case_entry.customer_data.length == 0">
					<strong><i class="fas fa-exclamation-triangle danger"></i> Customer data is removed one (1) month from the day the case was submitted.</strong>
				</div>
				<div class="customer-data" v-else>
					<div v-for="(row,index) in case_entry.customer_data" :key="index">
						<div v-if="row.section == 'common_customer_section'">{{ row.field }}: {{ row.value}}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapState} from 'vuex';
	import parsePhoneNumber from 'libphonenumber-js';
	export default {
		name: 'engineer-info',
		components: {},
		computed:{
			customerInfoTitle(){
				if(this.case_entry.document == undefined) return "Customer Info:";
				return (this.case_entry.document.version.document_type == "ticket")? "Customer Info" : "Additional Information";
			},
			engineerPhone(){
				if(this.case_entry.engineer.phone_country_code){
					let phNumber = parsePhoneNumber(this.case_entry.engineer.phone, this.case_entry.engineer.phone_country_code);
					return this.case_entry.engineer.phone_country_code + ": " + phNumber.formatInternational();
				}else{
					return this.case_entry.engineer.phone;
				}
			},
			casePriority(){
				if(this.case_entry.priority == undefined) return "";
				return this.case_entry.priority.ucfirst();
			},
			casePriorityNumber(){
				if(this.case_entry.priority == undefined) return "";
				let number = 0
				switch(this.case_entry.priority){
					case "low":
						number = 3;
					break;
					case "medium":
						number = 2;
					break;
					case "high":
						number =  1;
					break;
				}
				return number;
			},
			caseDescription(){
				if(this.case_entry.description == undefined) return "";
				return this.case_entry.description.nl2br();
			},
			...mapState({
				case_entry: state => state.case_entry
			})
		}
	}
</script>