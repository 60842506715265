<template>
	<div class="tsa-manage-partner-group">
		<CompanyChangeRequest
			:request_type="`${group.name} Group`"
			:is_open="show_request_window"
			@close="onCloseChangeRequest" />
		<div class="tsa-admin-header">
			<HelpHint flag="partner_group_details" :content="helpContent" /> <span class="page-name">{{ group.name }} &mdash;</span> To make changes <button class="tsa-btn tsa-btn-secondary" @click.prevent="contactTSA">Contact TSANet</button>
		</div>
		<Breadcrumb :routes="breadcrumb" />
		<Details :group="group" />
		<div class="uk-child-width-1-2@m uk-grid-small" uk-grid>
			<div><Partners :partners="partners" :group="group" v-if="showPartners" /></div>
			<div><Documents
				v-if="group.type != 'open_group'"
				:group="group"
				:partners="partners" /></div>
		</div>
	</div>
</template>

<script>
	import HelpHint from '@/components/Layout/HelpHint.vue';
	import Details from '@/components/Admin/PartnerGroups/Details.vue';
	import Partners from '@/components/Admin/PartnerGroups/Partners.vue';
	import Documents from '@/components/Admin/PartnerGroups/Documents.vue';
	import Breadcrumb from '@/components/Layout/Breadcrumb.vue';
	import CompanyChangeRequest from '@/components/Admin/CompanyChangeRequest.vue';

	import {mapState} from 'vuex';

	export default {
		name: 'admin-manage-partner-group',
		components: {
			HelpHint,
			Details,
			Partners,
			Documents,
			Breadcrumb,
			CompanyChangeRequest
		},
		data(){
			return {
				show_request_window: false,
				group: {},
				partners: []
			}
		},
		mounted(){
			this.initialize();
		},
		updated(){
			if(!this.has_initialized) this.initialize();
		},
		/**
		* remove bus event listeners before component is destroyed.
		* @return {[type]} [description]
		*/
		beforeUnmount(){
			this.emitter.off('token_ready');
		},
		methods: {
			initialize(){
				this.has_initialized = true;
				
				this.emitter.on('token_ready', () => this.loadPartnerGroup());
				if(this.jwt_token.access_token != undefined) this.loadPartnerGroup();
			},
			loadPartnerGroup(){
				const group_id = this.$route.params.group_id;
				//eslint-disable-next-line
				axios.get(`${process.env.VUE_APP_API_URL}admin/company/partner_groups/${group_id}`)
				.then(response => this.handleLoadGroup(response.data))
				//eslint-disable-next-line
				.catch(error => console.log(error));
			},
			handleLoadGroup(response){
				this.group = response.group;
				this.partners = response.partners;
			},
			/**
			 * request to close change request window
			 * @return {[type]} [description]
			 */
			onCloseChangeRequest(){
				this.show_request_window = false;
			},
			contactTSA(request_type){
				this.request_type = request_type;
				this.show_request_window = true;
			}
		},
		computed: {
			showPartners(){
				if(this.group.type != "partner_program") return true;
				return this.group.sponsor_company_id == this.user.company_id;
			},
			helpContent(){
				return {
					group_name: this.group.name
				}
			},
			breadcrumb(){
				return [
					{display: "Partner Groups" ,route: {name: "admin.partner_groups"}},
					(this.group.name != undefined)? this.group.name : ""
				];
			},
			...mapState({
				jwt_token: state => state.token,
				user : state => state.user
			})
		}
	}
</script>