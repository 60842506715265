<template>
	<div class="limited-copy">
		<div ref="copyField" class="limited-copy-content" v-html="copy"></div>
		<div v-if="showControls" class="limited-copy-controls">
			<button v-if="!expanded" class="uk-button uk-button-link" @click="expand">More <i class="fa fa-chevron-down"></i></button>
			<button v-if="expanded" class="uk-button uk-button-link" @click="collapse">Less <i class="fa fa-chevron-up"></i></button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'LimitedCopy.vue',
	components: {},
	mixins: [],
	props: {
		copy: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			expanded: false,
			showControls: false,
		}
	},
	mounted() {
		this.calculateControlNeeds();
	},
	methods: {
		calculateControlNeeds() {
			let maxHeight = this.$refs.copyField.scrollHeight;
			if(parseInt(window.getComputedStyle(this.$refs.copyField).height) < maxHeight){
				this.showControls = true;
			}
		},
		expand() {
			this.expanded = true;
			let maxHeight = this.$refs.copyField.scrollHeight;
			this.$refs.copyField.style.height = maxHeight + 'px';
		},
		collapse() {
			this.expanded = false;
			this.$refs.copyField.style.height = null;
		},
	},
	computed: {},
	watch: {
		copy() {
			setTimeout(() => this.calculateControlNeeds(), 15);
		}
	},
}
</script>