<template>
	<div class="tsa-documents tsa-box low-padding">
		<DefaultModal
			title="Add New Document"
			@close="onCancelAddDocument"
			:is_open="show_add_document">
				<UploadDocument
					:group="group"
					:partners="partners"
					@uploaded="onAssetUploaded" />
		</DefaultModal>
		<div class="tsa-section-title tsa-small">
			<button class="tsa-btn tsa-plain pull-right" @click="addDocument">Add Document/Link</button>
			Documents</div>
		<DataTable 
			:searchable="false"
			:table_data="documents"
			:table_headers="table_headers"
		/>
	</div>
</template>

<script>
	import DataTable from '@/components/Table/DataTable.vue';
	import DefaultModal from '@/components/Layout/DefaultModal.vue';
	import DeleteDocument from '@/components/SuperAdmin/Groups/Manage/DeleteDocument.vue';
	import UploadDocument from '@/components/SuperAdmin/Groups/Manage/UploadDocument.vue';
	import DownloadDocument from '@/components/SuperAdmin/Groups/Manage/DownloadDocument.vue';
	export default {
		name: "admin-partner-group-documents",
		components: {
			DataTable,
			DefaultModal,
			UploadDocument
		},
		props: {
			group: {
				type: Object,
				default: () => {}
			},
			partners: {
				type: Array,
				default: () => {}
			}
		},
		data(){
			return {
				table_headers: {
					name: {
						display: "Name",
						component: DownloadDocument
					},
					'company.name': {
						display: "Partner",
					},
					options: {
						display: "",
						component: DeleteDocument,
						sortable: false
					}
				},
				documents: [],
				show_add_document: false
			}
		},
		mounted(){
			this.emitter.on('confirm_delete_document', document_id => this.onDeleteDocument(document_id));
		},
		/**
		 * remove bus event listeners before component is destroyed.
		 * @return {[type]} [description]
		 */
		beforeUnmount(){
			this.emitter.off('confirm_delete_document');
		},
		methods:{
			/**
			 * load documents for a partner group.
			 * @return {[type]} [description]
			 */
			loadDocuments(){
				//eslint-disable-next-line
				axios.get(`${process.env.VUE_APP_API_URL}super_admin/groups/${this.group.id}/documents`)
				.then(response => this.handleLoadDocuments(response.data))
				//eslint-disable-next-line
				.catch(error => console.log(error));
			},
			/**
			 * save documents locally.
			 * @param  {[type]} response [description]
			 * @return {[type]}          [description]
			 */
			handleLoadDocuments(response){
				this.documents = response;
			},
			/**
			 * show add document window
			 */
			addDocument(){
				this.show_add_document = true;
			},
			/**
			 * hide add document window.
			 * @return {[type]} [description]
			 */
			onCancelAddDocument(){
				this.show_add_document = false;
			},
			/**
			 * a new document was uploaded
			 * so reload documents
			 * @return {[type]} [description]
			 */
			onAssetUploaded(){
				this.show_add_document = false;
				this.loadDocuments();
			},
			/**
			 * send request to delete a document
			 * @return {[type]} [description]
			 */
			onDeleteDocument(document_id){
				//eslint-disable-next-line
				axios.get(`${process.env.VUE_APP_API_URL}super_admin/groups/${this.group.id}/documents/${document_id}/delete`)
					.then(response => this.handleDeleteDocuments(response.data))
					//eslint-disable-next-line
					.catch(error => console.log(error));
			},
			//eslint-disable-next-line
			handleDeleteDocuments(response){
				this.loadDocuments();
			}
		},
		watch: {
			group(){
				this.loadDocuments();
			}
		}
	}
</script>