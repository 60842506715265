<template>
	<div class="manage-process-document">
		<CompanyChangeRequest
			:is_open="show_request_window"
			@close="onCloseChangeRequest"
			:request_type="request_type" />
		<!--ProcessDetails /-->
		<div class="process-details">
			<div class="tsa-admin-header">
				<HelpHint flag="inbound_process_details" /> <span>Process Details</span>
				<button class="tsa-btn" @click.prevent="submitForm">Update</button>
				&nbsp;
				<router-link v-if="documentType != 'parent'" class="tsa-btn" :to="testFormRoute"> View/Test Form</router-link>
			</div>
			<Breadcrumb :routes="breadcrumb" />
			<div class="uk-child-width-1-2@m uk-grid-small" uk-grid>
				<div>
					<div class="form-row with-label" :class="formRowClass('title')">
						<label>Process Name:</label>
						<input type="text" name="title" placeholder="Enter Process Name" v-model="form_data.title" />
						<span class="error-block">{{ errors.title }}</span>
					</div>
					<div class="form-row with-label" :class="formRowClass('support_hours')">
						<label>Support Hours:</label>
						<input type="text" name="support_hours" placeholder="Enter Support Hours" v-model="form_data.support_hours" />
						<span class="error-block">{{ errors.support_hours }}</span>
					</div>
					<div class="form-row with-label" :class="formRowClass('product_supported')">
						<label>Products Supported:</label>
						<input type="text" name="product_supported" placeholder="Enter Product Supported" v-model="form_data.product_supported" />
						<span class="error-block">{{ errors.product_supported }}</span>
					</div>
				</div>
				<div>
					<div class="form-row with-label">
						<label>Type:</label>
						<input type="text" :value="processTypeDisplay" disabled="disabled" class="no-borders" />
					</div>
					<!-- collaborate_email -->
					<div class="form-row with-label" :class="formRowClass('collaborate_email')"
						v-if="isDetailedDocument">
						<label>To Email:</label>
						<input type="email" name="product_supported" placeholder="Enter Collaborate Email" v-model="form_data.collaborate_email" />
						<span class="error-block">{{ errors.collaborate_email }}</span>
					</div>
					<!-- collaborate_cc_email -->
					<div class="form-row with-label" :class="formRowClass('collaborate_cc_email')"
						v-if="isDetailedDocument">
						<label>CC Email:</label>
						<input type="email" name="collaborate_cc_email" placeholder="Enter Collaborate CC Email" v-model="form_data.collaborate_cc_email" />
						<span class="error-block">{{ errors.collaborate_cc_email }}</span>
					</div>
				</div>
			</div>
		</div>
		<!-- EscalationDetails :users="users" /-->
		<div class="escalation-details" v-if="isDetailedDocument">
			<div class="tsa-admin-header">
				<HelpHint flag="process_document_escalation_details" /> <span>Escalation Details</span>
			</div>
			<div class="uk-child-width-1-2@m uk-grid-small" uk-grid>
				<div>
					<div class="tsa-section-title">Escalation Instructions</div>
					<div class="form-row wysiwyg" :class="formRowClass('escalation_instructions')">
						<wysiwyg-editor v-model="form_data.escalation_instructions"></wysiwyg-editor>
						<span class="error-block">{{ errors.escalation_instructions }}</span>
					</div>
				</div>
				<div>
					<div class="tsa-section-title">Escalation Contacts</div>
					<!-- escalation contact -->
					<div class="form-row with-label" :class="formRowClass('escalation_user_id')">
						<label>Escalation Contact:</label>
						<select v-model="form_data.escalation_user_id">
							<option :value="0">Choose Escalation Contact</option>
							<option v-for="user in users"
								:key="user.id"
								:value="user.id">{{ user.first_name }} {{ user.last_name }}</option>
						</select>
						<span class="error-block">{{ errors.escalation_user_id }}</span>
					</div>
					<!-- alternate escalation contact -->
					<div class="form-row with-label" :class="formRowClass('alt_escalation_user_id')">
						<label>Alternate Escalation Contact:</label>
						<select v-model="form_data.alt_escalation_user_id">
							<option :value="0">Choose Alt Escalation Contact</option>
							<option v-for="user in users"
								:key="user.id"
								:value="user.id">{{ user.first_name }} {{ user.last_name }}</option>
						</select>
						<span class="error-block">{{ errors.alt_escalation_user_id }}</span>
					</div>
					<!-- Escalation Alias -->
					<div class="form-row with-label" :class="formRowClass('escalation_alias')">
						<label>Escalation Alias:</label>
						<input type="email" name="escalation_alias" placeholder="Enter Escalation Alias (Optional)" v-model="form_data.escalation_alias" />
						<span class="error-block">{{ errors.escalation_alias }}</span>
					</div>
				</div>
			</div>
		</div>
		<AppliesTo
			:communities="communities"
			:departments="departments"
			:companies="companies"
			@change_request="contactTSA" />
		<DocumentFields @change_request="contactTSA" v-if="isDetailedDocument" />
		<StaticDocument v-if="documentType == 'default'" />
	</div>
</template>

<script>
	import {mapState} from 'vuex';
	//import ProcessDetails from '@/components/Admin/InboundProcess/Details.vue';
	//import EscalationDetails from '@/components/Admin/InboundProcess/EscalationDetails.vue';
	import AppliesTo from '@/components/Admin/InboundProcess/AppliesTo.vue';
	import CompanyChangeRequest from '@/components/Admin/CompanyChangeRequest.vue';
	import DocumentFields from '@/components/Admin/InboundProcess/DocumentFields.vue';
	import StaticDocument from '@/components/Admin/InboundProcess/StaticDocument.vue';
	import Breadcrumb from '@/components/Layout/Breadcrumb.vue';
	import HelpHint from '@/components/Layout/HelpHint.vue';
	import WysiwygEditor from "@/components/Form/WysiwygEditor.vue";

	export default {
		name : "manage-process-document",
		components: {
			//ProcessDetails,
			//EscalationDetails,
			CompanyChangeRequest,
			AppliesTo,
			DocumentFields,
			HelpHint,
			Breadcrumb,
			WysiwygEditor,
			StaticDocument
		},
		data(){
			return {
				show_request_window: false,
				request_type: "",
				companies: [],
				departments: [],
				communities: [],
				users: [],
				form_data:{
					escalation_user_id: 0,
					alt_escalation_user_id: 0,
					escalation_instructions: "",
					title: "",
					support_hours: "",
					product_supported: "",
					collaborate_email: "",
					collaborate_cc_email: "",
					escalation_alias: ""
				},
				errors: {},
			}
		},
		mounted(){
			this.initialize();
		},
		updated(){
			if(!this.has_initialized) this.initialize();
		},
		/**
		* remove bus event listeners before component is destroyed.
		* @return {[type]} [description]
		*/
		beforeUnmount(){
			this.emitter.off('token_ready');
		},
		methods: {
			initialize(){
				this.has_initialized = true;
				
				this.emitter.on('token_ready', () => this.loadDocumentData());
				if(this.jwt_token.access_token != undefined) this.loadDocumentData();
			},
			loadDocumentData(){
				this.emitter.emit('loader',true);
				const document_id = this.$route.params.document_id;
				//eslint-disable-next-line
				axios.get(`${process.env.VUE_APP_API_URL}admin/company/documents/${document_id}`)
				.then(response => this.handleLoadDocumentData(response.data))
				//eslint-disable-next-line
				.catch(error => console.log(error));
			},
			handleLoadDocumentData(response){
				this.emitter.emit('loader',false);
				this.$store.dispatch('caseData',{
					document: response.document,
					messages: null
				});
				this.companies = response.companies;
				this.departments = response.departments;
				this.communities = response.communities;
				this.users = response.users;
				this.sortUsers();
				this.cloneDocument();
			},
			/**
			 * copy document data into form data.
			 * @return {[type]} [description]
			 */
			cloneDocument(){
				// escalation details
				this.form_data.escalation_instructions = this.document.version.escalation_instructions;
				if(this.document.version.managers != null){
					this.document.version.managers.forEach(manager => {
						if(manager != undefined){
							if(manager.user_type == "main"){
								this.form_data.escalation_user_id = manager.user_id;
							}else{
								// alternate
								this.form_data.alt_escalation_user_id = manager.user_id;
							}
						}
					});
				}
				// details
				const fields = ['title','support_hours','product_supported','collaborate_email','collaborate_cc_email','escalation_alias'];
				
				for(let i in fields){
					let field = fields[i];
					this.form_data[field] = this.document.version[field];
				}
			},

			/**
			 * request to close change request window
			 * @return {[type]} [description]
			 */
			onCloseChangeRequest(){
				this.show_request_window = false;
			},
			contactTSA(request_type){
				this.request_type = request_type;
				this.show_request_window = true;
			},
			/**
			 * 'form-row' add 'has-error' class if field has error
			 * @param  {[type]} field [description]
			 * @return {[type]}       [description]
			 */
			formRowClass(field){
				let string = "";
				if(this.errors[field] != undefined ) string += " has-error";
				return string;
			},
			submitForm(){
				this.emitter.emit('loader',true);
				const endpoint = `${process.env.VUE_APP_API_URL}admin/company/documents/${this.document.id}`;
				//eslint-disable-next-line
				axios.post(endpoint,this.form_data)
					.then(response => this.handleUpdate(response.data))
					//eslint-disable-next-line
				    .catch(error => this.handleErrors(error.response.data.errors));
			},
			handleUpdate(response){
				this.emitter.emit('loader',false);
				if(response.error != undefined){
					this.emitter.emit('alert',{
						message: response.error,
						type: 'warning'
					});
				}else{
					this.emitter.emit('alert',response.message);
				}
			},
			/**
			 * handle validation errors.
			 * @param  {[type]} errors [description]
			 * @return {[type]}        [description]
			 */
			handleErrors (errors){
				// hide loader
				this.emitter.emit('loader',false);
				// clear all errors
				this.errors = {};
				for(var e in errors){
					this.errors[e] = errors[e][0];
				}
			},
			/**
			 * sorts users by first name since we cannot sort encrypted data
			 */
			sortUsers(){
				this.users.sort(function(a, b){
					if(a.first_name < b.first_name) { return -1; }
					if(a.first_name > b.first_name) { return 1; }
					return 0;
				});
				this.$forceUpdate();
			}
		},
		computed: {
			processTypeDisplay(){
				if(this.documentType == "ticket"){
					return "Standard Email Process";
				}else if(this.documentType == "solution_support"){
					return "Solution Support";}
				else if(this.documentType == "manager") {
					return "Manager Collaboration";
				}else if(this.documentType == "json"){
					return "JSON";
				}else{
					return "Static Document";
				}
			},
			/**
			 * get document type from 
			 * @return {[type]} [description]
			 */
			documentType(){
				if(this.document.version == undefined) return "ticket";
				return this.document.version.document_type;
			},
			isDetailedDocument(){
				return this.documentType == 'ticket' || this.documentType == 'solution_support' || this.documentType == 'manager' || this.documentType == 'json';
			},
			/**
			 * breadcrumb array
			 * @return {[type]} [description]
			 */
			breadcrumb(){
				return [
					{display: "Process" ,route: {name: "admin.inbound_process"}},
					"Process Details"
				];
			},
			testFormRoute(){
				return {name: 'admin.inbound_process.test',params: {id:this.$route.params.document_id}};
			},
			...mapState({
				company: state => state.company,
				document: state => state.contact_document,
				jwt_token: state => state.token
			})
		}
	}
</script>