<template>
	<span>
		<i v-if="cell_data.status == 'active'" class="fas fa-trash-alt danger" @click.prevent="deleteUser"></i>
		<i v-if="cell_data.status == 'inactive'" class="fas fa-user-alt-slash"></i>
	</span>
</template>

<script>
	export default {
		name: "company-delete-user",
		components:{

		},
		props:{
			cell_data:{
				type: Object,
				default: () => {}
			}
		},
		methods:{
			/**
			 * confirm that user wants to delete document.
			 * @return {[type]} [description]
			 */
			deleteUser(){
				this.emitter.emit('confirm',{
					message: `Are you sure you want to delete ${this.cell_data.name} from this company?`,
					flag: `sa_confirm_delete_user`,
					data: this.cell_data.id
				});
			}
		}
	}
</script>