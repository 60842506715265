<template>
    <div class="payment-form">
        <!-- first name -->
        <div class="form-row" :class="formRowClass('name_on_card')">
            <label>Name on Card:</label>
            <input type="text" name="name_on_card" placeholder="Name on Card" v-model="payment_data.name_on_card" />
            <span class="error-block">{{ errors.name_on_card }}</span>
        </div>
        <CreditCardField
			label_class=""
			field_class=""
			:required="true"
            :error="errors.card"
			label="Credit Card"
			@token="receiveToken"
		/>
		<div class="form-row">
			<img class="stripe-tag" src="../../assets/images/stripe.svg" alt="Powered by Stripe" />
		</div>
        <div class="form-row">
            <button class="tsa-btn pull-right" :disabled="notReady" @click="processPayment">Submit</button>
            <div class="clearfix"></div>
        </div>
    </div>
</template>

<script>
    // import any required components
    import CreditCardField from '@/components/Checkout/Fields/CreditCardField.vue';
    import {mapState} from 'vuex';
    // export component
    export default {
        // properties that the component will receive from parent
        props: {
        },
        // components to use in template
        components:{
            CreditCardField,
        },
        // component data
        data() {
            return {
                valid:{
                    card:false,
                    exp:false,
                    ccvn:false
                },
                payment_data:{
                    name_on_card:"",
					card_token:null,
                    expiration:{
                        month:0,
                        year:0
                    },
                    ccvn:""
                },
				ccvnCatcher:"",
                errors: {}
            };
        },
        // component was mounted
        mounted(){
            
        },
        // any methods needed for this component
        methods: {
			receiveToken(dt){
				if(dt && dt.token){
					this.payment_data.card_token = dt.token;
					this.valid.card = true;
				}
			},
            processPayment(){
                if(this.notReady) return;
                this.emitter.emit('loader',true);
                var data = {
                    name_on_card : this.payment_data.name_on_card,
                    card_token : this.payment_data.card_token,
                    contract_length : this.onboarding.payment.contract_length,
                    session_token : this.session_token
                };

                //eslint-disable-next-line
                axios.post(`${process.env.VUE_APP_API_URL}onboarding/${this.partner_group.vhost}/payment`,data)
                    .then(response => this.handlePaymentResponse(response.data))
                    //eslint-disable-next-line
                    .catch(error => this.handleErrors(error.response.data.errors));
            },
            handleErrors (errors){
                this.emitter.emit('loader',false);
                // clear all errors
                this.errors = {};
                for(var e in errors){
                    this.errors[e] = errors[e][0];
                }
            },
            handlePaymentResponse(response){
                this.emitter.emit('loader',false);
                if(response.success){
                    this.$emit('proccessed');
                }else{
                    this.emitter.emit('alert',{
                        type: "warning",
                        message: response.error
                    });
                }
            },
            /**
             * 'form-row' add 'has-error' class if field has error
             * @param  {[type]} field [description]
             * @return {[type]}       [description]
             */
            formRowClass(field){
                let string = "";
                if(this.errors[field] != undefined ) string += " has-error";
                return string;
            },
			/*ccvnInput(value){
				if(value.data){
					//skip the input object
				} else {
					this.payment_data.ccvn = value;
				}
			},
			expirationInput(value){
				if(value.month || value.year){
					this.payment_data.expiration = value;
				}
			}*/
        },
        // computed properties
        computed:{
            notReady(){
                return !this.valid.card;// || !this.valid.exp || !this.valid.ccvn;
            },
            ...mapState({
                partner_group: state => state.partner_group,
                onboarding: state => state.onboarding,
                session_token: state => state.onboarding_session_token,
            })
        },
        // property watchers
        watch:{
        }
    }
</script>