<template>
	<div class="tsa-register-progress">
		<div class="tsa-section" :class="isActive('contact')">
			<div class="tsa-dot"></div>
			<div class='tsa-section-name'>Contact Details</div>
		</div>
		<div class="tsa-line"></div>
		<div class="tsa-section" :class="isActive('agreements')">
			<div class="tsa-dot"></div>
			<div class='tsa-section-name'>Accept Agreement</div>
		</div>
		<div class="tsa-line"></div>
		<div class="tsa-section" :class="isActive('process')">
			<div class="tsa-dot"></div>
			<div class='tsa-section-name'>Process</div>
		</div>
	</div>
</template>

<script>
	export default {
		name : "register-progress",
		components : {

		},
		props: {
			section: {
				type: String,
				default: ""
			}
		},
		methods: {
			isActive(section){
				return (section == this.section)? "active" : "";
			}
		}
	}
</script>