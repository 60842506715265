<template>
    <div class="form-row tsa-custom-file">
        <input type="file" ref="file_input" @change="fileSelected($event)" />
        <label ref="label">{{ placeholder }}</label>
        <span class="error-block"><strong>{{ error }}</strong></span>
    </div>
</template>

<script>
    export default {
        name: "file-upload",
        props: {
            error: {
                type: String,
                default: ""
            },
            placeholder: {
                type: String,
                default: "Choose File"
            }
        },
        data(){
            return {
                form_data: {
                    file: null,
                    has_file: false
                }
            }
        },
        methods: {
            // user has selected a file
            fileSelected(event){
                // set file path on label
                var fieldVal = this.$refs.file_input.value;
                // Change the node's value by removing the fake path (Chrome)
                fieldVal = fieldVal.replace("C:\\fakepath\\", "");
                this.$refs.label.innerHTML = fieldVal;
                this.form_data.has_file = true;
                this.form_data.file = event.target.files[0];
                this.$emit('selected',event.target.files[0]);
            },
        }
    }
</script>