<template>
	<div class="tsa-admin">
		<div class="tsa-admin-header">
			<span class="page-name">{{ session.data.company.name }}</span> &mdash; Onboarding into <span class="page-name">{{ group.name }}</span>
		</div>
		<SessionForm :session="session" :countries="countries" />
	</div>
</template>

<script>

	import {mapState} from 'vuex';
	import SessionForm from '@/components/SuperAdmin/Onboarding/SessionForm.vue';

	export default {
		name: 'super-admin-onboarding-session',
		components: {
			SessionForm
		},
		data(){
			return {
				is_initialized: false,
				session: {
					data: {
						user: {},
						company: {},
						document: {},
						agreements: {}
					},
				},
				group: {},
				countries: {}
			}
		},
		updated(){
			this.initialize();
		},
		mounted(){
			this.initialize();
		},
		/**
		* remove bus event listeners before component is destroyed.
		* @return {[type]} [description]
		*/
		beforeUnmount(){
			this.emitter.off('token_ready');
		},
		methods: {
			initialize(){
				if(this.is_initialized) return;
				this.is_initialized = true;
				this.emitter.on('token_ready', () => this.loadOnboardingSession());
				if(this.jwt_token.access_token != undefined) this.loadOnboardingSession();
			},
			/**
			 * load onboarding session.
			 * @return {[type]} [description]
			 */
			loadOnboardingSession(){
				// eslint-disable-next-line
				axios.all([
					// eslint-disable-next-line
					axios.get(`${process.env.VUE_APP_API_URL}super_admin/onboarding/${this.$route.params.session_id}`),
					// eslint-disable-next-line
					axios.get(`${process.env.VUE_APP_API_URL}caller/countries`),
				])
				// eslint-disable-next-line
				.then(axios.spread((session_response, countries_response ) => {
					this.handleGetSession(session_response.data);
					this.countries = countries_response.data;
				}))
				//eslint-disable-next-line
				.catch(error => console.log(error));
			},
			handleGetSession(response){
				this.session = response.session;
				this.group = response.group;
			}
		},
		computed: {
			...mapState({
				jwt_token : state => state.token
			})
		}
	}
</script>