<template>
	<div></div>
</template>

<script>
	import RegistrationSaver from "../../Mixins/RegistrationSaver.vue"; 
	import {mapState} from 'vuex';
	export default {
		name : "group-onboarding-restore",
		props: {},
		components:{

		},
		mixins: [
			RegistrationSaver
		],
		data(){
			return {
				is_initialized: false
			}
		},
		mounted(){
			this.initialize();
		},
		updated(){
			this.initialize();
		},
		methods:{
			initialize(){
				this.restoreOnboardingSession();
			},

			/**
			 * user was previously trying to onboard.
			 */
			restoreOnboardingSession(){
				const token = this.$route.params.token;
				//eslint-disable-next-line
				axios.get(`${process.env.VUE_APP_API_URL}onboarding/restore/${token}`)
				.then(response => this.handleRestoreSession(response.data))
				//eslint-disable-next-line
				.catch(error => console.log(error));	
			},
			/**
			 * save data in store and reload landing data for
			 * @param  {[type]} response [description]
			 * @return {[type]}          [description]
			 */
			handleRestoreSession(response){
				this.$store.dispatch('restoreOnboarding',response);
				const current = this.$route.name;
				const route = `onboarding.${response.session.step}`;
				this.saveRegistrationData();
				if(current != route){
					// redirect to correct step.
					this.$router.push({name: route});
				}
			}
		},
		computed:{
			...mapState({
				jwt_token : state => state.token
			})
		}
	}
</script>