<template>
	<div>
		<ManageGroupView v-if="community_type =='solution_support' || community_type =='open_group'" />
		<ManagePartnerGroupView v-if="community_type =='partner_program'" />
	</div>
</template>

<script>
	import ManageGroupView from './ManageGroup.vue';
	import ManagePartnerGroupView from './ManagePartnerGroup.vue';

	export default {
		name: "group-details",
		components: {
			ManageGroupView,
			ManagePartnerGroupView
		},
		data(){
			return {
				is_initialized: false,
				community_type: ""
			}
		},
		updated(){
			this.initialize();
		},
		mounted(){
			this.initialize();
		},
		methods: {
			initialize(){
				if(this.is_initialized) return;
				this.is_initialized;
				this.community_type = this.$route.params.group_type;
			}
		},
		computed: {
		}
	}
</script>