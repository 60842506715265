/**
 * middleware checks that user can access caller features. if not redirect out.
 * the only user that can't access caller features are super admins.
 * @param  {[type]} options.next   [description]
 * @param  {[type]} options.router [description]
 * @return {[type]}                [description]
 */
export default function caller({next, router}) {
	const token = JSON.parse(localStorage.getItem('tsa_token'));
	let xhr = new XMLHttpRequest();
	let responseJSON = null;
	xhr.open("GET",`${process.env.VUE_APP_API_URL}auth/middleware`, false);
	xhr.setRequestHeader("Authorization", `Bearer ${token.access_token}`);
	xhr.setRequestHeader( 'Content-Type', 'application/json' );
	xhr.addEventListener( 'load',  function () {
		responseJSON = JSON.parse( xhr.responseText );
		//fun( xhr.responseJSON, xhr );
	});
	xhr.send();
	const role = responseJSON.role;

	if(responseJSON.authorized === false){
		return router.push({ name: 'logout'});
	}

	if (role == "super_admin") {
		localStorage.setItem('tsa_alert',"You do not have access to see this page.");
		return router.push({ name: 'super_admin.dashboard'});
	}

	return next();
}