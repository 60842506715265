<template>
	<div class="cases-list">
		<h1>My Cases</h1>
		<div uk-grid>
			<div class="uk-width-1-2 page-instructions"><p >Below is a list of all cases you have submitted and their status</p></div>
			<div class="uk-width-1-2 page-instructions uk-text-right"><a href="#" @click.prevent="navigate({name:'admin.reports'})" >View Company Reports</a></div>
		</div>
		<DataTable 
			:utc_note="true"
			:table_data="my_cases"
			:table_headers="my_cases_headers"
		/>
	</div>
</template>

<script>
	import {mapState} from 'vuex';
	import DataTable from '@/components/Table/DataTable';
export default {
	name: 'my-cases',
	components: {
		DataTable
	},
	data(){
		return {
			my_cases: [],
			has_initialized: false,
			my_cases_headers:{
				internal_case_number: {
					display: "Case#",
					route: "/my-cases/{token}"
				},
				company_name: {
					display: "Partner"
				},
				partner_case_number: {
					display: "Partner Case#"
				},
				summary: {
					display: "Summary"
				},
				created_at: {
					display: "Request Date",
					format: "date_time",
					default_sort: 'DESC'
				},
				responded_at: {
					display: "Respond Date",
					format: "date_time"
				}
			}
		}
	},
	mounted(){
		//this.loadDashboardData();
		this.initialize();
	},
	updated(){
		if(!this.has_initialized) this.initialize();
	},
	/**
	* remove bus event listeners before component is destroyed.
	* @return {[type]} [description]
	*/
	beforeUnmount(){
		this.emitter.off('token_ready');
	},
	methods:{
		initialize(){
			this.has_initialized = true;
			this.emitter.on('token_ready', () => this.loadMyCases());
			if(this.jwt_token.access_token != undefined) this.loadMyCases();
		},
		/**
		 * send request to get user cases.
		 * created by user
		 * company needs to respond on.
		 * @return {[type]} [description]
		 */
		loadMyCases(){
			this.emitter.emit('loader',true);
			//eslint-disable-next-line
			axios.get(`${process.env.VUE_APP_API_URL}caller/my-cases`)
			.then(response => this.handleGetCases(response.data))
			//eslint-disable-next-line
			.catch(error => console.log(error));
		},	
		handleGetCases(response){
			this.emitter.emit('loader',false);
			this.my_cases = response.my_cases;
		},
		navigate(route){
			this.$router.push(route);
		}
	},
	computed:{
		isAdmin(){
			return this.user.role == "business_manager" || this.user.role == "program_manager";
		},
		...mapState({
			jwt_token : state => state.token,
			user : state => state.user
		})
		
	}
}
</script>