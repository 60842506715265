<template>
	<div class="tsa-news">
		
		<div class="tsa-blog-posts">
			<div class="blog-post"
				v-for="post in posts"
				:key="post.id">
				<a class="post-image" :href="post.link" target="_blank">
					<img :src="postImagePath(post)" :alt="post.title.rendered + ' image'" />
				</a>
				<div class="post-info">
					<div class="post-title">
						<a :href="post.link" v-html="postTitle(post)" target="_blank" />
					</div>
				</div>
			</div>
		</div>
	</div>	
</template>

<script>
	import _ from 'lodash';
	export default {
		name: '',
		components: {
			
		},
		data(){
			return {
				posts: []
			}
		},
		mounted(){
			this.initialize();
		},
		updated(){
			this.initialize();
		},
		methods: {
			initialize(){
				if(this.posts.length > 0) return;
				//eslint-disable-next-line
				axios.get(process.env.VUE_APP_NEWS_URL)
				.then(response => this.handleGetResponse(response.data))
				//eslint-disable-next-line
				.catch(error => console.log(error));
			},
			handleGetResponse(response){
				this.posts = response;
			},
			/*postImage(post){
				return `background-image:url(${post._embedded['wp:featuredmedia'][0].source_url})`;
			},*/
			postImagePath(post){
				return post._embedded['wp:featuredmedia'][0].source_url;
			},
			postTitle(post){
				return `${post.title.rendered} &raquo;`;
			},
			postExcept(post){
				let string = post.excerpt.rendered.replace(/(<([^>]+)>)/ig,"");
				return _.truncate(string,{length: 200});
			}
		}
	}
</script>