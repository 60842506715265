<template>
	<div class="tsa-admin-partner-groups">
		<CompanyChangeRequest
			:is_open="show_request_window"
			@close="onCloseChangeRequest"
			request_type="Create/Modify Partner Groups" />
		<div class="tsa-admin-header">
			<span class="page-name">Partner Groups &mdash;</span> To create a new group or change existing <button class="tsa-btn tsa-btn-secondary" @click.prevent="contactTSA('Partner Groups')">Contact TSANet</button>
		</div>
		<div class="tsa-section-title">Partner Groups <HelpHint flag="partner_groups_list" /></div>
		<DataTable 
				search_placeholder="Search Partner Groups"
				:table_data="table_data"
				:table_headers="table_headers"
			/>
	</div>
	
</template>

<script>
	import {mapState} from 'vuex';

	import CompanyChangeRequest from '@/components/Admin/CompanyChangeRequest.vue';
	import HelpHint from '@/components/Layout/HelpHint.vue';
	import DataTable from '@/components/Table/DataTable';

	export default {
		name: "admin-partner-groups",
		components: {
			CompanyChangeRequest,
			HelpHint,
			DataTable
		},
		data(){
			return {
				has_initialized: false,
				show_request_window: false,
				table_data: [],
				table_headers: {
					'community.name': {
						display: "Name",
						route: "/admin/partner_groups/{community.id}",
					},
					'department.name': {
						display: "Department",
						render: data => {
							return (data.department == null)? "" : data.department.name;
						}
					},
					'community.type': {
						display: "Type",
						render: data => {
							return data.community.type.replace("_"," ").ucfirst();
						}
					},
					host: {
						display: "Host",
						render: data => {
							return data.community.host.name;
						}
					},
					user_access_flag: {
						display: "User Access",
						render: data => {
							return data.user_access_flag;
						}
					},
					process: {
						display: "Process",
						render: data => {
							return (data.document == null)? "" :  data.document.name;
						}
					}
				}
			}
		},
		mounted(){
			this.initialize();
		},
		updated(){
			if(!this.has_initialized) this.initialize();
		},
		/**
		* remove bus event listeners before component is destroyed.
		* @return {[type]} [description]
		*/
		beforeUnmount(){
			this.emitter.off('token_ready');
		},
		methods: {
			initialize(){
				this.has_initialized = true;
				
				this.emitter.on('token_ready', () => this.loadPartnerGroups());
				if(this.jwt_token.access_token != undefined) this.loadPartnerGroups();
			},
			loadPartnerGroups(){
				this.emitter.emit('loader',true);
				//eslint-disable-next-line
				axios.get(`${process.env.VUE_APP_API_URL}admin/company/partner_groups`)
				.then(response => this.handleLoadPartnerGroups(response.data))
				//eslint-disable-next-line
				.catch(error => console.log(error));
			},
			handleLoadPartnerGroups(response){
				this.emitter.emit('loader',false);
				this.table_data = response;
			},
			/**
			 * request to close change request window
			 * @return {[type]} [description]
			 */
			onCloseChangeRequest(){
				this.show_request_window = false;
			},
			contactTSA(request_type){
				this.request_type = request_type;
				this.show_request_window = true;
			}
		},
		computed: {
			...mapState({
				jwt_token: state => state.token
			})
		}
	}
</script>
