<template>
	<div class="tsa-admin tsa-dashboard">
		<div class="tsa-box low-padding">
			<div uk-grid class="uk-child-width-1-2@m uk-grid-small">
					<div>
						<SearchBox
						placeholder="Find a Company"
						:endpoint="enpoint('company')"
						@selected="companySelected" />
					</div>
					<div>
						<SearchBox
							placeholder="Find a User"
							:endpoint="enpoint('user')"
							@selected="userSelected" />
					</div>
				</div>
		</div>
		<CompanyApprovalQueue :queue="onboarding_queue" />
		<UserRegistrationQueue :queue="registration_queue" />
		<EscalationReporting :queue="escalation_queue" @reload="loadQueue" />
	</div>
</template>
<script>
	import SearchBox from '@/components/SuperAdmin/Dashboard/SearchBox.vue';
	import CompanyApprovalQueue from '@/components/SuperAdmin/Dashboard/CompanyApprovalQueue.vue';
	import UserRegistrationQueue from '@/components/SuperAdmin/Dashboard/UserRegistrationQueue.vue';
	import EscalationReporting from '@/components/SuperAdmin/Dashboard/EscalationReporting.vue';
	import {mapState} from 'vuex';
	export default {
		name: "dashboard",
		components: {
			SearchBox,
			CompanyApprovalQueue,
			UserRegistrationQueue,
			EscalationReporting
		},
		data(){
			return {
				onboarding_queue: [],
				registration_queue: [],
				escalation_queue: [],
				is_initialized: false
			}
		},
		mounted(){
			this.initialized();
		},
		updated(){
			this.initialized();
		},
		/**
		* remove bus event listeners before component is destroyed.
		* @return {[type]} [description]
		*/
		beforeUnmount(){
			this.emitter.off('token_ready');
			this.emitter.off('reload_queue');
		},
		methods: {
			initialized(){
				if(this.is_initialized) return;
				this.is_initialized = true;
				this.emitter.on('reload_queue', () => this.loadQueue());
				this.emitter.on('token_ready', () => this.loadQueue());
				if(this.jwt_token.access_token != undefined) this.loadQueue();
			},
			loadQueue(){
				this.emitter.emit('loader',true);
				//eslint-disable-next-line
				axios.all([
					//eslint-disable-next-line
					axios.get(`${process.env.VUE_APP_API_URL}super_admin/onboarding/queue`),
					//eslint-disable-next-line
					axios.get(`${process.env.VUE_APP_API_URL}super_admin/registration/queue`),
					//eslint-disable-next-line
					axios.get(`${process.env.VUE_APP_API_URL}super_admin/escalation/queue/${this.escalation_filter}`)
				])
				//eslint-disable-next-line
				.then(axios.spread((onboarding_response, registration_response, escalation_reponse ) => {
					this.onboarding_queue = onboarding_response.data;
					this.registration_queue = registration_response.data;
					this.escalation_queue = escalation_reponse.data;
					setTimeout(() => this.emitter.emit('loader',false), 0);
				}))
				//eslint-disable-next-line
				.catch(error => console.log(error));
			},
			/**
			 * navigate to company selected in search.
			 * @param  {[type]} company [description]
			 * @return {[type]}         [description]
			 */
			companySelected(company){
				this.$router.push({name: 'super_admin.company.detail',params: {company_id: company.id}});
			},
			/**
			 * navigate to user selected in search.
			 * @param  {[type]} company [description]
			 * @return {[type]}         [description]
			 */
			userSelected(user){
				this.$router.push({name: 'super_admin.user.edit',params: {company_id: user.company_id, user_id: user.id}});
			},
			enpoint(type){
				return `${process.env.VUE_APP_API_URL}super_admin/search/${type}`;
			}
		},
		computed: {
			...mapState({
				jwt_token : state => state.token,
				escalation_filter: state => state.escalation_filter
			})
		},
		watch: {
			escalation_filter(){
				this.loadQueue();
			}
		}
	}
</script>