/**
 * middleware checks that user is logged in.
 * @param  {[type]} options.next   [description]
 * @param  {[type]} options.router [description]
 * @return {[type]}                [description]
 */
export default function auth({next, router,to}) {
	if(localStorage.getItem('tsa_role')) localStorage.removeItem('tsa_role');
	if(localStorage.getItem('tsa_level')) localStorage.removeItem('tsa_level');
	// eslint-disable-next-line
	if (!localStorage.getItem('tsa_token')) {
		// save route user is trying to get to.
		const nextRoute = {
			name: to.name,
			params: to.params
		};
		localStorage.setItem("tsa_after_login", JSON.stringify(nextRoute));
		// save alert message
		localStorage.setItem('tsa_alert',"Access denied. You must be logged in to see this page.");
		// redirect to login page
		router.push({ name: 'home' });
	}

	return next();
}