<template>
    <div class="alert-modal tsa-hint-modal" v-bind:class="modalClass">
        <div class="modal-window">
            <div class="modal-header">
                <div class="pull-right close" @click="onOk"><i class="fa fa-times"></i></div>
                <span v-html="title"></span>
            </div>
            <div class="modal-body">
                <span v-html="hint"></span>
            </div>
            <div class='footer'>
                <a href="" @click.prevent="onOk" ref="close_btn">{{ button_text }}</a>
            </div>
        </div>
    </div>
</template>

<script>
    // import any required components

    // export component
    export default {
        // properties that the component will receive from parent
        props: {
            button_text:{
                type:String,
                default:"CLOSE",
            },
            is_open:{
                type:Boolean,
                default:false,
            },
            title:{
                type:String,
                default:""
            },
            hint:{
                type:String,
                default:""
            }
        },
        // components to use in template
        components:{

        },
        // component data
        data() {
            return {};
        },
        // component was mounted
        mounted(){
            
        },
        // any methods needed for this component
        methods: {
            onOk(){
                this.$emit('ok');
            },
        },
        // computed properties
        computed:{
            modalClass(){
                return (this.is_open)? " open" : "";
            },
        },
        // property watchers
        watch:{
            is_open(){
                if(this.is_open){
                    var self = this;
                    setTimeout(function() {
                        self.$refs.close_btn.focus();
                    },100);
                    
                }
            }
        }
    }
</script>