<template>
	<span>
		<i class="fas fa-trash-alt danger" @click.prevent="removePartner"></i>
	</span>
</template>

<script>
	export default {
		name: "group-remove-partner",
		components:{

		},
		props:{
			cell_data:{
				type: Object,
				default: () => {}
			}
		},
		methods:{
			/**
			 * confirm that user wants to delete document.
			 * @return {[type]} [description]
			 */
			removePartner(){
				this.emitter.emit('confirm',{
					message: `Are you sure you remove ${this.cell_data.company.name} from this group?`,
					flag: `confirm_remove_partner`,
					data: this.cell_data.id
				});
			}
		}
	}
</script>