<template>
	<a href="#" @click.prevent="editRelationship">
		<i class="fas fa-edit"></i>
	</a>
</template>

<script>
	export default {
		name: 'edit-relation-cell',
		components: {

		},
		props: {
			cell_data: {
				type: Object,
				default: () => {}
			}
		},
		methods: {
			editRelationship(){
				this.$router.push({
					name: "super_admin.group.detail",
					params: {
						group_id: this.cell_data.community.id,
						group_type: this.cell_data.community.type
					}
				});
			}
		}
	}
</script>