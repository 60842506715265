<template>
	<div class="tsa-box low-padding">
		<div class="tsa-section-title">User Registration Queue</div>
		<DataTable
			:utc_note="true"
			:table_headers="table_headers"
			:table_data="queue"
			:searchable="false" />
	</div>
</template>

<script>
	import DataTable from '@/components/Table/DataTable.vue';
	import QueueActions from '@/components/SuperAdmin/Dashboard/UserRegistrationQueueActions'

	export default {
		name: 'company-approval-queue',
		components: {
			DataTable
		},
		props: {
			queue: {
				type: Array,
				default: () => []
			}
		},
		data(){
			return {
				table_headers: {
					name: {
						render: data => {
							return `${data.first_name} ${data.last_name}`
						}
					},
					email: {},
					'company.name': {
						display: "Company"
					},
					created_at: {
						display: "Created",
						format: 'date'
					},
					action: {
						component: QueueActions
					}
				}
			}
		},
		computed: {
		}
	}
</script>