/**
 * middleware checks that user is a super admin
 * @param  {[type]} options.next   [description]
 * @param  {[type]} options.router [description]
 * @return {[type]}                [description]
 */
export default function not_limited({next, router}) {

	const token = JSON.parse(localStorage.getItem('tsa_token'));
	let xhr = new XMLHttpRequest();
	let responseJSON = null;
	xhr.open("GET",`${process.env.VUE_APP_API_URL}auth/middleware`, false);
	xhr.setRequestHeader("Authorization", `Bearer ${token.access_token}`);
	xhr.setRequestHeader( 'Content-Type', 'application/json' );
	xhr.addEventListener( 'load',  function () {
		responseJSON = JSON.parse( xhr.responseText );
		//fun( xhr.responseJSON, xhr );
	});
	xhr.send();

	if(responseJSON.authorized === false){
		return router.push({ name: 'logout'});
	}
	
	const level = responseJSON.level;
	if (level == "limited") {
		localStorage.setItem('tsa_alert',"Your membership level disables access to this feature.");
		return router.push({ name: 'dashboard'});
	}

	return next();
}