<template>
	<div class="home">
		<div v-if="isActive">
			<Home
				:view_is_ready="view_is_ready"
				v-if="is_member"  />
			<GroupHome
				:view_is_ready="view_is_ready"
				v-if="is_partner_group" />
		</div>
		<div  v-else>
			<CompanyInactive />
		</div>
	</div>
</template>

<script>

import {mapState} from 'vuex';
import Home from '@/components/Home/Home.vue';
import GroupHome from '@/components/PartnerGroup/Home.vue';
import CompanyInactive from '@/components/Home/CompanyInactive.vue';

export default {
	name: 'home',
	components: {
		Home,
		GroupHome,
		CompanyInactive
	},
	data(){
		return {
			view_is_ready: false,
			is_initialized: false,
			// current url is for a member
			is_member: false,
			// current url is for a partner group.
			is_partner_group: false
		}
	},
	mounted(){
		this.initialize();
	},
	updated(){
		this.initialize();
	},
	methods:{
		initialize(){
			if(this.is_initialized) return;
			this.is_initialized = true;
			if(this.app_ready) this.loadLandingData();
		},
		loadLandingData(){
			this.emitter.emit('loader',true);
			// eslint-disable-next-line
			axios.get(`${process.env.VUE_APP_API_URL}caller/landing/${this.app_slug}`)
			.then(response => this.handleGetLanding(response.data))
			// eslint-disable-next-line
			.catch(error => console.log(error));
		},
		/**
		 * save response in store object.
		 * @param  {[type]} response [description]
		 * @return {[type]}          [description]
		 */
		handleGetLanding(response){
			this.emitter.emit('loader',false);

			// member landing page
			if(response.group == null){
				if(response.company == null) response.company = {};
				this.$store.dispatch('landing',response);
				this.is_member = true;
				this.is_partner_group = false;
				setTimeout(() => this.view_is_ready = true, 0);
			}else{
				this.is_member = false;
				// partner group landing page
				this.$store.dispatch('partnerGroup',response);
				this.is_partner_group = true;
			}
		},
	},
	computed: {
		isActive(){
			if(this.company.id == undefined) return true;
			return this.company.status == "active";
		},
		...mapState({
			company: state => state.company,
			app_slug: state => state.app_slug,
			app_ready: state => state.app_ready
		})
	},

	watch: {
		app_ready(){
			this.loadLandingData();
		}
	}

}
</script>
