<template>
	<div></div>
</template>

<script>
import parsePhoneNumber from 'libphonenumber-js';
export default {
	name: "Filters",
	
	data() {
		return {
			errors: {},
		}
	},
	
	/**
	 * ESLint blocks the use of filters, so just using methods. ¯\_(ツ)_/¯
	 */
	methods: {
		nl2br(val){
			if(val.includes("<p") || val.includes("<div")){
				return val; //already formatted
			}
			let lines = val.split("\n");
			let ret = lines.join("<br />\n");
			return ret;
		},
		displayDate(val){
			if(val == undefined) return "";
			return this.$moment(val).format("MM/DD/YY hh:mm a");
		},
		displayLocalDate(val){
			if(val == undefined) return "";
			return this.$moment(val).local().format("MM/DD/YY hh:mm a");
		},
		formRowClass(field){
			let string = "form-row";
			if(this.errors[field] != undefined ) string += " has-error";
			return string;
		},
		formattedPhone(phone){
			if(!phone){ return "";}
			let phoneParts = phone.split(":");
			if(phoneParts.length > 1){
				let country = phoneParts[0];
				phoneParts.shift();
				let phNumber = parsePhoneNumber(phoneParts.join(":").trim(), country);
				if(phNumber.isValid()){
					return phNumber.country + ": " + phNumber.formatInternational();
				}
			}
			return phone;
		},
		generateString(length = 16) {
			let result = '';
			const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
			const charactersLength = characters.length;
			let counter = 0;
			while (counter < length) {
				result += characters.charAt(Math.floor(Math.random() * charactersLength));
				counter += 1;
			}
			return result;
		}
	}
}
</script>