<template>
	<div class="reset-password">
		<ResetPasswordForm />
	</div>
</template>

<script>
	import ResetPasswordForm from '@/components/Profile/ResetPasswordForm.vue';
	export default {
		name: "reset-password",
		components:{
			ResetPasswordForm
		}
	}
</script>