<template>
	<a href="#"
		@click.prevent="downloadDocument"
		:disabled="disabled"
		:class="buttonClass">{{ title }} <i :class="icon_class"></i></a>
</template>

<script>
	export default {
		name: 'export-button',
		components: {},
		props: {
			disabled : {
				type : Boolean,
				default : false
			},
			type : {
				type : String,
				default : "text-link"
			},
			// api url to export whatever list is being viewed.
			export_route: {
				type: String,
				default: ""
			},
			file_name: {
				type: String,
				default: ""
			},
			icon_class: {
				type: String,
				default: "far fa-file-excel"
			},
			title: {
				type: String,
				default: "Export"
			}
		},
		methods: {
			/**
			 * send request to export to spreadsheet.
			 * @return {[type]} [description]
			 */
			downloadDocument(){
				if(this.disabled) return;
				this.emitter.emit('loader',true);
				//eslint-disable-next-line
				axios.get(this.export_route,{
					'responseType': 'blob'
				})
				.then(response => this.handleDownload(response.data))
				//eslint-disable-next-line
				.catch(error => console.log(error));
			},
			/**
			 * create temp link to download response.
			 * @param  {[type]} response [description]
			 * @return {[type]}          [description]
			 */
			handleDownload(response){
				if(response.error != undefined){
					this.emitter.emit('alert',{
						type: "error",
						message: response.error
					});
				}
				this.emitter.emit('loader',false);
				const url = window.URL.createObjectURL(response);
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', this.file_name);
				document.body.appendChild(link);
				link.click();
				link.remove();
			}
		},
		computed : {
			buttonClass(){
				let c = "";
				switch(this.type) {
					case "text-link":
						c = "export-button";
					break;
					case "button":
						c = "tsa-btn";
					break;
				}
				return c;
			}
		}
	}
</script>