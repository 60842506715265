<template>
    <div :class="containerClass">
        <p v-html="requirement.options" />
        <span class="error-block"><strong>{{ error }}</strong></span>
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        name: "TSANetCopy",
        props: {
            requirement: {
				type: Object,
				default: () => {}
			},
            error: {
                type: String,
                default: ""
            },
			value: {
				type: Object,
				default: () => {}
			},
            disabled: {
                type: Boolean,
                default: false
            }
        },
        data(){
            return {
			}
        },
        mounted(){
        },
        methods: {
        },
        computed: {
            containerClass(){
                let string = `form-group-${this.requirement.group} form-row tsa-copy`;
                if(this.error.length > 0) string += ' has-error';
                if(this.requirement.group != null){
                    if(this.document_active_groups[this.requirement.group] === false){
                        string += " hide";
                    }
                }
                return string;
            },
            ...mapState({
                document_active_groups: state => state.document_active_groups
            })
        },
        watch:{
		}
    }
</script>