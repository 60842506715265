<template>
	<div class="login-as-user">
		Logged in as {{ user.first_name }} {{ user.last_name }}. <a href="" @click.prevent="backToAdmin">Back to Admin &raquo;</a>
	</div>
</template>

<script>
	import {mapState} from 'vuex';
	export default {
		name : 'logged-in-as-user',
		components: {

		},
		methods: {
			/**
			 * send request to go back to admin.
			 * @return {[type]} [description]
			 */
			backToAdmin(){
				this.emitter.emit('back_to_admin');
			}
		},
		computed: {
			...mapState({
				user: state => state.user
			})
		}
	}
</script>