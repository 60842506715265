<template>
	<div class="tsa-admin tsa-company-details">
		<Breadcrumb :routes="breadcrumb" />
		<div class="tsa-admin-header">
			<span class="page-name">{{company.name}}</span> &mdash; SSO Setup
		</div>
		<div uk-grid class="uk-child-width-1-2@m">
			<div>
				<div :class="formRowClass('sso_identity_source')" class="form-row with-label">
					<label>Entity Source:</label>
					<div>
						<input type="radio" value="url" name="contract_length" v-model="form_data.sso_identity_source" /> URL
						&nbsp;&nbsp;
						<input type="radio" value="file" name="contract_length" v-model="form_data.sso_identity_source" /> Upload File
					</div>
				</div>
				<div :class="formRowClass('sso_idp_file')" v-show="form_data.sso_identity_source == 'file'" class="with-label">
					<label>Entity XML File</label>
					<FileUpload
						placeholder="Choose XML File"
						@selected="ssofileSelected" />
				</div>
				<div class="form-row" v-if="sso_uploaded_file != null">
					<div><a :href="sso_uploaded_file" target="_blank">{{ sso_uploaded_file}}</a></div>
				</div>
				<!-- entity url -->
				<div :class="formRowClass('sso_idp_entity_url')" class="form-row with-label"  v-show="form_data.sso_identity_source == 'url'">
					<label>Entity Url:</label>
					<input type="text" placeholder="Enter IDP entify Url" v-model="form_data.sso_idp_entity_url" />
					<span class="error-block">{{ errors.sso_idp_entity_url }}</span>
				</div>
				<div class="form-row with-label">
					<label>&nbsp;</label>
					<div class="tsa-note"><small>Identifier of the IdP entity  (must be a URI)</small></div>
				</div>
				<!-- federation url -->
				<div :class="formRowClass('	sso_idp_federation_url')" class="form-row with-label">
					<label>Federation Url:</label>
					<input type="text" placeholder="Enter IDP Federation Url" v-model="form_data.sso_idp_federation_url" />
					<span class="error-block">{{ errors.sso_idp_federation_url }}</span>
				</div>
				<div class="form-row with-label">
					<label>&nbsp;</label>
					<div class="tsa-note"><small>URL Target of the IdP where the SP will send the Authentication Request Message.</small></div>
				</div>
				<!-- logout url -->
				<div :class="formRowClass('sso_idp_logout_url')" class="form-row with-label">
					<label>Logout Url:</label>
					<input type="text" placeholder="Enter IDP Logout Url" v-model="form_data.sso_idp_logout_url" />
					<span class="error-block">{{ errors.sso_idp_logout_url }}</span>
				</div>
				<div class="form-row with-label">
					<label>&nbsp;</label>
					<div class="tsa-note"><small>URL Location of the IdP where the SP will send the SLO Request.</small></div>
				</div>
				<div class="tsa-box low-padding">
					<div class="tsa-section-title">
						Service Provider URL
					</div>
					<h3 style="margin: 0;">{{ ssoServiceProviderUrl }}</h3>
					<span class="tsa-note">Provide this url to IT department to set up SSO on their end.</span>
				</div>
				<div class="form-row">
					<button class="tsa-btn pull-right" @click.prevent="saveSettings">Save Settings</button>
					<div class="clear-fix"></div>
				</div>
			</div>
			<div>
				<div class="tsa-box low-padding">
					<div class="tsa-section-title">Landing Page Settings:</div>
					<div :class="formRowClass('sso_login_section')" class="form-row">
						<div class="form-section">Login Section</div>
						<div class="form-row wysiwyg" :class="formRowClass('sso_login_section')">
							<wysiwyg-editor v-model="form_data.sso_login_section"></wysiwyg-editor>
							<span class="error-block">{{ errors.sso_login_section }}</span>
						</div>
					</div>
					<div :class="formRowClass('sso_registration_section')" class="form-row">
						<div class="form-section">Register Section</div>
						<div class="form-row wysiwyg" :class="formRowClass('sso_registration_section')">
							<wysiwyg-editor v-model="form_data.sso_registration_section"></wysiwyg-editor>
							<span class="error-block">{{ errors.sso_registration_section }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Breadcrumb from '@/components/Layout/Breadcrumb.vue';
	import {mapState} from 'vuex';
	import WysiwygEditor from "@/components/Form/WysiwygEditor.vue";
	import FileUpload from '@/components/Form/FileUpload.vue';

	export default {
		name: "sso-setup",
		components: {
			Breadcrumb,
			WysiwygEditor,
			FileUpload
		},
		data(){
			return {
				is_initialized: false,
				errors: {},
				form_data: {
					sso_idp_federation_url: "",
					sso_idp_logout_url: "",
					sso_idp_entity_url: "",
					sso_login_section: "",
					sso_registration_section: "",
					sso_identity_source : "url",
				},
				sso_uploaded_file : null,
				sso_idp_file: null,
			}
		},
		updated(){
			this.initialize();
		},
		mounted(){
			this.initialize();
		},
		/**
		* remove bus event listeners before component is destroyed.
		* @return {[type]} [description]
		*/
		beforeUnmount(){
			this.emitter.off('token_ready');
		},
		methods: {
			initialize(){
				if(this.is_initialized) return;
				if(this.jwt_token.access_token != undefined) this.getData();
				this.is_initialized = true;
				this.emitter.on('token_ready', () => this.getData());
			},
			getData(){
				this.cloneCompany();
			},
			cloneCompany(){
				// handle registration settings.
				this.form_data.sso_idp_federation_url = this.company.sso_idp_federation_url;
				this.form_data.sso_idp_logout_url = this.company.sso_idp_logout_url;
				this.form_data.sso_idp_entity_url = this.company.sso_idp_entity_url;
				this.form_data.sso_login_section = (this.company.sso_login_section == null)? "" : this.company.sso_login_section;
				this.form_data.sso_registration_section = (this.company.sso_registration_section == null)? "" : this.company.sso_registration_section;
				this.form_data.sso_identity_source = this.company.sso_identity_source;
				if(this.form_data.sso_identity_source == 'file'){
					this.sso_uploaded_file = `${process.env.VUE_APP_API_DOMAIN}storage/sso/${this.company.sso_identity_file}`;
				}
			},
			
			/**
			 * send api request to save settings
			 * @return {[type]} [description]
			 */
			saveSettings(){
				this.emitter.emit('loader',true);
				let formData = new FormData();
                formData.append("sso_idp_federation_url",this.form_data.sso_idp_federation_url);
                formData.append("sso_idp_logout_url",this.form_data.sso_idp_logout_url);
                formData.append("sso_idp_entity_url",this.form_data.sso_idp_entity_url);
                formData.append("sso_login_section",this.form_data.sso_login_section);
                formData.append("sso_registration_section",this.form_data.sso_registration_section);
                formData.append("sso_identity_source",this.form_data.sso_identity_source);

				//eslint-disable-next-line
				axios.post(`${process.env.VUE_APP_API_URL}admin/company/sso`,formData)
					.then(response => this.handleSaveSettings(response.data))
					//eslint-disable-next-line
				    .catch(error => this.handleErrors(error.response.data.errors));
			},
			/**
			 * let user know if it worked or not
			 * if it did redirect back to details.
			 * @param  {[type]} response [description]
			 * @return {[type]}          [description]
			 */
			handleSaveSettings(response){
				this.emitter.emit('loader',false);
				if(response.success){
					this.$store.commit("setSsoSettings",response.company);
					this.emitter.emit('alert',{
						'type' : "success",
						message: response.message
					});
					this.$router.push({name: "admin.company"});
					return;
				}

				this.emitter.emit('alert',{
					'type' : "warning",
					message: response.error
				});
			},
			handleErrors (errors){
				this.emitter.emit('loader',false);
				// clear all errors
				this.errors = {};
				for(var e in errors){
					this.errors[e] = errors[e][0];
				}
			},
			/**
			 * 'form-row' add 'has-error' class if field has error
			 * @param  {[type]} field [description]
			 * @return {[type]}       [description]
			 */
			formRowClass(field){
				let string = "";
				if(this.errors[field] != undefined ) string += " has-error";
				return string;
			},
			ssofileSelected(file){
				this.sso_idp_file = file;
			},
		},
		computed: {
			breadcrumb(){
				return [
					{display: this.company.name ,route: {name: "admin.company"}},
					"Single SignOn Settings"
				];
			},
			ssoServiceProviderUrl(){
				return `${process.env.VUE_APP_API_DOMAIN}saml2/${this.company.slug}/metadata`;
			},
			...mapState({
				jwt_token: state => state.token,
				company: state => state.company
			})
		},
		watch: {
			company(){
				this.cloneCompany();
			}
		}
	}
</script>