<template>
	<div :class="containerClass">
		<div class="tsa-modal-window tsa-missing-partner">
			<div class="tsa-section-title">Invite a Member</div>
			<i class="fas fa-times tsa-close" @click="closeModal"></i>
			<p>
				<span class="member-name">Missing Partner?</span> - Use this form to report a missing Partner to TSANet.
			</p>
			<div class="uk-child-width-1-2@m uk-grid-small" uk-grid>
				<!-- company -->
				<div class="form-row" :class="formRowClass('company_name')">
					<input type="text" v-model="form_data.company_name" placeholder="Enter Partner Company Name" />
					<span class="error-block">{{ errors.company_name }}</span>
				</div>
				<!-- company -->
				<div class="form-row" :class="formRowClass('contact_name')">
					<input type="text" v-model="form_data.contact_name" placeholder="Enter Partner Contact Name" />
					<span class="error-block">{{ errors.contact_name }}</span>
				</div>
				<!-- contact email -->
				<div class="form-row uk-width-1-1" :class="formRowClass('contact_email')">
					<input type="email" v-model="form_data.contact_email" placeholder="Enter Partner Contact Email" />
					<span class="error-block">{{ errors.contact_email }}</span>
				</div>
				<!-- comments -->
				<div class="form-row uk-width-1-1" :class="formRowClass('comment')">
					<textarea rows="6" placeholder="Short Comment on why you need to work with them. Provide any additional information you feel would help in your relationship request. Example General Problems encountered, etc." v-model="form_data.comment"></textarea>
					<span class="error-block">{{ errors.comment }}</span>
				</div>
				<div><button class='tsa-btn' @click="sendRequest">Send Request</button></div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'missing-partner',
		props:{
			is_open: {
				type: Boolean,
				default: false
			}
		},
		data(){
			return {
				form_data:{
					comment: "",
					company_name: "",
					contact_name: "",
					contact_email: ""
				},
				errors: {}
			}
		},
		components: {

		},
		methods:{
			sendRequest(){
				this.emitter.emit('loader',true);
				//eslint-disable-next-line
				axios.post(`${process.env.VUE_APP_API_URL}missing_partner_request`,this.form_data)
					.then(response => this.handleSendRequest(response.data))
					//eslint-disable-next-line
				    .catch(error => this.handleErrors(error.response.data.errors));
			},
			handleSendRequest(response){
				this.emitter.emit('loader',false);
				if(response.error != undefined){
					this.emitter.emit('alert',{
						type: "warning",
						message: response.error
					});
					return;
				}
				this.closeModal();
				this.emitter.emit('alert',response.message)
			},
			handleErrors (errors){
				this.emitter.emit('loader',false);
				// clear all errors
				this.errors = {};
				for(var e in errors){
					this.errors[e] = errors[e][0];
				}
			},
			closeModal(){
				this.$emit('close');
			},
			/**
			 * 'form-row' add 'has-error' class if field has error
			 * @param  {[type]} field [description]
			 * @return {[type]}       [description]
			 */
			formRowClass(field){
				let string = "";
				if(this.errors[field] != undefined ) string += " has-error";
				return string;
			},
		},
		computed:{
			containerClass(){
				let classList = "tsa-modal";
				if(this.is_open) classList += " show";
				return classList;
			}
		}
	}
</script>