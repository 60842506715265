<template>
	<div class="tsa-reset-password">
		<form @submit.prevent="submitForm">
			<div class="form-section">Reset Password</div>
			<p>Please enter the following information to reset your password</p>
			<div class="uk-child-width-1-2@m uk-grid-small" uk-grid>
				<div>
					<div class="form-row with-label" :class="formRowClass('email')">
						<label>Email</label>
						<input type="email" name="email" placeholder="Enter Your Email" v-model="form_data.email" />
						<span class="error-block">{{ errors.email }}</span>
					</div>
					<div class="form-row with-label" :class="formRowClass('password')">
						<label>New Password</label>
						<input type="password" name="password" placeholder="Enter New Password" v-model="form_data.password" />
						<span class="error-block">{{ errors.password }}</span>
					</div>
					<div class="form-row with-label">
						<label>Confirm Password</label>
						<input type="password" name="confirm_password" placeholder="Confirm Password" v-model="form_data.password_confirmation" />
					</div>
					<div class='form-row'>
						<button class='tsa-btn pull-right' @click.prevent="submitForm">Reset Password</button>
					</div>
				</div>
				<div style="position: relative;">
					<div class="form-row tsa-note small text-left" style="margin-top: 20px;">
						<PasswordRequirements />
					</div>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
	import PasswordRequirements from '@/components/Layout/PasswordRequirements.vue';
	export default {
		name: "reset-password-form",
		components: {
			PasswordRequirements
		},
		mounted(){
			this.form_data.token = this.$route.params.token;
		},
		data(){
			return {
				form_data: {
					email: "",
					password: "",
					password_confirmation: "",
					token: ""
				},
				errors: {

				}
			};
		},
		methods: {
			/**
			 * 'form-row' add 'has-error' class if field has error
			 * @param  {[type]} field [description]
			 * @return {[type]}       [description]
			 */
			formRowClass(field){
				let string = "";
				if(this.errors[field] != undefined ) string += " has-error";
				return string;
			},
			submitForm(){
				this.emitter.emit('loader',true);
				//eslint-disable-next-line
				axios.post(`${process.env.VUE_APP_API_URL}auth/reset_password`,this.form_data)
					.then(response => this.handleSubmitForm(response.data))
					.catch(error => this.handleErrors(error.response.data.errors));
			},
			/**
			 * save token to store
			 * load user info
			 * redirect to dashboard.
			 * @param  {[type]} response [description]
			 * @return {[type]}          [description]
			 */
			handleSubmitForm(response){
				this.emitter.emit('loader',false);
				if(response.error == undefined){
					this.$store.dispatch('token',response);
					this.emitter.emit('get_user');
					setTimeout(() => this.$router.push({name:'dashboard'}),0);
				}else{
					this.emitter.emit('alert',{message: response.error, type: "warning"});
				}

			},
			handleErrors (errors){
				this.emitter.emit('loader',false);
				// clear all errors
				this.errors = {};
				for(var e in errors){
					this.errors[e] = errors[e][0];
				}
			},
		}
	}
</script>