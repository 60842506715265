<template>
	<div class="tsa-favorite-row" @click.prevent="goToFav">
		<span class='tsa-company'>{{ entityName }}</span> 
		<span class='tsa-document'>&mdash;{{ partner.document.name }} -</span>
		<a class="invite" href="#" @click.prevent="goToFav">Collaborate</a>
	</div>
</template>
<script>
	export default {
		name: "favorite-row",
		components:{

		},
		props:{
			partner:{
				type: Object,
				default: () => {}
			}
		},
		methods: {
			goToFav(){
				const type = (this.partner.department == null)? "company" : "department",
					entityId = (this.partner.department == null)? this.partner.company.id : this.partner.department.id;
				this.$router.push({ name: 'submit-case', params: { type: type, 'entity_id': entityId }});
			}
		},
		computed:{
			/**
			 * if department return both company and department name
			 * @return {[type]} [description]
			 */
			entityName(){
				// linked to company
				if(this.partner.department == null){
					return this.partner.company.name;
				}else{
					// linked to department
					return `${this.partner.company.name} | ${this.partner.department.name}`;
				}
			},
			/**
			 * either department id or company id
			 * depending of what the favorite is linked to.
			 * @return {[type]} [description]
			 */
			entityId(){
				return (this.theType == "company")? this.partner.company_id : this.partner.department_id;
			}
		}
	}
</script>