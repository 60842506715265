<template>
	<div class="tsa-admin tsa-company-details">
		<Breadcrumb :routes="breadcrumb" />
		<div class="tsa-admin-header">
			<span class="page-name">{{company.name}}</span> &mdash; User Registration Settings
		</div>
		<div uk-grid class="uk-child-width-1-2@m">
			<div>
				<!-- requires -->
				<div class="form-row with-label" :class="formRowClass('requires_approval')">
					<label>Requires Approval</label>
					<select v-model="form_data.requires_approval">
						<option :value="0">Auto Approve All Users</option>
						<option :value="1">All Users Must be Approved</option>
					</select>
					<span class="error-block">{{ errors.requires_approval }}</span>
				</div>
				<div class='form-section'>Signup Managers</div>
				<div class="form-row with-label" :class="formRowClass('signup_manager_id')">
					<label>Primary</label>
					<select v-model="form_data.signup_manager_id">
						<option value="">Choose One</option>
						<option
							v-for="user in users"
							:value="user.id"
							:key="user.id">{{user.first_name}} {{user.last_name}}</option>
					</select>
					<span class="error-block">{{ errors.signup_manager_id }}</span>
				</div>
				<div class="form-row with-label" :class="formRowClass('alt_signup_manager_id')">
					<label>Alternate</label>
					<select v-model="form_data.alt_signup_manager_id">
						<option value="">Choose One (Optional)</option>
						<option
							v-for="user in users"
							:value="user.id"
							:key="user.id">{{user.first_name}} {{user.last_name}}</option>
					</select>
					<span class="error-block">{{ errors.alt_signup_manager_id }}</span>
				</div>
				<div class="form-row">
					<button class="tsa-btn pull-right" @click.prevent="saveSettings">Save Settings</button>
					<div class="clear-fix"></div>
				</div>
			</div>
			<div>
				<div class='form-section' style="margin-top: 0">Default Groups</div>
				<p style="margin:0"><small>User will have access to any of the grayed out groups because they are available to all users.</small></p>
				<div
					class="form-row checkbox"
					v-for="relation in communities"
					:key="relation.id">
						<input 
						type="checkbox"
						:value="relation.community.id"
						v-model="form_data.group_access"
						:disabled="relation.user_access_flag == 'all'" />
						<label>{{ relation.community.name }}</label>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Breadcrumb from '@/components/Layout/Breadcrumb.vue';
	import {mapState} from 'vuex';
	export default {
		name: "company-registration",
		components: {
			Breadcrumb
		},
		data(){
			return {
				is_initialized: false,
				errors: {},
				form_data: {
					requires_approval: 0,
					signup_manager_id: "",
					alt_signup_manager_id: "",
					group_access: []
				},
				users: [],
				communities: [],
				
			}
		},
		updated(){
			this.initialize();
		},
		mounted(){
			this.initialize();
		},
		/**
		* remove bus event listeners before component is destroyed.
		* @return {[type]} [description]
		*/
		beforeUnmount(){
			this.emitter.off('token_ready');
		},
		methods: {
			initialize(){
				if(this.is_initialized) return;
				this.is_initialized = true;
				this.emitter.on('token_ready', () => this.getData());
				if(this.jwt_token.access_token != undefined) this.getData();
				if(this.company.id != undefined) this.cloneCompany();
			},
			getData(){
				this.emitter.emit('loader',true);
				//eslint-disable-next-line
				axios.all([
					//eslint-disable-next-line
					axios.get(`${process.env.VUE_APP_API_URL}admin/company/relations`),
					//eslint-disable-next-line
					axios.get(`${process.env.VUE_APP_API_URL}admin/company/users`)
				])
				//eslint-disable-next-line
				.then(axios.spread((relations_response, users_response ) => {
					this.emitter.emit('loader',false);
					this.handleGetRelations(relations_response.data);
					this.users = users_response.data;
				}))
				//eslint-disable-next-line
				.catch(error => console.log(error));
			},
			cloneCompany(){
				// handle registration settings.
				if(this.company.registration){
					this.form_data.requires_approval = this.company.registration.requires_approval;
					this.form_data.signup_manager_id = this.company.registration.signup_manager_id;
					if(this.company.registration.alt_signup_manager_id) this.form_data.alt_signup_manager_id = this.company.registration.alt_signup_manager_id;
					for(let i in this.company.registration.communities){
						this.form_data.group_access.push(this.company.registration.communities[i].community_id);
					}
				}
			},
			handleGetRelations(response){
				this.communities = response;
				let checked = [];
				this.communities.forEach(relation => {
					if(relation.user_access_flag == "all") checked.push(relation.community_id)
				});
				this.form_data.group_access = checked;
			},
			/**
			 * send api request to save settings
			 * @return {[type]} [description]
			 */
			saveSettings(){
				this.emitter.emit('loader',true);
				//eslint-disable-next-line
				axios.post(`${process.env.VUE_APP_API_URL}admin/company/registration`,this.form_data)
					.then(response => this.handleSaveSettings(response.data))
					//eslint-disable-next-line
				    .catch(error => this.handleErrors(error.response.data.errors));
			},
			/**
			 * let user know if it worked or not
			 * if it did redirect back to details.
			 * @param  {[type]} response [description]
			 * @return {[type]}          [description]
			 */
			handleSaveSettings(response){
				this.emitter.emit('loader',false);
				if(response.success){
					this.emitter.emit('alert',{
						'type' : "success",
						message: response.message
					});
					this.$router.push({name: "admin.company"});
					this.$store.dispatch('registrationSettings',response.settings);
					return;
				}

				this.emitter.emit('alert',{
					'type' : "warning",
					message: response.error
				});
			},
			handleErrors (errors){
				this.emitter.emit('loader',false);
				// clear all errors
				this.errors = {};
				for(var e in errors){
					this.errors[e] = errors[e][0];
				}
			},
			/**
			 * 'form-row' add 'has-error' class if field has error
			 * @param  {[type]} field [description]
			 * @return {[type]}       [description]
			 */
			formRowClass(field){
				let string = "";
				if(this.errors[field] != undefined ) string += " has-error";
				return string;
			},
		},
		computed: {
			breadcrumb(){
				return [
					{display: this.company.name ,route: {name: "admin.company"}},
					"User Registration"
				];
			},
			...mapState({
				jwt_token: state => state.token,
				company: state => state.company
			})
		},
		watch: {
			company(){
				this.cloneCompany();
			}
		}
	}
</script>