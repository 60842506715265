<template>
	<div :class="containerClass">
		<div class="tsa-modal-window tsa-internal-notes">
			<i class="fas fa-times tsa-close" @click="closeModal"></i>
			<div class="tsa-section-title">{{ company.name }} Notes</div>
			<p class="page-instructions">Helpful information for your users.</p>
			<!--div class="tsa-all-notes">
				<div
					v-for="(note) in document_notes"
					:key="note.id"
					class="tsa-note">
						<i class="fas fa-trash pull-right" @click="deleteNote(note)"></i>
						<span class="date">{{ noteDate(note.created_at) }}:</span> {{ note.note }}
					</div>
			</div-->
			<div :class="formRowClass('note')">
				<wysiwyg-editor
					:key="'editor_' + openCount"
					ref="noteEditor"
					v-model="form_data.note"
				></wysiwyg-editor>
				<span class="error-block">{{ errors.note }}</span>
			</div>
			<div class="form-row">
				<button class="tsa-btn" @click.prevent="createNote">Save Note</button>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapState} from 'vuex';
	import WysiwygEditor from "@/components/Form/WysiwygEditor.vue";

	export default {
		name: 'manage-company-notes-modal',
		props:{
			is_open: {
				type: Boolean,
				default: false
			}
		},
		components: {
			WysiwygEditor
		},
		data(){
			return {
				form_data:{
					note: ""
				},
				note: {},
				errors: {},
				note_to_delete: {},
				openCount: 0
			}
		},
		mounted(){
			this.cloneNote();
		},
		methods:{
			closeModal(){
				this.$emit('close');
			},
			/**
			 * send updated notes to api
			 * @return {[type]} [description]
			 */
			createNote(){
				this.emitter.emit('loader',true);
				const data = {
					location: "home",
					note: this.$refs.noteEditor.getContent()
				}
				// eslint-disable-next-line
				axios.post(`${process.env.VUE_APP_API_URL}caller/notes/store`,data)
					.then(response => this.handleCreateNotes(response.data))
					//.catch(error => console.log(error))
					.catch(error => this.handleErrors(error.response.data.errors));
			},
			/**
			 * close window.
			 * @return {[type]} [description]
			 */
			handleCreateNotes(response){
				this.emitter.emit('loader',false);
				// update notes in store.
				if(response.error != undefined){
					this.emitter.emit('alert',{message: response.error, type: "warning"});
				}else{
					this.$store.dispatch('newCompanyNote',response.note);
					this.closeModal();
				}
			},
			/**
			 * handle validation errors.
			 * @param  {[type]} errors [description]
			 * @return {[type]}        [description]
			 */
			handleErrors (errors){
				// hide loader
				this.emitter.emit('loader',false);
				// clear all errors
				this.errors = {};
				for(var e in errors){
					this.errors[e] = errors[e][0];
				}
			},
			/**
			 * 'form-row' add 'has-error' class if field has error
			 * @param  {[type]} field [description]
			 * @return {[type]}       [description]
			 */
			formRowClass(field){
				let string = "form-row";
				if(this.errors[field] != undefined ) string += " has-error";
				return string;
			},
			cloneNote(){
				if(this.company.note != null){
					this.note = this.company.note;
					this.form_data.note = this.note.note;
				}
			},
		},
		computed:{
			containerClass(){
				let classList = "tsa-modal";
				if(this.is_open) classList += " show";
				return classList;
			},
			...mapState({
				company: state => state.company
			})
		},
		watch:{
			company(){
				this.cloneNote();
			},
			is_open(){
				if(this.is_open) {
					this.openCount++;
				}
			}
		}
	}
</script>