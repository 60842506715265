<template>
	<div class="tsa-verify-account">
		<h1>{{ page_title }}</h1>
		<!-- account verified -->
		<div v-if="account_verified">
			<div class="verify-message" v-html="verify_message"></div>
			<div class="tsa-go-to-login">
				<button class="tsa-btn" @click="goToLogin">Log In</button>
			</div>
		</div>
		<!-- account requires approval -->
		<div v-if="requires_approval" class="verify-message">
			Your request to create a User Account is now under review by your TSANet Business Manager.   If you do not receive an email after 24 hours, please contact TSANet <a href="mailto:membership@tsanet.org">membership@tsanet.org</a> or call us at <a href="tel:+1 913-345-9311">+1 913-345-9311</a>.
		</div>
		<!-- account is invalid -->
		<div class="tsa-go-to-login" v-if="is_invalid">
			<div uk-grid class="uk-child-width-1-2@m uk-grid-small">
				<div>
					<div :class="formRowClass('email')">
						<input type="email" name="email" placeholder="Email" v-model="form_data.email" />
						<span class="error-block">{{ errors.email }}</span>
					</div>
					<button class="tsa-btn pull-right" @click="revalidateAccount">Validate Again.</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'verify-account',
		components: {

		},
		data(){
			return {
				verify_message: "",
				account_verified: false,
				requires_approval: false,
				page_title: "Account Verification.",
				company: {},
				is_invalid: false,
				form_data: {
					email: ""
				},
				errors:{

				}
			}
		},
		mounted(){
			this.verifyAccount();
		},
		methods: {
			/**
			 * send token to verify user's account.
			 * @return {[type]} [description]
			 */
			verifyAccount(){
				this.emitter.emit('loader',true);
				const token = this.$route.params.token;
				//eslint-disable-next-line
				axios.get(`${process.env.VUE_APP_API_URL}auth/verify/${token}`)
				.then(response => this.handleVerifyAccount(response.data))
				//eslint-disable-next-line
				.catch(error => console.log(error));
			},
			handleVerifyAccount(response){
				// hide loader
				this.emitter.emit('loader',false);
				// display correct messages
				switch(response.action){
					case "invalid":
						this.page_title = "Invalid Token.";
						this.verify_message = response.error;
						this.is_invalid = true;
					break;
					case "verified":
						this.account_verified = true;
						this.company = response.company;
						this.page_title = "Account Verified";
						this.verify_message = response.message;
					break;
					case "approval":
						this.requires_approval = true;
						this.page_title = "Your Account Must be Approved.";
					break;
				}
				
			},
			/**
			 * navigate user to login page.
			 * @return {[type]} [description]
			 */
			goToLogin(){
				this.$router.push({name: "home-with-slug",params: {slug: this.company.slug }});
			},
			/**
			 * send request to revalidate account.
			 * @return {[type]} [description]
			 */
			revalidateAccount(){
				// clear all errors
				this.errors = {};
				this.emitter.emit('loader',true);
				//eslint-disable-next-line
				axios.post(`${process.env.VUE_APP_API_URL}auth/verify_account`,this.form_data)
					.then(response => this.handleReVerifyResponse(response.data))
					//eslint-disable-next-line
				    .catch(error => this.handleErrors(error.response.data.errors));
			},
			handleReVerifyResponse(response){
				this.emitter.emit('loader',false);
				if(response.error != undefined){
					this.emitter.emit('alert',{message: response.error, type: "warning"});
				}else{
					// success
					this.emitter.emit('alert',response.message);
					this.form_data.email = "";
				}
			},
			handleErrors (errors){
				this.emitter.emit('loader',false);
				// clear all errors
				this.errors = {};
				for(var e in errors){
					this.errors[e] = errors[e][0];
				}
			},
			/**
			 * 'form-row' add 'has-error' class if field has error
			 * @param  {[type]} field [description]
			 * @return {[type]}       [description]
			 */
			formRowClass(field){
				let string = "form-row";
				if(this.errors[field] != undefined ) string += " has-error";
				return string;
			},
		}
	}
</script>