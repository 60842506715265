<template>
	<div class="tsa-admin tsa-company-details">
		<Breadcrumb :routes="breadcrumb" />
		<div class="uk-child-width-1-2@m uk-grid-medium" uk-grid>
			<div>
				<Info :company="company"
				@updated="companyUpdated" />
			</div>
			<div>
				<Users :company="company" />
				<Departments :company="company" />
				<ContactDocuments :company="company" />
				<Communities :company="company" />
			</div>
		</div>
	</div>
</template>

<script>
	import {mapState} from 'vuex';
	import Info from '@/components/SuperAdmin/Company/Info.vue';
	import Users from '@/components/SuperAdmin/Company/Users.vue';
	import Departments from '@/components/SuperAdmin/Company/Departments.vue';
	import ContactDocuments from '@/components/SuperAdmin/Company/ContactDocuments.vue';
	import Communities from '@/components/SuperAdmin/Company/Communities.vue';
	import Breadcrumb from '@/components/Layout/Breadcrumb.vue';

	export default {
		name : 'company-details',
		components: {
			Info,
			Users,
			Departments,
			ContactDocuments,
			Communities,
			Breadcrumb,
		},
		data(){
			return {
				company: {},
				is_initialized: false,
				show_modal: false
			}
		},
		updated(){
			this.initialize();
		},
		mounted(){
			this.initialize();
		},
		/**
		* remove bus event listeners before component is destroyed.
		* @return {[type]} [description]
		*/
		beforeUnmount(){
			this.emitter.off('token_ready');
		},
		methods: {
			initialize(){
				if(this.is_initialized) return;
				this.is_initialized = true;
				this.emitter.on('token_ready', () => this.getCompany());
				if(this.jwt_token.access_token != undefined) this.getCompany();
			},
			getCompany(){
				this.emitter.emit('loader',true);
				//eslint-disable-next-line
				axios.get(`${process.env.VUE_APP_API_URL}super_admin/companies/${this.$route.params.company_id}`)
				.then(response => this.handleGetCompany(response.data.company))
				//eslint-disable-next-line
				.catch(error => console.log(error));
			},
			handleGetCompany(response){
				this.emitter.emit('loader',false);
				if(response.error != undefined){
					this.emitter.emit('alert',{
						type: 'error',
						message: response.error
					});
					this.$router.push({name: 'super_admin.companies'});
					return;
				}
				this.company = response;
			},
			companyUpdated(company){
				this.company = company;
			},
		},
		computed: {
			breadcrumb(){
				return [
					{display: "Companies" ,route: {name: "super_admin.companies"}},
					this.company.name
				];
			},
			...mapState({
				jwt_token: state => state.token
			})
		}
	}
</script>