<template>
	<div class="profile">
		<ProfileForm />
	</div>
</template>

<script>
	import ProfileForm from "@/components/Profile/ProfileForm";
	import {mapState} from 'vuex';

	export default {
		name: "profile",
		components: {
			ProfileForm
		},
		mounted(){
			this.initialize();
		},
		updated(){
			this.initialize();
		},
		/**
		* remove bus event listeners before component is destroyed.
		* @return {[type]} [description]
		*/
		beforeUnmount(){
			this.emitter.off('token_ready');
		},
		methods:{
			initialize(){
				this.emitter.on('token_ready', () => this.loadProfileFormData());
				if(this.jwt_token.access_token != undefined) this.loadProfileFormData();
			},
			loadProfileFormData(){
				// eslint-disable-next-line
				axios.get(`${process.env.VUE_APP_API_URL}users/profile_form_data`)
				.then(response => this.onProfileFormData(response.data))
				// eslint-disable-next-line
				.catch(error => console.log(error));
			},
			/**
			 * save form data in store
			 * @return {[type]} [description]
			 */
			onProfileFormData(response){
				this.$store.dispatch('profileFormData',response);
			}
		},
		computed:{
			...mapState({
				jwt_token : state => state.token
			})
		}
	}
</script>