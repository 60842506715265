<template>
	<div class="tsa-admin tsa-admin-company">
		<CompanyChangeRequest
			:is_open="show_request_window"
			@close="onCloseChangeRequest" />
		<div class="tsa-admin-header">
			<span class="page-name">Company - </span> To make changes to this page <button class="tsa-btn tsa-btn-secondary" @click.prevent="contactTSA">Contact TSANet &raquo;</button>
		</div>
		<Breadcrumb :routes="breadcrumb" />
		<div class="uk-child-width-1-2@m uk-grid-small" uk-grid>
			<div>
				<Info
					location="admin"
					:company="company" />
			</div>
			<div>
				<div class="tsa-box low-padding">
					<div class="tsa-section-title">Departments <HelpHint flag="departments_list" /></div>
					<DataTable
						:table_data="departments"
						:searchable="false"
						:table_headers="dept_headers"
					/>
				</div>
				<div class="tsa-box low-padding">
					<div class="tsa-section-title">Internal Notes <HelpHint flag="admin_internal_notes" /></div>
					<InternalNotes />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapState} from 'vuex';
	import DataTable from '@/components/Table/DataTable.vue';
	import CompanyChangeRequest from '@/components/Admin/CompanyChangeRequest.vue';
	import InternalNotes from '@/components/Admin/Company/InternalNotes.vue';
	import HelpHint from '@/components/Layout/HelpHint.vue';
	import Info from '@/components/SuperAdmin/Company/Info.vue';

	import Breadcrumb from '@/components/Layout/Breadcrumb.vue';

	export default {
		name: "admin-company",
		components: {
			DataTable,
			CompanyChangeRequest,
			HelpHint,
			Info,
			Breadcrumb,
			InternalNotes
		},
		data(){
			return {
				has_initialized: false,
				departments: [],
				dept_headers:{
					name: {
						display: "Name"
					},
					description: {
						display: "Description"
					},
					status: {
						display: "Status"
					}
				},
				show_request_window: false
			}
		},
		mounted(){
			this.initialize();
		},
		updated(){
			if(!this.has_initialized) this.initialize();
		},
		/**
		* remove bus event listeners before component is destroyed.
		* @return {[type]} [description]
		*/
		beforeUnmount(){
			this.emitter.off('token_ready');
		},
		methods: {
			initialize(){
				this.has_initialized = true;
				this.emitter.on('token_ready', () => this.loadDepartments());
				if(this.jwt_token.access_token != undefined) this.loadDepartments();
			},
			loadDepartments(){
				//eslint-disable-next-line
				axios.get(`${process.env.VUE_APP_API_URL}admin/company/${this.company.id}/departments`)
				.then(response => this.handleGetDepartments(response.data))
				//eslint-disable-next-line
				.catch(error => console.log(error));
			},
			handleGetDepartments(response){
				this.departments = response.departments;
			},
			/**
			 * request to close change request window
			 * @return {[type]} [description]
			 */
			onCloseChangeRequest(){
				this.show_request_window = false;
			},
			contactTSA(){
				this.show_request_window = true;
			}
		},
		computed:{
			membershipType(){
				if(this.company.membership_type == undefined) return "";
				return this.company.membership_type.ucfirst();
			},
			portalUrl(){
				if(this.company.slug == undefined) return "";
				return `${process.env.VUE_APP_URL}${this.company.slug}`;
			},
			loginPage(){
				return `${process.env.VUE_APP_URL}${this.company.slug}`;
			},
			breadcrumb(){
				return [];
			},
			...mapState({
				jwt_token: state => state.token,
				company: state => state.company
			})
		}
	}
</script>