<template>
	<div class="tsa-box low-padding">
		<div class="tsa-section-title">Approval Queue</div>
		<DataTable
			:utc_note="true"
			:table_headers="table_headers"
			:table_data="queue"
			:searchable="false" />
	</div>
</template>

<script>
	import DataTable from '@/components/Table/DataTable.vue';

	export default {
		name: 'company-approval-queue',
		components: {
			DataTable
		},
		props: {
			queue: {
				type: Array,
				default: () => []
			}
		},
		data(){
			return {
				table_headers: {
					'data.company.name': {
						display: "Company",
						route: "/super_admin/onboarding/{id}"
					},
					'group.name': {
						display: "Group"
					},
					status: {
						render: cell_data => {
							return cell_data.status.replace("_"," ").ucfirst();
						} 
					},
					created_at: {
						display: "Created",
						format: 'date'
					},
					sf_opportunity: {
						display: "SF Opportunity"
					}
				}
			}
		},
		methods: {
			
		}
	}
</script>