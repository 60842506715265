<template>
	<span>
		<input type="checkbox" @change="checkIfSelected" v-model="is_selected" />
	</span>
</template>

<script>
	import {mapState} from 'vuex';
	export default {
		name : 'select-user-row',
		components: {},
		props: {
			cell_data: {
				type: Object,
				default: () => {}
			}
		},
		data(){
			return {
				is_selected: false,
			}
		},
		mounted(){
			if(this.cell_data.group_access != undefined){
				if(this.cell_data.group_access.length > 0) this.is_selected = true;
			}
		},
		methods: {
			checkIfSelected(){
				if(this.is_selected){
					this.$store.dispatch('tableSelect',this.cell_data.id);
				}else{
					this.$store.dispatch('tableDeselect',this.cell_data.id);
				}
			},
		},
		computed:{
			...mapState({
				selected_users: state => state.table_data_selected_items 
			})
		},
		watch: {
			cell_data(){
				this.is_selected = this.selected_users.indexOf(this.cell_data.id) != -1;
			},
		}
	}
</script>