<template>
	<div class="tsa-admin manage-group">
		<Breadcrumb :routes="breadcrumb" />
		<div class="tsa-admin-header">
			<span class="page-name">{{ headerTitle }}</span><button class="tsa-btn" @click="saveGroup">Save Group</button>
		</div>
		<div uk-grid class="uk-child-width-1-2@m uk-grid-small tsa-document-form">
			<div>
				<div class="form-section">Group Details</div>
				<div :class="formRowClass('status')" class="with-label">
					<label>Status:</label>
					<select v-model="form_data.status">
						<option value='active'>Active </option>
						<option value='inactive'>Inactive</option>
					</select>
					<span class="error-block">{{ errors.status }}</span>
				</div>
				<div :class="formRowClass('name')" class="with-label">
					<label>Group Name:</label>
					<input type="text" placeholder="Enter Group Name" v-model="form_data.name" />
					<span class="error-block">{{ errors.name }}</span>
				</div>
				<div :class="formRowClass('description')" class="with-label">
					<label>Description:</label>	
					<div style="width: 100%;">
						<wysiwyg-editor v-model="form_data.description"></wysiwyg-editor>
					</div>
					<span class="error-block">{{ errors.description }}</span>
				</div>
				<div :class="formRowClass('type')" class="with-label">
					<label>Group Type:</label>	
					<select v-model="form_data.type">
						<option value='solution_support'>Solution Support</option>
						<option value='partner_program'>Partner Program</option>
						<option value='open_group'>Open Group</option>
					</select>
					<span class="error-block">{{ errors.type }}</span>
				</div>
				<div :class="formRowClass('sponsor_company_id')" class="with-label">
					<label>Host Company:</label>
					<div>
						<select v-model="form_data.sponsor_company_id" @change="loadDocuments">
							<option value="">Choose Host Member</option>
							<option
								v-for="company in companies"
								:value="company.id"
								:key="company.id">{{ company. name }}</option>
						</select>
					</div>
					<span class="error-block">{{ errors.sponsor_company_id }}</span>
				</div>
				<!-- department -->
				<div 
					:class="formRowClass('department_id')"
					v-if="hasDepartments"
					class="with-label">
					<label>Department:</label>
					<select v-model="form_data.department_id" :disabled="departmentDisabled">
						<option value="">Choose Department</option>
						<option 
							v-for="department in departments"
							:key="department.id"
							:value="department.id">{{department.name}}</option>
					</select>
					<span class="error-block">{{ errors.department_id }}</span>
				</div>
				<!-- document -->
				<div :class="formRowClass('document_id')" class="with-label">
					<label>Process Document:</label>
					<select v-model="form_data.document_id" :disabled="documentDisabled">
						<option value="">{{ documentEmptyOption }}</option>
						<option 
							v-for="pdoc in documents"
							:key="pdoc.id"
							:value="pdoc.id">{{pdoc.name}}</option>
					</select>
					<span class="error-block">{{ errors.document_id }}</span>
				</div>
				<div :class="formRowClass('addendum')" class="with-label">
					<label>Addendum:</label>
					<FileUpload
						placeholder="Select PDF Addendum"
						@selected="fileSelected" />
				</div>
				<div class="form-row with-label" v-show="hasAddendum">
					<label>&nbsp;</label>
					<a href="#" @click.prevent="downloadAddendum"><i class="fas fa-download"></i> community.addendum_name</a>
				</div>
				<div class="form-row checkbox" v-show="this.form_data.type == 'partner_program'">
					<div>
						<input type="checkbox" :value="1" name="force_addendum" ref="forceAddendum" v-model="form_data.force_addendum" /> <strong>Force Basic and Premium Members to Agree to Addendum.</strong>
					</div>
				</div>
				<div class="form-row checkbox">
					<div>
						<input type="checkbox" :value="1" name="custom_sla" v-model="form_data.custom_sla" ref="useCustomSLA" /> <strong>Use Custom SLA Details</strong>
					</div>
				</div>
				<!-- priority messages -->
				<div v-if="form_data.custom_sla">
					
					<div :class="formRowClass('p1_message')">
						<div class="form-section">Priority 1 Details</div>
						<div class="form-row wysiwyg" :class="formRowClass('p1_message')">
							<wysiwyg-editor v-model="form_data.p1_message"></wysiwyg-editor>
							<span class="error-block">{{ errors.p1_message }}</span>
						</div>
					</div>
					<div :class="formRowClass('p1_response_time')" class="with-label">
						<label>Response Time:</label>
						<input type="text" placeholder="Response Time (in hours)" v-model="form_data.p1_response_time" />
						<span class="error-block">{{ errors.p1_response_time }}</span>
					</div>
					<div :class="formRowClass('p2_message')">
						<div class="form-section">Priority 2 Details</div>
						<div class="form-row wysiwyg" :class="formRowClass('p2_message')">
							<wysiwyg-editor v-model="form_data.p2_message"></wysiwyg-editor>
							<span class="error-block">{{ errors.p2_message }}</span>
						</div>
					</div>
					<div :class="formRowClass('p2_response_time')" class="with-label">
						<label>Response Time:</label>
						<input type="text" placeholder="Response Time (in hours)" v-model="form_data.p2_response_time" />
						<span class="error-block">{{ errors.p2_response_time }}</span>
					</div>
					<div :class="formRowClass('p3_message')">
						<div class="form-section">Priority 3 Details</div>
						<div class="form-row wysiwyg" :class="formRowClass('p3_message')">
							<wysiwyg-editor v-model="form_data.p3_message"></wysiwyg-editor>
							<span class="error-block">{{ errors.p3_message }}</span>
						</div>
					</div>
					<div :class="formRowClass('p3_response_time')" class="with-label">
						<label>Response Time:</label>
						<input type="text" placeholder="Response Time (in hours)" v-model="form_data.p3_response_time" />
						<span class="error-block">{{ errors.p3_response_time }}</span>
					</div>
				</div>
			</div>
			<div>
				<div class="form-row checkbox">
					<div>
						<input type="checkbox" :value="1" name="msteams_integration" v-model="form_data.msteams_integration" /> <strong>Microsoft Teams Integration </strong>
					</div>
				</div>
				<div v-if="form_data.msteams_integration" style="margin-bottom: 20px;">
					<div class="tsa-admin-header"><span class="page-name"><img :src="require('../../assets/images/msteams_logo.png')" style="height: 25px;" /> Microsoft Teams</span></div>
					<p><strong>Enter the email of the user that has the rights to manage the support MS Teams team and the url for the team that will host the support channels.</strong></p>
					<div>
						<div :class="formRowClass('msteams_manager_email')" class="with-label">
							<label>Team Admin Email:</label>
							<input type="email" name="msteams_manager_email" placeholder="Enter Teams Manager" v-model="form_data.msteams_manager_email" />
							<span class="error-block">{{ errors.msteams_manager_email }}</span>
						</div>
					</div>
					<div>
						<div :class="formRowClass('msteams_team_url')" class="with-label">
							<label>Team URL:</label>
							<input type="text" name="msteams_team_url" placeholder="Enter Support Team URL" v-model="form_data.msteams_team_url" />
								<span class="error-block">{{ errors.msteams_team_url }}</span>
						</div>
					</div>
				</div>

				<GroupPartners
					:group="community"
					:companies="companies"
					v-if="isEditMode" />

				<GroupDocuments
					:group="community"
					:companies="companies"
					v-if="isEditMode && community.type !='open_group'" />
				
				<div class="tsa-box low-padding" v-if="form_data.type == 'partner_program'">
					<div class="tsa-section-title">Registration Status:</div>
					<div :class="formRowClass('registration_status_url')">
						<input type="email" placeholder="Enter Registration Status Url" v-model="form_data.registration_status_url" />
						<span class="error-block">{{ errors.registration_status_url }}</span>
					</div>
				</div>
				<!-- registration settings -->
				<div class="tsa-registration-settings tsa-box low-padding" v-if="form_data.type == 'partner_program'">
					<div class="tsa-section-title">Registration Page Settings:</div>
					<div :class="formRowClass('vhost')">
						<div class="tsa-slug-field">
							<div class="tsa-protocol">https://</div>
							<input type="text" placeholder="subdomain" v-model="form_data.vhost" />
							<div class="tsa-domain">{{ appDomain }}</div>
						</div>
						<span class="error-block">{{ errors.vhost }}</span>
					</div>
					<div :class="formRowClass('host_email')" class="with-label">
						<label>Host Email:</label>
						<input type="email" placeholder="Enter Host Email" v-model="form_data.host_email" />
						<span class="error-block">{{ errors.host_email }}</span>
					</div>
					<div :class="formRowClass('group_logo')" class="with-label">
						<label>Group Page Banner:</label>
						<FileUpload
							placeholder="Select Group Banner"
							@selected="bannerSelected" />
					</div>
					<div :class="formRowClass('price')" class="with-label">
						<label>One Year Price:</label>
						<input type="number" placeholder="Enter Price for One Year" v-model="form_data.price" />
						<span class="error-block">{{ errors.price }}</span>
					</div>
					<div :class="formRowClass('price_three_years')" class="with-label">
						<label>Three Year Price:</label>
						<input type="number" placeholder="Enter Price for Three Years" v-model="form_data.price_three_years" />
						<span class="error-block">{{ errors.price_three_years }}</span>
					</div>
					<div :class="formRowClass('instructions')">
						<div class="form-section">Program Summary</div>
						<div class="form-row wysiwyg" :class="formRowClass('instructions')">
							<wysiwyg-editor v-model="form_data.instructions"></wysiwyg-editor>
							<span class="error-block">{{ errors.instructions }}</span>
						</div>
					</div>
					<div :class="formRowClass('links')">
						<div class="form-section">Links Section</div>
						<div class="form-row wysiwyg" :class="formRowClass('links')">
							<wysiwyg-editor v-model="form_data.links"></wysiwyg-editor>
							<span class="error-block">{{ errors.links }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapState} from 'vuex';
	import FileUpload from '@/components/Form/FileUpload.vue';
	import GroupPartners from '@/components/SuperAdmin/Groups/Manage/GroupPartners.vue';
	import GroupDocuments from '@/components/SuperAdmin/Groups/Manage/GroupDocuments.vue';
	import Breadcrumb from '@/components/Layout/Breadcrumb.vue';
	import WysiwygEditor from "@/components/Form/WysiwygEditor.vue";

	export default {
		name: "manage-group",
		components: {
			FileUpload,
			GroupPartners,
			GroupDocuments,
			Breadcrumb,
			WysiwygEditor
		},
		data(){
			return {
				companies: [],
				form_data: {
					status: "active",
					name: "",
					description: "",
					sponsor_company_id: "",
					department_id: "",
					document_id: "",
					type: "solution_support",
					p1_message: "",
					p2_message: "",
					p3_message: "",
					vhost: "",
					host_email: "",
					price: 0,
					price_three_years: 0,
					instructions: "",
					links: "",
					custom_sla: 0,
					force_addendum: 0,
					registration_status_url: "",
					msteams_integration: 0,
					msteams_manager_email: "",
					msteams_team_url: ""
				},
				addendum_file: null,
				group_banner: null,
				errors: {},
				community: {},
				partner_group: {},
				is_initialized: false,
				documents: [],
				departments: [],
				prev_dept: null
			}
		},
		mounted(){
			this.initialized();
		},
		updated(){
			this.initialized();
		},
		/**
		* remove bus event listeners before component is destroyed.
		* @return {[type]} [description]
		*/
		beforeUnmount(){
			this.emitter.off('token_ready');
		},
		methods: {
			initialized(){
				if(this.is_initialized) return;
				this.is_initialized = true;
				this.emitter.on('token_ready', () => this.getCompanies());
				if(this.jwt_token.access_token != undefined) this.getCompanies();
			},
			/**
			 * get list of companies to assign group host
			 * @return {[type]} [description]
			 */
			getCompanies(){
				this.emitter.emit('loader',true);
				//eslint-disable-next-line
				axios.get(`${process.env.VUE_APP_API_URL}super_admin/companies/list`)
				.then(response => this.handleGetCompanies(response.data))
				//eslint-disable-next-line
				.catch(error => console.log(error));
			},
			/**
			 * save local
			 * if group id load group.
			 * @param  {[type]} response [description]
			 * @return {[type]}          [description]
			 */
			handleGetCompanies(response){
				this.emitter.emit('loader',false);
				this.companies = response;
				if(this.$route.params.group_id != 0) this.getGroup();
			},
			/**
			 * load group details
			 * @return {[type]} [description]
			 */
			getGroup(){
				this.emitter.emit('loader',true);
				//eslint-disable-next-line
				axios.get(`${process.env.VUE_APP_API_URL}super_admin/groups/${this.$route.params.group_id}`)
				.then(response => this.handleGetGroup(response.data))
				//eslint-disable-next-line
				.catch(error => console.log(error));
			},
			handleGetGroup(response){
				this.emitter.emit('loader',false);
				if(response.error != undefined){
					this.emitter.emit('alert',{
						type: "error",
						message: ""
					});
					return;
				}
				this.community = response.community;
				this.partner_group = response.partner_group;
				// copy data to form_data
				let new_data = {
					status: this.community.status,
					name: this.community.name,
					description: this.community.description,
					sponsor_company_id: this.community.sponsor_company_id,
					type: this.community.type,
					custom_sla: (this.community.custom_sla) ? true :false,
					p1_message: (this.community.p1_message == "null" || this.community.p1_message == null)? "" : this.community.p1_message,
					p2_message: (this.community.p2_message == "null" || this.community.p2_message == null)? "" : this.community.p2_message,
					p3_message: (this.community.p3_message == "null" || this.community.p3_message == null)? "" : this.community.p3_message,
					p1_response_time: this.community.p1_response_time,
					p2_response_time: this.community.p2_response_time,
					p3_response_time: this.community.p3_response_time,
					department_id: (this.community.department_id == null)? "" : this.community.department_id,
					document_id: (this.community.document_id == null)? "" : this.community.document_id,
					msteams_integration: this.community.msteams_integration,
					msteams_manager_email: this.community.msteams_manager_email,
					msteams_team_url: this.community.msteams_team_url
				};
				if(this.partner_group != null){
					new_data.vhost = this.partner_group.vhost;
					new_data.host_email = this.partner_group.host_email;
					new_data.price = this.partner_group.price;
					new_data.price_three_years = this.partner_group.price_three_years;
					new_data.instructions = this.partner_group.instructions;
					new_data.links = this.partner_group.links;
					new_data.force_addendum = (this.partner_group.force_addendum)? true : false;
				}

				this.prev_dept = this.community.department_id;

				if(this.community){
					this.form_data = new_data;
					this.loadDocuments();
				}
				if(this.form_data.type == "partner_program" && this.form_data.force_addendum === true){
					this.$refs.forceAddendum.click();
				}

				if(this.form_data.custom_sla){
					this.$refs.useCustomSLA.click();
				}
			},
			/**
			 * 'form-row' add 'has-error' class if field has error
			 * @param  {[type]} field [description]
			 * @return {[type]}       [description]
			 */
			formRowClass(field){
				let string = "form-row";
				if(this.errors[field] != undefined ) string += " has-error";
				return string;
			},
			fileSelected(file){
				this.addendum_file = file;
			},
			bannerSelected(file){
				this.group_banner = file;
			},
			/**
			 * send request to create/update group.
			 * @return {[type]} [description]
			 */
			saveGroup(){
				this.emitter.emit('loader',true);
				let data = new FormData();
				for(let i in this.form_data){
					data.append(i,this.form_data[i]);
				}
				if(this.addendum_file != null ){
					data.append("addendum_file", this.addendum_file);
                }
                if(this.group_banner != null ){
					data.append("group_banner", this.group_banner);
                }
				//eslint-disable-next-line
				axios.post(`${process.env.VUE_APP_API_URL}super_admin/groups/${this.$route.params.group_id}`,data)
					.then(response => this.handleSaveGroup(response.data))
					//eslint-disable-next-line
				    .catch(error => this.handleErrors(error.response.data.errors));
			},
			/**
			 * show errors or redirect to group details.
			 * @param  {[type]} response [description]
			 * @return {[type]}          [description]
			 */
			handleSaveGroup(response){
				this.emitter.emit('loader',false);
				if(response.error != undefined){
					this.emitter.emit('alert',{
						type: 'error',
						message: response.error
					});
					return;
				}
				this.community = response.community;
				// redirect to details
				this.$router.push({name:"super_admin.group.detail",params:{ group_id: response.community.id, group_type: response.community.type }});
			},
			handleErrors (errors){
				this.emitter.emit('loader',false);
				// clear all errors
				this.errors = {};
				for(var e in errors){
					this.errors[e] = errors[e][0];
				}
			},
			/**
			 * send request to download addendum
			 * @return {[type]} [description]
			 */
			downloadAddendum(){

			},
			/**
             * load contact documents for selected company
             * @return {[type]} [description]
             */
            loadDocuments(){
				// no client selected ?
				if(this.sponsor_company_id == ""){
					this.documents = [];
					return;
				}
				// clear departments
				this.departments = [];
				this.form_data.department_id = "";
				//eslint-disable-next-line
				axios.get(`${process.env.VUE_APP_API_URL}super_admin/companies/${this.form_data.sponsor_company_id}/docs_and_depts`)
				.then(response => this.handleGetDocuments(response.data))
				//eslint-disable-next-line
				.catch(error => console.log(error));
            },
            handleGetDocuments(response){
				this.documents = response.documents;
				this.departments = response.departments;
				if(this.prev_dept != null){
					this.form_data.department_id = this.prev_dept;
					this.prev_dept = null;
				}
			},
		},
		computed: {
			breadcrumb(){
				return [
					{display: "Groups" ,route: {name: "super_admin.groups"}},
					this.headerTitle					
				];
			},
			hasAddendum(){
				return this.community.addendum != null && this.community.addendum != undefined;
			},
			appDomain(){
				return process.env.VUE_APP_DOMAIN;
			},
			isEditMode(){
				return this.$route.params.group_id != 0;
			},
			headerTitle(){
				if(this.community.id != undefined){
					return `${this.community.name} Details`;
				}else{
					return 'New Group';
				}
			},
			/**
			 * check if the department dropdown should be disabled.
			 * @return {[type]} [description]
			 */
			departmentDisabled(){
				return this.form_data.sponsor_company_id == "";
			},
			/**
			 * check if the document dropdown should be disabled
			 * if partner not selected yes
			 * @return {[type]} [description]
			 */
			documentDisabled(){
				// if no company selected
				if(this.form_data.sponsor_company_id == "") return true;
				// if company has departments
				if(this.departments.length > 0){
					return this.form_data.department_id == "";
				}
				return false;
			},
			/**
			 * what label to show based on if a partner is selected or not.
			 * @return {[type]} [description]
			 */
			documentEmptyOption(){
				if(this.documentDisabled){
					return (this.departments.length > 0)? "Choose Department First" : "Choose Partner First";
				}else{
					return "Choose Process Document";
				}
			},
			/**
			 * check if the current partner has deparments
			 * @return {Boolean} [description]
			 */
			hasDepartments(){
				return this.departments.length > 0;
			},
			...mapState({
				jwt_token: state => state.token
			})
		}
	}
</script>