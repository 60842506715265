<template>
	<div class="tsa-admin-nav" v-if="isAdmin">
		<i class="fas fa-times tsa-close" @click="closeNav"></i>
		<nav class="tsa-nav-links" :class="ukWidth" uk-grid uk-height-match="target: > div > .tsa-item">
			<!-- company -->
			<div @click.capture="disabledForLimited">
				<router-link 
					:class="navItemClass('admin.company')"
					:to="{name:'admin.company'}"
					:disabled="isLimited">
					<div class="tsa-name"><i class="fas fa-ban"></i> Company</div>
					<p v-if="!isLimited">View membership details, define key roles and configure departments</p>
					<p v-if="isLimited">Limited Members are not able to create or manage Departments</p>
				</router-link>
			</div>
			<!-- users -->
			<div>
				<router-link :class="navItemClass('admin.users')" :to="{name:'admin.users'}">
					<div class="tsa-name">Users</div>
					<p>Configure how your users access the Member Web. Manage user access levels.</p>
				</router-link>
			</div>
			
			<!-- inbound process -->
			<div v-if="!isLimited">
				<router-link :class="navItemClass('admin.inbound_process')" :to="{name:'admin.inbound_process'}">
					<div class="tsa-name">Inbound Process</div>
					<p>Setup how other members contact your organization. Configure notification email.</p>
				</router-link>
			</div>
			<!-- inbound process -->
			<div v-if="isLimited">
				<router-link :class="navItemClass('admin.limited_process')" :to="{name:'admin.limited_process'}">
					<div class="tsa-name">Inbound Process</div>
					<p>Setup how other members contact your organization. Configure notification email.</p>
				</router-link>
			</div>
			<!-- partner groups -->
			<div @click.capture="disabledForLimited">
				<router-link
					:class="navItemClass('admin.partner_groups')"
					:to="{name:'admin.partner_groups'}"
					:disabled="isLimited">
					<div class="tsa-name"><i class="fas fa-ban"></i> Partner Groups</div>
					<p v-if="!isLimited">View Partner Groups you host or participate in. Create new Partner Groups.</p>
					<p v-if="isLimited">Limited Members do not have the ability to create or manage Partner Groups</p>
				</router-link>
			</div>
			<!-- reports -->
			<div>
				<router-link :class="navItemClass('admin.reports')" :to="{name:'admin.reports'}">
					<div class="tsa-name">Reports</div>
					<p>View user activity. Partner requests and learn ways to improve usage.</p>
				</router-link>
			</div>
			<!-- hub cases -->
			<!--div v-if="company.case_id">
				<a class="tsa-item" href="#" @click.prevent="loadCaseExchange">
					<div class="tsa-name">Case Exchange</div>
					<p>View list of cases submitted through Case Exchange</p>
				</a>
			</div-->
		</nav>
	</div>
</template>

<script>
	import {mapState} from 'vuex';

	export default {
		name: "admin-nav",
		components: {

		},
		methods:{
			navItemClass(route){
				let classList = "tsa-item";
				if(route == this.current_page) classList += " active";
				if(this.isLimited && (route == "admin.company" || route == "admin.partner_groups")) classList += " disabled";	
				return classList;
			},
			closeNav(){
				this.$store.dispatch('toggleAdminMenu');
			},
			disabledForLimited(e){
				if(this.isLimited) e.preventDefault();
			},
		},
		computed:{
			isAdmin(){
				if(this.user.id == undefined) return false;
				return true; // for now.
			},
			isLimited(){
				return this.company.membership_type == "limited";
			},
			ukWidth(){
				//const width = (this.company.case_id)? 6 : 5;
				const width = 5;
				return `uk-child-width-1-${width}@m`;
			},
			...mapState({
				user: state => state.user,
				current_page: state => state.current_page,
				company: state => state.company
			})
		}
	}
</script>