<template>
	<div class="tsa-partner-group-user-access">
		<div class="tsa-admin-header">
			<HelpHint flag="partner_group_user_acccess" /> <span class="page-name">{{ group.name }} &mdash;</span> User Access Management
		</div>
		<Breadcrumb :routes="breadcrumb" />
		<div class="uk-child-width-1-2@m uk-grid-small" uk-grid>
			<div><AddUsers
				:group="group"
				@new_user="loadUsers" /></div>
			<div>
			<UsersList :users="users"/></div>
		</div>
	</div>
</template>

<script>
	/* eslint-disable */
	import HelpHint from '@/components/Layout/HelpHint.vue';
	import AddUsers from '@/components/Admin/PartnerGroups/Users/AddUsers.vue';
	import UsersList from '@/components/Admin/PartnerGroups/Users/UsersList.vue';
	import Breadcrumb from '@/components/Layout/Breadcrumb.vue';

	import {mapState} from 'vuex';

	export default {
		name: "admin-partner-group-manage-access",
		components: {
			HelpHint,
			AddUsers,
			UsersList,
			Breadcrumb
		},
		data(){
			return {
				has_initialized: false,
				group: {},
				users: []
			}
		},
		mounted(){
			this.initialize();
		},
		updated(){
			if(!this.has_initialized) this.initialize();
		},
		/**
		* remove bus event listeners before component is destroyed.
		* @return {[type]} [description]
		*/
		beforeUnmount(){
			this.emitter.off('token_ready');
			this.emitter.off('confirm_delete_group_user');
		},
		methods: {
			/**
			 * initialize component
			 * @return {[type]} [description]
			 */
			initialize(){
				this.has_initialized = true;
				// listen for token ready
				this.emitter.on('token_ready', () => this.loadViewData());
				this.emitter.on('confirm_delete_group_user', user_id => this.removeAccess(user_id));
				// already loaded
				if(this.jwt_token.access_token != undefined) this.loadViewData();
			},
			/**
			 * load users and groups
			 * @return {[type]} [description]
			 */
			loadViewData(){
				this.emitter.emit('loader',true);
				const group_id = this.$route.params.group_id;
				axios.all([
					axios.get(`${process.env.VUE_APP_API_URL}admin/partner_groups/${group_id}`),
					axios.get(`${process.env.VUE_APP_API_URL}admin/company/partner_groups/${group_id}/users`)
				])
				.then(axios.spread((group_response, users_response ) => {
					this.emitter.emit('loader',false);
					this.group = group_response.data;
					this.users = users_response.data;		
				}))
			},
			/**
			 * load partner group details.
			 * @return {[type]} [description]
			 */
			loadGroup(){
				const group_id = this.$route.params.group_id;
				axios.get(`${process.env.VUE_APP_API_URL}admin/partner_groups/${group_id}`)
				.then(response => this.group = response.data)
				.catch(error => console.log(error));
			},
			loadUsers(){
				const group_id = this.$route.params.group_id;
				axios.get(`${process.env.VUE_APP_API_URL}admin/company/partner_groups/${group_id}/users`)
				.then(response => this.users = response.data)
				.catch(error => console.log(error));
			},
			/**
			 * remove user access to partner group
			 * @param  {[type]} user_id [description]
			 * @return {[type]}         [description]
			 */
			removeAccess(user_id){
				axios.get(`${process.env.VUE_APP_API_URL}admin/company/partner_groups/${this.$route.params.group_id}/users/${user_id}/delete`)
				.then(response => this.handleremoveAccess(response.data))
				.catch(error => console.log(error));
			},
			handleremoveAccess(response){
				if(response.error != undefined){
					this.emitter.emit('alert',response.error);
				}else{
					this.emitter.emit('alert',response.message);
					this.loadUsers();
				}
			}
		},
		computed:{
			breadcrumb(){
				return [
					{display: "Partner Groups" ,route: {name: "admin.partner_groups"}},
					{display: this.group.name ,route: {name: "admin.partner_groups.manage",params:{group_id: this.group.id }}},
					"User Access Management"
				];
			},
			...mapState({
				jwt_token: state => state.token
			})
		}
	}
</script>