<template>
	<div class="group-login">
		<ForgotPassword
			@close="closeResetPassword"
			:is_open="forgot_password.open" />
		<div class="uk-child-width-1-2@m uk-grid-small" uk-grid>
			<div>
				<Login
					@forgot="showForgotPassword"
				/>
				<Managers v-if="showManagers" />
				<div class="tsa-managers" v-if="showLoginPageText">
					<p v-html="company.login_page_text" />
				</div>
			</div>
			<div><EmailLogin /></div>
		</div>
	</div>
</template>

<script>

import EmailLogin from '@/components/Home/EmailLogin.vue';
import Managers from '@/components/Home/Managers.vue';
import Login from '@/components/Home/Login.vue';
import ForgotPassword from '@/components/Home/ForgotPassword.vue';

import {mapState} from 'vuex';

export default {
	name: 'group-login',
	components: {
		EmailLogin,
		Managers,
		Login,
		ForgotPassword
	},
	data(){
		return {
			forgot_password: {
				open: false
			}
		}
	},
	methods:{
		/**
		 * close forgot password window.
		 * @return {[type]} [description]
		 */
		closeResetPassword(){
			this.forgot_password.open = false;
		},
		/**
		 * show forgot password window
		 * @return {[type]} [description]
		 */
		showForgotPassword(){
			this.forgot_password.open = true;
		}
	},
	computed: {
		/**
		 * check if it should show list of managers.
		 * @return {[type]} [description]
		 */
		showManagers(){
			if(this.showLoginPageText) return false;
			return this.manager != null;
		},
		/**
		 * check if company has login page text.
		 * @return {[type]} [description]
		 */
		showLoginPageText(){
			if(this.company.login_page_text != undefined){
				if(this.company.login_page_text != null){
					return this.company.login_page_text.length > 0;
				}
			}
			return false;
		},
		...mapState({
			manager : state => state.manager,
			company: state => state.company
		})
	}
}

</script>