<template>
	<div class="tsa-company-departments">
		<Expandable
			:title="theTitle">
			<template v-slot:headerright>
				<button
					class="tsa-btn tsa-outline pull-right"
					@click.prevent="editDepartments"
					><i class="far fa-edit"></i> Edit</button>
			</template>
			<DataTable
				:table_headers="table_headers"
				:table_data="departments"
				:searchable="false" />
		</Expandable>
	</div>
</template>

<script>
	import Expandable from '@/components/Layout/ExpandableBox.vue';
	import DataTable from '@/components/Table/DataTable.vue';

	export default {
		name: 'company-departments',
		components: {
			Expandable,
			DataTable
		},
		props: {
			company: {
				type: Object,
				default: () => {}
			}
		},
		data(){
			return {
				table_headers:{
					name: {
						route: "/super_admin/companies/{company_id}/departments"
					},
					description: {},
					'document[0].name': {
						display: "Process",
						render: cell_data => {
							return (cell_data.document.length > 0)? cell_data.document[0].name : "";
						}
					}
				},
				departments: []
			}
		},
		methods: {
			editDepartments(){
				this.$router.push({name:'super_admin.company.departments',params:{company_id: this.$route.params.company_id}});
			},
			getDepartments(){
				//eslint-disable-next-line
				axios.get(`${process.env.VUE_APP_API_URL}super_admin/companies/${this.company.id}/departments`)
				.then(response => this.handleGetDepartments(response.data))
				//eslint-disable-next-line
				.catch(error => console.log(error));
			},
			handleGetDepartments(response){
				this.departments = response;
			}
		},
		computed: {
			theTitle(){
				return `Departments (${this.departments.length})`;
			}
		},
		watch: {
			company(){
				if(this.company.id != undefined) this.getDepartments();
			}
		}
	}
</script>