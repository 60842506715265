<template>
	<div>
		<div class="tsa-admin tsa-companies tsa-box low-padding">
			<div class="tsa-section-title">Reports &mdash; <span class="tsa-note">Please choose one of the following:</span></div>

		</div>
		<div class="uk-child-width-1-3@m uk-grid-small" uk-grid>
			<!-- user login report -->
			<div>
				<div class="tsa-box low-padding">
					<div class="tsa-section-title">User Logins Report</div>
					<div class="uk-child-width-1-2 uk-grid-small" uk-grid>
						<div>
							<div class="form-row">
								<DatePicker v-model="form_data.user_logins.start_date" placeholder="Start Date" />
							</div>
						</div>
						<div>
							<div class="form-row">
								<DatePicker v-model="form_data.user_logins.end_date" placeholder="End Date" />
							</div>
						</div>
						<div>
							<div class="form-row">
								<ExportButton type="button" :disabled="!userLoginIsActive" :export_route="userLoginRoute" :file_name="fileName('user_login','xlsx')" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- processes viewed report -->
			<div>
				<div class="tsa-box low-padding">
					<div class="tsa-section-title">Processes Viewed</div>
					<div class="uk-child-width-1-2 uk-grid-small" uk-grid>
						<div>
							<div class="form-row">
								<DatePicker v-model="form_data.proccesses_viewed.start_date" placeholder="Start Date" />
							</div>
						</div>
						<div>
							<div class="form-row">
								<DatePicker v-model="form_data.proccesses_viewed.end_date" placeholder="End Date" />
							</div>
						</div>
						<div>
							<div class="form-row">
								<ExportButton type="button" :disabled="!userProcessesIsActive" :export_route="processesRoute" :file_name="fileName('proccesses_viewed','xlsx')" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- word search report -->
			<div>
				<div class="tsa-box low-padding">
					<div class="tsa-section-title">Word Searches</div>
					<div class="uk-child-width-1-2 uk-grid-small" uk-grid>
						<div>
							<div class="form-row">
								<DatePicker v-model="form_data.word_search.start_date" placeholder="Start Date" />
							</div>
						</div>
						<div>
							<div class="form-row">
								<DatePicker v-model="form_data.word_search.end_date" placeholder="End Date" />
							</div>
						</div>
						<div>
							<div class="form-row">
								<ExportButton type="button" :disabled="!wordSearchIsActive" :export_route="wordSearchRoute" :file_name="fileName('word_searches','xlsx')" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- word search report -->
			<div>
				<div class="tsa-box low-padding">
					<div class="tsa-section-title">Business and Program Manager Report</div>
					<div class="uk-child-width-1-2 uk-grid-small" uk-grid>
						<div>
							<div class="form-row">
								<ExportButton type="button" :export_route="usersRoute" :file_name="fileName('managers','xlsx')" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import DatePicker from 'vue3-datepicker';
	import ExportButton from '@/components/SuperAdmin/ExportButton.vue';
	export default {
		name : 'super-admin-reports',
		props : {

		},
		components : {
			DatePicker,
			ExportButton
		},
		data(){
			return {
				form_data : {
					user_logins : {
						start_date : null,
						end_date : null
					},
					proccesses_viewed : {
						start_date : null,
						end_date : null
					},
					word_search : {
						start_date : null,
						end_date : null
					}
				}
			}
		},
		methods : {
			fileName(name,extension){
				return `${name}.${this.$moment().format("YYMMDD")}.${extension}`;
			}
		},
		computed : {
			userLoginIsActive(){
				return this.form_data.user_logins.start_date != null && this.form_data.user_logins.end_date != null;
			},
			userLoginRoute(){
				if(! this.userLoginIsActive ) return "";
				const start = this.$moment(this.form_data.user_logins.start_date).format("YY-MM-DD")
				const end = this.$moment(this.form_data.user_logins.end_date).format("YY-MM-DD");
				return `${process.env.VUE_APP_API_URL}super_admin/reports/user_logins/${start}/${end}`;
			},
			userProcessesIsActive(){
				return this.form_data.proccesses_viewed.start_date != null && this.form_data.proccesses_viewed.end_date != null;
			},
			processesRoute(){
				if(! this.userProcessesIsActive ) return "";
				const start = this.$moment(this.form_data.proccesses_viewed.start_date).format("YY-MM-DD")
				const end = this.$moment(this.form_data.proccesses_viewed.end_date).format("YY-MM-DD");
				return `${process.env.VUE_APP_API_URL}super_admin/reports/proccesses_viewed/${start}/${end}`;
			},
			wordSearchIsActive(){
				return this.form_data.word_search.start_date != null && this.form_data.word_search.end_date != null;
			},
			wordSearchRoute(){
				if(! this.wordSearchIsActive ) return "";
				const start = this.$moment(this.form_data.word_search.start_date).format("YY-MM-DD")
				const end = this.$moment(this.form_data.word_search.end_date).format("YY-MM-DD");
				return `${process.env.VUE_APP_API_URL}super_admin/reports/word_search/${start}/${end}`;
			},
			usersRoute(){
				return `${process.env.VUE_APP_API_URL}super_admin/reports/managers`;
			}
		}
	}
</script>