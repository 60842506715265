<template>
	<div class="tsa-admin tsa-manage-partner-group">
		<div class="tsa-admin-header">
			<span class="page-name">{{community.name}}</span>
			<button class="tsa-btn" @click="editGroup">Edit</button>
		</div>
		<div uk-grid class="uk-child-width-1-2@m uk-grid-small">
			<div>
				<div class="tsa-data">
					<div class="tsa-data-label">Group Name:</div>
					<div class="tsa-data-data">{{ community.name }}</div>
				</div>
				<div class="tsa-data">
					<div class="tsa-data-label">Group Type:</div>
					<div class="tsa-data-data">{{ groupType }}</div>
				</div>
				<div class="tsa-data">
					<div class="tsa-data-label">Partner Registration:</div>
					<div class="tsa-data-data"><a :href="registerUrl" target="_blank">{{ registerUrl }}</a></div>
				</div>
				<div class="tsa-data" v-if="partner_group.registration_status_url">
					<div class="tsa-data-label">Registration Status URL:</div>
					<div class="tsa-data-data ellipsis"><a :href="partner_group.registration_status_url" target="_blank">{{ partner_group.registration_status_url }}</a></div>
				</div>
				<div class="tsa-data" v-if="community.addendum != null">
					<div class="tsa-data-label">Partner Addendum:</div>
					<div class="tsa-data-data">
						<ExportButton
							:title="community.addendum_name"
							icon_class="fas fa-download"
							:export_route="downloadAddendum" />
					</div>
				</div>
				<div class="tsa-data" v-if="community.addendum != null && partner_group.force_addendum">
					<div class="tsa-data-label">&nbsp;</div>
					<div class="tsa-data-data"><i class="fas fa-exclamation-triangle danger"></i> Basic and Premium members must agree to addendum.</div>
				</div>
				<GroupPartners
						:group="community" />
			</div>
			<div>
				<div class="tsa-data">
					<div class="tsa-data-label">SLA Priority 1:</div>
					<div class="tsa-data-data" v-html="community.p1_message"></div>
				</div>
				<div class="tsa-data">
					<div class="tsa-data-label">SLA Priority 2:</div>
					<div class="tsa-data-data" v-html="community.p2_message"></div>
				</div>
				<div class="tsa-data">
					<div class="tsa-data-label">SLA Priority 3:</div>
					<div class="tsa-data-data" v-html="community.p3_message"></div>
				</div>
				<GroupDocuments
						:group="community" />
				<RegistrationDetails
					:partner_group="partner_group" />
			</div>
		</div>
	</div>
</template>

<script>
	import {mapState} from 'vuex';
	import GroupPartners from '@/components/SuperAdmin/Groups/Manage/GroupPartners.vue';
	import GroupDocuments from '@/components/SuperAdmin/Groups/Manage/GroupDocuments.vue';
	import RegistrationDetails from '@/components/SuperAdmin/Groups/Manage/RegistrationDetails.vue';
	import ExportButton from '@/components/SuperAdmin/ExportButton.vue';
	export default {
		name: "manage-partner-group",
		components: {
			GroupPartners,
			GroupDocuments,
			ExportButton,
			RegistrationDetails
		},
		data(){
			return {
				community: {
					p1_message: "",
					p2_message: "",
					p3_message: "",
					
				},
				partner_group: {}
			}
		},
		mounted(){
			this.initialized();
		},
		updated(){
			this.initialized();
		},
		/**
		* remove bus event listeners before component is destroyed.
		* @return {[type]} [description]
		*/
		beforeUnmount(){
			this.emitter.off('token_ready');
		},
		methods: {
			initialized(){
				if(this.is_initialized) return;
				this.is_initialized = true;
				this.emitter.on('token_ready', () => this.getGroup());
				if(this.jwt_token.access_token != undefined) this.getGroup();
			},
			getGroup(){
				this.emitter.emit('loader',true);
				//eslint-disable-next-line
				axios.get(`${process.env.VUE_APP_API_URL}super_admin/groups/${this.$route.params.group_id}`)
				.then(response => this.handleGetGroup(response.data))
				//eslint-disable-next-line
				.catch(error => console.log(error));
			},
			handleGetGroup(response){
				this.emitter.emit('loader',false);
				if(response.community.p1_message == null) response.community.p1_message = "";
				if(response.community.p2_message == null) response.community.p2_message = "";
				if(response.community.p3_message == null) response.community.p3_message = "";
				this.community = response.community;
				this.partner_group = response.partner_group;
			},
			/**
			 * route user to edit group
			 * @return {[type]} [description]
			 */
			editGroup(){
				this.$router.push({name: 'super_admin.group.edit', params: {group_id : this.community.id }});
			},
		},
		computed: {
			/**
			 * send request to download addendum
			 * @return {[type]} [description]
			 */
			downloadAddendum(){
				return `${process.env.VUE_APP_API_URL}super_admin/groups/${this.community.id}/addendum`;
			},
			groupType(){
				if(this.community.type == undefined) return "";
				return this.community.type.replace('_'," ").ucfirst();
			},
			registerUrl(){
				if(this.partner_group.vhost == undefined) return "";
				return process.env.VUE_APP_COMPANY_URL.replace("{slug}",this.partner_group.vhost);
			},
			...mapState({
				jwt_token: state => state.token
			})
		}
	}
</script>