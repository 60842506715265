<template>
	<div class="tsa-contact-document tsa-default">
		<div class="tsa-contact-document tsa-default">
			<div class="uk-child-width-1-2@m uk-grid-small tsa-document-form" uk-grid>
				<div>
					<div class="form-section">&lt;Partner&gt; Contact <i class="fas fa-user"></i></div>
					<div class="form-row">
						<input type="text" name="first_name" placeholder="Enter Your First Name" disabled="disabled" />
					</div>
					<div class="form-row">
						<input type="text" name="last_name" placeholder="Enter Your Last Name" disabled="disabled" />
					</div>
					<div class="form-row">
						<input type="email" name="email" placeholder="Enter Your Email" disabled="disabled" />
					</div>
					<div class="form-row">
						<input type="text" name="phone_number" placeholder="Enter Your Phone" disabled="disabled" />
					</div>
					<div class="form-row">
						<input type="text" name="internal_case_number" :placeholder="`Enter Your <Partner> Internal Case #`" disabled="disabled" />
					</div>
				</div>
				<div>
					<!-- custom requirements -->
					<div class="form-section">{{ commonCustomerTitle }}</div>
					<component :is="whichComponent(requirement)"
						v-for="(requirement, index) in common_customer_section"
						:key="index"
						:requirement="requirement"
						:disabled="requirement.requirement.type != 'groupselect'"
					/>
					
				</div>
			</div>
			<div class="uk-child-width-1-2@m uk-grid-small tsa-document-form" uk-grid>
				<div>
					<div class="form-section">Problem Information</div>
					<div class="form-row">
						<input type="text" name="problem_summary" placeholder="Enter Problem Summary" disabled="disabled" />
					</div>
					<div class="form-row">
						<textarea rows="6" name="problem_description" placeholder="Enter description of problem and include product versions, troubleshooting steps and error messages. Do not share any Customer Data such as IP addresses, login IDs or other sensitive information." disabled="disabled"></textarea>
					</div>
					<component :is="whichComponent(requirement)"
						v-for="(requirement, index) in problem_section"
						:key="index"
						:disabled="true"
						:requirement="requirement"
					/>
				</div>
				<div>
					<div class="form-section">&nbsp;</div>
					<div class="tsa-priority">
						<select disabled="disabled">
							<option value="">Select Priority</option>
							<option value='low'>Low</option>
							<option value='medium'>Medium</option>
							<option value='high'>High</option>
						</select>
						<button disabled="disabled">Submit</button>
					</div>
					<div class="priority-notes">
						<i class="fas fa-exclamation-circle"></i>
						<p>
							Display information based on Priority selected <br>
							(Example Response time or special instructions)
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import RequirementField from '@/components/ContactDocuments/RequirementField.vue';
	import Email from '@/components/ContactDocuments/Inputs/Email.vue';
	import Hidden from '@/components/ContactDocuments/Inputs/Hidden.vue';
	import Number from '@/components/ContactDocuments/Inputs/Number.vue';
	import Phone from '@/components/ContactDocuments/Inputs/Phone.vue';
	import Select from '@/components/ContactDocuments/Inputs/Select.vue';
	import String from '@/components/ContactDocuments/Inputs/String.vue';
	import TextField from '@/components/ContactDocuments/Inputs/Text.vue';
	import Url from '@/components/ContactDocuments/Inputs/Url.vue';
	import TierSelect from '@/components/ContactDocuments/Inputs/TierSelect.vue';
	import DbSelect from '@/components/ContactDocuments/Inputs/DbSelect.vue';
	import GroupSelect from '@/components/ContactDocuments/Inputs/GroupSelect.vue';
	import Copy from '@/components/ContactDocuments/Inputs/Copy.vue';

	import {mapState} from 'vuex';
	export default {
		name: "document-sample",
		components: {
			Email,
			RequirementField,
			Hidden,
			Number,
			Phone,
			Select,
			String,
			TextField,
			Url,
			TierSelect,
			GroupSelect,
			DbSelect,
			Copy
		},
		data(){
			return {
				common_customer_section: [],
				contact_section: [],
				problem_section: []
			}
		},
		mounted(){
			this.handleLoadDocument();
		},
		methods: {
			handleLoadDocument(){
				let common_customer_section = [],
					problem_section = [],
					contact_section = [];	
				// common contact section
				for(let i in this.document.version.requirements){
					let requirement = this.document.version.requirements[i];
					switch(requirement.section){
						case "common_customer_section":
							common_customer_section.push(requirement);
						break;
						case "problem_section":
							problem_section.push(requirement);
						break;
						case "contact_section":
							contact_section.push(requirement);
						break;
					}
				}
				this.common_customer_section = common_customer_section;
				this.problem_section = problem_section;
				this.contact_section = contact_section;
			},
			whichComponent(item) {
				if(item.requirement.type == "hidden") {
					return "Hidden";
				}
				if(item.requirement.type == "integer") {
					return "Number";
				}
				if(item.requirement.type == "phone") {
					return "Phone";
				}
				if(item.requirement.type == "select") {
					return "Select";
				}
				if(item.requirement.type == "string") {
					return "String";
				}
				if(item.requirement.type == "text") {
					return "TextField";
				}
				if(item.requirement.type == "url") {
					return "Url";
				}
				if(item.requirement.type == "email") {
					return "Email";
				}
				if(item.requirement.type == "tierselect")
					return "TierSelect";

				if(item.requirement.type == "db_select")
					return "DbSelect";

				if(item.requirement.type == "groupselect")
					return "GroupSelect";

				if(item.requirement.type == "copy")
					return "Copy";
				
				return "RequirementField";
					
			},
		},
		computed: {
			commonCustomerTitle(){
				if(this.document.version.common_customer_section_title != null) return this.document.version.common_customer_section_title;
				
				return (this.document.version.document_type == "ticket")? "Common Customer Contact" : "Additional Information";
			},
			...mapState({
				document: state => state.contact_document
			})
		},
		watch: {
			document(){
				this.handleLoadDocument();
			}
		}
	}
</script>