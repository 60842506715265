<template>
	<div class="tsa-document-header">
		<InternalNotes
			:is_open="internal_notes"
			@close="onCloseInternalNotes" />
		<GroupDocuments
			:is_open="group_documents"
			@close="onCloseGroupDocuments" />
		<div class="tsa-document-data">
			<div class='uk-grid-small' uk-grid>
				<div class="tsa-data uk-width-1-4@m">
					<div v-if="contact_document.version.support_hours !== null && contact_document.version.support_hours !== undefined">
						<span>Support Hours</span>
						<p>{{ contact_document.version.support_hours }}</p>
					</div>
					<div v-else>
						&nbsp;
					</div>
				</div>
				<div class="tsa-title uk-width-1-2@m">
					{{ titleString }} <i :class="favoriteClass" @click="toggleFavorite"></i>
				</div>
				<div class='tsa-data products uk-width-1-4@m'>
					<div v-if="contact_document.version.product_supported !== null && contact_document.version.product_supported !== undefined">
						<span>Products Supported</span>
						<p>{{ contact_document.version.product_supported }}</p>
					</div>
					<div v-else>
						&nbsp;
					</div>
				</div>
			</div>
		</div>
		<div>
			<div class="notes-and-documents uk-grid-collapse uk-grid-match" uk-grid ref="gridContainer">
				<div v-if="showNotes" :class="innerGridClass">
					<div class="tsa-section notes">
						<div class="tsa-title">Internal Notes:&nbsp;</div>
						<div class="notes" v-html="firstNote"></div>
						<div class="more"><a href="" @click.prevent="viewNotes">View More...</a></div>
					</div>
				</div>
				<div v-if="hasDocuments" :class="innerGridClass">
					<div class="tsa-section notes">
						<div class="tsa-title">Group Documents:&nbsp;</div>
						<div class="notes"><DownloadDocument :cell_data="contact_document.files[0]"/></div>
						<div class="more"><a href="" @click.prevent="viewDocuments">View More ({{ contact_document.files.length}})</a></div>
					</div>
				</div>
			</div>
		</div>
		<div class="admin-note" v-if="hasAdminNote">
			<span>Note:</span>
			<div style="flex-grow: 1" v-html="adminNote" />
		</div>
	</div>
</template>
<script>
	import {mapState} from 'vuex';
	import InternalNotes from '@/components/ContactDocuments/InternalNotes.vue';
	import GroupDocuments from '@/components/ContactDocuments/GroupDocuments.vue';
	import DownloadDocument from '@/components/Admin/PartnerGroups/DownloadDocument.vue';

	export default {
		name: "document-header",
		data(){
			return {
				// show internal notes modal
				internal_notes: false,
				// show group documents modal
				group_documents: false
			}
		},
		components: {
			InternalNotes,
			GroupDocuments,
			DownloadDocument
		},
		methods:{
			/**
			 * send request to toggle favorite on current contact document.
			 * @return {[type]} [description]
			 */
			toggleFavorite(){
				this.emitter.emit('loader',true);
				const data = {
					document_id : this.contact_document.id,
					company_id : this.contact_document.company_id,
					type: this.$route.params.type,
					department_id: this.$route.params.entity_id,
					is_favorite: !this.contact_document.is_favorite
				};
				//eslint-disable-next-line
				axios.post(`${process.env.VUE_APP_API_URL}caller/favorites/toggle`,data)
					.then(response => this.handleToggleFavorite(response.data))
					//eslint-disable-next-line
					.catch(error => console.log(error));
			},
			/**
			 * set favorite toggle to response.
			 * @param  {bool} response true if it was set to favorite.
			 * @return {[type]}          [description]
			 */
			handleToggleFavorite(response){
				// hide loader
				this.emitter.emit('loader',false);
				// change 
				this.$store.dispatch('isFavorite',response);
			},
			/**
			 * show add/edit notes window.
			 * @return {[type]} [description]
			 */
			viewNotes(){
				this.internal_notes = true;
			},
			/**
			 * show add/edit documents window.
			 * @return {[type]} [description]
			 */
			viewDocuments(){
				this.group_documents = true;
			},
			/**
			 * close internal notes modal
			 * @return {[type]} [description]
			 */
			onCloseInternalNotes(){
				this.internal_notes = false;
			},
			/**
			 * close group documents modal
			 * @return {[type]} [description]
			 */
			onCloseGroupDocuments(){
				this.group_documents = false;
			},
		},
		computed:{
			isFavorite(){
				return this.contact_document.is_favorite;
			},
			/**
			 * toggle icon classed based on being favorite
			 * @return {[type]} [description]
			 */
			favoriteClass(){
				return (this.isFavorite)? "fas fa-star" : "far fa-star";
			},
			hasDocuments(){
				if(this.contact_document.files == undefined) return false;
				return this.contact_document.files.length > 0;
			},
			noDocumentsClass(){
				return (!this.hasDocuments)? "no-documents" : "";
			},
			/**
			 * get the string for the first note
			 * before there were multiple notes
			 * @return {[type]} [description]
			 */
			firstNote(){
				if(this.document_notes == null) return "";

				//return (this.document_notes.note != null)? this.document_notes.note.replace(/(<([^>]+)>)/ig,"") : "";
				return (this.document_notes.note != null)? this.document_notes.note : "";
			},
			/**
			 * show company name if department is null
			 * @return {[type]} [description]
			 */
			titleString(){
				if(this.contact_document.company == undefined) return "";
				let string = this.contact_document.company.name;
				if(this.contact_document.department != null) string += ` | ${this.contact_document.department.name}`;
				return string;
			},
			innerGridClass(){
				if(this.hasDocuments && this.showNotes) return 'uk-width-1-2@m';
				return 'uk-width-1-1@m';
			},
			/**
			 * if program manager or business manager they can edit a note.
			 * @return {[type]} [description]
			 */
			canEditNote(){
				return this.user.role == "business_manager" || this.user.role == "program_manager";
			},
			/**
			 * if the user is caller they can view notes
			 * @return {[type]} [description]
			 */
			canViewNote(){
				return this.user.role == "caller";
			},
			/**
			 * check to see if we need to show the note block.
			 * @return {[type]} [description]
			 */
			showNotes(){
				// if user can edit a note
				if(this.canEditNote) return true;
				// if the user can view notes and there is a note
				if(this.canViewNote && this.hasNote) return true;
				// nope
				return false;
			},
			/**
			 * check that current process document has a note.
			 * @return {Boolean} [description]
			 */
			hasNote(){
				return this.document_notes != null;
			},
			hasAdminNote(){
				if (this.contact_document.version.admin_note == undefined) return false;
				if (this.contact_document.version.admin_note == null) return false;
				if (this.contact_document.version.admin_note.length === 0) return false;

				return true;
			},
			adminNote(){
				return (this.hasAdminNote)? this.contact_document.version.admin_note : "";
			},
			...mapState({
				contact_document: state => state.contact_document,
				document_notes: state => state.document_notes,
				user: state => state.user
			})
		},
		watch:{
			contact_document(){
				setTimeout(() => {
					//eslint-disable-next-line
					UIkit.update(this.$refs.gridContainer);
				},1000);
				
			},
		}
	}
</script>