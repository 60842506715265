<template>
	<div class="tsa-contact-document tsa-default">
		<DocumentHeader />
		<div class="uk-child-width-1-2@m uk-grid-small tsa-document-form" uk-grid="masonry: true">
			<div>
				<div class="form-section">{{ submittingCompany.name }} Contact <i class="fas fa-user"></i>
					<!-- update profile when I submit case. -->
					&nbsp; <span class="tsa-note">Changes will update your profile.</span>
				</div>
				<div :class="formRowClass('first_name')">
					<input type="text" name="first_name" placeholder="Enter Your First Name" v-model="form_data.first_name" />
					<span class="error-block">{{ errors.first_name }}</span>
				</div>
				<div :class="formRowClass('last_name')">
					<input type="text" name="last_name" placeholder="Enter Your Last Name" v-model="form_data.last_name" />
					<span class="error-block">{{ errors.last_name }}</span>
				</div>
				<div :class="formRowClass('email')">
					<input type="email" name="email" placeholder="Enter Your Email" v-model="form_data.email" />
					<span class="error-block">{{ errors.email }}</span>
				</div>
				<div :class="formRowClass('phone')">
					<TelephoneField
						:phone="form_data.phone"
						:defaultCountry="form_data.phone_country_code"
						@country-changed="countryChanged"
						@phone-changed="onPhoneChanged"
						@onlyNumbers="onOnlyNumbers"
						:formatted="true"
						placeholder="Enter Phone" />
					<span class="error-block">{{ errors.phone }}</span>
				</div>
				<div :class="formRowClass('internal_reference')">
					<input type="text" name="internal_reference" placeholder="Internal Reference (Case / Project / Customer)" v-model="form_data.internal_reference" />
					<span class="error-block">{{ errors.internal_reference }}</span>
				</div>
			</div>
			<div class="uk-padding-small" style="padding-top: 0;">
				<div class="form-section">Additional Information</div>
				<div :class="formRowClass('business_impact')">
					<textarea placeholder="Business Impact" rows="6" v-model="form_data.business_impact"></textarea>
					<span class="error-block">{{ errors.business_impact }}</span>
				</div>
				<div :class="formRowClass('notes')">
					<textarea placeholder="Note to Manager" rows="6" v-model="form_data.notes"></textarea>
					<span class="error-block">{{ errors.notes }}</span>
				</div>
				<div :class="formRowClass('vendor_reference')">
					<input type="text" name="vendor_reference" :placeholder="'Existing case# with ' + company.name" v-model="form_data.vendor_reference" />
					<span class="error-block">{{ errors.vendor_reference }}</span>
				</div>
				<button @click.prevent="submitRequest" class="tsa-btn">Submit</button>
			</div>
		</div>
	</div>
</template>

<script>
import {mapState} from "vuex";
import TelephoneField from '@/components/Form/TelephoneField.vue';
import DocumentHeader from "../ContactDocuments/DocumentHeader";
import _ from "lodash";

export default {
	name: "ManagerRequest",
	components:{
		DocumentHeader,
		TelephoneField
	},
	data() {
		return {
			company:{ //recipient company
				id: null,
				name: ""
			},
			form_data:{
				first_name: "",
				last_name: "",
				email: "",
				phone: "",
				phone_country_code: "",
				only_numbers: "",
				internal_reference: "",
				vendor_reference: "",
				business_impact: "",
				escalation_type: "",
				update_profile: true,
				notes:""
			},
			errors: {
			},
		}
	},
	mounted() {
		this.copyUserData();
		this.getCompany();
	},
	methods: {
		/**
		 * 'form-row' add 'has-error' class if field has error
		 * @param  {[type]} field [description]
		 * @return {[type]}       [description]
		 */
		formRowClass(field){
			let string = "form-row";
			if(this.errors[field] != undefined ) string += " has-error";
			return string;
		},
		copyUserData(){
			this.form_data.first_name = this.user.first_name;
			this.form_data.last_name = this.user.last_name;
			this.form_data.email = this.user.email;
			const phoneNumber = (this.user.phone == null)? "" : this.user.phone.replace(/\D/g,'');
			this.form_data.phone = phoneNumber;
			this.form_data.phone_country_code = this.user.phone_country_code;
			this.form_data.company = this.company.full_name;
		},
		getCompany(){
			//eslint-disable-next-line
			axios.get(`${process.env.VUE_APP_API_URL}caller/companies/${this.document.company_id}`).then(
				(res) => this.updateCompany(res),
				(res) => this.failCompany(res)
			);
		},
		updateCompany(res){
			this.company = res.data;
		},
		failCompany(res){
			console.log(res);
		},
		submitRequest(){
			let data = _.clone(this.form_data);

			data.receive_company_id = this.document.company_id;
			data.contact_document_id = this.document.id;

			// clear all errors
			this.errors = {};

			// show loader
			this.emitter.emit('loader',true);

			// eslint-disable-next-line
			axios.post(`${process.env.VUE_APP_API_URL}manager/request/create`,data)
				.then(response => this.submitRequestResponse(response.data))
				.catch(error => this.handleErrors(error));
		},
		submitRequestResponse(response){
			// update local profile data.
			this.$store.commit('setProfileDataFromCase',{
				first_name : this.form_data.first_name,
				last_name : this.form_data.last_name,
				phone_number : this.form_data.phone_number,
				phone_country_code : this.engineer_country_code,
				email: this.form_data.email
			});
			// hide loader
			this.emitter.emit('loader',false);
			// alert message
			this.emitter.emit('alert',response.message);
			// save case details to store.
			this.$store.dispatch('caseDetails',response);
			// let app know case was submitted
			this.$emit('submitted',response);
		},
		/**
		 * handle validation errors.
		 * @param  {[type]} errors [description]
		 * @return {[type]}        [description]
		 */
		handleErrors (error){
			let errors = [];
			if (error.response && error.response.data && error.response.data.errors){
				errors = error.response.data.errors;
			}
			// hide loader
			this.emitter.emit('loader',false);
			// clear all errors
			this.errors = {};
			for(var e in errors){
				this.errors[e] = errors[e][0];
			}
		},
		countryChanged(data){
			if(data == null) return;
			if(typeof data === 'string'){
				this.form_data.phone_country_code = data;
			}else{
				// object
				this.form_data.phone_country_code = data.iso2;
			}
		},
		onPhoneChanged(number){
			this.form_data.phone = number;
		},
		onOnlyNumbers(digits){
			this.form_data.only_numbers = digits;
		}
	},
	computed:{
		...mapState({
			document: state => state.contact_document,
			user: state => state.user,
			submittingCompany: state => state.company
		})
	},
	watch:{
		user(){
			this.copyUserData();
		},
	}
}
</script>