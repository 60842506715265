<template>
	<div class="auto-login">
		Please Wait...
	</div>
</template>

<script>

export default {
	name: 'auto-login',
	components: {
	},
	mounted(){
		// eslint-disable-next-line
		axios.post(`${process.env.VUE_APP_API_URL}caller/auto_login`,{
			token: this.$route.params.token
		})
		.then(response => this.handleAutoLogin(response.data))
		// eslint-disable-next-line
		.catch(error => console.log(error));
	},
	methods:{
		/**
		 * save response in store object.
		 * @param  {[type]} response [description]
		 * @return {[type]}          [description]
		 */
		handleAutoLogin(response){
			this.$store.dispatch('token',response);
			this.emitter.emit('get_user');
			if(this.$route.query.redir){
				let redir = this.$route.query.redir;
				if(redir == "case"){
					let caseToken = this.$route.query.token;
					setTimeout(() => this.$router.push({name:"admin.case-info", params:{token:caseToken}}),0);
				} else {
					setTimeout(() => this.$router.push({name:redir}),0);
				}
			} else {
				setTimeout(() => this.$router.push({name:'dashboard'}),0);
			}
			
		}
	}
}
</script>