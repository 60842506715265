<template>
	<div class="tsa-submit-case">
		<RequestSent v-show="request_sent" />
		<div v-show="!request_sent">
			<Default
				v-if="managedDocument"
				@submitted="caseSubmitted" />
			<StaticDocument
				v-if="staticDocument"
				:process_document="document" />
			<ParentDocument
				v-if="parentDocument"
				@selected="onSubProcessSelected"
				:process_document="document" />
			<ManagerRequest
				v-if="managerDocument"
				@submitted="caseSubmitted"
			/>
		</div>
		
	</div>
</template>
<script>
	import Default from "@/components/ContactDocuments/Default.vue";
	import RequestSent from "@/components/ContactDocuments/RequestSent.vue";
	import StaticDocument from '@/components/ContactDocuments/StaticDocument.vue';
	import ParentDocument from '@/components/ContactDocuments/ParentDocument.vue';
	import {mapState} from 'vuex';
	import ManagerRequest from "@/components/Manager/ManagerRequest";
	export default {
		name: "submit-case",
		components: {
			ManagerRequest,
			Default,
			RequestSent,
			StaticDocument,
			ParentDocument
		},
		data(){
			return {
				request_sent: false,
				is_initialized: false
			}
		},
		mounted(){
			this.initialized();
		},
		updated(){
			this.initialized();
		},
		/**
		* remove bus event listeners before component is destroyed.
		* @return {[type]} [description]
		*/
		beforeUnmount(){
			this.emitter.off('token_ready');
			this.emitter.off('route_change');
		},
		methods:{
			initialized(){
				if(this.is_initialized) return;
				this.is_initialized = true;
				this.emitter.on('token_ready', () => this.loadDocuments());
				if(this.jwt_token.access_token != undefined) this.loadDocuments();
				this.emitter.on('route_change', () => this.clearData());
			},
			caseSubmitted(response){
				this.request_sent = true;
				this.emitter.emit('alert',response.message);
			},
			/**
			 * load documents for client.
			 * @return {[type]} [description]
			 */
			loadDocuments(){
				this.emitter.emit('loader',true);
				// api url
				const url = `${process.env.VUE_APP_API_URL}caller/documents/${this.$route.params.type}/${this.$route.params.entity_id}`;
				// eslint-disable-next-line
				axios.get(url)
				.then(response => this.documentsResponse(response.data))
				// eslint-disable-next-line
				.catch(error => console.log(error));
			},
			onSubProcessSelected(document_id){
				// api url
				const url = `${process.env.VUE_APP_API_URL}caller/sub_documents/${document_id}/${this.$route.params.type}/${this.$route.params.entity_id}`;
				// eslint-disable-next-line
				axios.get(url)
				.then(response => this.onSubDocumentLoaded(response.data))
				// eslint-disable-next-line
				.catch(error => console.log(error));
			},
			onSubDocumentLoaded(response){
				this.$store.commit("overwriteCaseProcess",response);
			},
			/**
			 * set document in store.
			 * @param  {[type]} response [description]
			 * @return {[type]}          [description]
			 */
			documentsResponse(response){
				this.emitter.emit('loader',false);
				this.$store.dispatch('caseData',{
					//company: response.company,
					document: response.documents[0],
					messages: response.priority_messages
				});
			},
			/**
			 * clear process document data.
			 * @return {[type]} [description]
			 */
			clearData(){
				this.$store.dispatch('clearCaseData');
			}
		},
		computed:{
			managedDocument(){
				if(this.document.version != undefined){
					if(this.document.version.document_type == "ticket" || this.document.version.document_type == "solution_support" || this.document.version.document_type == "json") return true;
				}
				return false;
			},
			staticDocument(){
				if(this.document.version != undefined){
					if(this.document.version.document_type == "default") return true;
				}
				return false;
			},
			parentDocument(){
				if(this.document.version != undefined){
					if(this.document.version.document_type == "parent") return true;
				}
				return false;
			},
			managerDocument(){
				if(this.document.version != undefined){
					if(this.document.version.document_type == "manager") return true;
				}
				return false;
			},
			...mapState({
				jwt_token: state => state.token,
				document: state => state.contact_document
			})
		}
	}
</script>