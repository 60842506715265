<template>
    <div :class="containerClass">
        <select v-model="main_select_value" :placeholder="placeholder" :disabled="disabled">
            <option value="">Choose a {{ requirement.requirement.label}}</option>
            <option v-for="(option,index) in main_options" :key="index" :value="option.option">{{option.option}}</option>
        </select>
        <div class="autocomplete-field" v-show="settings.searchable">
            <input type="text" 
                v-model="search_term"
                :disabled="this.main_select_value ==''"
                style="margin-top: 5px;"
                :placeholder="emptyOption"
                v-show="this.sub_select_value == ''"
                ref="searchField" />
            <div class="autocomplete-suggestions" v-show="searching">
                <div v-for="(row,index) in search_results"
                    class="autocomplete-suggestion"
                    :key="index"
                    @click="resultSelected(row)">
                        <span v-html="highlightMatchedResult(row)" ></span>
                </div>
                <div class="autocomplete-suggestion" v-show="search_results.length === 0" @click.prevent="useSearchTerm">{{ settings.no_results }}. Use <strong>{{ search_term }}</strong></div>
            </div>
            <div class="selected-result" v-show="sub_select_value != ''">
                {{ sub_select_value }} <i class="fas fa-times" @click.prevent="resetSelected"></i>
            </div>
        </div>
        <select
            v-show="!settings.searchable"
            v-model="sub_select_value"
            :disabled="this.main_select_value ==''"
            style="margin-top: 5px;">
            <option value="">{{ emptyOption }}</option>
            <option v-for="(option,index) in sub_options" :key="index" :value="option">{{option}}</option>
        </select>
        <span class="error-block"><strong>{{ error }}</strong></span>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import _ from 'lodash';

    export default {
        name: "TSANetTierSelect",
        props: {
            requirement: {
				type: Object,
				default: () => {}
			},
            error: {
                type: String,
                default: ""
            },
			value: {
				type: Object,
				default: () => {}
			},
            placeholder: {
                type: String,
                default: "Choose One"
            },
            disabled: {
                type: Boolean,
                default: false
            }
        },
        data(){
            return {
				main_select_value: "",
                sub_select_value: "",
                sub_options: [],
                main_options: [],
                linked_required_options : [],
                settings: {},
                search_term : "",
                search_results : [],
                searching: false
			}
        },
        mounted(){
            this.handleOptions();
            this.checkLinkedVisibility();
        },
        methods: {
            handleOptions(){
                
                if(this.requirement.options){
                    this.main_options = JSON.parse(this.requirement.options);
                }

                if(this.requirement.additional_settings != null) this.settings = JSON.parse(this.requirement.additional_settings);
            },
            /**
             * reset sub value if nothing selected
             * look through main options for sub options
             * @return {[type]} [description]
             */
            handleMainValueSelected(){
                this.sub_select_value = "";
                // if main select not selected ?
                if(this.main_select_value == ""){
                    this.sub_options = [];
                    return;
                }
                for(let i in this.main_options){
                    const option = this.main_options[i];
                    if(option.option == this.main_select_value){
                        this.sub_options = option.options;
                        if(this.settings.linked_required != undefined){
                            this.linked_required_options = option.linked_required;
                        }
                        break;
                    }
                }
                if(this.settings.searchable){
                    setTimeout(() => {
                        this.$refs.searchField.focus();
                    },0);
                }
            },
            checkLinkedVisibility(){
                // check if the value of the field is linked to another field.
                if(this.settings.linked_required != undefined){
                     let value = (this.sub_select_value == "")?  "" : `${this.main_select_value} : ${this.sub_select_value}`;

                    //const linkedElem = document.getElementById(this.settings.linked_required);
                    if(value == ""){
                        this.emitter.emit(`${this.settings.linked_required}_hide`);
                    }else{
                        // if one of the options requires another field
                        if(this.linked_required_options.indexOf(this.sub_select_value) !== -1){
                            // send event so that field can be shown
                            this.emitter.emit(`${this.settings.linked_required}_show`);
                        }else{
                            // send event so that field can be hidden
                            this.emitter.emit(`${this.settings.linked_required}_hide`);
                        }
                    }
                }
            },
            searchOptions(){
                const lowerSeach = this.search_term.toLowerCase();
                // eslint-disable-next-line
                this.search_results = _.filter(this.sub_options, (value) => {
                    return value.toLowerCase().indexOf(lowerSeach) !== -1;
                });
            },
            resultSelected(row){
                this.search_term = "";
                this.sub_select_value = row;
            },
            /**
             * use the search term as the selected item for sub select
             * @return {[type]} [description]
             */
            useSearchTerm(){
                this.sub_select_value = this.search_term;
                this.search_term = "";
            },
            /**
            * hightlight the entered term in string (set strong tag)
            * @param  {string} value source string
            * @return {string} html string
            */
            highlightMatchedResult(row){
                    let pattern = '(' + this.escapeRegExChars(this.search_term ) + ')';
                    return row
                    // eslint-disable-next-line
                    .replace(new RegExp(pattern, 'gi'), '<strong>$1<\/strong>')
                    .replace(/&/g, '&amp;')
                    .replace(/</g, '&lt;')
                    .replace(/>/g, '&gt;')
                    .replace(/"/g, '&quot;')
                    .replace(/&lt;(\/?strong)&gt;/g, '<$1>');
            },
            resetSelected(){
                this.sub_select_value = "";
                setTimeout(() => {
                    this.$refs.searchField.focus();
                },0);
            },
            /**
            * function name says it all
            * @param  {string} value [description]
            * @return escaped string
            */
            escapeRegExChars: function (value) {
                return value.replace(/[|\\{}()[\]^$+*?.]/g, "\\$&");
            },
        },
        computed: {
            emptyOption(){
                if(this.main_select_value == ""){
                    return `First Select ${this.requirement.requirement.label}`;
                }else{
                    return this.settings.sub_placeholder;
                }
            },
            containerClass(){
                let string = `form-group-${this.requirement.group} form-row tsa-custom-select`;
                if(this.error.length > 0) string += ' has-error';
                if(this.requirement.group != null){
                    if(this.document_active_groups[this.requirement.group] === false){
                        string += " hide";
                    }
                }
                return string;
            },
            ...mapState({
                document_active_groups: state => state.document_active_groups
            })
        },
        watch:{
            main_select_value(){
                this.handleMainValueSelected();
            },
            sub_select_value(){
                let value = (this.sub_select_value == "")?  "" : `${this.main_select_value} : ${this.sub_select_value}`;
                this.$emit('change',{
                    'requirement_id' : this.requirement.requirement_id,
                    'data' : value
                });
                this.checkLinkedVisibility();
            },
            search_term(){
                // if no searching clear.
                if(this.search_term.length === 0){
                    this.search_results = [];
                    this.searching = false;
                }else{
                    // search table
                    this.searchOptions();
                    this.searching = true;
                }
            }
		}
    }
</script>