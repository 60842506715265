<template>
	<div class="tsa-login-register">
		<div class="uk-child-width-1-2@m uk-grid-small" uk-grid>
			<div>
				<!-- login section -->
				<div class="tsa-box low-padding">
					<div class="tsa-section-title">SSO Login</div>
					<!-- sso login text -->
					<div>
						<p v-html="company.sso_login_section" />
						<div style="text-align: center;">
							<!-- login link -->
							<a class="tsa-btn ib" :href="company.sso_idp_federation_url">Login</a>
						</div>
					</div>
				</div>
				<!-- tsa managers -->
				<Managers v-if="showManagers" />
				<!-- login text-->
				<div class="tsa-managers" v-if="showLoginPageText">
					<p v-html="company.login_page_text" />
				</div>
			</div>
			<div>
				<div class="tsa-box low-padding">
					<div class="tsa-section-title">Need an Account?</div>
					<!-- sso login text -->
					<div>
						<p v-html="company.sso_registration_section" />
						<!--div>
							<a class="tsa-btn ib pull-right" :href="company.sso_idp_federation_url">Register</a>
							<div class="clear-after"></div>
						</div-->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

	// import components
	import Managers from '@/components/Home/Managers.vue';

	import {mapState} from 'vuex';

	export default {
		name: 'sso-login',
		components: {
			Managers
		},
		data(){
			return {
				forgot_password: {
					open: false
				}
			}
		},
		methods:{
			/**
			 * close forgot password window.
			 * @return {[type]} [description]
			 */
			closeResetPassword(){
				this.forgot_password.open = false;
			},
			/**
			 * show forgot password window
			 * @return {[type]} [description]
			 */
			showForgotPassword(){
				this.forgot_password.open = true;
			}
		},
		computed: {
			/**
			 * check if it should show list of managers.
			 * @return {[type]} [description]
			 */
			showManagers(){
				if(this.showLoginPageText) return false;
				return this.manager != null;
			},
			/**
			 * check if company has login page text.
			 * @return {[type]} [description]
			 */
			showLoginPageText(){
				if(this.company.login_page_text != undefined){
					if(this.company.login_page_text != null){
						return this.company.login_page_text.length > 0;
					}
				}
				return false;
			},
			...mapState({
				manager : state => state.manager,
				app_slug: state => state.app_slug,
				company: state => state.company
			})
		}
	}
</script>