<template>
	<div class="tsa-company-users">
		<Expandable
			:title="theTitle">
			<template v-slot:after_title>
				<ExportButton :export_route="exportRoute" file_name="users.xlsx" />
			</template>
			<template v-slot:headerright>
				<button
					class="tsa-btn tsa-outline pull-right"
					@click.prevent="addNewUser"
					><i class="fas fa-plus"></i> Add New</button>
			</template>
			<DataTable
				:utc_note="true"
				:row_class="setRowClass"
				:table_headers="table_headers"
				:table_data="users" />
		</Expandable>
	</div>
</template>

<script>
	import Expandable from '@/components/Layout/ExpandableBox.vue';
	import DeleteUser from '@/components/SuperAdmin/Company/DeleteUserCell.vue';
	import DataTable from '@/components/Table/DataTable.vue';
	import ExportButton from '@/components/SuperAdmin/ExportButton.vue';

	export default {
		name: 'company-users',
		components: {
			Expandable,
			DataTable,
			ExportButton
		},
		props: {
			company: {
				type: Object,
				default: () => {}
			}
		},
		data(){
			return {
				table_headers:{
					name: {
						route: "/super_admin/companies/{company_id}/users/{id}",
						render: cell_data => {
							if(
								(cell_data.first_name == null && cell_data.last_name == null) || (cell_data.first_name == "" && cell_data.last_name == "")
							) return "N/A";
							return 	`${cell_data.first_name} ${cell_data.last_name}`;
						}
					},
					location: {
						render: cell_data => {
							let city = (cell_data.city == null)? "" : cell_data.city,
							country = (cell_data.country == null)? "" : cell_data.country.name;
							return (city.length)? `${city}, ${country}` : country;
						}
					},
					role: {},
					'last_login_date': {
						display: "Last Login",
						format: 'date_time'
					},
					registration_type: {
						display: "Created Through",
					},
					'': {
						display: "&nbsp;",
						searchable: false,
						component: DeleteUser
					}
				},
				users: [],
				initialized: false
			}
		},
		mounted(){
			// set listener to remove user.
			this.emitter.on('sa_confirm_delete_user',user_id => this.deleteUser(user_id));
		},
		/**
		 * remove bus event listeners before component is destroyed.
		 * @return {[type]} [description]
		 */
		beforeUnmount(){
			this.emitter.off('sa_confirm_delete_user');
		},
		methods: {
			addNewUser(){
				this.$router.push({name: "super_admin.user.edit", params: {company_id: this.company.id, user_id : 0}});
			},
			getUsers(){
				//eslint-disable-next-line
				axios.get(`${process.env.VUE_APP_API_URL}super_admin/companies/${this.company.id}/users`)
				.then(response => this.handleGetUsers(response.data))
				//eslint-disable-next-line
				.catch(error => console.log(error));
			},
			handleGetUsers(response){
				this.users = response;
			},
			/**
			 * set the row style for the user row.
			 * strike through if the user is inactive
			 * @param {[type]} cell_data [description]
			 */
			setRowClass(user){
				return (user.status == "inactive")? "row-inactive" : "";
			},
			/**
			 * send request to delete user
			 * @return {[type]} [description]
			 */
			deleteUser(user_id){
				this.emitter.emit('loader',true);
				const url = `${process.env.VUE_APP_API_URL}super_admin/users/${user_id}/delete`;
				//eslint-disable-next-line
				axios.get(url)
				.then(response => this.handleDeleteUser(response.data))
				//eslint-disable-next-line
				.catch(error => console.log(error));
			},
			/**
			 * if error alert error
			 * otherwise success message
			 * @return {[type]} [description]
			 */
			handleDeleteUser(response){
				this.emitter.emit('loader',false);
				if(response.error != undefined){
					this.emitter.emit('alert',{message: response.error, type: "warning"});
				}else{
					this.emitter.emit('alert',response.message);
					this.getUsers();
				}
			}
		},
		computed: {
			theTitle(){
				return `Users (${this.users.length})`;
			},
			exportRoute(){
				return `${process.env.VUE_APP_API_URL}super_admin/companies/${this.company.id}/users/export`;
			}
		},
		watch: {
			company(){
				if(this.company.id != undefined) this.getUsers();
			}
		}
	}
</script>