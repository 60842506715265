<template>
	<div class="manage-users">
		<Modal
			title="Change User Password"
			:is_open="modal.open"
			v-if="user.id != undefined && company.login_method != 'sso'"
			@close="closeModal">
				<div class="modal-inner">
					<ChangePassword
						:user="user"
						@updated="closeModal" />
				</div>
		</Modal>
		<Breadcrumb :routes="breadcrumb" />
		<div class="tsa-admin-header">
			{{ company.name }} &mdash; <span class="page-name">{{ headerDisplay }}</span>
			<button @click.prevent="loginAsUser" v-if="user.id != undefined" class="tsa-btn">Login as {{user.first_name}}</button>
		</div>
		<UserForm
			:form_ready="form_ready"
			:user="user"
			:access="access"
			:roles="roles"
			:company="company"
			@password="openModal"
			@updated="onUserUpdated" />
	</div>
</template>

<script>
// import any required components
import {mapState} from 'vuex';
import UserForm from '@/components/SuperAdmin/Users/UserForm.vue';
import ChangePassword from '@/components/SuperAdmin/Users/ChangePassword.vue';
import Modal from '@/components/Layout/DefaultModal.vue';
import Breadcrumb from '@/components/Layout/Breadcrumb.vue';

// export component
export default {
	name: "admin-manage-user",
	// properties that the component will receive from parent
	props: {
	},
	// components to use in template
	components:{
		UserForm,
		ChangePassword,
		Modal,
		Breadcrumb
	},
	// component data
	data() {
		return {
			user: {},
			form_data: {

			},
			access: [],
			communities: [],
			roles: [],
			company: {
				login_method : "email_link"
			},
			modal: {
				open: false
			},
			form_ready: false
		};
	},
	updated(){
		this.initialize();
	},
	mounted(){
		this.initialize();
	},
	/**
	* remove bus event listeners before component is destroyed.
	* @return {[type]} [description]
	*/
	beforeUnmount(){
		this.emitter.off('token_ready');
	},
	methods: {
		initialize(){
			if(this.is_initialized) return;
			this.is_initialized = true;
			this.emitter.on('token_ready', () => this.loadCompany());
			if(this.jwt_token.access_token != undefined) this.loadCompany();
		},
		/**
		 * load current company data.
		 * @return {[type]} [description]
		 */
		loadCompany(){
			if(this.$route.params.company_id){
				//eslint-disable-next-line
				axios.get(`${process.env.VUE_APP_API_URL}super_admin/companies/${this.$route.params.company_id}`)
					.then(response => this.handleGetCompany(response.data))
					//eslint-disable-next-line
					.catch(error => console.log(error));
			} else {
				this.loadUser()
			}
		},
		handleGetCompany(response){
			this.company = response.company;
			if(this.$route.params.user_id != 0){
				if(!this.user.id){
					this.loadUser();
				} else {
					setTimeout(()=> { this.form_ready = true},0);
				}
			}else{
				// ready because don't need to load the user.
				this.form_ready = true;
			}
		},
		loadUser(){
			this.emitter.emit('loader',true);
			//eslint-disable-next-line
			axios.get(`${process.env.VUE_APP_API_URL}super_admin/users/${this.$route.params.user_id}`)
			.then(response => this.handleGetUser(response.data))
			//eslint-disable-next-line
			.catch(error => console.log(error));
		},
		handleGetUser(response){
			this.emitter.emit('loader',false);
			if(response.error != undefined){
				this.emitter.emit('alert',{message: response.error,type:'warning'});
				this.$router.push({name : 'super_admin.admin.users'});
			}else{
				this.user = response.user;
				this.access = response.access;
				this.communities = response.communities;
			}
			if(!this.company.id){
				//eslint-disable-next-line
				axios.get(`${process.env.VUE_APP_API_URL}super_admin/companies/${this.user.company_id}`)
					.then(response => this.handleGetCompany(response.data))
					//eslint-disable-next-line
					.catch(error => console.log(error));
			} else {
				setTimeout(()=> { this.form_ready = true},0);
			}
		},
		/**
		 * event listener for when the user was update in UserForm component.
		 * @param  {[type]} response [description]
		 * @return {[type]}          [description]
		 */
		onUserUpdated(response){
			//this.user = response.user;
			// redirect
			if(response.save_and_new){
				// redirect to new user.
				this.$router.push({name: "super_admin.user.edit", params: {company_id : this.company.id,user_id: 0}});
			}else{
				// back to users list
				this.$router.push({name: "super_admin.company.detail",params: {company_id : this.company.id }});
			}
		},
		openModal(){
			this.modal.open = true;
		},
		closeModal(){
			this.modal.open = false;
		},
		/**
		 * send request to log in as user.
		 * @return {[type]} [description]
		 */
		loginAsUser(){
			this.emitter.emit('login_as_user',this.user.id);
		}
	},
	computed:{
		breadcrumb(){
			return [
				{display: "Companies" ,route: {name: "super_admin.companies"}},
				{display: this.company.name, route: {name: "super_admin.company.detail",params: {company_id : this.company.id}}},
				this.headerDisplay
			];
		},
		headerDisplay(){
			return (this.user.id == undefined)? "New User" : `${this.user.first_name} ${this.user.last_name}`;
		},
		...mapState({
			jwt_token : state => state.token
		})
	}
}
</script>