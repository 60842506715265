<template>
	<div>
		<div class="tsa-admin-header">
			<HelpHint flag="process_document_fields" /> <span>Fields &mdash;</span> To modify <button class="tsa-btn tsa-btn-secondary" @click.prevent="changeRequest">Contact TSANet</button>
		</div>
		<div class="admin-note">
			<span>Note:</span>
			<div style="flex-grow: 1" v-html="adminNote" />
		</div>
		<div class="tsa-submit-case">
			<ManagerSample v-if="document.version.document_type == 'manager'" />
			<DocumentSample v-else />
		</div>
	</div>
</template>
<script>
	import HelpHint from '@/components/Layout/HelpHint.vue';
	import DocumentSample from '@/components/Admin/InboundProcess/DocumentSample.vue';
	import ManagerSample from "../../Manager/ManagerSample.vue";
	import {mapState} from 'vuex';

	export default {
		name: "document-fields",
		components: {
			HelpHint,
			DocumentSample,
			ManagerSample
		},
		data(){
			return {
				errors: {},
				show_request_window: false
			}
		}, mounted() {console.log(this.document.version.document_type)},
		methods: {
			changeRequest(){
				this.$emit('change_request', "Document Fields");
			}
		},
		computed : {
			hasAdminNote(){
				if (this.document.version.admin_note == undefined) return false;
				if (this.document.version.admin_note == null) return false;
				if (this.document.version.admin_note.length === 0) return false;

				return true;
			},
			adminNote(){
				return (this.hasAdminNote)? this.document.version.admin_note : "To add a note contact TSANet";
			},
			...mapState({
				document: state => state.contact_document
			})
		}
	}
</script>