<template>
	<div class="case-info">
		<div class="tsa-admin-header tsa-admin-header-flex">
			<div>
				Case Details &mdash; <span class="page-name">{{ pageTitle }}</span>
			</div>
			<case-info-controls v-if="case_entry" :case-entry="case_entry" @updated="updateCase" />
			<div class="utc-note">
				<span>NOTE:</span>&nbsp;<i>All time stamps are shown in UTC time.</i>
			</div>
		</div>
		<div class="uk-child-width-1-2@m uk-grid-medium" uk-grid>
			<div>
				<CaseDetails
				:case_entry="case_entry" />
			</div>
			<div>
				<CaseResponse
					v-if="case_entry.status == 'accepted' || case_entry.status == 'open' || case_entry.status == 'closed'"
					:case_entry="case_entry" @updated="updateCase" />
				<case-more-info v-if="case_entry.status == 'information'" :case-entry="case_entry" @updated="updateCase"></case-more-info>
				<case-rejected v-if="case_entry.status == 'rejected'" :case-entry="case_entry"></case-rejected>
			</div>
		</div>
		<div v-if="showEscalationInstructions">
			<div class="tsa-admin-header"><span class="page-name">Escalation Instructions</span></div>
			<div class="uk-container">
				<div v-html="escalationInstructions" />
			</div>
		</div>
		<case-notes :case-entry="case_entry" @updated="updateCase" />
	</div>
</template>

<script>

	import CaseDetails from '@/components/CaseDetails/CaseDetails.vue';
	import CaseResponse from '@/components/CaseDetails/CaseResponse.vue';
	import CaseMoreInfo from '@/components/CaseDetails/CaseMoreInfo.vue';
	import CaseRejected from '@/components/CaseDetails/CaseRejected.vue';
	import CaseNotes from "@/components/CaseDetails/CaseNotes.vue";
	import CaseInfoControls from "@/components/CaseDetails/CaseInfoControls.vue";
	import {mapState} from 'vuex';

	export default {
		name: "case-info",
		components: {
			CaseDetails,
			CaseResponse,
			CaseMoreInfo,
			CaseRejected,
			CaseNotes,
			CaseInfoControls
		},
		data(){
			return {
				case_entry: {
					company: {},
					receiver: {},
					engineer: {},
					response: {},
					customer_data: []
				},
				has_initialized: false
			}
		},
		mounted(){
		//this.loadDashboardData();
			this.initialize();
		},
		updated(){
			if(!this.has_initialized) this.initialize();
		},
		/**
		 * remove bus event listeners before component is destroyed.
		 * @return {[type]} [description]
		 */
		beforeUnmount(){
			this.emitter.off('token_ready');
		},
		methods: {
			initialize(){
				this.has_initialized = true;
				this.emitter.on('token_ready', () => this.loadCaseInformation());
				if(this.jwt_token.access_token != undefined) this.loadCaseInformation();
			},
			updateCase(){
				this.loadCaseInformation();
			},
			/**
			 * load case information using token
			 * @return {[type]} [description]
			 */
			loadCaseInformation(){
				this.emitter.emit('loader',true);
				const token = this.$route.params.token;
				//eslint-disable-next-line
				axios.get(`${process.env.VUE_APP_API_URL}caller/case-details/${token}`)
				.then(response => this.handleLoadCaseInfo(response.data))
				//eslint-disable-next-line
				.catch(error => console.log(error));
			},
			/**
			 * save case information to store so children component can display information.
			 * @param  {[type]} response [description]
			 * @return {[type]}          [description]
			 */
			handleLoadCaseInfo(response){
				this.emitter.emit('loader',false);
				// no error?
				if(response.error == undefined){
					this.case_entry = response.case;
				}else{
					// show error to user
					this.emitter.emit('alert',{message: response.error, type: "warning"});
				}
			}
		},
		computed: {
			showEscalationInstructions(){
				return true;
				//return this.case_entry.submit_company_id == this.user.company_id;
			},
			escalationInstructions(){
				if(this.case_entry.document == undefined) return "";
				return this.case_entry.document.version.escalation_instructions;
			},
			pageTitle(){
				if(this.case_entry.id == undefined) return "";
				if(this.company.id == this.case_entry.submit_company_id){
					return `Outbound to ${this.case_entry.receiver.name}`;
				}else{
					return `Inbound from ${this.case_entry.company.name}`;
				}
			},
			breadcrumb(){
				return [
					{display: "Reports" ,route: {name: "admin.reports"}},
					`#${this.case_entry.internal_case_number} &mdash; ${this.pageTitle}`
				];
			},
			decisionReady(){
				if(this.case_entry.responses){
					for(let i = 0; this.case_entry.responses.length > i;++i){
						if(this.case_entry.responses[i].type == "information-response"){
							return true;
						}
					}
				}
				return false;
			},
			...mapState({
				company: state => state.company,
				jwt_token: state => state.token,
				user: state => state.user
			})
		}
	}
</script>