<template>
	<div v-if="!responseSent && confirmed">
		<h1>Critical Escalation Request Details</h1>
		<div class="uk-child-width-1-2@m uk-grid-small tsa-document-form" uk-grid="masonry: true">
			<div>
				<div class="form-section">Respond to {{ fromCompany.name }}
				</div>
				<div :class="formRowClass('first_name')">
					<input type="text" name="first_name" placeholder="Manager First Name" v-model="form_data.first_name" />
					<span class="error-block">{{ errors.first_name }}</span>
				</div>
				<div :class="formRowClass('last_name')">
					<input type="text" name="last_name" placeholder="Manager Last Name" v-model="form_data.last_name" />
					<span class="error-block">{{ errors.last_name }}</span>
				</div>
				<div :class="formRowClass('email')">
					<input type="email" name="email" placeholder="Manager Email" v-model="form_data.email" />
					<span class="error-block">{{ errors.email }}</span>
				</div>
				<div :class="formRowClass('phone')">
					<TelephoneField
						:phone="form_data.phone"
						:defaultCountry="form_data.phone_country_code"
						@country-changed="countryChanged"
						@phone-changed="onPhoneChanged"
						@onlyNumbers="onOnlyNumbers"
						:formatted="true"
						placeholder="Enter Phone" />
					<span class="error-block">{{ errors.phone }}</span>
				</div>
				<div :class="formRowClass('internal_reference')">
					<input type="text" name="internal_reference" placeholder="Internal Reference (Case / Project / Customer)" v-model="form_data.internal_reference" />
					<span class="error-block">{{ errors.internal_reference }}</span>
				</div>
			</div>
			<div class="uk-padding-small" style="padding-top: 0;">
				<div class="form-section">Additional Information</div>
				<div :class="formRowClass('notes')">
					<textarea placeholder="Note to Manager" rows="6" v-model="form_data.notes"></textarea>
					<span class="error-block">{{ errors.notes }}</span>
				</div>
				<button @click.prevent="submitResponse" class="tsa-btn">Submit</button>
			</div>
		</div>
		<RequestDetails :request-data="submission_data"></RequestDetails>
	</div>
	<div v-if="responseSent">
		<request-sent></request-sent>
	</div>
</template>

<script>
import TelephoneField from '@/components/Form/TelephoneField.vue';
import RequestDetails from "@/components/Manager/RequestDetails";
import RequestSent from "@/components/ContactDocuments/RequestSent";
import _ from "lodash";
export default {
	name: "ManagerResponse",
	components: {
		TelephoneField,
		RequestDetails,
		RequestSent
	},
	data() {
		return {
			fromCompany:{
				id: null,
				name: ""
			},
			toCompany:{
				id: null,
				name: ""
			},
			submission_data:{
				first_name: "",
				last_name: "",
				email: "",
				phone: "",
				notes: ""
			},
			form_data:{
				first_name: "",
				last_name: "",
				email: "",
				phone: "",
				phone_country_code: "",
				only_numbers: "",
				internal_reference: "",
				escalation_type: "",
				update_profile: true,
				notes:""
			},
			errors: {
			},
			responseSent: false,
			confirmed: false
		}
	},
	mounted() {
		this.emitter.emit('loader',true);
		//eslint-disable-next-line
		axios.get(`${process.env.VUE_APP_API_URL}manager/request/get/${this.$route.params.token}`)
			.then((pack) => this.loadRquest(pack))
			.catch((err) => this.failRequest(err));
	},
	methods: {
		loadRquest(pack){
			if(pack && pack.data){
				this.fromCompany = pack.data.submitting_company;
				this.toCompany = pack.data.receiving_company;
				this.submission_data = pack.data;
				if(pack.data.responses && pack.data.responses.length > 0){
					//eslint-disable-next-line
					axios.get(`${process.env.VUE_APP_API_URL}manager/request/response/get/${this.$route.params.token}`)
						.then(pack => this.responseResponse(pack))
						.catch(error => this.handleErrors(error));
				} else {
					this.confirmed = true;
					this.emitter.emit('loader',false);
				}
			}
		},
		failRequest(err){
			this.emitter.emit('loader',false);
			console.warn(err);
		},
		submitResponse(){
			let data = _.clone(this.form_data);

			// clear all errors
			this.errors = {};

			// show loader
			this.emitter.emit('loader',true);

			// eslint-disable-next-line
			axios.post(`${process.env.VUE_APP_API_URL}manager/request/response/` + this.submission_data.token,data)
				.then(pack => this.responseResponse(pack))
				.catch(error => this.handleErrors(error));
		},
		responseResponse(pack){
			if(pack.data){
				// hide loader
				this.emitter.emit('loader',false);
				// alert message
				this.emitter.emit('alert',pack.data.message);
				// save case details to store.
				this.$store.dispatch('caseDetails',pack.data);
				this.responseSent = true;
			}

		},
		/**
		 * 'form-row' add 'has-error' class if field has error
		 * @param  {[type]} field [description]
		 * @return {[type]}       [description]
		 */
		formRowClass(field){
			let string = "form-row";
			if(this.errors[field] != undefined ) string += " has-error";
			return string;
		},
		/**
		 * handle validation errors.
		 * @param  {[type]} errors [description]
		 * @return {[type]}        [description]
		 */
		handleErrors (error){
			let errors = [];
			if (error.response && error.response.data && error.response.data.errors){
				errors = error.response.data.errors;
			}
			// hide loader
			this.emitter.emit('loader',false);
			// clear all errors
			this.errors = {};
			for(var e in errors){
				this.errors[e] = errors[e][0];
			}
		},
		countryChanged(data){
			if(data == null) return;
			if(typeof data === 'string'){
				this.form_data.phone_country_code = data;
			}else{
				// object
				this.form_data.phone_country_code = data.iso2;
			}
		},
		onPhoneChanged(number){
			this.form_data.phone = number;
		},
		onOnlyNumbers(digits){
			this.form_data.only_numbers = digits;
		}
	}
}
</script>