<template>
	<span>
		<i class="fas fa-trash-alt danger" @click.prevent="deleteDocument"></i>
	</span>
</template>

<script>
	export default {
		name: "admin-partner-group-delete-document",
		components:{

		},
		props:{
			cell_data:{
				type: Object,
				default: () => {}
			}
		},
		methods:{
			/**
			 * confirm that user wants to delete document.
			 * @return {[type]} [description]
			 */
			deleteDocument(){
				this.emitter.emit('confirm',{
					message: `Are you sure you want to delete ${this.cell_data.document_name} from this partner group?`,
					flag: `confirm_delete_document`,
					data: this.cell_data.id
				});
			}
		}
	}
</script>