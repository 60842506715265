<template>
    <input type="hidden" :value="field_data" />
</template>

<script>
    export default {
        name: "hidden",
        props: {
            requirement: {
				type: Object,
				default: () => {}
			},
			model: {
				type: Object,
				default: () => {}
			},
            error: {
                type: String,
                default: ""
            }
        },
        data(){
            return {
				field_data: ""
			}
        },
        mounted(){
            this.field_data = this.requirement.options;
        },
        computed: {
        },
        methods: {
        },
        watch:{
			field_data(){
				this.$emit('change',{
					'requirement_id' : this.requirement.requirement_id,
					'data' : this.field_data
				});
			}
		}
    }
</script>