<template>
	<div class="tsa-profile-form">
		<div class="uk-child-width-1-2@m uk-grid-small" uk-grid>
			<div>
				<div class="form-section">{{ user.first_name }} {{ user.last_name }}: Contact Details</div>
				<!-- first name -->
				<div class="form-row with-label" :class="formRowClass('first_name')">
					<label>First Name</label>
					<input type="text" name="first_name" placeholder="Enter Your First Name" v-model="form_data.first_name" />
					<span class="error-block">{{ errors.first_name }}</span>
				</div>
				<!-- last name -->
				<div class="form-row with-label" :class="formRowClass('last_name')">
					<label>Last Name</label>
					<input type="text" name="last_name" placeholder="Enter Your Last Name" v-model="form_data.last_name" />
					<span class="error-block">{{ errors.last_name }}</span>
				</div>
				<!-- email -->
				<div class="form-row with-label" :class="formRowClass('email')">
					<label>Email</label>
					<input type="email" name="email" placeholder="Enter Your Email" v-model="form_data.email" />
					<span class="error-block">{{ errors.email }}</span>
				</div>
				<!-- phone -->
				<div class="form-row with-label" :class="formRowClass('phone')">
					<label>Phone</label>
					<telephone-field-two
						:phone="form_data.phone"
						:defaultCountry="user.phone_country_code"
						@country-changed="countryChanged"
						@phone-changed="onPhoneChanged"
						placeholder="Enter Phone" />
					<span class="error-block">{{ errors.phone }}</span>
				</div>
				<!-- country -->
				<div class="form-row with-label" :class="formRowClass('country')">
					<label>Country</label>
					<select v-model="form_data.country_id">
						<option value="">Choose Country</option>
						<option
							v-for="(name,country_id) in countries"
							:key="country_id"
							:value="country_id">{{ name }}</option>
					</select>
					<span class="error-block">{{ errors.country }}</span>
				</div>
				<!-- city -->
				<div class="form-row with-label" :class="formRowClass('city')">
					<label>City</label>
					<input type="text" name="city" placeholder="Enter Your City" v-model="form_data.city" />
					<span class="error-block">{{ errors.city }}</span>
				</div>
				<div class="form-row tsa-note" :class="formRowClass('agree_confirm')" style="margin-top: 20px;width: 100%;">
					<input type="checkbox" v-model="form_data.agree_confirm" />
					I agree to the <a href="https://www.tsanet.org/legal-policy/" target="_blank">TSANet Privacy Policy</a>
					<span class="error-message">You must accept the terms of the privacy policy.</span>
				</div>
				<div class='form-row'>
					<button class='tsa-btn pull-right' @click.prevent="updateProfile">Update Profile</button>
				</div>
			</div>
			<div v-if="allowPasswordChange">
				<div class="form-section">Change Password</div>
				<!-- last name -->
				<div class="form-row" :class="formRowClass('password')">
					<input type="password" name="password" placeholder="Enter New Password" v-model="password.password" />
					<span class="error-block">{{ errors.password }}</span>
				</div>
				<!-- email -->
				<div class="form-row">
					<input type="password" name="password_confirmation" placeholder="Confirm Password" v-model="password.password_confirmation" />
				</div>
				<div class="form-row tsa-note small text-left">
					<PasswordRequirements />
				</div>
				<div class="form-row">
					<button class='tsa-btn pull-right' @click.prevent="changePassword">Change</button>

				</div>
				<div class="form-row">
					<button class='tsa-btn tsa-danger pull-right' @click.prevent="deleteAccount">Delete</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import {mapState} from 'vuex';
	//import _ from 'lodash';
	import PasswordRequirements from '@/components/Layout/PasswordRequirements';
	import TelephoneFieldTwo from '@/components/Form/TelephoneFieldTwo.vue';
	export default {
		name: 'profile-form',
		components:{
			PasswordRequirements,
			TelephoneFieldTwo
		},
		props: {
		},
		data(){
			return {
				form_data: {
					first_name: "",
					last_name: "",
					email: "",
					phone: "",
					country_id: "",
					city: "",
					phone_country_code: "",
					agree_confirm: false,
				},
				errors: {},
				password: {
					password: "",
					password_confirmation: ""
				}
			}
		},
		mounted(){
			this.cloneUser();
			this.emitter.on("pf_confirm_delete_user", () => this.finalizeUserDeletion());
		},
		methods:{
			/**
			 * 'form-row' add 'has-error' class if field has error
			 * @param  {[type]} field [description]
			 * @return {[type]}       [description]
			 */
			formRowClass(field){
				let string = "";
				if(this.errors[field] != undefined ) string += " has-error";
				return string;
			},
			/**
			 * clone user into form data.
			 * @return {[type]} [description]
			 */
			cloneUser(){
				let data = {...this.user};
				if(data.phone == null) data.phone = "";
				this.form_data = data;
				if(this.user.agreement_date !== null && this.user.agreement_date !== ""){
					this.form_data.agree_confirm = true;
				}
			},
			updateProfile(){
				// clear all errors
				this.errors = {};
				this.emitter.emit('loader',true);
				// eslint-disable-next-line
				axios.post(`${process.env.VUE_APP_API_URL}users/profile`,this.form_data)
					.then(response => this.handlePostResponse(response.data))
					// eslint-disable-next-line
					.catch(error => this.handleErrors(error.response.data.errors));
			},
			handlePostResponse(response){
				this.emitter.emit('loader',false);
				this.$store.dispatch('user',{user: response});
				this.emitter.emit('alert',"Profile Updated");
			},
			handleErrors (errors){
				this.emitter.emit('loader',false);
				// clear all errors
				this.errors = {};
				for(var e in errors){
					this.errors[e] = errors[e][0];
				}
			},
			/**
			 * send api request to change password.
			 * @return {[type]} [description]
			 */
			changePassword(){
				this.emitter.emit('loader',true);
				// eslint-disable-next-line
				axios.post(`${process.env.VUE_APP_API_URL}users/password`,this.password)
					.then(response => this.handleChangePassword(response.data))
					// eslint-disable-next-line
					.catch(error => this.handleErrors(error.response.data.errors));
			},
			deleteAccount(){
				this.emitter.emit('confirm',{
					message: `Are you sure you want to delete your account?`,
					flag: `pf_confirm_delete_user`,
					data: this.user.id
				});
			},
			finalizeUserDeletion(){
				this.emitter.off("pf_confirm_delete_user");
				// eslint-disable-next-line
				axios.post(`${process.env.VUE_APP_API_URL}users/delete`, {
					token: "tqAavTrBjl"
				})
					.then(response => this.handlePostDeletion(response.data))
					// eslint-disable-next-line
					.catch(error => this.handleErrors(error.response.data.errors));
			},
			handlePostDeletion(response){
				this.emitter.emit('loader',false);
				this.$store.commit('setUserData',{user:{role:""}});
				this.emitter.emit('alert',response.message);
				this.$router.push({name:"home"});
			},
			handleChangePassword(response){
				this.emitter.emit('loader',false);
				if(response.error != undefined){
					this.emitter.emit('alert',{
						type: "warning",
						message: response.error
					});
				}else{
					this.emitter.emit('alert',response.message);
				}
			},
			countryChanged(data){
				if(data == null) return;
				if(typeof data === 'string'){
					this.form_data.phone_country_code = data;
				}else{
					// object
					this.form_data.phone_country_code = data.iso2;
				}
			},
			onPhoneChanged(phone){
				this.form_data.phone = phone;
			}
		},
		watch:{
			user(){
				this.cloneUser();
			}
		},
		computed:{
			allowPasswordChange(){
				return this.company.login_method != 'sso';
			},
			...mapState({
				user: state => state.user,
				countries: state => state.countries,
				company : state => state.company
			})
		}
	}
</script>