<template>
		<div class="tsa-search-box">
			<input type="text" :placeholder="placeholder" v-model="search_term">
			<button @click="filterResults" class='tsa-btn search'><i class="fas fa-search"></i></button>
			<div class="autocomplete-suggestions" v-show="search_term.length >= 2">
				<div v-for="(row,index) in results"
					class="autocomplete-suggestion"
					:key="index"
					@click="resultSelected(row)">
						<span v-html="highlightMatchedResult(row)" ></span>
				</div>
			<div class="tsa-no-results" v-show="noResults">No Results Found</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'search-partner-box',
		components:{

		},
		props: {
			placeholder: {
				type: String,
				default: "Search"
			},
			// if using a preloaded list.
			data: {
				type: Array,
				default: () => []
			},
			// if the component is using an API call to get results.
			endpoint: {
				type: String,
				default: ""
			},
			// field to search in results.
			search_field: {
				type: String,
				default: "name"
			}
		},
		data(){
			return {
				results: [],
				search_data: [],
				search_term: "",
				local_search: false
			}
		},
		mounted(){
			// if no search end point
			if(this.endpoint.length == 0){
				// use preloaded data.
				this.search_data = this.data;
				this.local_search = true;
			}
		},
		methods:{
			search(){
				if(this.local_search){
					this.filterResults();
				}else{
					// send request to api endpoint
					if( this.search_term .length <= 1 ) return;
					//eslint-disable-next-line
					axios.post(this.endpoint,{ term: this.search_term  })
						.then(response => this.handleSearchResponse(response.data))
						//eslint-disable-next-line
					    .catch(error => console.log(error));
				}
			},
			handleSearchResponse(response){
				this.results = response;
			},
			/**
        	 * filter results based on entered term
        	 * @return void
        	 */
			filterResults(){
				this.results = []; // clear prev results.
				if( this.search_term .length <= 1 ) return;
				var lowerTerm = this.search_term .toLowerCase();
				// loop through all search data.
				for(var i in this.search_data){
					var result = this.search_data[i];
					if(result[this.search_field].toLowerCase().indexOf(lowerTerm) != -1){
						this.results.push(result);
					}
				}
			},
			/**
			* hightlight the entered term in string (set strong tag)
			* @param  {string} value source string
			* @return {string} html string
			*/
			//eslint-disable-next-line
            highlightMatchedResult(row){
					let pattern = '(' + this.escapeRegExChars(this.search_term ) + ')',
					value = null;
					try {
						value = eval(`row.${this.search_field}`);
					}catch(err){
						value = null;
					}
					
					if(value == null) return "";

					return value
					// eslint-disable-next-line
					.replace(new RegExp(pattern, 'gi'), '<strong>$1<\/strong>')
					.replace(/&/g, '&amp;')
					.replace(/</g, '&lt;')
					.replace(/>/g, '&gt;')
					.replace(/"/g, '&quot;')
					.replace(/&lt;(\/?strong)&gt;/g, '<$1>');
			},
			/**
			* function name says it all
			* @param  {string} value [description]
			* @return escaped string
			*/
			escapeRegExChars: function (value) {
				return value.replace(/[|\\{}()[\]^$+*?.]/g, "\\$&");
			},
			resultSelected(row){
				this.results = [];
				this.search_term  = "";
				this.$emit('selected',row);
			},
		},
		watch:{
			endpoint(){
				if(this.endpoint.length > 0 ) this.local_search = false;
			},
			search_term(){
				this.search();
			}
        },
		computed:{
			noResults(){
				return this.results.length == 0 && this.search_term .length >= 2;
			}
		}
	}
</script>