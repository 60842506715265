<template>
	<div class="onboarding-session-form">
		<div class="page-section">
			<div class="tsa-section-title">Sales Force Opportunity</div>
			<div class="form-row">
				<input type="text" v-model="form_data.sf_opportunity" placeholder="Enter Sales Force Opportunity URL" />
			</div>
		</div>
		<!-- contact information -->
		<div class="page-section">
			<div class="tsa-section-title">Contact Information</div>
			<div class="uk-child-width-1-2@m uk-grid-medium" uk-grid>
				<!-- Company Information -->
				<div>
					<div class="form-section">Company Information</div>
					<div :class="formRowClass('company_name')">
						<input type="text" name="company.name" placeholder="Enter Company Name" v-model="form_data.company.name" />
						<span class="error-block">{{ errors.company_name }}</span>
					</div>
					<div :class="formRowClass('company_address')">
						<input type="text" name="company.address" placeholder="Enter Address" v-model="form_data.company.address" />
						<span class="error-block">{{ errors.company_address }}</span>
					</div>
					<div :class="formRowClass('company_city')">
						<input type="text" name="company.city" placeholder="Enter City" v-model="form_data.company.city" />
						<span class="error-block">{{ errors.company_city }}</span>
					</div>
					<div :class="formRowClass('company_state')">
						<input type="text" name="company.state" placeholder="Enter State" v-model="form_data.company.state" />
						<span class="error-block">{{ errors.company_state }}</span>
					</div>
					<div :class="formRowClass('company_zipcode')">
						<input type="text" name="company.zipcode" placeholder="Enter Postal Code" v-model="form_data.company.zipcode" />
						<span class="error-block">{{ errors.company_zipcode }}</span>
					</div>
					<div :class="formRowClass('company_url')">
						<input type="text" name="company.url" placeholder="Enter Company Website" v-model="form_data.company.url" />
						<span class="error-block">{{ errors.company_url }}</span>
					</div>
					<!-- country -->
					<div :class="formRowClass('company_country_id')">
						<select v-model="form_data.company.country_id">
							<option value="">Choose Country</option>
							<option
								v-for="(name,country_id) in countries"
								:key="country_id"
								:value="country_id">{{ name }}</option>
						</select>
						<span class="error-block">{{ errors.company_country_id }}</span>
					</div>
				</div>
				<!-- User Information -->
				<div>
					<div class="form-section">User Information</div>
					<div :class="formRowClass('user_first_name')">
						<input type="text" name="first_name" placeholder="Enter First Name" v-model="form_data.user.first_name" />
						<span class="error-block">{{ errors.user_first_name }}</span>
					</div>
					<div :class="formRowClass('user_last_name')">
						<input type="text" name="last_name" placeholder="Enter Last Name" v-model="form_data.user.last_name" />
						<span class="error-block">{{ errors.user_last_name }}</span>
					</div>
					<div :class="formRowClass('user_email')">
						<input type="email" name="email" placeholder="Enter Email Address" v-model="form_data.user.email" />
						<span class="error-block">{{ errors.user_email }}</span>
					</div>
					<div :class="formRowClass('user_phone')">
						<TelephoneField
							:phone="form_data.user.phone"
							:defaultCountry="form_data.user.phone_country_code"
							@country-changed="countryChanged"
							@phone-changed="onPhoneChanged"
							placeholder="Enter Phone" />
						<span class="error-block">{{ errors.user_phone }}</span>
					</div>
				</div>
			</div>
		</div>
		<div class="page-section">
			<div class="tsa-section-title">Process</div>
			<div class="uk-child-width-1-2@m uk-grid-medium" uk-grid>
				<div>
					<div :class="formRowClass('document_support_hours')" class="with-label">
						<label>Support Hours:</label>
						<input type="text" placeholder="Enter Support Hours" v-model="form_data.document.support_hours" />
						<span class="error-block">{{ errors.document_support_hours }}</span>
					</div>
					<div :class="formRowClass('document_product_supported')" class="with-label">
						<label>Products Supported:</label>
						<input type="text" placeholder="Enter Products Supported" v-model="form_data.document.product_supported" />
						<span class="error-block">{{ errors.document_product_supported }}</span>
					</div>
					<div :class="formRowClass('document_support_email')" class="with-label">
						<label>Support Email</label>
						<input type="email" placeholder="Enter Support Email Alias" v-model="form_data.document.support_email" />
						<span class="error-block">{{ errors.document_support_email }}</span>
					</div>
					<div :class="formRowClass('document_manager_first_name')" class="with-label">
						<label>Support Manager First Name</label>
						<input type="text" placeholder="Enter Support Manager First Name" v-model="form_data.document.manager_first_name" />
						<span class="error-block">{{ errors.document_manager_first_name }}</span>
					</div>
					<div :class="formRowClass('document_manager_last_name')" class="with-label">
						<label>Support Manager Last Name</label>
						<input type="text" placeholder="Enter Support Manager Last Name" v-model="form_data.document.manager_last_name" />
						<span class="error-block">{{ errors.document_manager_last_name }}</span>
					</div>
					<div :class="formRowClass('document_manager_email')" class="with-label">
						<label>Support Email</label>
						<input type="email" placeholder="Enter Support Manager Email" v-model="form_data.document.manager_email" />
						<span class="error-block">{{ errors.document_manager_email }}</span>
					</div>
				</div>
				<div>
					<div :class="formRowClass('document_escalation_instructions')" class="with-label">
						<label>Escalation Instructions</label>
						<div class="form-row wysiwyg" :class="formRowClass('escalation_instructions')">
							<wysiwyg-editor v-model="form_data.document.escalation_instructions"></wysiwyg-editor>
							<span class="error-block">{{ errors.document_escalation_instructions }}</span>
						</div>
					</div>
					<div :class="formRowClass('document_login_page_text')" class="with-label">
						<label>Login Page Text</label>
						<div class="form-row wysiwyg" :class="formRowClass('document_login_page_text')">
							<wysiwyg-editor v-model="form_data.document.login_page_text"></wysiwyg-editor>
							<span class="error-block">{{ errors.document_login_page_text }}</span>
						</div>
					</div>
					<div class="form-row with-label save-form">
						<label>&nbsp;</label>
						<div>
							<button class="tsa-btn tsa-plain" @click.prevent="saveSession">Save</button>
							<button class="tsa-btn tsa-success" @click.prevent="approveSession">Approve</button>
							<button class="tsa-btn tsa-danger" style="padding: 10px 22px;" @click.prevent="rejectSession">Reject</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// import any required components
import _ from 'lodash';
import WysiwygEditor from "@/components/Form/WysiwygEditor.vue";
import TelephoneField from '@/components/Form/TelephoneField.vue';

// export component
export default {
	// properties that the component will receive from parent
	props: {
		session: {
			type: Object,
				default: () => {}
		},
		countries: {
			type: Object,
				default: () => {}
		},
	},
	// components to use in template
	components:{
		WysiwygEditor,
		TelephoneField
	},
	// component data
	data() {
		return {
			form_data: {
				sf_opportunity: "",
				company: {},
				user: {},
				document: {},
				agreements: {}
			},
			errors: {},
		};
	},
	// component was mounted
	mounted(){

	},
	// any methods needed for this component
	methods: {
		/**
		 * 'form-row' add 'has-error' class if field has error
		 * @param  {[type]} field [description]
		 * @return {[type]}       [description]
		 */
		formRowClass(field){
			let string = "form-row";
			if(this.errors[field] != undefined ) string += " has-error";
			return string;
		},
		/**
		 * handle validation errors.
		 * @param  {[type]} errors [description]
		 * @return {[type]}        [description]
		 */
		handleErrors (errors){
			// hide loader
			this.emitter.emit('loader',false);
			// clear all errors
			this.errors = {};
			for(var e in errors){
				const field = e.replace('.','_');
				let string = errors[e][0];
				this.errors[field] = string.replace('user.','user_').replace('company.','company_').replace('document.','document_');
			}
		},
		cloneSession(){
			let data = _.cloneDeep(this.session.data);
			data.sf_opportunity = this.session.sf_opportunity;
			this.form_data = data;
		},
		/**
		 * user is saving the session without approval or rejection.
		 * @return {[type]} [description]
		 */
		saveSession(){
			this.emitter.emit('loader',true);
			//eslint-disable-next-line
			axios.post(`${process.env.VUE_APP_API_URL}super_admin/onboarding/${this.session.id}/put`,this.form_data)
				.then(response => this.handleSave(response.data))
				//eslint-disable-next-line
			    .catch(error => this.handleErrors(error.response.data.errors));
		},
		handleSave(response){
			this.emitter.emit('loader',false);
			if(response.success){
				this.emitter.emit('alert',"Onboarding session updated.");
				this.$router.push({name: 'super_admin.dashboard'});
			}
		},
		/**
		 * send approval request for session.
		 * @return {[type]} [description]
		 */
		approveSession(){
			this.emitter.emit('loader',true);
			//eslint-disable-next-line
			axios.post(`${process.env.VUE_APP_API_URL}super_admin/onboarding/${this.session.id}/approve`,this.form_data)
				.then(response => this.handleApprove(response.data))
				//eslint-disable-next-line
			    .catch(error => this.handleErrors(error.response.data.errors));
		},
		/**
		 * let user know it was approved and redirect to dashboard.
		 * @param  {[type]} response [description]
		 * @return {[type]}          [description]
		 */
		handleApprove(response){
			this.emitter.emit('loader',false);
			if(response.success){
				this.emitter.emit('alert',"Member has been approved and is now part of TSANet connect.");
				this.$router.push({name: 'super_admin.dashboard'});
			}
		},
		/**
		 * send reject request for session.
		 * @return {[type]} [description]
		 */
		rejectSession(){
			this.emitter.emit('loader',true);
			//eslint-disable-next-line
			axios.post(`${process.env.VUE_APP_API_URL}super_admin/onboarding/${this.session.id}/reject`)
				.then(response => this.handleReject(response.data))
				//eslint-disable-next-line
			    .catch(error => console.log(error));
		},
		handleReject(response){
			this.emitter.emit('loader',false);
			if(response.success){
				this.emitter.emit('alert',{
					type: "success",
					message: response.message
				});
				this.$router.push({name: "super_admin.dashboard"});
			}
		},
		countryChanged(data){
			if(data == null) return;
			if(typeof data === 'string'){
				this.form_data.user.phone_country_code = data;
			}else{
				// object
				this.form_data.user.phone_country_code = data.iso2;
			}
		},
		onPhoneChanged(number){
			this.form_data.user.phone = number;
		}
	},
	// computed properties
	computed:{
	},
	// property watchers
	watch:{
		session(){
			this.cloneSession();
		}
	}
}
</script>