<template>
<div></div>
</template>

<script>

import GlobalStorage from "../Classes/GlobalStorage";
import {mapState} from "vuex";

export default {
	name: "RegistrationSaver",

	props: {},
	components: {},
	data() {
		return {}
	},
	mounted() {
		
	},
	methods: {
		autoSaveRestoreRegistrationData(){
			if(this.partner_group.id){
				this.saveRegistrationData();
			} else {
				this.loadRegistrationData();
			}
		},
		saveRegistrationData(){
			GlobalStorage.save("tsa_onboarding_partner_group", this.partner_group);
			GlobalStorage.save("tsa_onboarding_company", this.company);
			GlobalStorage.save("tsa_onboarding_countries", this.countries);
		},
		loadRegistrationData(){
			let testPartner = GlobalStorage.load("tsa_onboarding_partner_group");
			let testCompany = GlobalStorage.load("tsa_onboarding_company");
			let testCountries = GlobalStorage.load("tsa_onboarding_countries");
			if(testPartner && testCompany && testPartner.id && testCompany.id){
				this.$store.dispatch('partnerGroup',{
					company: testCompany,
					group: testPartner,
					countries: testCountries
				});
				
			}
			this.restoreRegistrationSession();
		},
		clearRegistrationData(){
			GlobalStorage.save("tsa_onboarding_partner_group", null);
			GlobalStorage.save("tsa_onboarding_company", null);
			GlobalStorage.save("tsa_onboarding_countries", null);
			GlobalStorage.save("tsa_onboarding_session", null);
		},
		saveRegistrationSession(sessionData){
			GlobalStorage.save("tsa_onboarding_session", sessionData);
		},
		restoreRegistrationSession(){
			if(GlobalStorage.has("tsa_onboarding_session_token")) {
				//better to try to get it from the server
				this.restoreOnboardingSession(GlobalStorage.load("tsa_onboarding_session_token"));
			}
			else if(GlobalStorage.has("tsa_onboarding_session")){
				let sessionData = GlobalStorage.load("tsa_onboarding_session");
				this.$store.dispatch('onboardingSession',sessionData);
			}
		},
		/**
		 * user was previously trying to onboard.
		 */
		restoreOnboardingSession(token){
			//eslint-disable-next-line
			axios.get(`${process.env.VUE_APP_API_URL}onboarding/restore/${token}`)
				.then(response => this.handleRestoreSession(response.data))
				//eslint-disable-next-line
				.catch(error => console.log(error));
		},
		/**
		 * save data in store and reload landing data for
		 * @param  {[type]} response [description]
		 * @return {[type]}          [description]
		 */
		handleRestoreSession(response){
			this.$store.dispatch('restoreOnboarding',response);
			const current = this.$route.name;
			const route = `onboarding.${response.session.step}`;
			this.saveRegistrationData();
			if(current != route){
				// redirect to correct step.
				this.$router.push({name: route});
			}
		}
	},
	computed:{
		...mapState({
			partner_group: state => state.partner_group,
			company: state => state.company,
			countries: state => state.countries,
			session_token: state => state.onboarding_session_token
		})
	}
}
</script>