<template>
	<td :class="cellCssClass">
		<span v-if="!hasRoute && !hasComponent" v-html="cellValue" />
		<router-link v-if="hasRoute" :to="dynamicRoute">{{ cellValue }}</router-link>
		<component
			:is="header.component"
			:cell_data='cell_data'
			v-if="hasComponent" />
	</td>
</template>

<script>
	export default {
		name: 'data-table-cell',
		components: {

		},
		props: {
			header: {
				type: Object,
				default: () => {}
			},
			cell_data: {
				type: Object,
				default: () => {}
			},
			field: {
				type: String,
				default: () => ""
			}
		},
		data(){
			return {
				tdComp: null
			}
		},
		methods: {
		},
		computed: {
			/**
			* return cell value
			* format if set in header settings
			* @param  {mixed} value  value for cell
			* @param  {obj} header header settings
			* @return {mixed} formatted value
			*/
			cellValue(){
				// check for render function
				if(this.header.render != undefined) return this.header.render(this.cell_data);

				// try to get value from field name
				let value = "";
				try{
					value = eval(`this.cell_data.${this.field}`);
				//eslint-disable-next-line
				}catch(e){
				}
				if(this.header.format == undefined){
					return value;
				}
				if(value == "") return "N/A";
				// format value if need.
				switch(this.header.format){
					case "date_time":
						if(value == null) return "";
						value = this.$moment(value)/*.utc(value).local()*/.format("MMM-DD-YYYY hh:mm a");
					break;
					case "date":
						if(value == null) return "";
						value = this.$moment(value)/*.utc(value).local()*/.format("MMM-DD-YYYY");
					break;
					case "local_date_time":
						if(value == null) return "";
						value = this.$moment(value).utc(value).local().format("MMM-DD-YYYY hh:mm a");
					break;
					case "local_date":
						if(value == null) return "";
						value = this.$moment(value).utc(value).local().format("MMM-DD-YYYY");
					break;
					case "link":
						value = `<a href="${value}" target="${this.header.format_target}">${value}</a>`;
					break
				}
				return value;
			},
			/**
			 * match cell data to create a url.
			 * @return {string} url
			 */
			dynamicRoute(){
				let route = this.header.route;
				let matches = route.match(/\{(.*?)\}/gs);
				if(matches){
					//eslint-disable-next-line
					let self = this;
					matches.forEach(match => {
						let field = match.replace(/[{}]/g,"");
						try{
							let value = eval(`self.cell_data.${field}`);
							route = route.replace(`{${field}}`,value);
						}catch(e){
							//route = "";
						}
					});
				}
				return route;
			},
			/**
			 * check wether the cell should use a custom component
			 * @return {Boolean} [description]
			 */
			hasComponent(){
				return this.header.component != undefined;
			},
			hasRoute(){
				return this.header.route != undefined;
			},
			cellCssClass(){
				return (this.header.css_class != undefined)? this.header.css_class : "";
			}
		}
	}
</script>