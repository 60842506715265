<template>
	<div :class="containerClass">
		<TelephoneField
			:id="`requirement_phone-${requirement.id}`"
			:disabled="disabled"
			ref="phonefield"
			@country-changed="countryChanged"
			@phone-changed="onPhoneChanged"
			@is-valid="emitChange"
			:formatted="true"
			:placeholder="placeholder" />
		<span class="error-block"><strong>{{ error }}</strong></span>
	</div>
</template>

<script>
	import {mapState} from 'vuex';
	import TelephoneField from '@/components/Form/TelephoneField.vue';
	export default {
		name: "TSANetPhone",
		components: {
			TelephoneField
		},
		props: {
			requirement: {
				type: Object,
				default: () => {}
			},
			error: {
				type: String,
				default: ""
			},
            disabled: {
				type: Boolean,
				default: false
            },
			value: {
				type: String,
				default: ""
			},
		},
		data(){
			return {
				phone_number: "",
				country_code: ""
			}
		},
		mounted(){
			if(this.value != ""){
				this.$refs.phonefield.v_phone = this.value;
				this.onPhoneChanged(this.value);
			}
		},
		computed: {
			placeholder(){
				let ph = `Enter ${this.requirement.requirement.label}`;
				if(!this.requirement.required) ph += " (Optional)";
				return ph;
			},
			containerClass(){
                let string = `form-group-${this.requirement.group} form-row tsa-custom-phone`;
                if(this.error.length > 0) string += ' has-error';
                if(this.requirement.group != null){
                    if(this.document_active_groups[this.requirement.group] === false){
                        string += " hide";
                    }
                }
                return string;
            },
            ...mapState({
                document_active_groups: state => state.document_active_groups
            })
		},
		methods: {
			countryChanged(data){
				if(data == null) return;
				if(typeof data === 'string'){
					this.country_code = data;
				}else{
					// object
					this.country_code = data.iso2;
				}
			},
			onPhoneChanged(number){
				this.phone_number = number;
			},
			/**
			 * send value to parent component
			 * if invalid phone number send empty string
			 * @param  {[type]} valid_number [description]
			 * @return {[type]}              [description]
			 */
			emitChange(valid_number){
				let value = (valid_number)? `${this.country_code}: ${this.phone_number}` : "";
				this.$emit('change',{
					requirement_id : this.requirement.requirement_id,
					data: value
				});
			},
        }
    }
</script>