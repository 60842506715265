<template>
    <div class="tsa-loader" v-show="show">
        <div class="loader">
        <i class="fas fa-sync fa-spin fa-3x fa-fw"></i>
    </div>
</div>
</template>

<script>
// import any required components

// export component
export default {
    name: "loader",
    // properties that the component will receive from parent
    props: {
        show:{
            type:Boolean,
            default:false
        }
    },
    // components to use in template
    components:{

    },
    // component data
    data() {
        return {};
    },
    // component was mounted
    mounted(){

    },
    // any methods needed for this component
    methods: {
    },
    // computed properties
    computed:{
    },
    // property watchers
    watch:{
    }
}
</script>