<template>
	<div class="partner-group-details">
		<div class="uk-child-width-1-2@m uk-grid-small" uk-grid>
			<div>
				<div class="tsa-data">
					<!-- name -->
					<div class="tsa-data-label">Group Name:</div>
					<div class="tsa-data-value">{{ group.name }}</div>
				</div>
				<div class="tsa-data">
					<!-- type -->
					<div class="tsa-data-label">Group Type:</div>
					<div class="tsa-data-value">{{ groupType }}</div>
				</div>
				<div class="tsa-data" v-if="isPartnerGroup">
					<!-- registration link -->
					<div class="tsa-data-label">Partner Registration:</div>
					<div class="tsa-data-value">
						<a class="tsa-btn tsa-plain pull-right" target="_blank"
							:href="registrationStatusUrl"
							v-if="registrationStatusUrl">Registration Status</a>
						<a :href="registerUrl" target="_blank" >{{ registerUrl }}</a>
					</div>
				</div>
				<!-- code of conduct -->
				<div class="tsa-data" v-if="codeOfConduct.length > 0">
					<div class="tsa-data-label">Code of Conduct:</div>
					<div class="tsa-data-value">
						<ExportButton
							:title="codeOfConduct"
							icon_class="fas fa-file-download"
							:export_route="codeOfConductDownloadUrl" />
					</div>
				</div>
				<!-- addendum -->
				<div class="tsa-data" v-if="currentLicense.length > 0">
					<div class="tsa-data-label">Current License:</div>
					<div class="tsa-data-value">
						<ExportButton
							:title="currentLicense"
							icon_class="fas fa-file-download"
							:export_route="currentLicenseDownloadUrl" />
					</div>
				</div>
				<!-- addendum -->
				<div class="tsa-data" v-if="addendum.length > 0">
					<div class="tsa-data-label">Partner Addendum:</div>
					<div class="tsa-data-value">
						<ExportButton
							:title="addendum"
							icon_class="fas fa-file-download"
							:export_route="addendumDownloadUrl" />
					</div>
				</div>
			</div>
			<div>
				<!-- inbound process -->
				<div class="tsa-data">
					<div class="tsa-data-label">Inbound Process:</div>
					<div class="tsa-data-value">
						<a href="#" @click.prevent="viewProcess">{{ processDocumentName }}</a>
					</div>
				</div>
				<div class="tsa-data">
					<!-- user access -->
					<div class="tsa-data-label">Internal User Access:</div>
					<div class="tsa-data-value">
						{{ internalUserAccess }}
						<router-link v-if="accessFlag != 'all'" :to="{name:'admin.partner_groups.users',group_id:group.id}" class="tsa-btn tsa-plain pull-right">Manage Access</router-link>
					</div>
				</div>
				<div class="tsa-data">
					<div class="tsa-data-label">SLA Details:</div>
					<div class="tsa-data-value" v-html="groupSLADetails"></div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import ExportButton from '@/components/SuperAdmin/ExportButton.vue';
	export default {
		name: "admin-partner-group-details",
		components: {
			ExportButton
		},
		props: {
			group: {
				type: Object,
				default: () => {}
			}
		},
		methods: {
			/**
			 * navigate to inbound process for this group.
			 * @return {[type]} [description]
			 */
			viewProcess(){
				this.$router.push({name:'admin.inbound_process.manage',params:{document_id: this.group.my_relation.document.id }});
			}
		},
		computed:{
			accessFlag(){
				if(this.group.my_relation == undefined) return "";
				return this.group.my_relation.user_access_flag;
			},
			groupType(){
				if (this.group.type == undefined) return "";
				return this.group.type.unSlug();
			},
			registerDomain(){
				if(this.group.vhost == undefined) return "";
				return `http://${this.group.vhost}.${process.env.VUE_APP_DOMAIN}/`;
			},
			addendum(){
				if(this.group.addendum == undefined) return "";
				return this.group.addendum;
			},
			codeOfConduct(){
				if(this.group.code_of_conduct == undefined) return "";
				return this.group.code_of_conduct;
			},
			currentLicense(){
				if(this.group.current_license == undefined) return "";
				return this.group.current_license;
			},
			addendumDownloadUrl(){
				return `${process.env.VUE_APP_API_URL}admin/company/partner_groups/${this.group.id}/addendum`;
			},
			currentLicenseDownloadUrl(){
				return `${process.env.VUE_APP_API_URL}admin/company/partner_groups/${this.group.id}/current_license`;
			},
			codeOfConductDownloadUrl(){
				return `${process.env.VUE_APP_API_URL}admin/company/partner_groups/${this.group.id}/code_of_conduct`;
			},
			internalUserAccess(){
				return this.accessFlag.ucfirst();
			},
			documentName(){
				if(this.group.document == undefined) return "";
				return this.group.document.name;
			},
			processDocumentName(){
				if(this.group.my_relation == null) return "";
				return this.group.my_relation.document.name;
			},
			groupSLADetails(){
				if(!this.group.custom_sla){
					return "Using default SLA details";
				}else{
					return `<strong>High - P1:</strong> ${this.group.p1_message}<br>
							<strong>Medium - P2:</strong> ${this.group.p2_message}<br>
							<strong>Low - P3:</strong> ${this.group.p3_message}`;
				}
			},
			isPartnerGroup(){
				return this.group.partner_group != null;
			},
			registrationStatusUrl(){
				if(this.group.partner_group == null) return "";
				return this.group.partner_group.registration_status_url;
			},
			registerUrl(){
				if(this.group.partner_group == undefined || this.group.partner_group == null) return "";
				return `https://${this.group.partner_group.vhost}.${process.env.VUE_APP_DOMAIN}`;
			},
		}
	}
</script>