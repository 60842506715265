<template>
	<div class="tsa-contact-document tsa-default">

		<div class="uk-child-width-1-2@m uk-grid-small tsa-document-form" uk-grid="masonry: true">
			<div>
				<div class="form-section">{{ submittingCompany.name }} Contact <i class="fas fa-user"></i>
					<!-- update profile when I submit case. -->
					&nbsp; <span class="tsa-note">Changes will update your profile.</span>
				</div>
				<div >
					<input type="text" name="first_name" placeholder="Enter Your First Name" disabled="disabled" />
					<span class="error-block">{{ errors.first_name }}</span>
				</div>
				<div >
					<input type="text" name="last_name" placeholder="Enter Your Last Name" disabled="disabled" />
					<span class="error-block">{{ errors.last_name }}</span>
				</div>
				<div >
					<input type="email" name="email" placeholder="Enter Your Email" disabled="disabled" />
					<span class="error-block">{{ errors.email }}</span>
				</div>
				<div >
					<input type="text" name="phone_number" placeholder="Enter Your Phone" disabled="disabled" />
					<span class="error-block">{{ errors.phone }}</span>
				</div>
				<div >
					<input type="text" name="internal_reference" placeholder="Internal Reference (Case / Project / Customer)" disabled="disabled" />
					<span class="error-block">{{ errors.internal_reference }}</span>
				</div>
			</div>
			<div class="uk-padding-small" style="padding-top: 0;">
				<div class="form-section">Additional Information</div>
				<div >
					<textarea placeholder="Business Impact" rows="6" disabled="disabled"></textarea>
					<span class="error-block">{{ errors.business_impact }}</span>
				</div>
				<div >
					<textarea placeholder="Note to Manager" rows="6" disabled="disabled"></textarea>
					<span class="error-block">{{ errors.notes }}</span>
				</div>
				<div >
					<input type="text" name="vendor_reference" :placeholder="'Existing case# with ' + company.name" disabled="disabled" />
					<span class="error-block">{{ errors.vendor_reference }}</span>
				</div>
				<button @click.prevent="submitRequest" class="tsa-btn">Submit</button>
			</div>
		</div>
	</div>
</template>

<script>
import {mapState} from "vuex";

export default {
	name: "ManagerRequest",
	components:{

	},
	data() {
		return {
			company:{ //recipient company
				id: null,
				name: ""
			},
			form_data:{
				first_name: "",
				last_name: "",
				email: "",
				phone: "",
				phone_country_code: "",
				only_numbers: "",
				internal_reference: "",
				vendor_reference: "",
				business_impact: "",
				escalation_type: "",
				update_profile: true,
				notes:""
			},
			errors: {
			},
		}
	},
	mounted() {
		this.copyUserData();
		this.getCompany();
	},
	methods: {
		/**
		 * 'form-row' add 'has-error' class if field has error
		 * @param  {[type]} field [description]
		 * @return {[type]}       [description]
		 */
		formRowClass(field){
			let string = "form-row";
			if(this.errors[field] != undefined ) string += " has-error";
			return string;
		},
		copyUserData(){
			this.form_data.first_name = this.user.first_name;
			this.form_data.last_name = this.user.last_name;
			this.form_data.email = this.user.email;
			const phoneNumber = (this.user.phone == null)? "" : this.user.phone.replace(/\D/g,'');
			this.form_data.phone = phoneNumber;
			this.form_data.phone_country_code = this.user.phone_country_code;
			this.form_data.company = this.company.full_name;
		},
		getCompany(){
			//eslint-disable-next-line
			axios.get(`${process.env.VUE_APP_API_URL}caller/companies/${this.document.company_id}`).then(
				(res) => this.updateCompany(res),
				(res) => this.failCompany(res)
			);
		},
		updateCompany(res){
			this.company = res.data;
		},
		failCompany(res){
			console.log(res);
		},
		/**
		 * handle validation errors.
		 * @param  {[type]} errors [description]
		 * @return {[type]}        [description]
		 */
		handleErrors (error){
			let errors = [];
			if (error.response && error.response.data && error.response.data.errors){
				errors = error.response.data.errors;
			}
			// hide loader
			this.emitter.emit('loader',false);
			// clear all errors
			this.errors = {};
			for(var e in errors){
				this.errors[e] = errors[e][0];
			}
		},
	},
	computed:{
		...mapState({
			document: state => state.contact_document,
			user: state => state.user,
			submittingCompany: state => state.company
		})
	},
	watch:{
		user(){
			this.copyUserData();
		},
	}
}
</script>