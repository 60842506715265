<template>
	<div class="case-info">
		<Breadcrumb :routes="breadcrumb" />
		<div class="uk-child-width-1-2@m uk-grid-medium" uk-grid>
			<div>
				<CaseDetails
				:case_entry="case_entry" />
			</div>
			<!--div>
				<CaseResponse
					:case_entry="case_entry" />
			</div-->
		</div>
	</div>
</template>

<script>

	import CaseDetails from '@/components/CaseDetails/CaseDetails.vue';
	//import CaseResponse from '@/components/CaseDetails/CaseResponse.vue';
	import Breadcrumb from '@/components/Layout/Breadcrumb.vue';
	import {mapState} from 'vuex';
	export default {
		name: "case-info",
		components: {
			CaseDetails,
			//CaseResponse,
			Breadcrumb,
		},
		data(){
			return {
				case_entry: {
					company: {},
					receiver: {},
					engineer: {},
					response: {},
					customer_data: []
				},
				has_initialized: false
			}
		},
		mounted(){
		//this.loadDashboardData();
			this.initialize();
		},
		updated(){
			if(!this.has_initialized) this.initialize();
		},
		/**
		* remove bus event listeners before component is destroyed.
		* @return {[type]} [description]
		*/
		beforeUnmount(){
			this.emitter.off('token_ready');
		},
		methods: {
			initialize(){
				this.has_initialized = true;
				this.emitter.on('token_ready', () => this.loadCaseInformation());
				if(this.jwt_token.access_token != undefined) this.loadCaseInformation();
			},
			/**
			 * load case information using token
			 * @return {[type]} [description]
			 */
			loadCaseInformation(){
				this.emitter.emit('loader',true);
				const case_id = this.$route.params.case_id;
				//eslint-disable-next-line
				axios.get(`${process.env.VUE_APP_API_URL}super_admin/escalation/${case_id}/details`)
				.then(response => this.handleLoadCaseInfo(response.data))
				//eslint-disable-next-line
				.catch(error => console.log(error));
			},
			/**
			 * save case information to store so children component can display information.
			 * @param  {[type]} response [description]
			 * @return {[type]}          [description]
			 */
			handleLoadCaseInfo(response){
				this.emitter.emit('loader',false);
				// no error?
				if(response.error == undefined){
					this.case_entry = response.case;
				}else{
					// show error to user
					this.emitter.emit('alert',{message: response.error, type: "warning"});
				}
			}
		},
		computed: {
			breadcrumb(){
				return [
					{display: "Dashboard" ,route: {name: "super_admin.dashboard"}},
					`#${this.case_entry.internal_case_number}`
				];
			},
			...mapState({
				jwt_token: state => state.token
			})
		}
	}
</script>