<template>
	<div class="tsa-breadcrumbs" :class="wrapper_class">
        <span class="route"
            v-for="(route,index) in routes"
            :key="index">
            <a href="#" @click.prevent="navigate(route.route)" v-if="typeof route != 'string'">{{ routeDisplay(route) }}</a>
            <span class="current" v-if="typeof route == 'string'" v-html="route" />
            <span class="separator">&nbsp;<i class="fa fa-caret-right"></i>&nbsp;</span>
        </span>
		<slot></slot>
    </div>
</template>

<script>
    // import any required components

    // export component
    export default {
        // properties that the component will receive from parent
        props: {
            routes: {
                type: Array,
                default: () => []
            },
            wrapper_class:{
                type: String,
                default: ""
            }
        },
        // components to use in template
        components:{

        },
        // component data
        data() {
            return {};
        },
        // component was mounted
        mounted(){
            
        },
        // any methods needed for this component
        methods: {
            navigate(route){
                this.$router.push(route);
            },
            routeDisplay(route){
                return (route)? route.display : "";
            }
        },
        // computed properties
        computed:{
/*        	routesHtml(){
        		let breadcrumbs = '';
        		// if only one route in 
				if(this.routes.length === 1){
					breadcrumbs += this.routes[0].name;
				}else{
					// build breadcrumb path
					for(let i in this.routes){
						let route = this.routes[i];
						if(route.route == null){
							breadcrumbs += `<br><span>${route.name}</span>`;
						}else{
							breadcrumbs += `<a href="${route.route}">${route.name}</a>`;
						}
						if(i < this.routes.length - 1) breadcrumbs += `&nbsp;<i class="fa fa-caret-right"></i>&nbsp;`;
					}
					return breadcrumbs;
				}
        	}*/
        },
        // property watchers
        watch:{
        }
    }
</script>