<template>
	<div class="tsa-admin tsa-companies tsa-box low-padding">
		<div class="tsa-section-title">
			<button class='tsa-btn pull-right' @click="addNew"><i class="fas fa-plus"></i> Add New</button>
			Companies <ExportButton :export_route="exportRoute" file_name="companies.xlsx" />
		</div>
		<DataTable
			:table_data="companies"
			:table_headers="table_headers"
			search_placeholder="Find a Company" />
	</div>
</template>

<script>

	import DataTable from '@/components/Table/DataTable.vue';
	import ExportButton from '@/components/SuperAdmin/ExportButton.vue';
	import {mapState} from 'vuex';

	export default {
		name: "admin-companies",
		components: {
			DataTable,
			ExportButton
		},
		data(){
			return {
				companies: [],
				table_headers: {
					name:{
						route: "/super_admin/companies/{id}"
					},
					groups:{
						searchable: false,
						style: "width: 30%;"
					},
					membership_type: {
						display: "Membership",
						searchable: false
					},
					users: {
						searchable: false
					},
					status: {
						render: cell_data => {
							return cell_data.status.ucfirst();
						}
					},
					login: {
						searchable: false,
						display: "Login Page",
						format : 'link',
						format_target : 'blank'
					}
				},
				is_initialized: false
			}
		},
		mounted(){
			this.initialized();
		},
		updated(){
			this.initialized();
		},
		/**
		* remove bus event listeners before component is destroyed.
		* @return {[type]} [description]
		*/
		beforeUnmount(){
			this.emitter.off('token_ready');
		},
		methods: {
			initialized(){
				if(this.is_initialized) return;
				this.is_initialized = true;
				this.emitter.on('token_ready', () => this.getCompanies());
				if(this.jwt_token.access_token != undefined) this.getCompanies();
			},
			getCompanies(){
				//eslint-disable-next-line
				axios.get(`${process.env.VUE_APP_API_URL}super_admin/companies`)
				.then(response => this.handleGetCompanies(response.data))
				//eslint-disable-next-line
				.catch(error => console.log(error));
			},
			handleGetCompanies(response){
				this.companies = response;
			},
			addNew(){
				this.$router.push({name:"super_admin.company.edit",params:{company_id:0}});
			}
		},
		computed: {
			exportRoute(){
				return `${process.env.VUE_APP_API_URL}super_admin/companies/export`;
			},
			...mapState({
				jwt_token: state => state.token
			})
		}
	}
</script>