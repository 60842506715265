<template>
	<div class="manage-document">
		<Breadcrumb :routes="breadcrumb" />
		<div class="tsa-admin-header">
			{{company.name}} <span class="page-name">Process Details</span>
			<button class="tsa-btn" @click="saveDocument">Save</button>
			&nbsp;
			<router-link v-if="documentType != 'parent'" class="tsa-btn" :to="testFormRoute"> View/Test Form</router-link>
		</div>
		<div uk-grid class="uk-child-width-1-2@m uk-grid-small">
			<div>
				<div :class="formRowClass('status')" class="with-label">
					<label>Status:</label>
					<select v-model="form_data.status">
						<option value='approved'>Approved</option>
						<option value='pending'>Pending</option>
						<option value='rejected'>Rejected</option>
					</select>
					<span class="error-block">{{ errors.status }}</span>
				</div>
				<div :class="formRowClass('title')" class="with-label">
					<label>Process Name:</label>
					<input type="text" placeholder="Enter Title" v-model="form_data.title" />
					<span class="error-block">{{ errors.title }}</span>
				</div>
				<div :class="formRowClass('support_hours')" class="with-label">
					<label>Support Hours:</label>
					<input type="text" placeholder="Enter Support Hours" v-model="form_data.support_hours" />
					<span class="error-block">{{ errors.support_hours }}</span>
				</div>
				<div :class="formRowClass('product_supported')" class="with-label">
					<label>Products Supported:</label>
					<input type="text" placeholder="Enter Products Supported" v-model="form_data.product_supported" />
					<span class="error-block">{{ errors.product_supported }}</span>
				</div>
			</div>
			<div>
				<div :class="formRowClass('document_type')" class="with-label">
					<label>Document Type:</label>
					<select v-model="form_data.document_type" :disabled="true">
						<option value='ticket'>Standard Email Process</option>
						<option value='solution_support'>Solution Support</option>
						<option v-if="form_data.document_type == 'manager'" value='manager'>Manager Collaboration</option>
						<option v-if="form_data.document_type == 'json'" value='json'>JSON</option>
					</select>
					<span class="error-block">{{ errors.document_type }}</span>
				</div>
				<div :class="formRowClass('collaborate_email')" class="with-label">
					<label>To Email:</label>
					<input type="email" placeholder="Enter Collaborate Email" v-model="form_data.collaborate_email" />
					<span class="error-block">{{ errors.collaborate_email }}</span>
				</div>
				<div
					v-if="company.membership_type != 'limited'"
					:class="formRowClass('collaborate_cc_email')" class="with-label">
					<label>CC Email:</label>
					<input type="email" placeholder="Enter Collaborate CC Email" v-model="form_data.collaborate_cc_email" />
					<span class="error-block">{{ errors.collaborate_cc_email }}</span>
				</div>
				<div
					v-if="form_data.document_type == 'json'"
					:class="formRowClass('json_type')" class="with-label">
					<label>Submission Route:</label>
					<select v-model="form_data.json_type">
						<option value='cisco_xylem'>Cisco Xylem</option>
					</select>
				</div>
				<!--div class="form-row checkbox with-label">
					<label>&nbsp;</label>
					<div class="tsa-box low-padding">
						<input type="checkbox" v-model="form_data.notify_on_change" /> <strong>Notify membership@tsanet.org when users edit document.</strong>
					</div>
				</div-->
			</div>
		</div>
		<div class="tsa-admin-header" style="margin-top: 20px;"><span class="page-name">Admin Note</span></div>
		<div class="form-row"  style="margin-bottom: 20px;">
			<wysiwyg-editor v-model="form_data.admin_note"></wysiwyg-editor>
		</div>
		<div class="tsa-admin-header"><span class="page-name">Escalation Details</span></div>
		<div uk-grid class="uk-child-width-1-2@m uk-grid-small">
			<div>
				<div :class="formRowClass('escalation_instructions')" class="with-label">
					<label>Escalation Instructions:</label>
					<div class="form-row wysiwyg" :class="formRowClass('escalation_instructions')">
						<wysiwyg-editor v-model="form_data.escalation_instructions"></wysiwyg-editor>
						<span class="error-block">{{ errors.escalation_instructions }}</span>
					</div>
				</div>
			</div>
			<div>
				<div :class="formRowClass('main_escalation_contact')" class="with-label">
					<label>Main Escalation Contact:</label>
					<select v-model="form_data.main_escalation_contact">
						<option value=''>Choose One</option>
						<option
							v-for="user in users"
							:key="user.id"
							:value="user.id">{{ user.first_name }} {{ user.last_name }}</option>
					</select>
					<span class="error-block">{{ errors.main_escalation_contact }}</span>
				</div>
				<div
					v-if="company.membership_type != 'limited'"
					:class="formRowClass('alt_escalation_contact')" class="with-label">
					<label>Alt Escalation Contact:</label>
					<select v-model="form_data.alt_escalation_contact">
						<option value=''>Choose One</option>
						<option
							v-for="user in users"
							:key="user.id"
							:value="user.id">{{ user.first_name }} {{ user.last_name }}</option>
					</select>
					<span class="error-block">{{ errors.alt_escalation_contact }}</span>
				</div>
				<!-- Escalation Alias -->
				<div
					v-if="company.membership_type != 'limited'"
					class="form-row with-label" :class="formRowClass('escalation_alias')">
					<label>Escalation Alias:</label>
					<input type="email" name="escalation_alias" placeholder="Enter Escalation Alias (Optional)" v-model="form_data.escalation_alias" />
					<span class="error-block">{{ errors.escalation_alias }}</span>
				</div>
			</div>
		</div>
		<div uk-grid class="uk-child-width-1-2@m uk-grid-small">
			<div>
				<div class="tsa-admin-header"><span class="page-name">Email Templates</span></div>
				<div class="form-row with-label">
					<label>New Receive Member:</label>
					<select v-model="form_data.email_templates.new_receiving_member">
						<option :value="0">Choose New Receive Member Template</option>
						<option
							:value="template.id"
							v-for="template in email_templates.new_receiving_member"
							:key="template.id"
						>{{ template.name }}</option>
					</select>
				</div>
			</div>
			<div>
				<div class="tsa-admin-header"><span class="page-name">Tags</span></div>
				<div class="form-row">
					<textarea v-model="form_data.tags" rows="3"></textarea>
					<span class="tsa-note"><strong>Note:</strong> One Tag per Line.</span>
				</div>
			</div>
		</div>
		<AppliesTo :document="document" />
		<InternalNotes
					:process_document="process_document" />
	</div>
</template>

<script>
	import {mapState} from 'vuex';
	import AppliesTo from '@/components/ContactDocuments/AppliesTo.vue';
	import Breadcrumb from '@/components/Layout/Breadcrumb.vue';
	import WysiwygEditor from "@/components/Form/WysiwygEditor.vue";
	import InternalNotes from '@/components/SuperAdmin/Company/DocumentInternalNotes.vue';

	import _ from 'lodash';
	export default {
		name: 'manage-document',
		components: {
			AppliesTo,
			Breadcrumb,
			WysiwygEditor,
			InternalNotes
		},
		props:{
			process_document: {
				type: Object,
				default: () => {}
			}
		},
		data(){
			return {
				company: {},
				departments: [],
				groups: [],
				users: {},
				is_initialized: false,
				errors: {},
				form_data: {
					//notify_on_change: 0,
					status: "approved",
					title: "",
					support_hours: "7/24/365",
					product_supported: "All",
					document_type: "ticket",
					json_type:"",
					collaborate_email: "",
					collaborate_cc_email: "",
					main_escalation_contact: '',
					alt_escalation_contact: '',
					escalation_instructions: "",
					escalation_alias: "",
					departments: [],
					groups: [],
					email_templates : {
						new_receiving_member: 1
					},
					admin_note : "",
					tags : ""
				},
				document: {},
				email_templates: {
					new_receiving_member : []
				},
			}
		},
		mounted(){
			this.initialized();
		},
		updated(){
			this.initialized();
		},
		/**
		* remove bus event listeners before component is destroyed.
		* @return {[type]} [description]
		*/
		beforeUnmount(){
			this.emitter.off('token_ready');
		},
		methods: {
			initialized(){
				if(this.is_initialized) return;
				this.is_initialized = true;
				if(this.process_document.id != undefined) this.cloneDocument();
				this.emitter.on('token_ready', () => this.getFormData());
				if(this.jwt_token.access_token != undefined) this.getFormData();
				this.form_data.document_type = this.$route.params.type;
			},
			/**
			 * get current company data
			 * @return {[type]} [description]
			 */
			getFormData(){
				const company_id = this.$route.params.company_id;
				//eslint-disable-next-line
				axios.get(`${process.env.VUE_APP_API_URL}super_admin/companies/${company_id}/contact_documents/form_data`)
				.then(response => this.handleFormData(response.data))
				//eslint-disable-next-line
				.catch(error => console.log(error));
			},
			handleFormData(response){
				this.company = response.company;
				this.departments = response.departments;
				this.groups = response.communities;
				this.users = response.users;

				response.email_templates.forEach(template => {
					this.email_templates[template.type].push(template);
				});
				this.sortUsers();
				if(this.form_data.document_type == "manager"){
					for(let i = 0; i < this.email_templates.new_receiving_member.length;++i){
						if(this.email_templates.new_receiving_member[i].slug == "manager-request"){
							this.form_data.email_templates.new_receiving_member = this.email_templates.new_receiving_member[i].id;
							break;
						}
					}
				}
			},
			cloneDocument(){
				this.document = _.cloneDeep(this.process_document);
				// copy data to form data
				this.form_data.status = this.document.version.status;
				this.form_data.title = this.document.name;
				this.form_data.support_hours = this.document.version.support_hours;
				this.form_data.product_supported = this.document.version.product_supported;
				this.form_data.document_type = this.document.version.document_type;
				this.form_data.json_type = this.document.version.json_type;
				this.form_data.collaborate_email = this.document.version.collaborate_email;
				this.form_data.collaborate_cc_email = this.document.version.collaborate_cc_email;
				this.form_data.escalation_instructions = this.document.version.escalation_instructions;
				this.form_data.escalation_alias = this.document.version.escalation_alias;
				this.form_data.admin_note = (this.process_document.version.admin_note == null)? "" :  this.process_document.version.admin_note;

				//this.form_data.notify_on_change = this.document.notify_on_change;

				// handle managers
				for(let i in this.document.version.managers){
					const manager = this.document.version.managers[i];
					switch(manager.user_type){
						case "main":
							this.form_data.main_escalation_contact = manager.user_id;
						break;
						case "alternate":
							this.form_data.alt_escalation_contact = manager.user_id;
						break;
					}
				}

				// loop through tags
				let tags = [];
				for(let i in this.document.version.tags){
					const tag = this.document.version.tags[i];
					tags.push(tag.tag);
				}
				this.form_data.tags = tags.join("\n");

				for(let i in this.document.version.departments){
					const department = this.document.version.departments[i];
					if(department.department_id) this.form_data.departments.push(department.department_id);
				}

				for(let i in this.document.group_relations){
					const relation = this.document.group_relations[i];
					if(relation.community_id) this.form_data.groups.push(relation.community_id);
				}

				if(this.document.email_templates.length){
					this.document.email_templates.forEach(template => {
						this.form_data.email_templates[template.type] = template.email_template_id;
					});
				}

			},
			/**
			 * 'form-row' add 'has-error' class if field has error
			 * @param  {[type]} field [description]
			 * @return {[type]}       [description]
			 */
			formRowClass(field){
				let string = "form-row";
				if(this.errors[field] != undefined ) string += " has-error";
				return string;
			},
			saveDocument(){
				this.emitter.emit('loader',true);
				const company_id = this.$route.params.company_id,
				document_id = this.$route.params.document_id,
				url = `${process.env.VUE_APP_API_URL}super_admin/companies/${company_id}/contact_documents/${document_id}`;
				let data = _.cloneDeep(this.form_data);
				data.tags = this.form_data.tags.split("\n");
				//eslint-disable-next-line
				axios.post(url,data)
					.then(response => this.handleSaveDocument(response.data))
					//eslint-disable-next-line
				    .catch(error => this.handleErrors(error.response.data.errors));
			},
			/**
			 * show errors or redirect to group details.
			 * @param  {[type]} response [description]
			 * @return {[type]}          [description]
			 */
			handleSaveDocument(response){
				this.emitter.emit('loader',false);
				if(response.error != undefined){
					this.emitter.emit('alert',{
						type: 'error',
						message: response.error
					});
					return;
				}
				// redirect to details
				this.$router.push({name:"super_admin.company.detail",params:{company_id: this.$route.params.company_id}});
			},
			handleErrors (errors){
				this.emitter.emit('loader',false);
				// clear all errors
				this.errors = {};
				for(var e in errors){
					this.errors[e] = errors[e][0];
				}
			},
			/**
			 * sorts users by first name since we cannot sort encrypted data
			 */
			sortUsers(){
				this.users.sort(function(a, b){
					if(a.first_name < b.first_name) { return -1; }
					if(a.first_name > b.first_name) { return 1; }
					return 0;
				});
				this.$forceUpdate();
			}
		},
		computed:{
			/**
			 * get document type from 
			 * @return {[type]} [description]
			 */
			documentType(){
				if(this.document.version == undefined) return "";
				return this.document.version.document_type;
			},
			testFormRoute(){
				return {name: 'super_admin.inbound_process.test',params: {
					company_id: this.$route.params.company_id,
					id: this.$route.params.document_id}};
			},
			breadcrumb(){
				return [
					{display: "Companies" ,route: {name: "super_admin.companies"}},
					{display: this.company.name, route: {name: "super_admin.company.detail",params: {company_id : this.company.id}}},
					"Process Details"
				];
			},
			...mapState({
				jwt_token: state => state.token
			})
		},
		watch: {
			process_document() {
				this.cloneDocument();
			}
		}
	}
</script>