<template>
	<div class="tsa-partner-groups-process-document tsa-partner-onboarding">
		<GroupHeader />
		<div class="onboarding-instructions" v-if="this.onboarding_session.company != undefined">
			<HelpHint :flag="headerFlag" :content="helpContent" /> <span class="page-name">You are a {{ memberType }} Member</span>
		</div>
		<div class="uk-child-width-1-2@m uk-grid-medium" uk-grid>
			<div>
				<div class="tsa-box low-padding">
					<div class="tsa-section-title">
						Select Support Process <HelpHint flag="limited_select_support_process" />
					</div>
					<div :class="formRowClass('document_id')">
						<select v-model="form_data.document_id">
							<option value="">Choose One</option>
							<option
								v-for="document in documents"
								:key="document.id"
								:value="document.id">{{ document.name }}</option>
							<option value="new">Create a new one for me</option>
						</select>
						<span class="error-block">{{ errors.document_id }}</span>
					</div>
					<div class="tsa-section-title">
						Confirm User Access <HelpHint flag="limited_user_access" />
					</div>
					<div class='form-row'>
						<select v-model="form_data.user_access">
							<option value="open">Open to All Users</option>
							<option value="restricted">Restricted</option>
						</select>
					</div>
				</div>
			</div>
		</div>
		<div class="tsa-register-footer">
			<div class='tsa-progress-wrapper'>
				<Progress section="process" />
			</div>
			<button class="tsa-btn" @click.prevent="saveSelections">Submit</button>
			&nbsp;&nbsp;
			<a href="#" @click.prevent="saveForLater"><small>Save and Finish Later</small></a>
		</div>
	</div>
</template>
<script>
	import HelpHint from '@/components/Layout/HelpHint.vue';
	import GroupHeader from '@/components/PartnerGroup/Header.vue';
	import Progress from '@/components/PartnerGroup/Progress.vue';
	import RegistrationSaver from "../../Mixins/RegistrationSaver.vue";
	import {mapState} from 'vuex';
	import _ from 'lodash';
	export default {
		name : 'onboarding-choose-process',
		components: {
			HelpHint,
			GroupHeader,
			Progress
		},
		mixins: [
			RegistrationSaver
		],
		data(){
			return {
				form_data: {
					document_id: "",
					user_access: "open"
				},
				errors: {},
				documents: []
			}
		},
		mounted(){
			if(!this.partner_group || !this.partner_group.id){
				this.loadRegistrationData();
			}
			if(this.partner_group.id != undefined) this.loadDocuments();
		},
		methods: {
			saveSelections(){
				this.emitter.emit('loader',true);
				let data = _.cloneDeep(this.form_data);
				data.session_token = this.session_token;

				//eslint-disable-next-line
				axios.post(`${process.env.VUE_APP_API_URL}onboarding/${this.partner_group.vhost}/register/existing`,data)
					.then(response => this.handlePostResponse(response.data))
					//eslint-disable-next-line
				    .catch(error => this.handleErrors(error.response.data.errors));
			},
			handlePostResponse(response){
				this.emitter.emit('loader',false);
				if(response.valid){
					this.$store.dispatch('resetOnboarding');
					this.$router.push({name:'onboarding.success'});
				}
			},
			/**
			 * 'form-row' add 'has-error' class if field has error
			 * @param  {[type]} field [description]
			 * @return {[type]}       [description]
			 */
			formRowClass(field){
				let string = "form-row";
				if(this.errors[field] != undefined ) string += " has-error";
				return string;
			},
			handleErrors (errors){
				this.emitter.emit('loader',false);
				// clear all errors
				this.errors = {};
				for(var e in errors){
					this.errors[e] = errors[e][0];
				}
			},
			saveForLater(){

			},
			loadDocuments(){
				this.emitter.emit('loader',true);
				//eslint-disable-next-line
				axios.get(`${process.env.VUE_APP_API_URL}onboarding/${this.partner_group.vhost}/documents/${this.onboarding_session.company.id}`)
				.then(response => this.handleGetDocuments(response.data))
				//eslint-disable-next-line
				.catch(error => console.log(error));
			},
			handleGetDocuments(response){
				this.emitter.emit('loader',false);
				this.documents = response;
			}

		},
		computed: {
			memberType(){
				if(this.onboarding_session.company == undefined) return "";
				return this.onboarding_session.company.membership_type.ucfirst();
			},
			headerFlag(){
				return (this.memberType == "Limited")? 'limited_agreements_member_type' : 'agreements_member_type';
			},
			helpContent(){
				if(this.onboarding_session.company.id == undefined) return {};
				return {
					level: this.onboarding_session.company.membership_type
				};
			},
			...mapState({
				company: state => state.company,
				partner_group: state => state.partner_group,
				session_token: state => state.onboarding_session_token,
				onboarding: state => state.onboarding,
				onboarding_session: state => state.onboarding_session
			})
		},
		watch:{
			onboarding_session(){
				this.loadDocuments();
			}
		}
	}
</script>