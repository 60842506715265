<template>
	<div class="tsa-admin tsa-groups tsa-box low-padding">
		<div class="tsa-section-title">
			<button class='tsa-btn pull-right' @click="addNew"><i class="fas fa-plus"></i> Add New</button>
			Groups <ExportButton :export_route="exportRoute" file_name="groups.xlsx" />
		</div>
		<DataTable
			:table_data="groups"
			:table_headers="table_headers"
			:searchable="true"
			search_placeholder="Find a Group" />
	</div>
</template>

<script>

	import DataTable from '@/components/Table/DataTable.vue';
	import DeleteGroupCell from '@/components/SuperAdmin/Groups/DeleteGroupCell.vue';
	import ExportButton from '@/components/SuperAdmin/ExportButton.vue';
	import {mapState} from 'vuex';

	export default {
		name: "admin-groups",
		components: {
			DataTable,
			ExportButton
		},
		data(){
			return {
				groups: [],
				table_headers: {
					name:{
						route: "/super_admin/groups/{id}/{type}"
					},
					type:{
						display: "Group Type",
						searchable: false,
						render: data => {
							return data.type.replace('_', " ").ucfirst();
						}
					},
					host: {
						display: "Host",
					},
					companies: {
						searchable: false
					},
					registration_page: {
						searchable: false,
						display: "Registration Page",
						render: (cell_data) => {
							if(cell_data.type == "partner_program"){
								return `https://${cell_data.vhost}.${process.env.VUE_APP_DOMAIN}`;
							}else{
								return "&nbsp;";
							}
						}
					},
					delete: {
						component: DeleteGroupCell
					}
				},
				is_initialized: false
			}
		},
		mounted(){
			this.initialize();
		},
		updated(){
			this.initialize();
		},
		/**
		* remove bus event listeners before component is destroyed.
		* @return {[type]} [description]
		*/
		beforeUnmount(){
			this.emitter.off('token_ready');
			this.emitter.off('confirm_delete_group');
			this.emitter.off('sa_delete_group');
		},
		methods: {
			initialize(){
				if(this.is_initialized) return;
				this.is_initialized = true;
				this.emitter.on('token_ready', () => this.getGroups());
				this.emitter.on('confirm_delete_group', group => this.confirmDeleteGroup(group));
				this.emitter.on('sa_delete_group', group_id => this.deleteGroup(group_id));
				if(this.jwt_token.access_token != undefined) this.getGroups();

			},
			getGroups(){
				this.emitter.emit('loader',true);
				//eslint-disable-next-line
				axios.get(`${process.env.VUE_APP_API_URL}super_admin/groups`)
				.then(response => this.handleGetGroups(response.data))
				//eslint-disable-next-line
				.catch(error => console.log(error));
			},
			handleGetGroups(response){
				this.emitter.emit('loader',false);
				this.groups = response;
			},
			/**
			 * go to add new group route.
			 */
			addNew(){
				this.$router.push({name:"super_admin.group.edit",params:{group_id:0}});
			},
			/**
			 * ask user to confirm deleting a group.
			 * @param  {[type]} group [description]
			 * @return {[type]}       [description]
			 */
			confirmDeleteGroup(group){
				this.emitter.emit('confirm',{
					message: `Are you sure you want to delete the group ${group.name}?`,
					flag: `sa_delete_group`,
					data: group.id
				});
			},
			/**
			 * send api request to delete group
			 * @param  {[type]} group_id [description]
			 * @return {[type]}          [description]
			 */
			deleteGroup(group_id){
				this.emitter.emit('loader',true);
				//eslint-disable-next-line
				axios.get(`${process.env.VUE_APP_API_URL}super_admin/groups/${group_id}/delete`)
				.then(response => this.handleDelete(response.data))
				//eslint-disable-next-line
				.catch(error => console.log(error));
			},
			/**
			 * let user know the group was deleted and reload list.
			 * @param  {[type]} response [description]
			 * @return {[type]}          [description]
			 */
			handleDelete(response){
				this.emitter.emit('loader',false);
				if(response.success){
					this.emitter.emit('alert',{
						type: "success",
						message: response.message
					});
					this.getGroups();
				}
			}
		},
		computed: {
			exportRoute(){
				return `${process.env.VUE_APP_API_URL}super_admin/groups/export`;
			},
			...mapState({
				jwt_token: state => state.token
			})
		}
	}
</script>