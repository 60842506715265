<template>
	<div class="tsa-search-partner">
		<div class="tsa-box">
			<div class="tsa-heading">New Partner Collaboration</div>
			<div class="uk-container">
				<div uk-grid class="uk-child-width-1-2@m uk-grid-medium">
					<div><SearchPartnerBox /></div>
					<div><MyFavoritesList /></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import SearchPartnerBox from '@/components/Dashboard/SearchPartnerBox.vue';
	import MyFavoritesList from '@/components/Dashboard/MyFavoritesList.vue';
	export default {
		name: 'search-partner',
		data(){
			return {
				member: {},
			}
		},
		components:{
			SearchPartnerBox,
			MyFavoritesList,
		},
		methods:{
		}
	}
</script>