<template>
	<div :class="containerClass">
		<div class="tsa-modal-window tsa-company-change-request">
			<div class="tsa-section-title">Change Request to {{request_type}}</div>
			<i class="fas fa-times tsa-close" @click="closeModal"></i>
			<p>
				Please type the changes you need in the box below. Please allow 24-48 hours for the changes to take place. You will be notified once the changes have been made.
			</p>
			<div class="uk-child-width-1-2@m uk-grid-small" uk-grid>
				<div>
					<div :class="formRowClass('changes')">
						<textarea rows="6" placeholder="Please specify the changes you need" v-model="form_data.changes"></textarea>
						<span class="error-block">{{ errors.changes }}</span>
					</div>
				</div>
				<div><button class='tsa-btn' @click="sendRequest">Send Request</button></div>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapState} from 'vuex';

	export default {
		name: 'company-change-request',
		props:{
			is_open: {
				type: Boolean,
				default: false
			},
			request_type: {
				type: String,
				default: "Company Details"
			}
		},
		data(){
			return {
				form_data: {
					changes: ""
				},
				errors: {}
			}
		},
		components: {

		},
		methods:{
			/**
			 * let parent component know we need to close the modal.
			 * @return {[type]} [description]
			 */
			closeModal(){
				this.form_data.changes = "";
				this.$emit('close');
			},
			/**
			 * 'form-row' add 'has-error' class if field has error
			 * @param  {[type]} field [description]
			 * @return {[type]}       [description]
			 */
			formRowClass(field){
				let string = "form-row";
				if(this.errors[field] != undefined ) string += " has-error";
				return string;
			},
			sendRequest(){
				this.emitter.emit('loader',true);
				
				let data = {
					change_type: this.request_type,
					changes: this.form_data.changes
				};
				//eslint-disable-next-line
				axios.post(`${process.env.VUE_APP_API_URL}admin/company/change`,data)
					.then(response => this.handleSendRequest(response.data))
					//eslint-disable-next-line
					.catch(error => this.handleErrors(error.response.data.errors));
			},
			/**
			 * if error alert
			 * if success show message.
			 * @param  {[type]} response [description]
			 * @return {[type]}          [description]
			 */
			handleSendRequest(response){
				this.emitter.emit('loader',false);
				if(response.error != undefined){
					this.emitter.emit('alert', {message: response.error, type: "warning"});
				}else{
					this.closeModal();
					this.emitter.emit('alert', response.message);
				}
			},
			/**
			 * hide loader and add errors to erros array so they're displayed
			 * @param  {[type]} errors [description]
			 * @return {[type]}        [description]
			 */
			handleErrors (errors){
				this.emitter.emit('loader',false);
				// clear all errors
				this.errors = {};
				for(var e in errors){
					this.errors[e] = errors[e][0];
				}
			},
		},
		computed:{
			containerClass(){
				let classList = "tsa-modal";
				if(this.is_open) classList += " show";
				return classList;
			},
			...mapState({
				company: state => state.company,
				user: state => state.user
			})
		}
	}
</script>