/* eslint-disable */
export class Interactive{

	static getPosition(el) {
		let xPosition = 0;
		let yPosition = 0; 
		while (el) {
			if (el.tagName == "BODY") {
				// deal with browser quirks with body/window/document and page scroll
				let xScrollPos = el.scrollLeft || document.documentElement.scrollLeft;
				let yScrollPos = el.scrollTop || document.documentElement.scrollTop;
				xPosition += (el.offsetLeft - xScrollPos + el.clientLeft);
				yPosition += (el.offsetTop - yScrollPos + el.clientTop);
			} else {
				xPosition += (el.offsetLeft - el.scrollLeft + el.clientLeft);
				yPosition += (el.offsetTop - el.scrollTop + el.clientTop);
			}
			el = el.offsetParent;
		}
		return {
			x: xPosition,
			y: yPosition
		};
	}

	static getRectSon(el){
		return el.getBoundingClientRect();
	}

	/**
     * scroll window to the stop.
     * @param  {int} scrollDuration duration in seconds
     * @return void
     */
    static scrollToTop(scrollDuration) {
        const   scrollHeight = window.scrollY,
                scrollStep = Math.PI / ( scrollDuration / 15 ),
                cosParameter = scrollHeight / 2;
        var     scrollCount = 0,
                scrollMargin,
                scrollInterval = setInterval( function() {
                    if ( window.scrollY != 0 ) {
                        scrollCount = scrollCount + 1;  
                        scrollMargin = cosParameter - cosParameter * Math.cos( scrollCount * scrollStep );
                        window.scrollTo( 0, ( scrollHeight - scrollMargin ) );
                    } 
                    else clearInterval(scrollInterval); 
                }, 15 );
    }

	/**
	* Slide an element down like jQuery's slideDown function using CSS3 transitions.
	* @see https://gist.github.com/ludder/4226288
	* @param  {element}  el
	* @param  {string}   timing
	* @return {void}
	*/
	static slideDown(el, timing) {
		timing = timing || '300ms ease';

		// Get element height
		el.style.webkitTransition = 'initial';
		el.style.transition = 'initial';
		el.style.visibility = 'hidden';
		el.style.maxHeight = 'initial';
		var height = el.offsetHeight + 'px';
		el.style.removeProperty('visibility');
		el.style.maxHeight = '0';
		el.style.overflow = 'hidden';

		// Begin transition
		el.style.webkitTransition = 'max-height ' + timing + ', opacity ' + timing + '';
		el.style.transition = 'max-height ' + timing + ', opacity ' + timing + '';
		var endSlideDown = function() {
		el.style.removeProperty('-webkit-transition');
		el.style.removeProperty('transition');
		el.removeEventListener(transitionEvent('end'), endSlideDown);
		};
		requestAnimationFrame(function() {
		el.addEventListener(transitionEvent('end'), endSlideDown);
		el.style.maxHeight = height;
		el.style.opacity = '1';
		});
	}

	/**
	* Slide an element up like jQuery's slideUp function using CSS3 transitions.
	* @see https://gist.github.com/ludder/4226288
	* @param  {element}  el
	* @param  {string}   timing
	* @return {void}
	*/
	static slideUp(el, timing) {
		timing = timing || '300ms ease';

		// Get element height
		el.style.webkitTransition = 'initial';
		el.style.transition = 'initial';
		var height = el.offsetHeight + 'px';
		el.style.maxHeight = height;
		el.style.overflow = 'hidden';

		// Begin transition
		el.style.webkitTransition = 'max-height ' + timing + ', opacity ' + timing + '';
		el.style.transition = 'max-height ' + timing + ', opacity ' + timing + '';
		var endSlideDown = function() {
		el.style.removeProperty('-webkit-transition');
		el.style.removeProperty('transition');
		el.removeEventListener(transitionEvent('end'), endSlideDown);
		};
		requestAnimationFrame(function() {
		el.style.maxHeight = '0';
		el.style.opacity = '0';
		});
	}
}