<template>
	<div>
		<DefaultModal
			title="Choose Document Type"
			@close="onCancelAddDocument"
			:is_open="show_modal">
				<center>
					<button class="tsa-btn" @click.prevent="addNew('ticket')">Ticket Type Document</button>
					<br>
					<br>
					<button class="tsa-btn" @click.prevent="addNew('default')">Static Document</button>
					<br>
					<br>
					<button class="tsa-btn" @click.prevent="addNew('solution_support')">Solution Support</button>
					<br>
					<br>
					<button class="tsa-btn" @click.prevent="addNew('parent')">Parent Form</button>
					<br>
					<br>
					<button class="tsa-btn" @click.prevent="addNew('msteams')"><img :src="require('../../../assets/images/msteams_logo.png')" style="height: 25px;" /> MS Teams Integration</button>
					<br>
					<br>
					<button class="tsa-btn" @click.prevent="addNew('json')">JSON Form</button>
					<br>
					<br>
					<button class="tsa-btn" @click.prevent="addNew('manager')">Manager Form</button>

				</center>
		</DefaultModal>
		<div class="tsa-company-contact-documents">
			<Expandable
				:title="theTitle">
				<template v-slot:headerright>
					<button
						class="tsa-btn tsa-outline pull-right"
						@click.prevent="chooseDocumentType"
						><i class="fas fa-plus"></i> Add New</button>
				</template>
				<DataTable
					:table_headers="table_headers"
					:table_data="table_data"
					:searchable="false" />
			</Expandable>
		</div>
	</div>
</template>

<script>
	import Expandable from '@/components/Layout/ExpandableBox.vue';
	import DataTable from '@/components/Table/DataTable.vue';
	import DeleteProcessCell from '@/components/SuperAdmin/Company/DeleteProcessCell.vue';
	import DefaultModal from '@/components/Layout/DefaultModal.vue';

	export default {
		name: 'company-contact-documents',
		components: {
			Expandable,
			DataTable,
			DefaultModal
		},
		props: {
			company: {
				type: Object,
				default: () => {}
			}
		},
		data(){
			return {
				table_headers:{
					name: {
						route: "/super_admin/companies/{company_id}/documents/{document_id}/edit/{document_type}"
					},
					departments: {},
					groups: {},
					actions: {
						display: "&nbsp;",
						component: DeleteProcessCell
					}
				},
				table_data: [],
				show_modal: false
			}
		},
		mounted(){
			this.emitter.on('confirm_delete_contact_document',document_id => this.deleteDocument(document_id));
		},
		/**
		 * remove bus event listeners before component is destroyed.
		 * @return {[type]} [description]
		 */
		beforeUnmount(){
			this.emitter.off('confirm_delete_contact_document');
		},
		methods: {
			/**
			 * show modal to choose doc type.
			 * @return {[type]} [description]
			 */
			chooseDocumentType(){
				this.show_modal = true;
			},
			/**
			 * link to add new document and pass type.
			 * @param {[type]} type [description]
			 */
			addNew(type){
				this.$router.push({name:'super_admin.document.edit',params:{company_id: this.company.id,document_id:0,type:type}});
			},
			getTableData(){
				//eslint-disable-next-line
				axios.get(`${process.env.VUE_APP_API_URL}super_admin/companies/${this.company.id}/contact_documents`)
				.then(response => this.handleGetTableData(response.data))
				//eslint-disable-next-line
				.catch(error => console.log(error));
			},
			/**
			 * send request to delete document.
			 * @param  {[type]} document_id [description]
			 * @return {[type]}             [description]
			 */
			deleteDocument(document_id){
				this.emitter.emit('loader',true);
				//eslint-disable-next-line
				axios.get(`${process.env.VUE_APP_API_URL}super_admin/companies/${this.company.id}/contact_documents/${document_id}/delete`)
				.then(response => this.handleDeleteDocument(response.data))
				//eslint-disable-next-line
				.catch(error => console.log(error));
			},
			handleDeleteDocument(response){
				this.emitter.emit('loader',false);
				this.emitter.emit('alert',response.message);
				this.getTableData();
			},
			handleGetTableData(response){
				this.table_data = response;
			},
			/**
			 * hide add document modal.
			 * @return {[type]} [description]
			 */
			onCancelAddDocument(){
				this.show_modal = false;
			}
		},
		computed: {
			theTitle(){
				return `Process Forms (${this.table_data.length})`;
			}
		},
		watch: {
			company(){
				if(this.company.id != undefined) this.getTableData();
			}
		}
	}
</script>