<template>
	<div>
		<h1 class="text-center">Your account is disabled. Contact membership@tsanet.org to re-activate.</h1>
	</div>
</template>

<script>
	export default {
		name : "company-inactive",
		props : {

		}
	};
</script>