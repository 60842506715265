<template>
	<div class="manage-process-document">
		<CompanyChangeRequest
			:is_open="show_request_window"
			@close="onCloseChangeRequest"
			:request_type="request_type" />
		<!--ProcessDetails /-->
		<div class="process-details">
			<div class="tsa-admin-header">
				<HelpHint flag="limited_process_details" /> <span class="page-name">Process &mdash;</span> To modify <button class="tsa-btn tsa-btn-secondary" @click.prevent="contactTSA('Support Process')">Contact TSANet</button>
				<router-link v-if="documentType != 'parent'" class="tsa-btn" :to="testFormRoute" style="margin-left: 10px;"> View/Test Form</router-link>
			</div>
			<div class="uk-child-width-1-2@m uk-grid-small" uk-grid>
				<div>
					<div class="form-row with-label">
						<label>Support Hours:</label>
						<input type="text" name="support_hours" placeholder="Enter Support Hours" v-model="form_data.support_hours" :disabled="true" />
					</div>
					<div class="form-row with-label">
						<label>Products Supported:</label>
						<input type="text" name="product_supported" placeholder="Enter Product Supported" v-model="form_data.product_supported" :disabled="true" />
					</div>
					<!-- Escalation Alias -->
					<div class="form-row with-label">
						<label>Support Email Alias:</label>
						<input type="email" name="escalation_alias" placeholder="Enter Escalation Alias (Optional)" v-model="form_data.collaborate_email" :disabled="true" />
					</div>
					<!-- Escalation Alias -->
					<div class="form-row with-label">
						<label>Support Manager Email:</label>
						<input type="email" name="escalation_alias" placeholder="Support Manager Email" v-model="form_data.support_manager_email" :disabled="true" />
					</div>
				</div>
				<div>
					<div class="sample-escalation">
						<div class="form-section">Escalation Instructions:</div>
						<div class="form-row" v-html="form_data.escalation_instructions" />
					</div>
				</div>
			</div>
		</div>
		<DocumentFields @change_request="contactTSA" />
	</div>
</template>

<script>
	import {mapState} from 'vuex';
	import CompanyChangeRequest from '@/components/Admin/CompanyChangeRequest.vue';
	import DocumentFields from '@/components/Admin/InboundProcess/DocumentFields.vue';

	import HelpHint from '@/components/Layout/HelpHint.vue';


	export default {
		name : "admin-limited-process",
		components: {
			CompanyChangeRequest,
			DocumentFields,
			HelpHint
		},
		data(){
			return {
				show_request_window: false,
				request_type: "",
				companies: [],
				departments: [],
				communities: [],
				form_data:{
					escalation_user_id: 0,
					alt_escalation_user_id: 0,
					escalation_instructions: "",
					title: "",
					support_hours: "",
					product_supported: "",
					collaborate_email: "",
					collaborate_cc_email: "",
					escalation_alias: ""
				},
				errors: {}
			}
		},
		mounted(){
			this.initialize();
		},
		updated(){
			if(!this.has_initialized) this.initialize();
		},
		/**
		* remove bus event listeners before component is destroyed.
		* @return {[type]} [description]
		*/
		beforeUnmount(){
			this.emitter.off('token_ready');
		},
		methods: {
			initialize(){
				this.has_initialized = true;
				
				this.emitter.on('token_ready', () => this.loadDocumentData());
				if(this.jwt_token.access_token != undefined) this.loadDocumentData();
			},
			loadDocumentData(){
				this.emitter.emit('loader',true);
				//eslint-disable-next-line
				axios.get(`${process.env.VUE_APP_API_URL}admin/company/documents/limited`)
				.then(response => this.handleLoadDocumentData(response.data))
				//eslint-disable-next-line
				.catch(error => console.log(error));
			},
			handleLoadDocumentData(response){
				this.emitter.emit('loader',false);
				this.$store.dispatch('caseData',{
					document: response.document,
					messages: null
				});
				this.companies = response.companies;
				this.departments = response.departments;
				this.communities = response.communities;
				this.cloneDocument();
			},
			/**
			 * copy document data into form data.
			 * @return {[type]} [description]
			 */
			cloneDocument(){
				// escalation details
				this.form_data.escalation_instructions = this.document.version.escalation_instructions;
				if(this.document.version.managers != null){
					this.document.version.managers.forEach(manager => {
						if(manager.user_type == "main"){
							this.form_data.escalation_user_id = manager.user_id;
							this.form_data.support_manager_email = manager.user.email;
						}else{
							// alternate
							this.form_data.alt_escalation_user_id = manager.user_id;
						}
					});
				}
				// details
				const fields = ['title','support_hours','product_supported','collaborate_email','collaborate_cc_email','escalation_alias'];
				
				for(let i in fields){
					let field = fields[i];
					this.form_data[field] = this.document.version[field];
				}
			},

			/**
			 * request to close change request window
			 * @return {[type]} [description]
			 */
			onCloseChangeRequest(){
				this.show_request_window = false;
			},
			contactTSA(request_type){
				this.request_type = request_type;
				this.show_request_window = true;
			},
			/**
			 * 'form-row' add 'has-error' class if field has error
			 * @param  {[type]} field [description]
			 * @return {[type]}       [description]
			 */
			formRowClass(field){
				let string = "";
				if(this.errors[field] != undefined ) string += " has-error";
				return string;
			},
		},
		computed: {
			testFormRoute(){
				return {name: 'admin.inbound_process.test',params: {id:this.document.id}};
			},
			/**
			 * get document type from 
			 * @return {[type]} [description]
			 */
			documentType(){
				if(this.document.version == undefined) return "ticket";
				return this.document.version.document_type;
			},
			...mapState({
				company: state => state.company,
				document: state => state.contact_document,
				jwt_token: state => state.token
			})
		}
	}
</script>