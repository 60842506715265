<template>
	<div class="company-logo">
		<img v-if="companyUrl && companyUrl != ''" width="60" height="60" :src="clearbit(companyUrl)" :alt="altName(companyName)">
	</div>
</template>

<script>

export default {
	name: "CompanyLogo",
	
	props: {
		companyUrl: {
			type: String,
			default: ""
		},
		companyName: {
			type: String,
			default: ""
		},
	},
	components: {},
	data() {
		return {}
	},
	mounted() {
		
	},
	methods: {
		clearbit(val){
			val = val.toString().replace("http://", "");
			val = val.toString().replace("https://", "");
			let parts = val.split("/");
			val = parts[0];
			
			return "https://logo.clearbit.com/" + val;
		},
		altName(cName){
			if(cName == ''){
				return "Company Logo";
			}
			
			return cName + " Logo";
		}
	},
}
</script>