<template>
	<div class="document-applies-to">
		<div class="tsa-admin-header">
			<HelpHint flag="inbound_process_applies_to" /> <span>Applies To &mdash;</span> To modify <button class="tsa-btn tsa-btn-secondary" @click.prevent="changeRequest">Contact TSANet</button>
		</div>
		<div>
			<p style="text-align:center"><strong>Note:</strong> This shows what groups and departments this process document applies to.</p>
			<DataTable 
				:table_headers="table_headers"
				:table_data="table_data"
				:searchable="false" />
		</div>
	</div>
</template>
<script>
	import HelpHint from '@/components/Layout/HelpHint.vue';
	import DataTable from '@/components/Table/DataTable.vue';
	import _ from 'lodash';
	import {mapState} from 'vuex';

	export default {
		name: "document-applies-to",
		components: {
			HelpHint,
			DataTable
		},
		props:{
		},
		data(){
			return {
				table_data: [],
				table_headers: {
					'community.name': {
						display: "Group"
					},
					'department.name': {
						display: "Department",
						render: cell_data => {
							if(cell_data.department == null){
								return "N/A";
							}else{
								return cell_data.department.name;
							}
						}
					}
				}
			}
		},
		methods: {
			changeRequest(){
				this.$emit('change_request', "Document Applies to Relations");
			},
			cloneTableData(){
				this.table_data = _.cloneDeep(this.document.group_relations);
			}
		},
		computed: {
			...mapState({
				document : state => state.contact_document,
				company: state => state.company
			})
		},
		watch: {
			document() {
				this.cloneTableData();
			}
		}
	}
</script>