<template>
	<div class="tsa-admin manage-company">
		<Breadcrumb :routes="breadcrumb" />
		<div uk-grid class="uk-child-width-1-2@m uk-grid-small tsa-document-form">
			<div>
				<div class="form-section">Company Details <HelpHint flag="company_details" /></div>
				<div :class="formRowClass('name')">
					<input type="text" placeholder="Enter Company Name" v-model="form_data.name" />
					<span class="error-block">{{ errors.name }}</span>
				</div>
				<div :class="formRowClass('url')">
					<input type="text" placeholder="Enter Company Url" v-model="form_data.url" />
					<span class="error-block">{{ errors.url }}</span>
				</div>
				<div :class="formRowClass('membership_type')">
					<select v-model="form_data.membership_type">
						<option value='basic'>Basic</option>
						<option value='standard'>Standard</option>
						<option value='premium'>Premium</option>
						<option value='elite'>Elite</option>
						<option value='limited'>Limited</option>
					</select>
				</div>
				<div :class="formRowClass('login_method')">
					<select v-model="form_data.login_method">
						<option value="email_link">Email Authentication Link </option>
						<option value="requires_registration">Requires Registration</option>
						<option value="sso">Single Sign On</option>
					</select>
					<span class="error-block">{{ errors.login_method }}</span>
				</div>
				<div :class="formRowClass('domain')">
					<input type="text" placeholder="Enter  Email Domain" v-model="form_data.domain" />
					<span class="error-block">{{ errors.domain }}</span>
				</div>
				<div :class="formRowClass('logo')">
					<div class="uk-grid-small uk-child-width-1-2" uk-grid>
						<div class="uk-width-expand"><FileUpload
						placeholder="Logo (optional)"
						@selected="fileSelected" /></div>

						<div class="tsa-company-logo"
							:style="`background-image: url(${companyLogoUrl});`"
							v-if="hasLogo"></div>
					</div>
				</div>
				
				<div class="form-section">Outbound process bcc Email:</div>
				<div :class="formRowClass('communications_email')">
					<input type="email" placeholder="Enter Outbound process bcc Email" v-model="form_data.communications_email" />
					<span class="error-block">{{ errors.communications_email }}</span>
				</div>

				<div class="form-section">Inbound process bcc Email:</div>
				<div :class="formRowClass('bcc_response_email')">
					<input type="email" placeholder="Enter Inbound process bcc Email" v-model="form_data.bcc_response_email" />
					<span class="error-block">{{ errors.bcc_response_email }}</span>
				</div>

				<div class="form-section">Login Page Url</div>
				<div :class="formRowClass('slug')">
					<div class="tsa-slug-field">
						<div class="tsa-protocol">https://</div>
						<input type="text" v-model="form_data.slug" placeholder="Enter Subdomain" />
						<div class="tsa-domain">{{ appDomain }}</div>
					</div>
					<span class="error-block">{{ errors.slug }}</span>
				</div>
			</div>
			<div>
				<div>
					<div class="form-section">Show in Public Web</div>
					<select v-model="form_data.show_in_public_web">
						<option :value="1">Yes</option>
						<option :value="0">No</option>
					</select>
				</div>

				<div :class="formRowClass('login_page_text')">
					<div class="form-section">Login Page Text</div>
					<div class="form-row wysiwyg" :class="formRowClass('login_page_text')">
						<wysiwyg-editor v-model="form_data.login_page_text"></wysiwyg-editor>
						<span class="error-block">{{ errors.login_page_text }}</span>
					</div>
				</div>
				
				<div>
					<div class="form-section">Allow Collaboration Feed</div>
					<select v-model="form_data.allow_notes">
						<option :value="1">Yes</option>
						<option :value="0">No</option>
					</select>
				</div>
				
				<div class="form-row uk-margin-small-top">
					<button class="tsa-btn pull-right" @click="saveCompany">Submit</button>
				</div>
			</div>
		</div>
		<!-- SSO Setup -->
		<div class="tsa-admin-header" v-show="form_data.login_method == 'sso'" style="margin-top:20px;">Single Signon Setup</div>
		<div uk-grid class="uk-child-width-1-2@m uk-grid-small tsa-document-form"  v-show="form_data.login_method == 'sso'">
			<div>
				<div class="form-row with-label">
					<label>Entity Source:</label>
					<div>
						<input type="radio" value="url" name="contract_length" v-model="form_data.sso_identity_source" /> URL
						&nbsp;&nbsp;
						<input type="radio" value="file" name="contract_length" v-model="form_data.sso_identity_source" /> Upload File
					</div>
				</div>
				<div :class="formRowClass('sso_idp_file')" v-show="form_data.sso_identity_source == 'file'" class="with-label">
					<label>Entity XML File</label>
					<FileUpload
						placeholder="Choose XML File"
						@selected="ssofileSelected" />
				</div>
				<div class="form-row with-label" v-if="sso_uploaded_file != null">
					<label>&nbsp;</label>
					<div><a :href="sso_uploaded_file" target="_blank">{{ sso_uploaded_file}}</a></div>
				</div>
				<!-- entity url -->
				<div :class="formRowClass('sso_idp_entity_url')" class="with-label" v-show="form_data.sso_identity_source == 'url'">
					<label>Entity Url</label>
					<input type="text" placeholder="Enter IDP entify Url" v-model="form_data.sso_idp_entity_url" />
					<span class="error-block">{{ errors.sso_idp_entity_url }}</span>
				</div>
				<div class="form-row with-label">
					<label>&nbsp;</label>
					<div class="tsa-note"><small>Identifier of the IdP entity  (must be a URI)</small></div>
				</div>
				<!-- federation url -->
				<div :class="formRowClass('	sso_idp_federation_url')" class="with-label">
					<label>Federation Url:</label>
					<input type="text" placeholder="Enter IDP Federation Url" v-model="form_data.sso_idp_federation_url" />
					<span class="error-block">{{ errors.sso_idp_federation_url }}</span>
				</div>
				<div class="form-row with-label">
					<label>&nbsp;</label>
					<div class="tsa-note"><small>URL Target of the IdP where the SP will send the Authentication Request Message.</small></div>
				</div>
				<!-- logout url -->
				<div :class="formRowClass('sso_idp_logout_url')" class="with-label">
					<label>Logout Url:</label>
					<input type="text" placeholder="Enter IDP Logout Url" v-model="form_data.sso_idp_logout_url" />
					<span class="error-block">{{ errors.sso_idp_logout_url }}</span>
				</div>
				<div class="form-row with-label">
					<label>&nbsp;</label>
					<div class="tsa-note"><small>URL Location of the IdP where the SP will send the SLO Request.</small></div>
				</div>
				<div class="tsa-box low-padding">
					<div class="tsa-section-title">
						Service Provider URL
					</div>
					<h3 style="margin: 0;">{{ ssoServiceProviderUrl }}</h3>
					<span class="tsa-note">Provide this url to {{ form_data.name }} to set up SSO on their end.</span>
				</div>
			</div>
			<div>
				<div class="tsa-box low-padding">
					<div class="tsa-section-title">Landing Page Settings:</div>
					<div :class="formRowClass('sso_login_section')" class="form-row">
						<div class="form-section">Login Section</div>
						<div class="form-row wysiwyg" :class="formRowClass('sso_login_section')">
							<wysiwyg-editor v-model="form_data.sso_login_section"></wysiwyg-editor>
							<span class="error-block">{{ errors.sso_login_section }}</span>
						</div>
					</div>
					<div :class="formRowClass('sso_registration_section')" class="form-row">
						<div class="form-section">Register Section</div>
						<div class="form-row wysiwyg" :class="formRowClass('sso_registration_section')">
							<wysiwyg-editor v-model="form_data.sso_registration_section"></wysiwyg-editor>
							<span class="error-block">{{ errors.sso_registration_section }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import FileUpload from '@/components/Form/FileUpload.vue';
	import HelpHint from '@/components/Layout/HelpHint.vue';
	import {mapState} from 'vuex';
	import Breadcrumb from '@/components/Layout/Breadcrumb.vue';
	import WysiwygEditor from "@/components/Form/WysiwygEditor.vue";

	export default {
		name: 'manage-company',
		components: {
			FileUpload,
			HelpHint,
			Breadcrumb,
			WysiwygEditor
		},
		data(){
			return {
				form_data: {
					name: "",
					url: "",
					membership_type: "standard",
					region_text: "",
					login_method: "requires_registration",
					public_web_text: "",
					login_page_text: "",
					communications_email: "",
					bcc_response_email: "",
					slug:"",
					domain:"",
					logo: null,
					sso_idp_federation_url: "",
					sso_idp_logout_url: "",
					sso_idp_entity_url: "",
					sso_login_section: "",
					sso_registration_section: "",
					sso_identity_source : "url",
					show_in_public_web : 1,
					allow_notes : 0,
				},
				logo_file: null,
				sso_idp_file: null,
				errors: {

				},
				company: {},
				is_initialized: false,
				email_templates: {
					new_receiving_member : []
				},
				sso_uploaded_file : null
			}
		},
		mounted(){
			this.initialized();
		},
		updated(){
			this.initialized();
		},
		/**
		* remove bus event listeners before component is destroyed.
		* @return {[type]} [description]
		*/
		beforeUnmount(){
			this.emitter.off('token_ready');
		},
		methods: {
			initialized(){
				if(this.is_initialized) return;
				this.is_initialized = true;
				this.emitter.on('token_ready', () => this.getCompany());
				if(this.jwt_token.access_token != undefined) this.getCompany();
			},
			getCompany(){
				if(this.$route.params.company_id == 0) return;
				this.emitter.emit('loader',true);
				//eslint-disable-next-line
				axios.get(`${process.env.VUE_APP_API_URL}super_admin/companies/${this.$route.params.company_id}`)
				.then(response => this.handleGetCompany(response.data))
				//eslint-disable-next-line
				.catch(error => console.log(error));
			},
			handleGetCompany(response){
				const company = response.company;
				if(company.communications_email == null) company.communications_email = "";
				if(company.bcc_response_email == null) company.bcc_response_email = "";
				this.emitter.emit('loader',false);
				if(company.region_text == null || company.region_text == "null") company.region_text = "";
				
				this.company = company;
				
				// set form data values
				this.form_data.name = company.name;
				this.form_data.url = company.url;
				this.form_data.membership_type = company.membership_type;
				this.form_data.region_text = company.region_text;
				this.form_data.login_method = company.login_method;
				this.form_data.public_web_text = company.public_web_text;
				this.form_data.login_page_text = company.login_page_text;
				this.form_data.communications_email = company.communications_email;
				this.form_data.bcc_response_email = company.bcc_response_email;
				this.form_data.slug = company.slug;
				this.form_data.domain = company.domain;
				this.form_data.logo = company.logo;
				this.form_data.sso_idp_federation_url = company.sso_idp_federation_url == null ? "" : company.sso_idp_federation_url;
				this.form_data.sso_idp_logout_url = company.sso_idp_logout_url == null ? "" : company.sso_idp_logout_url;
				this.form_data.sso_idp_entity_url = company.sso_idp_entity_url == null ? "" : company.sso_idp_entity_url;
				this.form_data.sso_login_section = company.sso_login_section == null ? "" : company.sso_login_section;
				this.form_data.sso_registration_section = company.sso_registration_section == null ? "" : company.sso_registration_section;
				this.form_data.sso_identity_source = company.sso_identity_source == null ? "" : company.sso_identity_source;
				if(this.form_data.sso_identity_source == 'file'){
					this.sso_uploaded_file = `${process.env.VUE_APP_API_DOMAIN}storage/sso/${company.sso_identity_file}`;
				}
				this.form_data.show_in_public_web  = company.show_in_public_web;
				this.form_data.allow_notes  = company.allow_notes;
			},
			/**
			 * 'form-row' add 'has-error' class if field has error
			 * @param  {[type]} field [description]
			 * @return {[type]}       [description]
			 */
			formRowClass(field){
				let string = "form-row";
				if(this.errors[field] != undefined ) string += " has-error";
				return string;
			},
			fileSelected(file){
				this.logo_file = file;
			},
			ssofileSelected(file){
				this.sso_idp_file = file;
			},
			/**
			 * send data to api to create/edit company
			 * @return {[type]} [description]
			 */
			saveCompany(){
				this.emitter.emit('loader',true);

				let formData = new FormData();
                formData.append("name",this.form_data.name);
                formData.append("url",this.form_data.url);
                formData.append("region_text",this.form_data.region_text);
                formData.append("membership_type",this.form_data.membership_type);
                formData.append("login_method",this.form_data.login_method);
                formData.append("public_web_text",this.form_data.public_web_text);
                formData.append("login_page_text",this.form_data.login_page_text);
                formData.append("slug",this.form_data.slug);
                formData.append("domain",this.form_data.domain);
                formData.append("sso_idp_federation_url",this.form_data.sso_idp_federation_url);
                formData.append("sso_idp_logout_url",this.form_data.sso_idp_logout_url);
                formData.append("sso_idp_entity_url",this.form_data.sso_idp_entity_url);
                formData.append("sso_login_section",this.form_data.sso_login_section);
                formData.append("sso_registration_section",this.form_data.sso_registration_section);
                formData.append("sso_identity_source",this.form_data.sso_identity_source);
                formData.append("show_in_public_web",this.form_data.show_in_public_web);
                formData.append("allow_notes",this.form_data.allow_notes);

                if(this.form_data.communications_email.length > 0) formData.append("communications_email",this.form_data.communications_email);
                if(this.form_data.bcc_response_email.length > 0) formData.append("bcc_response_email",this.form_data.bcc_response_email);

                if(this.logo_file != null ){
					formData.append("logo_file", this.logo_file);
                }
                if(this.sso_idp_file != null ){
					formData.append("sso_idp_file", this.sso_idp_file);
                }
                //eslint-disable-next-line
				axios.post(`${process.env.VUE_APP_API_URL}super_admin/companies/${this.$route.params.company_id}`,formData)
					.then(response => this.handleSaveCompany(response.data))
					//eslint-disable-next-line
				    .catch(error => this.handleErrors(error.response.data.errors));
			},
			handleSaveCompany(response){
				this.emitter.emit('loader',false);
				if(response.error != undefined){
					this.emitter.emit('alert',{
						type: "error",
						message: response.error
					});
				}else{
					this.$router.push({name:"super_admin.company.detail",params:{company_id: response.id }});
				}
				
			},
			handleErrors (errors){
				this.emitter.emit('loader',false);
				// clear all errors
				this.errors = {};
					for(var e in errors){
					this.errors[e] = errors[e][0];
				}
			},		
		},
		computed:{
			breadcrumb(){
				if(this.$route.params.company_id == 0){
					return [
						{display: "Companies" ,route: {name: "super_admin.companies"}},
						"New Company"
					];
				}else{
					return [
						{display: "Companies" ,route: {name: "super_admin.companies"}},
						{display: this.company.name, route: {name: "super_admin.company.detail",params: {company_id : this.company.id}}},
						"Edit Company"
					];
				}
			},
			/**
			 * api url to load company logo
			 * @return {[type]} [description]
			 */
			companyLogoUrl(){
				return `${process.env.VUE_APP_API_URL}resources/company_logo/${this.company.slug}`;
			},
			appDomain(){
				return process.env.VUE_APP_DOMAIN;
			},
			hasLogo(){
				if(this.company.logo == undefined || this.company.logo == null) return false;
				return this.company.logo.length > 0;
			},
			ssoServiceProviderUrl(){
				return `${process.env.VUE_APP_API_DOMAIN}saml2/${this.form_data.slug}/metadata`;
			},
			...mapState({
				jwt_token: state => state.token
			})
		}
	}
</script>