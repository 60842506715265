<template>
    <DefaultModal
        :class="modalClass"
        title="Update Profile"
        :is_open="opened"
        @close="onCloseModal">
        <p>It looks like we need additional profile information. Please update the form and click <strong>Submit</strong></p>
        <div class="alert-scrollable">
            <div class="uk-grid-small uk-flex-center" uk-grid style="padding: 0 40px;">
                <!-- profile information data -->
                <div :class="gridClass">
                    <!-- first name -->
                    <div class="form-row with-label" :class="formRowClass('first_name')">
                        <label>First Name</label>
                        <input type="text" name="first_name" placeholder="Enter Your First Name" v-model="form_data.first_name" />
                        <span class="error-block">{{ errors.first_name }}</span>
                    </div>
                    <!-- last name -->
                    <div class="form-row with-label" :class="formRowClass('last_name')">
                        <label>Last Name</label>
                        <input type="text" name="last_name" placeholder="Enter Your Last Name" v-model="form_data.last_name" />
                        <span class="error-block">{{ errors.last_name }}</span>
                    </div>
                    <!-- email -->
                    <div class="form-row with-label" :class="formRowClass('email')">
                        <label>Email</label>
                        <input type="email" name="email" placeholder="Enter Your Email" v-model="form_data.email" />
                        <span class="error-block">{{ errors.email }}</span>
                    </div>
                    <!-- phone -->
                    <div class="form-row with-label" :class="formRowClass('phone')">
                        <label>Phone</label>
                        <TelephoneField
                            :phone="form_data.phone"
                            :defaultCountry="form_data.phone_country_code"
                            @country-changed="countryChanged"
                            @phone-changed="onPhoneChanged"
                            placeholder="Enter Phone" />
                        <span class="error-block">{{ errors.phone }}</span>
                    </div>
                    <!-- country -->
                    <div class="form-row with-label" :class="formRowClass('country')">
                        <label>Country</label>
                        <select v-model="form_data.country_id">
                            <option value="">Choose Country</option>
                            <option
                            v-for="(name,country_id) in countries"
                            :key="country_id"
                            :value="country_id">{{ name }}</option>
                        </select>
                        <span class="error-block">{{ errors.country }}</span>
                    </div>
                    <!-- city -->
                    <div class="form-row with-label" :class="formRowClass('city')">
                        <label>City</label>
                        <input type="text" name="city" placeholder="Enter Your City" v-model="form_data.city" />
                        <span class="error-block">{{ errors.city }}</span>
                    </div>
					<div class="form-row tsa-note" :class="formRowClass('agree_confirm')" style="margin-top: 20px;width: 100%;text-align: center;">
						<input type="checkbox" v-model="form_data.agree_confirm" />
						I agree to the <a href="https://www.tsanet.org/legal-policy/" target="_blank">TSANet Privacy Policy</a>
						<span class="error-message">You must accept the terms of the privacy policy.</span>
					</div>
                </div>
                <!-- password change -->
                <div v-show="allowPasswordChange" class="uk-width-1-2@m">
                    <!-- last name -->
                    <div class="form-row with-label" :class="formRowClass('password')">
                        <label>Password</label>
                        <input type="password" name="password" placeholder="Enter New Password" v-model="form_data.password" />
                        <span class="error-block">{{ errors.password }}</span>
                    </div>
                    <!-- email -->
                    <div class="form-row with-label">
                        <label>Confirm</label>
                        <input type="password" name="password_confirmation" placeholder="Confirm Password" v-model="form_data.password_confirmation" />
                    </div>
                    <div class="form-row with-label tsa-note small text-left">
                        <label>&nbsp;</label>
                        <div>
                            <PasswordRequirements />
                        </div>
                    </div>
                </div>
            </div>
        </div>

         <div style="padding: 0 40px 20px 40px;" class="clear_after">
            <button class='tsa-btn pull-right ' @click.prevent="updateProfile">Update Profile</button>
        </div>
    </DefaultModal>
</template>

<script>
    // import any required components
    import DefaultModal from '@/components/Layout/DefaultModal.vue';
    import { mapState } from 'vuex';
    import PasswordRequirements from '@/components/Layout/PasswordRequirements.vue';
    import TelephoneField from '@/components/Form/TelephoneField.vue';
    import _ from 'lodash';
    // export component
    export default {
        // properties that the component will receive from parent
        props: {
            opened : {
                type : Boolean,
                default : false
            }
        },
        // components to use in template
        components:{
            DefaultModal,
            PasswordRequirements,
            TelephoneField
        },
        // component data
        data() {
            return {
                form_data: {
                    first_name: "",
                    last_name: "",
                    email: "",
                    phone: "",
                    country_id: "",
                    city: "",
                    phone_country_code: "US",
                    password : "",
                    password_confirmation : "",
					agree_confirm: false,
                },
                errors: {},
            };
        },
        // component was mounted
        mounted(){
			if(this.user.agreement_date !== null && this.user.agreement_date !== ""){
				this.form_data.agree_confirm = true;
			}
        },
        // any methods needed for this component
        methods: {
            onCloseModal(){
                this.$emit('close');
            },
            /**
             * 'form-row' add 'has-error' class if field has error
             * @param  {[type]} field [description]
             * @return {[type]}       [description]
             */
            formRowClass(field){
                let string = "";
                if(this.errors[field] != undefined ) string += " has-error";
                return string;
            },
            /**
             * send request to update profile
             * @return {[type]} [description]
             */
            updateProfile(){
                // clear all errors
                this.errors = {};
                this.emitter.emit('loader',true);
                // eslint-disable-next-line
                axios.post(`${process.env.VUE_APP_API_URL}users/profile`,this.form_data)
                    .then(response => this.handlePostResponse(response.data))
                    // eslint-disable-next-line
                    .catch(error => this.handleErrors(error.response.data.errors));
            },
            /**
             * on profile saved.
             * @param  {[type]} response [description]
             * @return {[type]}          [description]
             */
            handlePostResponse(response){
                this.emitter.emit('loader',false);
                this.$store.dispatch('user',{user: response});
                this.emitter.emit('alert',"Profile Updated");
                this.$emit('close');
            },
            handleErrors (errors){
                this.emitter.emit('loader',false);
                // clear all errors
                this.errors = {};
                for(var e in errors){
                    this.errors[e] = errors[e][0];
                }
            },
            countryChanged(data){
                if(data == null) return;
                if(typeof data === 'string'){
                    this.form_data.phone_country_code = data;
                }else{
                    // object
                    this.form_data.phone_country_code = data.iso2;
                }
            },
            /**
             * when input changes make sure letters cannot be entered
             * remove dial code if it is in place.
             * @param  {[type]} options.number  [description]
             * @param  {[type]} options.isValid [description]
             * @param  {[type]} options.country [description]
             * @return {[type]}                 [description]
             */
            onPhoneChanged(number){
                this.form_data.phone = number;
            },
            loadProfileFormData(){
                // eslint-disable-next-line
                axios.get(`${process.env.VUE_APP_API_URL}users/profile_form_data`)
                .then(response => this.onProfileFormData(response.data))
                // eslint-disable-next-line
                .catch(error => console.log(error));
            },
            /**
             * save form data in store
             * @return {[type]} [description]
             */
            onProfileFormData(response){
                this.$store.dispatch('profileFormData',response);
                this.cloneUser();
            },
            /**
             * clone user into form data.
             * @return {[type]} [description]
             */
            cloneUser(){
                let data = _.clone(this.user);
                if(data.phone == null) data.phone = "";
                if(data.country_id == null || data.country_id == undefined) data.country_id = "";
                data.password = "";
                data.password_confirmation = "";
                if(data.phone_country_code == null) data.phone_country_code = "US";
                this.form_data = data;
				if(this.user.agreement_date !== null && this.user.agreement_date !== ""){
					this.form_data.agree_confirm = true;
				}
            },
        },
        // computed properties
        computed:{
            gridClass(){
                return this.allowPasswordChange ? "uk-width-1-2@m" : "uk-width-expand";
            },
            /**
             * checks if the company uses sso for login.
             * @return {Boolean} [description]
             */
            allowPasswordChange(){
                return this.company.login_method == 'requires_registration' || this.company.login_method == "email_link";
            },
            modalClass() {
                return (this.allowPasswordChange)? "full-width" : "";
            },
            ...mapState({
                user: state => state.user,
                countries : state => state.countries,
                company : state => state.company
            })
        },
        // property watchers
        watch:{
            opened(){
                if(this.opened && this.countries.length == 0){
                    this.loadProfileFormData();
                }
            }
        }
    }
</script>