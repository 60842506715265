<template>
	<div class="document-applies-to">
		<div class="tsa-admin-header">
			<HelpHint flag="inbound_process_static_document" /> <span>Process Preview &mdash;</span> To modify <button class="tsa-btn tsa-btn-secondary" @click.prevent="changeRequest">Contact TSANet</button>
		</div>
		<div class="static-process-document" style="margin-top: 20px" v-html="document.static_document" />
	</div>
</template>
<script>
	import HelpHint from '@/components/Layout/HelpHint.vue';
	import {mapState} from 'vuex';

	export default {
		name: "document-applies-to",
		components: {
			HelpHint
		},
		props:{
		},
		data(){
			return {
				
			}
		},
		methods: {
			changeRequest(){
				this.$emit('change_request', `${this.document.name} Static Process Details`);
			},
		},
		computed: {
			...mapState({
				document : state => state.contact_document
			})
		},
		watch: {
		}
	}
</script>