<template>
	<div class="tsa-footer">
		<div class="uk-text-left">
			<a href="https://www.tsanet.org/tsanet-renews-iso-27001-and-27701-certifications/" class="bsi-logo" target="_blank"><img src="../../assets/images/bsi_logo_2022.png"></a>
			<a class="clearbit" href="https://clearbit.com" target="_blank"><img width="20" height="20" src="https://logo.clearbit.com/clearbit.com" alt="Clearbit Logo">
				Logos provided by Clearbit
			</a>
		</div>
		
		<div>
			<a href="https://www.tsanet.org/wp-content/uploads/2019/02/TSANet-Connect_V6.mp4" target="_blank">How it Works</a>
			<a href="https://www.tsanet.org/legal-policy/" target="_blank">Privacy Policy</a>
			<a href="http://www.tsanet.org" target="_blank">TSANet.org</a>
		</div>
		<a href="https://www.tsanet.org/tsanet-connect-2-0-september-release" class="spring-update" target="_blank"><img src="../../assets/images/icon_fall-2024.png"></a>
		
	</div>
</template>
<script>
	export default {
		name: 'tsa-footer',
		components:{

		}
	}
</script>
<style>
</style>