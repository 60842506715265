<template>
	<span class="tsa-actions">
		<button class="tsa-btn tsa-success" @click.prevent="approveUser">Approve</button>
		<button class="tsa-btn tsa-danger" @click.prevent="rejectUser">Reject</button>
	</span>
</template>

<script>
	import {mapState} from 'vuex';

	export default {
		name: "admin-partner-group-delete-document",
		components:{

		},
		props:{
			cell_data:{
				type: Object,
				default: () => {}
			}
		},
		methods:{
			/**
			 * send request to approve user
			 * @return {[type]} [description]
			 */
			approveUser(){
				this.emitter.emit('loader',true);
				//eslint-disable-next-line
				axios.get(`${process.env.VUE_APP_API_URL}admin/company/registration/${this.cell_data.id}/approve`)
				.then(response => this.handleResponse(response.data))
				//eslint-disable-next-line
				.catch(error => console.log(error));
			},
			/**
			 * send request to reject user.
			 * @return {[type]} [description]
			 */
			rejectUser(){
				this.emitter.emit('loader',true);
				//eslint-disable-next-line
				axios.get(`${process.env.VUE_APP_API_URL}admin/company/registration/${this.cell_data.id}/reject`)
				.then(response => this.handleResponse(response.data))
				//eslint-disable-next-line
				.catch(error => console.log(error));
			},
			handleResponse(response){
				this.emitter.emit('loader',true);
				if(response.success){
					this.emitter.emit('alert',{
						type: "success",
						message: response.message
					});
					this.emitter.emit('reload_queue');
				}else{
					this.emitter.emit('alert',{
						type: "warning",
						message: response.message
					});
				}
			}
		},
		computed: {
			...mapState({
				jwt_token : state => state.token
			})
		}
	}
</script>