<template>
	<div>
		<div class="tsa-section-title">Response: <small class="alert">Collaboration rejected</small></div>
		<div v-if="rejectionLetter != null">
			<div class="tsa-data" >
				<!-- company -->
				<div class="tsa-data-label">Company:</div>
				<div class="tsa-data-value">{{ caseEntry.receiver.name }}</div>
			</div>
			<div class="tsa-data" >
				<!-- submission date -->
				<div class="tsa-data-label">Date:</div>
				<div class="tsa-data-value">{{ dateSubmitted }}</div>
			</div>
			<div class="tsa-data" >
				<!-- name -->
				<div class="tsa-data-label">Name:</div>
				<div class="tsa-data-value">{{ rejectionLetter.engineer_name }}</div>
			</div>
			<div class="tsa-data" >
				<!-- email -->
				<div class="tsa-data-label">Email:</div>
				<div class="tsa-data-value">{{ rejectionLetter.engineer_email }}</div>
			</div>
			<div class="tsa-data" >
				<!-- email -->
				<div class="tsa-data-label">Phone:</div>
				<div class="tsa-data-value">{{ formattedPhone(rejectionLetter.engineer_phone) }}</div>
			</div>
			<div class="tsa-data" >
				<!-- next steps -->
				<div class="tsa-data-label">Reason:</div>
				<limited-copy class="tsa-data-value" :copy="nextSteps" ></limited-copy>
			</div>
		</div>
	</div>
</template>

<script>
import {mapState} from "vuex";
import parsePhoneNumber from 'libphonenumber-js';
import LimitedCopy from "@/components/Form/LimitedCopy.vue";
export default {
	name: "CaseRejected",
	components: {
		LimitedCopy
	},
	props: {
		caseEntry: {
			type: Object,
			default: () => {}
		}
	},
	data() {
		return {
			rejectionLetter: null,
		}
	},
	mounted() {
		this.rejectionLetter = this.findRejection();
	},
	methods: {
		findRejection(){
			if(this.caseEntry.responses){
				for(let i = 0; this.caseEntry.responses.length > i;++i){
					if(this.caseEntry.responses[i].type == "rejection"){
						return this.caseEntry.responses[i];
					}
				}
			}
			return null;
		},
		formattedPhone(phone){
			if(!phone){ return "";}
			let phoneParts = phone.split(":");
			if(phoneParts.length > 1){
				let country = phoneParts[0];
				phoneParts.shift();
				let phNumber = parsePhoneNumber(phoneParts.join(":").trim(), country);
				if(phNumber.isValid()){
					return phNumber.country + ": " + phNumber.formatInternational();
				}
			}
			return phone;
		},
	},
	computed:{
		/**
		 * convert \n to <br>
		 * @return {[type]} [description]
		 */
		nextSteps(){
			if(this.rejectionLetter.next_steps == undefined) return "";
			return this.rejectionLetter.next_steps.nl2br();
		},
		/**
		 * format date and change to local timezone.
		 * @return {[type]} [description]
		 */
		dateSubmitted(){
			if(this.rejectionLetter.created_at == undefined) return "";
			return this.$moment(this.rejectionLetter.created_at)/*.local()*/.format("MM/DD/YY hh:mm a");
		},
		...mapState({
			user: state => state.user,
			company: state => state.company
		})
	}
}
</script>
<style>
.tsa-section-title .alert{
	color: #cc0000;
}
</style>