<template>
	<div class="tsa-box low-padding">
		<div class="tsa-section-title">
			
		</div>
		<div class="clearfix"></div>
		<DataTable
			:loader_on_sort="true"
			:utc_note="true"
			:table_headers="table_headers"
			:table_data="queue"
			:searchable="false" >
			<template v-slot:search>
				<div>Escalation Reporting
					<div class="form-row">
						Start: <input type="date" v-model="reportStart" /> End: <input type="date" v-model="reportEnd" /> <br />
						<ExportButton :export_route="exportReportRoute" />
					</div>
				</div>
			</template>
			<template v-slot:middle>
				<div class="form-row">
					<select v-model="escalation_filter">
						<option value="all-10">View All Cases (Last 10 Days)</option>
						<option value="open">View Open Cases Only</option>
						<option value="late">View Late Cases</option>
					</select>
				</div>
			</template>
		</DataTable>
	</div>
</template>

<script>
	import DataTable from '@/components/Table/DataTable.vue';
	import CaseDetailsCell from '@/components/SuperAdmin/Dashboard/CaseDetailsCell.vue';
	import DeleteCase from '@/components/SuperAdmin/Dashboard/DeleteCaseCell.vue';
	import ExportButton from '@/components/SuperAdmin/ExportButton.vue';
	export default {
		name : "super-admin-escalation-reporting",
		components: {
			DataTable,
			ExportButton
		},
		props: {
			queue: {
				type: Array,
				default: () => []
			}
		},
		data(){
			return {
				table_headers: {
					'internal_case_number': {
						display: "Case #"
					},
					'company.name': {
						display: "From Partner"
					},
					'receiver.name': {
						display: "To Partner"
					},
					'priority_level': {
						display: "Priority",
						render: cell_data => {
							let priority = "";
							switch(cell_data.priority){
								case "low":
									priority = "Priority 3";
								break;
								case "medium":
									priority = "Priority 2";
								break;
								case "high":
									priority = "Priority 1";
								break;
							}
							return priority;
						}
					},
					'created_at': {
						format: "date_time",
						default_sort : "desc"
					},
					'document.name': {
						display: "Document Name",
						route: "/super_admin/companies/{document.company_id}/documents/{document.id}/edit/{document.version.document_type}"
					},
					'status': {
						render: cell_data => {
							if(cell_data.status === "accepted"){
								return "Closed";
							}
							if(cell_data.status !== null){
								let status = cell_data.status;
								return status.charAt(0).toUpperCase() + status.slice(1);
							}
							return (!cell_data.responded)? "Open" : "Closed";
						}
					},
					'details': {
						component: CaseDetailsCell
					},
					'': {
						display: "&nbsp;",
						searchable: false,
						component: DeleteCase
					}
				},
        reportStart:null,
        reportEnd:null
			}
		},
		mounted(){
			// set listener to remove user.
			this.emitter.on('sa_confirm_delete_case',user_id => this.deleteCase(user_id)); 
		},
		/**
		 * remove bus event listeners before component is destroyed.
		 * @return {[type]} [description]
		 */
		beforeUnmount(){
			this.emitter.off('sa_confirm_delete_case');
		},
		methods: {
			/**
			 * send request to delete user
			 * @return {[type]} [description]
			 */
			deleteCase(case_id){
				this.emitter.emit('loader',true);
				const url = `${process.env.VUE_APP_API_URL}super_admin/escalation/${case_id}/delete`;
				//eslint-disable-next-line
				axios.get(url)
				.then(response => this.handleDeleteCase(response.data))
				//eslint-disable-next-line
				.catch(error => console.log(error));
			},
			/**
			 * if error alert error
			 * otherwise success message
			 * @return {[type]} [description]
			 */
			handleDeleteCase(response){
				this.emitter.emit('loader',false);
				if(response.error != undefined){
					this.emitter.emit('alert',{message: response.error, type: "warning"});
				}else{
					this.emitter.emit('alert',response.message);
					this.$emit("reload");
				}
			}
		},
		computed: {
			exportReportRoute() {
				if(this.reportStart && this.reportEnd){
					return `${process.env.VUE_APP_API_URL}super_admin/escalation/report/${this.escalation_filter}/${this.reportStart}/${this.reportEnd}`; 
				}
				else if(this.reportStart){
					return `${process.env.VUE_APP_API_URL}super_admin/escalation/report/${this.escalation_filter}/${this.reportStart}`;
				} else if(this.reportEnd){
					return `${process.env.VUE_APP_API_URL}super_admin/escalation/report/${this.escalation_filter}/0/${this.reportEnd}`;
				}
				return `${process.env.VUE_APP_API_URL}super_admin/escalation/report/${this.escalation_filter}`;
			},
			escalation_filter: {
				get(){ return this.$store.state.escalation_filter; },
				set (newValue){
					return this.$store.commit('setEscalationFilter',newValue);
				}
			}
		},
	}
</script>