<template>
	<div class="tsa-verify-account">
		<h1>{{ pageTitle }}</h1>
		<!-- account verified -->
		<div>
			<div class="verify-message" v-html="approval_message"></div>
		</div>
		<div class="uk-child-width-1-2@m uk-grid-small" uk-grid v-show="action == 'reject' && allow_form">
			<div>
				<div class="tsa-section-title">Please provide a reason as to why you are rejecting this account.</div>
				<div class="form-row wysiwyg" :class="formRowClass('note')">
					<wysiwyg-editor v-model="form_data.note"></wysiwyg-editor>
					<span class="error-block">{{ errors.note }}</span>
				</div>
				<div class="form-row">
					<button class="tsa-btn pull-right" @click.prevent="approvalRequest">Reject User</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import WysiwygEditor from "@/components/Form/WysiwygEditor.vue";
	export default {
		name: "account-approval",
		components: {
			WysiwygEditor
		},
		data(){
			return {
				page_title: null,
				approval_message: "",
				action: "",
				form_data: {
					note: ""
				},
				allow_form: true,
				errors: {},
			}
		},
		mounted(){
			this.action = this.$route.params.action;
			// send request if needs to approve
			if(this.action == 'approve'){
				this.approvalRequest();
			}
		},
		methods: {
			/**
			 * send request to approve/reject user account.
			 * @return {[type]} [description]
			 */
			approvalRequest(){
				this.emitter.emit('loader', true);
				//eslint-disable-next-line
				axios.post(`${process.env.VUE_APP_API_URL}users/approval/${this.$route.params.token}/${this.$route.params.action}`,this.form_data)
					.then(response => this.handleApprovalRequest(response.data))
					//eslint-disable-next-line
				    .catch(error => this.handleErrors(error.response.data.errors));
			},
			handleApprovalRequest(response){
				this.emitter.emit('loader', false);
				if(response.success){
					if(response.approved){
						this.page_title = `${response.user.first_name} ${response.user.last_name} has been approved.`;
					}else{
						this.page_title = `${response.user.first_name} ${response.user.last_name} has been rejected.`;
						this.allow_form = false;
					}
				}
				this.approval_message = response.message;
			},
			/**
			 * 'form-row' add 'has-error' class if field has error
			 * @param  {[type]} field [description]
			 * @return {[type]}       [description]
			 */
			formRowClass(field){
				let string = "form-row";
				if(this.errors[field] != undefined ) string += " has-error";
				return string;
			},
			/**
			* display validation errors
			* @param  {[type]} errors [description]
			* @return {[type]}        [description]
			*/
			handleErrors (errors){
				// hide loader
				this.emitter.emit('loader',false);
				// clear all errors
				this.errors = {};
				for(var e in errors){
					this.errors[e] = errors[e][0];
				}
			},
		},
		computed: {
			pageTitle(){
				if(this.page_title != null) return this.page_title;
				return (this.action == "approve")? "Approve User Account" : "Reject User Account";
			}
		}
	}
</script>