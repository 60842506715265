import { createRouter, createWebHashHistory } from "vue-router";

import Home from './views/Home.vue';
import Dashboard from './views/Dashboard.vue';
import SubmitCase from './views/SubmitCase.vue';
import Profile from './views/Profile.vue';
import AutoLogin from './views/AutoLogin.vue';
import CaseResponse from './views/CaseResponse.vue';
import ResetPassword from './views/ResetPassword.vue';
import VefifyAccount from './views/VerifyAccount.vue';
import MyCases from './views/MyCases.vue';
import CaseInfo from './views/CaseInfo.vue';

import AccountApproval from './views/AccountApproval.vue';

import AdminCompany from './views/admin/Company.vue';
import AdminCompanyRegistration from './views/admin/CompanyRegistration.vue';
import AdminUsers from './views/admin/Users.vue';
import AdminInboundProcess from './views/admin/InboundProcess.vue';
import AdminInboundProcessTest from './views/admin/AdminInboundProcessTest.vue';
import AdminPartnerGroups from './views/admin/PartnerGroups.vue';
import AdminReports from './views/admin/Reports.vue';
import AdminCaseInfo from './views/admin/CaseInfo.vue';
import AdminManageUser from './views/admin/AdminManageUser.vue';
import ManageProcessDocument from './views/admin/ManageProcessDocument.vue';
import ManagePartnerGroup from './views/admin/ManagePartnerGroup.vue';
import PartnerGroupUserAccess from './views/admin/PartnerGroupUserAccess.vue';
import AdminLimitedProcess from './views/admin/LimitedProcess.vue';
import AdminSsoSetup from './views/admin/SsoSetup.vue';

import GroupOnboardingContact from './views/partner_groups/GroupOnboardingContact.vue';
import GroupOnboardingAgreements from './views/partner_groups/GroupOnboardingAgreements.vue';
import GroupOnboardingProcessDocument from './views/partner_groups/GroupOnboardingProcessDocument.vue';
import GroupOnboardingChooseProcess from './views/partner_groups/GroupOnboardingChooseProcess.vue';
import GroupOnboardingSuccess from './views/partner_groups/GroupOnboardingSuccess.vue';
import GroupOnboardingRestore from './views/partner_groups/GroupOnboardingRestore.vue';
import GroupOnboardingBasicPremium from './views/partner_groups/GroupOnboardingBasicPremium.vue';

/* SUPER ADMIN VIEWS */
import SuperAdminCompanies from './views/super_admin/Companies.vue';
import SuperAdminCompanyRegistration from './views/super_admin/CompanyRegistration.vue';
import SuperAdminGroups from './views/super_admin/Groups.vue';
import SuperAdminDashboard from './views/super_admin/Dashboard.vue';
import SuperAdminCompanyDetails from './views/super_admin/CompanyDetails.vue';
import SuperAdminManageCompany from './views/super_admin/ManageCompany.vue';
import SuperAdminGroupDetails from './views/super_admin/GroupDetails.vue';
import SuperAdminManageGroup from './views/super_admin/ManageGroup.vue';
import SuperAdminDocument from './views/super_admin/Document.vue';
//import SuperAdminManageDocument from './views/super_admin/ManageDocument.vue';
import SuperAdminManageDepartments from './views/super_admin/ManageDepartments.vue';
import SuperAdminManageCompanyCommunity from './views/super_admin/ManageCompanyCommunity.vue';
import SuperAdminManageUser from './views/super_admin/ManageUser.vue';
import SuperAdminCaseInfo from './views/super_admin/CaseInfo.vue';
import SuperAdminReports from './views/super_admin/Reports.vue';
import SuperAdminListUsers from './views/super_admin/ListUsers.vue';
import SuperAdminInboundProcessTest from './views/super_admin/SuperAdminInboundProcessTest.vue';

import SuperAdminOnboardingSession from './views/super_admin/OnboardingSession.vue';

import CaseProvideInformation from "./views/CaseProvideInformation";
import CaseInfoBase from "./views/CaseInfoBase";

import MSTeamsConnected from './views/msteams/Connected.vue';

import PageNotFound from './views/PageNotFound.vue';

import ManagerResponse from "./components/Manager/ManagerResponse";

import Logout from './views/Logout.vue';

// middleware
import super_admin from './middleware/super_admin';
import auth from './middleware/auth';
import admin from './middleware/admin';
import caller from './middleware/caller';
import not_limited from './middleware/not_limited';

const routes = [
  {
    path: '/auth/logout',
    name: 'auth.logout',
    component: Logout
  },
  /**
   * ADMIN ROUTES
   */
  {
    path: '/admin/company',
    name: 'admin.company',
    component: AdminCompany,
    meta: {
      middleware: [auth,admin,not_limited]
    }
  },
  {
    path: '/admin/company/registration',
    name: 'admin.company.registration',
    component: AdminCompanyRegistration,
    meta: {
      middleware: [auth,admin,not_limited]
    }
  },
  {
    path: '/admin/company/sso',
    name: 'admin.company.sso',
    component: AdminSsoSetup,
    meta: {
      middleware: [auth,admin,not_limited]
    }
  },
  {
    path: '/admin/users',
    name: 'admin.users',
    component: AdminUsers,
    meta: {
      middleware: [auth,admin]
    }
  },
  {
    path: '/admin/users/:id',
    name: 'admin.user.manage',
    component: AdminManageUser,
    meta: {
      middleware: [auth,admin]
    }
  },
  {
    path: '/admin/inbound_process',
    name: 'admin.inbound_process',
    component: AdminInboundProcess,
    meta: {
      middleware: [auth,admin,not_limited]
    }
  },
  {
    path: '/admin/inbound_process/:id/test',
    name: 'admin.inbound_process.test',
    component: AdminInboundProcessTest,
    meta: {
      middleware: [auth,admin]
    }
  },
  {
    path: '/admin/limited_process',
    name: 'admin.limited_process',
    component: AdminLimitedProcess,
    meta: {
      middleware: [auth,admin,]
    }
  },
  {
    path: '/admin/partner_groups',
    name: 'admin.partner_groups',
    component: AdminPartnerGroups,
    meta: {
      middleware: [auth,admin,not_limited]
    }
  },
  {
    path: '/admin/partner_groups/:group_id',
    name: 'admin.partner_groups.manage',
    component: ManagePartnerGroup,
    meta: {
      middleware: [auth,admin,not_limited]
    }
  },
  {
    path: '/admin/partner_groups/:group_id/users',
    name: 'admin.partner_groups.users',
    component: PartnerGroupUserAccess,
    meta: {
      middleware: [auth,admin,not_limited]
    }
  },
  {
    path: '/admin/reports',
    name: 'admin.reports',
    component: AdminReports,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: "/admin/case/:token",
    'name': "admin.case-info",
    'component': AdminCaseInfo,
    props: true,
    meta: {
      middleware: [auth]
    }
  },
  {
    path: '/admin/inbound_process/:document_id',
    name: 'admin.inbound_process.manage',
    component: ManageProcessDocument,
    meta: {
      middleware: [auth,admin]
    }
  },
  /**
   * FRONT END ROUTES
   */
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      middleware: [auth,caller]
    }
  },
  {
    path: '/case/:token/respond',
    name: 'case-response',
    component: CaseResponse,
    /*meta: {
      middleware: [auth,caller]
    }*/
  },
  {
    path: '/case/:token/information',
    name: 'case-provide-information',
    component: CaseProvideInformation,
    /*meta: {
      middleware: [auth,caller]
    }*/
  },
  {
      path: "/case/:token/response",
      'name': "case-info-base-response",
      'component': CaseInfoBase,
      /*meta: {
          middleware: [auth]
      }*/
  },
  {
    path: '/case/:type/:entity_id',
    name: 'submit-case',
    component: SubmitCase,
    meta: {
      middleware: [auth,caller]
    }
  },
  {
    path: '/profile',
    name: "profile",
    component: Profile,
    meta: {
      middleware: auth
    }
  },
  {
    path: '/auto_login/:token',
    name: "auto_login",
    component: AutoLogin,
    /*meta: {
      middleware: [caller]
    }*/
  },
  {
    path: "/my-cases",
    'name': "cases",
    'component': MyCases,
    meta: {
      middleware: [auth,caller]
    }
  },
  {
    path: "/my-cases/:token",
    'name': "case-info",
    'component': CaseInfo,
    meta: {
      middleware: [auth,caller]
    }
  },
  {
    path: '/reset_password/:token',
    'name' : 'reset_password',
    'component' : ResetPassword
  },
  {
    path: "/verify_account/:token",
    'component' : VefifyAccount
  },
  {
    path: "/users/approval/:token/:action",
    name: 'account_approval',
    component: AccountApproval,
  },
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/onboarding/contact',
    name: 'onboarding.contact',
    component: GroupOnboardingContact,
  },
  {
    path: '/onboarding/agreements',
    name: 'onboarding.agreements',
    component: GroupOnboardingAgreements,
  },
  {
    path: '/onboarding/process',
    name: 'onboarding.process',
    component: GroupOnboardingProcessDocument,
  },
  {
    path: '/onboarding/choose-process',
    name: 'onboarding.choose-process',
    component: GroupOnboardingChooseProcess
  },
  {
    path: '/onboarding/basic-premium',
    name: 'onboarding.basic-premium',
    component: GroupOnboardingBasicPremium
  },
  {
    path: '/onboarding/success',
    name: 'onboarding.success',
    component: GroupOnboardingSuccess,
  },
  {
    path: '/onboarding/restore/:token',
    name: 'onboarding.restore',
    component: GroupOnboardingRestore
  },
  {
    path: '/manager/response/:token',
    name: "manager.response",
    component: ManagerResponse,
    /*meta: {
      middleware: [auth, manager]
    }*/
  },
  /* SUPER ADMIN ROUTES */
  {
    path: '/super_admin/dashboard',
    name: "super_admin.dashboard",
    component: SuperAdminDashboard,
    meta: {
      middleware: [auth,super_admin]
    }
  },
  {
    path: '/super_admin/companies',
    name: 'super_admin.companies',
    component: SuperAdminCompanies,
    meta: {
      middleware: [auth,super_admin]
    }
  },
  {
    path: '/super_admin/companies/:company_id',
    name: "super_admin.company.detail",
    component: SuperAdminCompanyDetails,
    meta: {
      middleware: [auth,super_admin]
    }
  },
  {
    path: '/super_admin/companies/:company_id/registation',
    name: "super_admin.company.registration",
    component: SuperAdminCompanyRegistration,
    meta: {
      middleware: [auth,super_admin]
    }
  },
  {
    path: "/super_admin/companies/:company_id/edit",
    name: 'super_admin.company.edit',
    component: SuperAdminManageCompany,
    meta: {
      middleware: [auth,super_admin]
    }
  },
  {
    path: "/super_admin/companies/:company_id/departments",
    name: 'super_admin.company.departments',
    component: SuperAdminManageDepartments,
    meta: {
      middleware: [auth,super_admin]
    }
  },
  {
    path: '/super_admin/companies/:company_id/users/:user_id',
    name: "super_admin.user.edit",
    component: SuperAdminManageUser,
    meta: {
      middleware: [auth,super_admin]
    }
  },
  {
    path: "/super_admin/companies/:company_id/documents/:document_id/edit/:type",
    name: 'super_admin.document.edit',
    component: SuperAdminDocument,
    meta: {
      middleware: [auth,super_admin]
    }
  },
  {
    path: "/super_admin/companies/:company_id/groups/:relation_id/edit",
    name: 'super_admin.company.groups.edit',
    component: SuperAdminManageCompanyCommunity,
    meta: {
      middleware: [auth,super_admin]
    }
  },
  {
    path: '/super_admin/groups',
    name: 'super_admin.groups',
    'component': SuperAdminGroups,
    meta: {
      middleware: [auth,super_admin]
    }
  },
  {
    path: "/super_admin/groups/:group_id/edit",
    name: 'super_admin.group.edit',
    component: SuperAdminManageGroup,
    meta: {
      middleware: [auth,super_admin]
    }
  },
  {
    path: '/super_admin/groups/:group_id/:group_type',
    name: "super_admin.group.detail",
    component: SuperAdminGroupDetails,
    meta: {
      middleware: [auth,super_admin]
    }
  },
  {
    path: '/super_admin/onboarding/:session_id',
    name: "super_admin.onboarding.session",
    component: SuperAdminOnboardingSession,
    meta: {
      middleware: [auth,super_admin]
    }
  },
  {
    path: '/super_admin/cases/:case_id',
    name: "super_admin.cases.case_info",
    component: SuperAdminCaseInfo,
    meta: {
      middleware: [auth,super_admin]
    }
  },
  {
    path: '/super_admin/reports',
    name: "super_admin.reports",
    component: SuperAdminReports,
    meta: {
      middleware: [auth,super_admin]
    }
  },
  {
    path: '/super_admin/companies/:company_id/inbound_process/:id/test',
    name: 'super_admin.inbound_process.test',
    component: SuperAdminInboundProcessTest,
    meta: {
      middleware: [auth,super_admin]
    }
  },
  {
    path: '/super_admin/users',
    name: "super_admin.list_users",
    component: SuperAdminListUsers,
    meta: {
      middleware: [auth,super_admin]
    }
  },
  {
    path: '/super_admin/user/:user_id',
    name: 'super_admin.user.manage',
    component: SuperAdminManageUser,
    meta: {
      middleware: [auth,super_admin]
    }
  },
  {
    path : '/msteams/connected/:token',
    'name' : 'msteams.connected',
    'component': MSTeamsConnected
  },
  {
    path : '/404',
    name : "404",
    component: PageNotFound
  },
  // all routes before these line
  {
    path: '/:slug',
    name: 'home-with-slug',
    component: Home
  },
  {
    path: "/:catchAll(.*)",
    name : "page-not-found",
    redirect: '/404'
  },
];

const router = createRouter({
  routes,
  history: createWebHashHistory()
});

// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  }

  return next();
});



export default router;