<template>
	<div class="tsa-applies-to">
		<div class="tsa-admin-header"><span class="page-name">Applies To</span></div>
		<div>
			<p><strong>Note:</strong> This shows what groups and departments this process document applies to.</p>
			<DataTable 
				:table_headers="table_headers"
				:table_data="table_data"
				:searchable="false" />
		</div>
	</div>
</template>

<script>
	import DataTable from '@/components/Table/DataTable.vue';
	import EditRelationCell from '@/components/ContactDocuments/EditRelationCell.vue';
	import _ from 'lodash';
	export default {
		name: 'document-applies-to',
		components: {
			DataTable
		},
		props:{
			document: {
				type: Object,
				default: () => {}
			},
		},
		mounted(){
			if(this.document.group_relations != undefined){
				this.cloneTableData();
			}
		},
		data(){
			return {
				table_data: [],
				table_headers: {
					'community.name': {
						display: "Group"
					},
					'department.name': {
						display: "Department",
						render: cell_data => {
							if(cell_data.department == null){
								return "N/A";
							}else{
								return cell_data.department.name;
							}
						}
					},
					'actions': {
						display: '',
						component: EditRelationCell
					}
				}
			}
		},
		methods: {
			cloneTableData(){
				this.table_data = _.cloneDeep(this.document.group_relations);
			}
		},
		watch: {
			document() {
				this.cloneTableData();
			}
		}
	}
</script>