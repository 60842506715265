<template>
	<span>
		<a href="" @click.prevent="downloadDocument" v-if="cell_data.document_type == 'file'"> <i class="fas fa-download"></i> {{ cell_data.name }}</a>
		<a :href="cell_data.url" target="_blank" v-if="cell_data.document_type == 'url'"><i class="fas fa-link"></i> {{ cell_data.name }}</a>
	</span>
</template>

<script>
	export default {
		name: 'download-document',
		components: {

		},
		props:{
			cell_data:{
				type: Object,
				default: () => {}
			}
		},
		methods: {
			/**
			 * send request to export usage to spreadsheet.
			 * @return {[type]} [description]
			 */
			downloadDocument(){
				this.emitter.emit('loader',true);
				//eslint-disable-next-line
				axios.get(`${process.env.VUE_APP_API_URL}super_admin/groups/documents/${this.cell_data.id}/download`,{
					'responseType': 'blob'
				})
				.then(response => this.handleDownload(response.data))
				//eslint-disable-next-line
				.catch(error => console.log(error));
			},
			/**
			 * create temp link to download response.
			 * @param  {[type]} response [description]
			 * @return {[type]}          [description]
			 */
			handleDownload(response){
				if(response.error != undefined){
					this.emitter.emit('alert',{
						type: "error",
						message: response.error
					});
				}
				this.emitter.emit('loader',false);
				const url = window.URL.createObjectURL(response);
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', this.cell_data.original_filename);
				document.body.appendChild(link);
				link.click();
				link.remove();
			}
		}
	}
</script>