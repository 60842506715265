<template>
	<div class="tsa-login-register">
		<ForgotPassword
			@close="closeResetPassword"
			:is_open="forgot_password.open" />
		<div class="uk-child-width-1-2@m uk-grid-small uk-flex-center" uk-grid>
			<div v-if="app_slug != null">
				<Login
					@forgot="showForgotPassword"
				/>
				<Managers v-if="showManagers" />
				<div class="tsa-managers" v-if="showLoginPageText">
					<p v-html="company.login_page_text" />
				</div>
			</div>
			<div v-if="app_slug != null">
				<div class="tsa-box">
					<RegisterForm />
				</div>
			</div>
			<div v-else>
				<LandingPageSearch />
			</div>
		</div>
	</div>
</template>

<script>

	// import components
	import Login from '@/components/Home/Login.vue';
	import RegisterForm from '@/components/Home/RegisterForm.vue';
	import Managers from '@/components/Home/Managers.vue';
	import ForgotPassword from '@/components/Home/ForgotPassword.vue';
	import LandingPageSearch from '@/components/Home/LandingPageSearch.vue';

	import {mapState} from 'vuex';

	export default {
		name: 'login-register',
		components: {
			Login,
			RegisterForm,
			Managers,
			ForgotPassword,
			LandingPageSearch
		},
		data(){
			return {
				forgot_password: {
					open: false
				}
			}
		},
		methods:{
			/**
			 * close forgot password window.
			 * @return {[type]} [description]
			 */
			closeResetPassword(){
				this.forgot_password.open = false;
			},
			/**
			 * show forgot password window
			 * @return {[type]} [description]
			 */
			showForgotPassword(){
				this.forgot_password.open = true;
			}
		},
		computed: {
			/**
			 * check if it should show list of managers.
			 * @return {[type]} [description]
			 */
			showManagers(){
				if(this.showLoginPageText) return false;
				return this.manager != null;
			},
			/**
			 * check if company has login page text.
			 * @return {[type]} [description]
			 */
			showLoginPageText(){
				if(this.company.login_page_text != undefined){
					if(this.company.login_page_text != null){
						return this.company.login_page_text.length > 0;
					}
				}
				return false;
			},
			...mapState({
				manager : state => state.manager,
				app_slug: state => state.app_slug,
				company: state => state.company
			})
		}
	}
</script>