<template>
	<div class="tsa-admin tsa-manage-departments">
		<Breadcrumb :routes="breadcrumb" />
		<div class="tsa-admin-header">
			{{company.name}} <span class="page-name">&mdash; Departments</span>
			<button class="tsa-btn" @click="addDepartment">Add New</button>
		</div>
		<div class="tsa-department-list">
			<div class="tsa-department-row">
				<div class="tsa-heading">Name</div>
				<div class="tsa-heading">Description</div>
				<div class="tsa-heading">Group</div>
				<div class="tsa-heading">Process</div>
				<div class="tsa-heading">Status</div>
				<div>&nbsp;</div>
			</div>
			<DepartmentRow
				v-for="(department,index) in departments"
				:key="index"
				:index="index"
				:department="department"
				:contact_documents="contact_documents"
				:groups="groups"
				@delete="removeRow"
				@save="onDepartmentSaved" />
		</div>
	</div>
</template>

<script>
	import DepartmentRow from '@/components/SuperAdmin/Departments/DepartmentRow.vue';
	import {mapState} from 'vuex';
	import Breadcrumb from '@/components/Layout/Breadcrumb.vue';
	export default {
		name : 'manage-departments-view',
		components: {
			DepartmentRow,
			Breadcrumb
		},
		data() {
			return {
				company:{},
				contact_documents: [],
				groups: [],
				departments: [
					{},
				],
				is_initialized: false
			}
		},
		mounted(){
			this.initialized();
		},
		updated(){
			this.initialized();
		},
		/**
		* remove bus event listeners before component is destroyed.
		* @return {[type]} [description]
		*/
		beforeUnmount(){
			this.emitter.off('token_ready');
		},
		methods: {
			initialized(){
				if(this.is_initialized) return;
				this.is_initialized = true;
				this.emitter.on('token_ready', () => this.getViewData());
				if(this.jwt_token.access_token != undefined) this.getViewData();
			},
			/**
			 * load company information
			 * @return {[type]} [description]
			 */
			getViewData(){
				this.emitter.emit('loader',true);
				//eslint-disable-next-line
				axios.all([
					//eslint-disable-next-line
					axios.get(`${process.env.VUE_APP_API_URL}super_admin/companies/${this.$route.params.company_id}`),
					//eslint-disable-next-line
					axios.get(`${process.env.VUE_APP_API_URL}super_admin/companies/${this.$route.params.company_id}/contact_documents/all`),
					//eslint-disable-next-line
					axios.get(`${process.env.VUE_APP_API_URL}super_admin/companies/${this.$route.params.company_id}/departments`),
					//eslint-disable-next-line
					axios.get(`${process.env.VUE_APP_API_URL}super_admin/groups`)
				])
				//eslint-disable-next-line
				.then(axios.spread((company_response, documents_response, departments_response, groups_response ) => {
					this.company = company_response.data.company;
					this.contact_documents = documents_response.data;
					this.departments = departments_response.data;
					this.groups = groups_response.data;
					// add blank one if no departments.
					if(this.departments.length == 0) this.addDepartment();

					this.emitter.emit('loader',false);
				}))
				//eslint-disable-next-line
				.catch(error => console.log(error));
			},
			/**
			 * add empty row.
			 */
			addDepartment(){
				this.departments.push({});
			},
			/**
			 * listener for when a row send a request to be removed.
			 * @return {[type]} [description]
			 */
			removeRow(index){
				this.departments.splice(index,1);
			},
			/**
			 * replace object in correct index
			 * @param  {[type]} data [description]
			 * @return {[type]}      [description]
			 */
			onDepartmentSaved(data){
				this.departments[data.index] = data.department;
			}
		},
		computed: {
			breadcrumb(){
				return [
					{display: "Companies" ,route: {name: "super_admin.companies"}},
					{display: this.company.name, route: {name: "super_admin.company.detail",params: {company_id : this.company.id}}},
					"Departments"
				];
			},
			...mapState({
				jwt_token: state => state.token
			})
		}
	}
</script>