<template>
	<div class="tsa-request-sent">
		<div class="tsa-content-box">
			<div class="tsa-section-title">Request Details</div>
            <div class="request-copy email-copy" v-html="case_details"></div>
            <div v-if="hasEscalation" class="tsa-section-title">Escalation Information: <span>What to do if you do not get a response and need to escalate</span></div>
            <div v-if="hasEscalation" class="request-copy" v-html="document.version.escalation_instructions">
            </div>
		</div>
	</div>
</template>
<script>
	import {mapState} from 'vuex';
	import { copyText } from 'vue3-clipboard';
	export default {
		name: "request-sent",
		components: {

		},
		data(){
			return {
				copy_text : ""
			}
		},
		methods:{
			addTextToClipboard(){
				copyText(this.case_details_plain);
			},
			/**
			 * content copied successfully
			 * @return {[type]} [description]
			 */
			onCopySuccess(){

			},
			/**
			 * content copy error
			 * @return {[type]} [description]
			 */
			onCopyError(){

			}
		},
		computed:{
			hasEscalation(){
				return this.document && this.document.version &&
					this.document.version.escalation_instructions &&
					typeof this.document.version.escalation_instructions !== "undefined" &&
					this.document.version.escalation_instructions !== "";
			},
			...mapState({
				document: state => state.contact_document,
				case_details: state => state.case_details,
				case_details_plain: state =>state.case_details_plain
			})
		}
	}
</script>