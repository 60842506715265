<template>
	<div class="tsa-login">
		<div class="tsa-section-title">Login</div>
		<form @submit.prevent="login">
			<div class="uk-child-width-1-2@m uk-grid-small" uk-grid>
				<div>
					<div :class="formRowClass('email')">
						<input type="email" v-model="form_data.email" placeholder="Enter your Email" />
						<span class="error-block">{{ errors.email }}</span>
					</div>
					<div :class="formRowClass('password')">
						<input type="password" v-model="form_data.password" placeholder="Enter your Password" />
						<span class="error-block">{{ errors.password }}</span>
					</div>
				</div>
				<div>
					<div class="form-row">
						<button @click.prevent="login" class="tsa-btn">Login</button>
					</div>
					<div class='form-row'>
						<a href="" @click.prevent="forgotPassword">forgot password?</a>
					</div>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
	import {mapState} from 'vuex';
	export default {
		name: "login",
		components: {

		},
		data(){
			return {
				errors: {},
				form_data:{
					email: "",
					password: ""
				}
			}
		},
		methods:{
			login(){
				this.emitter.emit('loader',true);
				//eslint-disable-next-line
				axios.post(`${process.env.VUE_APP_API_URL}auth/login`,this.form_data)
					.then(response => this.handleLogin(response.data))
					//eslint-disable-next-line
				    .catch(error => this.handleErrors(error.response.data.errors));
			},
			handleLogin(response){
				this.emitter.emit('loader',false);
				// has error
				if(response.error != undefined){
					this.emitter.emit('alert',{message: response.error, type: "warning"});
				}else{
					this.$store.dispatch('token',response);
					this.emitter.emit('get_user');
					//setTimeout(() => this.redirectToDashboard(),0);
				}
			},
			/**
			 * hide loader and add errors to erros array so they're displayed
			 * @param  {[type]} errors [description]
			 * @return {[type]}        [description]
			 */
			handleErrors (errors){
				this.emitter.emit('loader',false);
				// clear all errors
				this.errors = {};
				for(var e in errors){
					this.errors[e] = errors[e][0];
				}
			},
			/**
			 * 'form-row' add 'has-error' class if field has error
			 * @param  {[type]} field [description]
			 * @return {[type]}       [description]
			 */
			formRowClass(field){
				let string = "form-row";
				if(this.errors[field] != undefined ) string += " has-error";
				return string;
			},
			forgotPassword(){
				this.$emit('forgot');
			}
		},
		computed:{
			...mapState({
				user: state => state.user
			})
		}
	}
</script>