<template>
	<div v-if="requestData && requestData.submitting_company">
		<div class="tsa-section-title" style="margin-top: 30px;">Request Information:</div>
		<div class="request-details">
			<div>
				<div class="tsa-data">
					<!-- company -->
					<div class="tsa-data-label">Company:</div>
					<div class="tsa-data-value">{{ requestData.submitting_company.name }}</div>
				</div>
				<div class="tsa-data">
					<!-- case number -->
					<div class="tsa-data-label">{{ requestData.submitting_company.name }} Reference:</div>
					<div class="tsa-data-value">{{ requestData.internal_reference }}</div>
				</div>
				<div v-if="requestData.vendor_reference" class="tsa-data">
					<!-- case number -->
					<div class="tsa-data-label">Case # with {{ requestData.receiving_company.name }}:</div>
					<div class="tsa-data-value">{{ requestData.vendor_reference }}</div>
				</div>
				<div class="tsa-data">
					<div class="tsa-data-label">Date:</div>
					<div class="tsa-data-value">{{ dateSubmitted }}</div>
				</div>
			</div>
			<div>
				<div class="tsa-data">
					<!-- submitted by -->
					<div class="tsa-data-label">Submitted by:</div>
					<div class="tsa-data-value">
						{{ requestData.first_name }} {{ requestData.last_name }}<br>
						<a :href="`mailto:${requestData.email}`">{{ requestData.email }}</a>
					</div>
				</div>
				<div class="tsa-data">
					<!-- business impact -->
					<div class="tsa-data-label">Business Impact:</div>
					<div class="tsa-data-value" v-html="businessImpact"></div>
				</div>
				<div class="tsa-data">
					<!-- summary -->
					<div class="tsa-data-label">Description:</div>
					<div class="tsa-data-value" v-html="responseNote"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "RequestDetails",
	components: {},
	props: {
		requestData:{
			type: Object,
			default: null
		}
	},
	data() {
		return {

		};
	},
	mounted() {
	},
	methods: {

	},
	computed:{

		dateSubmitted(){
			if(this.requestData.created_at == undefined) return "";
			return this.$moment(this.requestData.created_at).format("MM/DD/YY hh:mm a");
		},
		responseNote(){
			if(this.requestData.notes == undefined) return "";
			return this.requestData.notes.nl2br();
		},
		businessImpact(){
			if(this.requestData.business_impact == undefined) return "";
			return this.requestData.business_impact.nl2br();
		}
	}
}
</script>