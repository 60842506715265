<template>
    <div :class="containerClass">
        <input 
            type="text"
            :value="value" 
            @input="emitChange" 
            ref='textfield'
            :disabled="disabled"
            :placeholder="placeholder"
        />
        <span class="error-block"><strong>{{ error }}</strong></span>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    export default {
        name: "TSANetUrl",
        props: {
            requirement: {
                type: Object,
                default: () => {}
            },
            value: {
                type: String,
                default: ""
            },
            error: {
                type: String,
                default: ""
            },
            disabled: {
                type: Boolean,
                default: false
            }
        },
        data(){
            return {}
        },
        methods: {
        },
        computed: {
            placeholder(){
                let label = this.requirement.requirement.label.replace('{{Send_Member}}',this.company.name);
                let ph = `Enter ${label}`;
                return ph;
            },
            containerClass(){
                let string = `form-group-${this.requirement.group} form-row tsa-custom-text`;
                if(this.error.length > 0) string += ' has-error';
                if(this.requirement.group != null){
                    if(this.document_active_groups[this.requirement.group] === false){
                        string += " hide";
                    }
                }
                return string;
            },
            ...mapState({
                document_active_groups: state => state.document_active_groups,
                // the company of the user that is logged in.
                company : state => state.company
            })
        },
        watch:{
            emitChange(){
                this.$emit('change',{
                    requirement_id : this.requirement.requirement_id,
                    data: this.$refs.textfield.value
                });
            },
        }
    }
</script>