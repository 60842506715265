<template>
	<div>
		<div class="tsa-admin-header" style="margin-top:20px;">
			<span class="page-name">Internal Notes ({{ notes.length }})</span>
		</div>
		<div class="tsa-document-list" style="margin-top: 20px">
			<DataTable
				:searchable="false"
				:table_headers="table_headers"
				:row_class="rowClass"
				:table_data="notes" />
		</div>
	</div>
</template>

<script>
	import DataTable from '@/components/Table/DataTable.vue';

	export default {
		name: 'document-internal-notes',
		components: {
			DataTable
		},
		props: {
			process_document: {
				type: Object,
				default: () => {}
			}
		},
		data(){
			return {
				table_headers:{
					'company.name': {
						display: "Member",
					},
					notes: {
						display: "Note",
						render: cell_data => {
							return cell_data.note.replace(/<[^>]*>?/gm, '').truncate();
						}
					}
				},
				notes: [],
				initialized: false
			}
		},
		mounted(){
			if(this.process_document.id != undefined){
				this.getNotes();
			}
		},
		methods: {
			getNotes(){
				//eslint-disable-next-line
				axios.get(`${process.env.VUE_APP_API_URL}super_admin/companies/${this.process_document.company_id}/contact_documents/${this.process_document.id}/notes`)
				.then(response => this.handleGetNotes(response.data))
				//eslint-disable-next-line
				.catch(error => console.log(error));
			},
			handleGetNotes(response){
				this.notes = response;
			},
			/**
			 * determine row class for document
			 * @return {[type]} [description]
			 */
			//eslint-disable-next-line
			rowClass(cell_data){
				return "document-notes-row"
			}
		},
		computed: {
		},
		watch: {
			process_document(){
				if(this.process_document.id != undefined) this.getNotes();
			}
		}
	}
</script>