<template>
	<div class="tsa-department-row">
		<!-- name -->
		<div :class="formRowClass('name')">
			<input type="text" placeholder="Enter Department Name" v-model="form_data.name" @keyup="rowChanged" />
			<span class="error-block">{{ errors.name }}</span>
		</div>
		<!-- description -->
		<div :class="formRowClass('description')">
			<input type="text" placeholder="Enter Department Description" v-model="form_data.description" @keyup="rowChanged" />
			<span class="error-block">{{ errors.description }}</span>
		</div>
		<!-- group -->
		<div :class="formRowClass('community_id')">
			<select v-model="form_data.community_id" @change="rowChanged">
				<option value=''>Choose Group</option>
				<option
					v-for="group in groups"
					:key='group.id'
					:value="group.id">{{ group.name }}</option>
			</select>
			<span class="error-block">{{ errors.community_id }}</span>
		</div>
		<!-- document -->
		<div :class="formRowClass('contact_document_id')">
			<select v-model="form_data.contact_document_id" @change="rowChanged">
				<option value=''>Choose Process</option>
				<option
					v-for="doc in contact_documents"
					:key='doc.id'
					:value="doc.id">{{ doc.name }}</option>
			</select>
			<span class="error-block">{{ errors.contact_document_id }}</span>
		</div>
		<!-- status -->
		<div :class="formRowClass('status')">
			<select v-model="form_data.status" @change="rowChanged">
				<option value='active'>Active</option>
				<option value='inactive'>Inctive</option>
			</select>
			<span class="error-block">{{ errors.status }}</span>
		</div>
		<div class="tsa-actions">
			<a href="" @click.prevent="confirmDeleteRow"><i class="fas fa-trash-alt danger"></i></a>
			<a href="" @click.prevent="saveDepartment" :disabled="save_disabled"><i class="fas fa-save"></i></a>
		</div>
	</div>
</template>
<script>
	export default {
		name: "department-row",
		components: {

		},
		props: {
			department: {
				type: Object,
				default: () => {}
			},
			contact_documents: {
				type: Array,
				default: () => []
			},
			groups: {
				type: Array,
				default: () => []
			},
			index: {
				type: Number,
				default: 0
			}
		},
		data(){
			return {
				form_data: {
					name : "",
					description: "",
					community_id: "",
					contact_document_id: "",
					status: "active"
				},
				errors: {},
				department_id: 0,
				save_disabled: true
			}
		},
		mounted(){
			this.emitter.on('delete_deparment',index => this.onDeleteDepartment(index));
			// if actual department
			// copy data to form_data.
			if(this.department.id != undefined){
				this.copyToFormData();
			}
		},
		/**
		 * remove bus event listeners before component is destroyed.
		 * @return {[type]} [description]
		 */
		beforeUnmount(){
			this.emitter.off('delete_deparment');
		},
		methods: {
			/**
			 * 'form-row' add 'has-error' class if field has error
			 * @param  {[type]} field [description]
			 * @return {[type]}       [description]
			 */
			formRowClass(field){
				let string = "form-row";
				if(this.errors[field] != undefined ) string += " has-error";
				return string;
			},
			/**
			 * ask user if they want to remove row.
			 * @return {[type]} [description]
			 */
			confirmDeleteRow(){
				if(this.department_id != 0){
					this.emitter.emit('confirm',{
						message: `Are you sure you want to delete the ${this.department.name} Department?`,
						flag: 'delete_deparment',
						data: this.index 
					});
				}else{
					// empty row. delete it.
					this.$emit('delete',this.index);
				}
			},
			/**
			 * user confirmed they wanted to delete department.
			 * check index to make sure this is the row that sent confirmation.
			 * @param  {[type]} index [description]
			 * @return {[type]}       [description]
			 */
			onDeleteDepartment(index){
				// if this row is the one being deleted?
				if(index == this.index){
					this.deleteDepartment();
				}
			},
			/**
			 * send API request to delete department
			 * @return {[type]} [description]
			 */
			deleteDepartment(){
				//eslint-disable-next-line
				axios.get(`${process.env.VUE_APP_API_URL}super_admin/departments/${this.department_id}/delete`)
				.then(response => this.handleDeleteDepartment(response.data))
				//eslint-disable-next-line
				.catch(error => console.log(error));
			},
			/**
			 * let parent component know to remove object from array
			 * @return {[type]} [description]
			 */
			handleDeleteDepartment(){
				this.$emit('delete',this.index);
			},
			/**
			 * the user changed the data in the row
			 * @return {[type]} [description]
			 */
			rowChanged(){
				this.save_disabled = false;
			},
			/**
			 * send request to save department
			 * @return {[type]} [description]
			 */
			saveDepartment(){
				this.save_disabled = true;
				this.emitter.emit('loader',true);
				//eslint-disable-next-line
				axios.post(`${process.env.VUE_APP_API_URL}super_admin/companies/${this.$route.params.company_id}/departments/${this.department_id}`,this.form_data)
					.then(response => this.handleSaveDepartment(response.data))
					//eslint-disable-next-line
				    .catch(error => this.handleErrors(error.response.data.errors));
			},
			handleSaveDepartment(response){
				this.emitter.emit('loader',false);
				this.save_disabled = true;
				this.department_id = response.id;
				// let parent component know that a department was created/updated
				this.$emit('save',{
					department: response,
					index: this.index
				});
			},
			handleErrors (errors){
				this.save_disabled = false;
				this.emitter.emit('loader',false);
				// clear all errors
				this.errors = {};
				for(var e in errors){
					this.errors[e] = errors[e][0];
				}
			},
			/**
			 * copy data from department object to form_data
			 * so it is displayed in dropdowns
			 * @return {[type]} [description]
			 */
			copyToFormData(){
				let dept_id = "";
				if(this.department.document != undefined){
					dept_id = (this.department.document.length == 0)? "" : this.department.document[0].id;
				}
				this.department_id = this.department.id;
				this.form_data = {
					name : this.department.name,
					description: this.department.description,
					contact_document_id: dept_id,
					status: this.department.status,
					community_id: (this.department.community.length == 0)? "" : this.department.community[0].id
				}
			}
		},
		watch: {
			department(){
				if(this.department.id != undefined){
					this.copyToFormData();
				}
			}
		}
	}
</script>