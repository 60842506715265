<template>
	<div class="tsa-admin tsa-manage-company-community">
		<Breadcrumb :routes="breadcrumb" />
		<div class="tsa-admin-header">
			<span class="page-name">{{company.name}}</span> &mdash; Group Access
		</div>
		<div v-if="relation_id == 0">
			<div class="tsa-section-title">Please search for a community:</div>
			<SearchBox
				placeholder="Find a Community"
				:endpoint="searchEndPoint"
				search_field="name"
				@selected="onSelectedCommunity" />
		</div>
		<div uk-grid class="uk-child-width-1-2@m uk-grid-small" style="margin-top: 20px;">
			<div>
				<!-- community access settings -->
				<div class="tsa-box low-padding" v-show="groupSelected">
					<div class="form-section">{{ group.name }}</div>
					<p>Business Hour required with Sev 1 SLA (Next Day). Mutual Customer requirements defined in contact instructions.</p>
					<div class="form-row with-label">
						<label>Is Active:</label>
						<select v-model="form_data.is_active">
							<option :value="0">No</option>
							<option :value="1">Yes</option>
						</select>
						<span class="error-block">{{ errors.department_id }}</span>
					</div>
					<div :class="formRowClass('department_id')" class="with-label">
						<label>Department:</label>
						<select v-model="form_data.department_id">
							<option value="">Choose Department</option>
							<option 
								v-for="department in departments"
								:key="department.id"
								:value="department.id">{{department.name}}</option>
						</select>
						<span class="error-block">{{ errors.department_id }}</span>
					</div>
					<div :class="formRowClass('document_id')" class="with-label">
						<label>Process Document:</label>
						<select v-model="form_data.document_id">
							<option value="">Choose Process</option>
							<option 
								v-for="pdoc in documents"
								:key="pdoc.id"
								:value="pdoc.id">{{pdoc.name}}</option>
						</select>
						<span class="error-block">{{ errors.document_id }}</span>
					</div>
					<div class="form-row with-label">
						<label>User Access:</label>
						<select v-model="form_data.user_access_flag">
							<option value='all'>All Users</option>
							<option value='limit'>Limited Access</option>
						</select>
					</div>
					<div class="form-row with-label">
						<label>Relation to Group:</label>
						<select v-model="form_data.member_relationship">
							<option value='many_to_many'>See all Members</option>
							<option value='one_to_many'>Only Sponsor</option>
						</select>
					</div>
					<div class='form-row'>
						<button class="tsa-btn pull-right" @click.prevent="saveSettings">Save Settings</button>
					</div>
				</div>
			</div>
			<div>
				<div class="tsa-box low-padding" v-show="form_data.user_access_flag == 'limit'">
					<div class="form-section">Select Users</div>
					<DataTable
					:table_headers="table_headers"
					:table_data="users" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapState} from 'vuex';
	import SearchBox from '@/components/Layout/SearchBox.vue';
	import DataTable from '@/components/Table/DataTable.vue';
	import SelectRow from '@/components/SuperAdmin/CompanyCommunity/SelectUserRow.vue';
	import _ from 'lodash';
	import Breadcrumb from '@/components/Layout/Breadcrumb.vue';
	
	export default {
		name: 'manage-company-community',
		components: {
			SearchBox,
			DataTable,
			Breadcrumb
		},
		data(){
			return {
				company: {},
				users: [],
				documents: [],
				departments: [],
				is_initialized: false,
				group: {},
				form_data: {
					community_id : "",
					user_access_flag: 'all',
					member_relationship: 'many_to_many',
					document_id: "",
					department_id: "",
					is_active: 1
				},
				table_headers:{
					selected: {
						searchable: false,
						sortable: false,
						display: "",
						component: SelectRow
					},
					name: {
						render: cell_data => {
							return `${cell_data.first_name} ${cell_data.last_name}`
						}
					},
					location: {
						render: cell_data => {
							let city = (cell_data.city == null)? "" : cell_data.city,
							country = (cell_data.country == null)? "" : cell_data.country.name;
							return (city.length)? `${city}, ${country}` : country;
						}
					},
					role: {}
				},
				errors: {},
				relation_id: 0
			}
		},
		updated(){
			this.initialize();
		},
		mounted(){
			this.initialize();
		},
		/**
		* remove bus event listeners before component is destroyed.
		* @return {[type]} [description]
		*/
		beforeUnmount(){
			this.emitter.off('token_ready');
		},
		methods: {
			initialize(){
				if(this.is_initialized) return;
				this.is_initialized = true;
				this.relation_id = this.$route.params.relation_id;
				this.emitter.on('token_ready', () => this.getCompany());
				if(this.jwt_token.access_token != undefined) this.getCompany();
			},
			getCompany(){
				this.emitter.emit('loader',true);
				let promises = [
					//eslint-disable-next-line
					axios.get(`${process.env.VUE_APP_API_URL}super_admin/companies/${this.$route.params.company_id}`),
					//eslint-disable-next-line
					axios.get(`${process.env.VUE_APP_API_URL}super_admin/companies/${this.$route.params.company_id}/users/${this.relation_id}`),
					//eslint-disable-next-line
					axios.get(`${process.env.VUE_APP_API_URL}super_admin/companies/${this.$route.params.company_id}/contact_documents/all`),
					//eslint-disable-next-line
					axios.get(`${process.env.VUE_APP_API_URL}super_admin/companies/${this.$route.params.company_id}/departments/all`),
				];
				if(this.relation_id != 0){
					//eslint-disable-next-line
					promises.push(axios.get(`${process.env.VUE_APP_API_URL}super_admin/companies/${this.$route.params.company_id}/relation/${this.relation_id}`));
				}
				//eslint-disable-next-line
				axios.all(promises)
				//eslint-disable-next-line
				.then(axios.spread((company_response, users_response, documents_response , departments_response, relation_response ) => {
					this.emitter.emit('loader',false);
					this.company = company_response.data.company;
					this.users = users_response.data;
					this.documents = documents_response.data;
					this.departments = departments_response.data;
					if(relation_response) this.handleGetRelation(relation_response.data);
				}));
			},
			handleGetRelation(response){
				let relation = response.relation; 
				this.onSelectedCommunity(relation.community);
				this.form_data.department_id = relation.department_id;
				this.form_data.document_id = relation.document_id;
				this.form_data.user_access_flag = relation.user_access_flag;
				this.form_data.member_relationship = relation.member_relationship;
				this.form_data.is_active = parseInt(relation.is_active);
				if(response.users != undefined){
					this.$store.dispatch('tableSelectValues',response.users);
				}
			},
			/**
			 * user selected a community from the list.
			 * @return {[type]} [description]
			 */
			onSelectedCommunity(group){
				this.group = group;
				this.form_data.community_id = group.id;
				// if new relation set default.
				if(this.relation_id == 0){
					switch(group.type){
						case 'solution_support':
						case 'open_group':
							this.form_data.member_relationship = "many_to_many";
						break;
						case 'partner_program':
							// sponsor
							if(group.sponsor_company_id == this.$route.params.company_id){
								// sponsor can see all members
								this.form_data.member_relationship = "many_to_many";
							}else{
								// members can only see sponsor
								this.form_data.member_relationship = "one_to_many";
							}
						break;
					}
				}
			},
			/**
			 * 'form-row' add 'has-error' class if field has error
			 * @param  {[type]} field [description]
			 * @return {[type]}       [description]
			 */
			formRowClass(field){
				let string = "form-row";
				if(this.errors[field] != undefined ) string += " has-error";
				return string;
			},
			/**
			 * send request to save settings
			 * @return {[type]} [description]
			 */
			saveSettings(){
				this.emitter.emit('loader',true);
				let data = _.clone(this.form_data);
				data.selected_users = this.selected_users;
				data.relation_id = this.relation_id;
				//eslint-disable-next-line
				axios.post(`${process.env.VUE_APP_API_URL}super_admin/companies/${this.$route.params.company_id}/groups/put`,data)
					.then(response => this.handleSaveSettings(response.data))
					//eslint-disable-next-line
				    .catch(error => this.handleErrors(error.response.data.errors));

			},
			handleSaveSettings(response){
				this.emitter.emit('loader',false);
				if(response.error != undefined){
					this.emitter.emit('alert',{
						type: 'error',
						message: response.error
					});
					return;
				}
				this.$router.push({name: "super_admin.company.detail", params: {company_id: this.$route.params.company_id }});
			},
			handleErrors (errors){
				this.emitter.emit('loader',false);
				// clear all errors
				this.errors = {};
				for(var e in errors){
					this.errors[e] = errors[e][0];
				}
			},
		},
		computed: {
			breadcrumb(){
				return [
					{display: "Companies" ,route: {name: "super_admin.companies"}},
					{display: this.company.name, route: {name: "super_admin.company.detail",params: {company_id : this.company.id}}},
					"Group Access"
				];
			},
			searchEndPoint(){
				return `${process.env.VUE_APP_API_URL}super_admin/groups/search`;
			},
			groupSelected(){
				return this.group.id != undefined;
			},
			...mapState({
				jwt_token: state => state.token,
				selected_users: state => state.table_data_selected_items
			})
		}
	}
</script>