<template>
	<div class="tsa-case-response">
		<div class="tsa-response-fields" v-if="can_respond && dataProcessed">
			<div class="response-header">Respond to {{ case_entry.company.name }} - Case Problem Summary</div>
			<ResponseForm v-if="(noResponses || readyForFinalResponse) && (!isAccepted && !isRejected && !justResponded)" @responded="responseSent" />
			<ResponseData v-if="!noResponses" :show-reply="needMoreInformation || gotMoreInformation" />
			<EngineerInfo />
		</div>
		<div class="in-page-error" v-if="!can_respond && dataProcessed">
			<i class="fas fa-exclamation-triangle"></i> {{ error_message }}</div>
	</div>
</template>
<script>
	import ResponseForm from '@/components/CaseResponse/ResponseForm.vue';
	import ResponseData from '@/components/CaseResponse/ResponseData.vue';
	import EngineerInfo from '@/components/CaseResponse/EngineerInfo.vue';
	import {mapState} from 'vuex';
	export default {
		name: 'case-response',
		components:{
			ResponseForm,
			ResponseData,
			EngineerInfo
		},
		data(){
			return {
				can_respond: true,
				noResponses: false,
				readyForFinalResponse: false,
				isAccepted: false,
				isRejected: false,
				justResponded: false,
				needMoreInformation: false,
				gotMoreInformation: false,
				dataProcessed: false,
				error_message: ""
			}
		},
		mounted(){
			/*this.emitter.on('token_ready', () => this.getCaseInformation());
			if(this.jwt_token.access_token != undefined) this.getCaseInformation();*/
			this.getCaseInformation();
		},
		methods:{
			getCaseInformation(){
				const url = `${process.env.VUE_APP_API_URL}caller/case/${this.$route.params.token}`;
				// eslint-disable-next-line
				axios.get(url)
				.then(response => this.handleGetCaseInformation(response.data))
				// eslint-disable-next-line
				.catch(error => console.log(error));
			},
			handleGetCaseInformation(response){
				// if error
				if(response.error != undefined){
					this.error_message = response.error;
					this.can_respond = false;
				}else{
					// save case information in store
					this.$store.dispatch('caseEntry',response.case);
					this.$store.dispatch('caseResponse',response.response);
					this.$store.dispatch('caseResponses',response.allResponses);
					this.$store.commit('setAppSlug',response.receiver.slug);
				}
				this.setSwitches();
			},
			responseSent(){
				this.justResponded = true;
				this.getCaseInformation();
			},
			setSwitches(){
				this.noResponses = false;
				this.readyForFinalResponse = false;
				this.isAccepted = false;
				this.isRejected = false;
				this.gotMoreInformation = false;
				this.needMoreInformation = false;
				if(this.case_entry.responses && this.case_entry.responses.length > 0){
					for(let i = 0; this.case_entry.responses.length > i;++i){
						if(this.case_entry.responses[i].type == "approval"){

							this.isAccepted = true;
						}
						if(this.case_entry.responses[i].type == "rejection"){

							this.isRejected = true;
						}
						if(this.case_entry.responses[i].type == "information-request"){

							this.needMoreInformation = true;
						}
						if(this.case_entry.responses[i].type == "information-response"){

							this.gotMoreInformation = true;
						}
						if(i == this.case_entry.responses.length-1){
							this.$store.dispatch('caseResponse',this.case_entry.responses[i]);
						}
					}
				} else {
					this.noResponses = true;
				}
				if(this.gotMoreInformation && !this.isAccepted && !this.isRejected){
					this.readyForFinalResponse = true;
				}
				this.dataProcessed = true; //we have what we need and can show stuff now
			}
		},
		computed:{
			...mapState({
				case_entry: state => state.case_entry,
				jwt_token: state => state.token,
				case_response: state => state.case_response,
				case_responses: state => state.case_responses
			})
		}
	}
</script>