<template>
	<div class="uk-child-width-1-2@m uk-grid-small tsa-document-form" uk-grid>
		<div>
			<div class="form-section">{{ case_entry.receiver.name }} Case Information</div>
			<div v-if="form_data.case_number && form_data.case_number.length > 0" class="form-row data-row">
				<label>{{ case_entry.receiver.name }} Case Number:</label>
				<span>&nbsp;{{ form_data.case_number }}</span>
			</div>
			<div class="form-row data-row">
				<label>{{ labels.name }}:</label>
				<span>&nbsp;{{ form_data.engineer_name }}</span>
			</div>
			<div class="form-row data-row">
				<label>{{ labels.email }}:</label>
				<span>&nbsp;{{ form_data.engineer_email }}</span>
			</div>
			<div class="form-row data-row">
				<label>{{ labels.phone }}:</label>
				<span>&nbsp;{{ displayPhone }}</span>
			</div>
		</div>
		<div>
			<div class="form-section">{{ labels.nextSteps }}</div>
			<div class="form-row data-text">
				<p v-html="nextSteps" class="tsa-break-word"></p>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapState} from 'vuex';
	import parsePhoneNumber from 'libphonenumber-js';
	export default {
		name: "response-data",
		components:{
			
		},
		props:{
			showReply: {
				type: Boolean,
				default: false
			}
		},
		data(){
			return{
				form_data:{
					case_number: "",
					engineer_name: "",
					engineer_email: "",
					engineer_phone: "",
					next_steps: "",
				},
				labels:{
					name:"",
					email:"",
					phone:"",
					nextSteps:""
				},
				acceptedLabels:{
					name:"Assigned Engineer First and Last Name",
					email:"Assigned Engineer Email",
					phone:"Assigned Engineer Phone",
					nextSteps:"Note: Example Instructions for next steps"
				},
				infoLabels:{
					name:"Your Name",
					email:"Email",
					phone:"Phone (Optional)",
					nextSteps:"Information Needed"
				},
				rejectionLabels:{
					name:"Your Name",
					email:"Email",
					phone:"Phone (Optional)",
					nextSteps:"Rejection Reason"
				},
				displayPhone: null
			}
		},
		mounted(){
			if(this.case_response != null){
				this.copyCase();
				this.displayPhone = this.formattedPhone(this.case_response.engineer_phone);
			}
		},
		methods: {
			copyCase(){
				let isAccepted = false;
				let isRejected = false;
				let needMoreInformation = false;
				if(this.case_response != null && !this.showReply){
					this.labels = this.acceptedLabels;
					this.form_data = this.case_response;
				}
				if(this.case_responses != null && this.showReply){
					for(let i = 0; this.case_responses.length > i;++i) {
						if (this.case_responses[i].type == "information-request") {
							needMoreInformation = true;
							this.form_data = this.case_responses[i];
						}
						if (this.case_responses[i].type == "information-response") {
							needMoreInformation = true;
						}
						if(this.case_entry.responses[i].type == "approval"){
							isAccepted = true;
							this.form_data = this.case_responses[i];
						}
						if(this.case_entry.responses[i].type == "rejection"){
							isRejected = true;
							this.form_data = this.case_responses[i];
						}
					}
				}
				if(isAccepted){
					this.labels = this.acceptedLabels;
				}else if(isRejected){
					this.labels = this.rejectionLabels;
				} else if(needMoreInformation){
					this.labels = this.infoLabels;
				}
			},
			formattedPhone(phone){
				if(phone){
					let phoneParts = phone.split(":");
					if(phoneParts.length > 1){
						let country = phoneParts[0];
						phoneParts.shift();
						let phNumber = parsePhoneNumber(phoneParts.join(":").trim(), country);
						if(phNumber.isValid()){
							return phNumber.country + ": " + phNumber.formatInternational();
						}
					}
				}
				return phone;
			},
			findReply(){
				if(this.caseEntry.responses){
					for(let i = 0; this.caseEntry.responses.length > i;++i){
						if(this.caseEntry.responses[i].type == "information-response"){
							return this.caseEntry.responses[i];
						}
					}
				}
				return null;
			}
		},
		computed: {
			/**
			 * convert \n to <br>
			 * @return {[type]} [description]
			 */
			nextSteps(){
				if(this.case_response.next_steps == undefined) return "";
				return this.case_response.next_steps.nl2br();
			},
			...mapState({
				case_entry: state => state.case_entry,
				case_response: state => state.case_response,
				case_responses: state => state.case_responses,
			})
		},
		watch:{
			case_response(){
				this.copyCase();
			},
			case_responses(){
				this.copyCase();
			}
		}
	}
</script>