<template>
	<div id="app">
		<!-- update profile -->
		<UpdateProfileModal
			:opened="update_profile" 
			@close="onCloseUpdateProfile" />
		<!-- update agreement modal -->
		<UpdateAgreementModal
			:opened="update_agreement"
			@close="onCloseUpdateAgreement" />
		<!-- block for IE window -->
		<DefaultModal
			title="Internet Explorer Not Supported."
			:allow_close="false"
			:is_open="using_ie">
				<div style="padding: 0 20px;">
					<p>We no longer support Internet Explorer. Please use one of the following browsers:</p>
					<div class="uk-child-width-1-3 uk-grid-small" uk-grid>
						<!-- download Google Chrome -->
						<div style="text-align: center">
							<a href="https://www.google.com/chrome/" target="_blank">
								<img src='~@/assets/images/chrome-logo.png' width="124" /></a>
							<br>
							<br>
							<a href="https://www.google.com/chrome/" target="_blank">Google Chrome</a>
						</div>
						<!-- download Firefox -->
						<div style="text-align: center">
							<a href="https://www.mozilla.org/en-US/firefox/" target="_blank">
								<img src='~@/assets/images/firefox-logo.png' width="124" />
							</a>
							<br>
							<br>
							<a href="https://www.mozilla.org/en-US/firefox/" target="_blank">Mozilla Firefox</a>
						</div>
						<!-- download Edge -->
						<div style="text-align: center">
							<a href="https://www.mozilla.org/en-US/firefox/" target="_blank">
								<img src='~@/assets/images/edge-logo.png' width="124" />
							</a>
							<br>
							<br>
							<a href="https://www.microsoft.com/en-us/windows/microsoft-edge" target="_blank">Microsoft Edge</a>
						</div>
						
					</div>
				</div>
		</DefaultModal>
		<Loader
			:show="show_loader" />
		<ConfirmModal
            :is_open="confirm.open"
            @confirm="onConfirm"
            @cancel="onConfirmCancel">
            <span v-html="confirm.message" />
        </ConfirmModal>
		<AlertModal
			:is_open="alert.open"
			@ok="closeAlert"
			:type="alert.type">
			<span v-html="alert.message" />
		</AlertModal>
		<LoggedInAsUser v-if="login_as_user" />
		<div class="window-content" :class="{blocked: using_ie}">
			<Header />
			<AdminNav v-if="show_admin_menu" />
			<AlertBanner ref="alertBanner" />
			<router-view/>
			<Footer />
		</div>
	</div>
</template>

<script>
	import Header from './components/Layout/Header.vue';
	import Footer from './components/Layout/Footer.vue';
	import AlertModal from './components/Layout/AlertModal.vue';
	import ConfirmModal from './components/Layout/ConfirmModal.vue';
	import Loader from './components/Layout/Loader.vue';
	import AdminNav from './components/Layout/AdminNav.vue';
	import LoggedInAsUser from './components/Layout/LoggedInAsUser.vue';
	import DefaultModal from './components/Layout/DefaultModal.vue';
	import {mapState} from 'vuex';
	import _ from 'lodash';
	import UpdateProfileModal from './components/Profile/UpdateProfileModal.vue';
	import UpdateAgreementModal from './components/Profile/UpdateAgreementModal.vue';
	import AlertBanner from "./components/Layout/AlertBanner.vue";

	export default {
		name: "App",
		components: {
			Header,
			Footer,
			AlertModal,
			ConfirmModal,
			Loader,
			AdminNav,
			LoggedInAsUser,
			DefaultModal,
			UpdateProfileModal,
			UpdateAgreementModal,
			AlertBanner
		},
		data(){
			return {
				alert: {
					open: false,
					message: "",
					type: "success"
				},
				show_loader: false,
				// values for confirm window.
				confirm:{
                    open:false,
                    message:"",
                    flag: "",
                    data: null
                },
                back_to_admin: false,
                back_to_admin_route: {},
                iddle_timeout: null,
                currently_logging_in_as_user: false,
                using_ie: false,
                update_profile : false,
                update_agreement : false,
			}
		},
		mounted(){

			// check if user is using IE
			if(this.isIE()){
				// if so block.
				this.using_ie = true;
				// don't do anything else.
				return;
			}

			// Bus Events
			
			// alert window
			this.emitter.on('alert', alert => this.showAlert(alert));
			// alert banner
			this.emitter.on('alert_banner', alert => this.showBanner(alert));
			// logout current user
			this.emitter.on('logout',() => this.logout());
			// get current user data
			this.emitter.on('get_user',() => this.getUser());
			// show/hide loader
			this.emitter.on('loader', show => this.showLoader(show));
			// confirm window.
			this.emitter.on('confirm', data => this.showConfirm(data));
			// login as user
			this.emitter.on('login_as_user', user_id => this.logInAsUser(user_id,false));
			// return to admin
			this.emitter.on('back_to_admin', () => this.backToAdmin());

			let token = JSON.parse(localStorage.getItem('tsa_token'));
			// if token is in local storage
			if(token){
				this.$store.dispatch('token',token);
				//eslint-disable-next-line
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;
				this.getUser();
			}else{
				this.checkCurrentRoute();
			}

			setTimeout(() => {
				this.checkUrlSlug();
			}, 1000);

			this.resetIddleTimer();
		},
		methods:{
			checkUrlSlug(){
				// get domain.
				let host = window.location.host;
				//if dev mode passing slug.
				if(this.$route.name == "home-with-slug"){
					host = `${this.$route.params.slug}.${host}`;
				}
				// save it to store.
				this.$store.dispatch('appUrl',host);

				// see if there are any alerts.
				this.checkForAlerts();
			},
			// check the current route and see if you need to take action
			checkCurrentRoute(){
				const name = this.$route.name;
				switch(name){
					case "onboarding.restore":
					break;
					case "onboarding.contact":
					case "onboarding.agreements":
					case "onboarding.process":
					case "onboarding.choose-process":
					case "onboarding.basic-premium":
						//eslint-disable-next-line
						let onboarding_token = localStorage.getItem('tsa_onboarding_session_token');
						if(onboarding_token){
							this.restoreOnboardingSession(onboarding_token);
						}else{
							// redirect to login page
							this.$router.push({ name: 'home' });
						}
					break;
					case "onboarding.success":
						this.$router.push({ name: 'home' });
					break;
				}
			},
			checkForAlerts(){
				const alert = localStorage.getItem('tsa_alert');
				if(alert != null){
					localStorage.removeItem('tsa_alert');
					this.emitter.emit('alert_banner',{
						type: 'danger',
						message: alert
					});
				}
			},
			
			showBanner(alertData){
				this.$refs.alertBanner.showBanner(alertData.message, 
					typeof alertData.type !== 'undefined' ? alertData.type:'danger')
			},
			
			/**
			 * show Alert message.
			 * @param  {[type]} alert [description]
			 * @return {[type]}       [description]
			 */
			showAlert(alert){
				let type = "success";
				let message = alert;
				if(typeof alert == "object"){
					message = alert.message;
					type = alert.type;
				}
				this.alert = {
					open: true,
					message: message,
					type: type
				};
			},
			/**
			 * close alert window
			 * @return {[type]} [description]
			 */
			closeAlert(){
				this.alert.open = false;
			},
			/**
			 * toggle visibility of loder modal
			 * @param  {boolean} show toogle value
			 * @return {void}
			 */
			showLoader(show){
				this.show_loader = show;
			},
			/**
			 * get current user data.
			 * @return {[type]} [description]
			 */
			getUser(){
				this.showLoader(true);
				//eslint-disable-next-line
				axios.get(`${process.env.VUE_APP_API_URL}auth/me`)
				.then(response => this.handleGetUser(response.data))
				//eslint-disable-next-line
				.catch(error => this.handleGetUserError(error));
			},
			/**
			 * got user data.
			 * @param  {[type]} response [description]
			 * @return {[type]}          [description]
			 */
			handleGetUser(response){
				this.showLoader(false);
				this.$store.dispatch('user',response);
				const role = response.user.role;
				const route_name = this.$route.name;

				if(response.user.force_update_profile){
					this.update_profile = true;
				}
				else if(response.user.force_update_agreement){
					this.update_agreement = true;
				}

				// if user should be redirect to a previous route.
				let previous = JSON.parse(localStorage.getItem('tsa_after_login'));
				if(previous != null){
					localStorage.removeItem('tsa_after_login');
					this.$router.push(previous);
					return;
				}

				let adminToken = JSON.parse(localStorage.getItem('tsa_admin_token'));
				if(adminToken){
					this.$store.dispatch('loggedInAsUser', true);
					// go to user dashboard based on role.
					if(response.user.role == "super_admin"){
						this.$router.push({name:"super_admin.dashboard"});
					}else{
						this.$router.push({name:"dashboard"});
					}
				}else{
					if(this.back_to_admin){
						this.back_to_admin = false;
						this.$router.push(this.back_to_admin_route);
						this.back_to_admin_route = {};
						return;
					}
					// default redirects
					if(route_name == "home" || route_name == "home-with-slug"){
						let name = (role == 'super_admin')? "super_admin.dashboard" : "dashboard";
						this.$router.push({name: name});
						return;
					}
					if(route_name == "dashboard" && response.user.role == "super_admin"){
						this.$router.push({name:"super_admin.dashboard"});
					}
					if(route_name == "super_admin.dashboard" && response.user.role != "super_admin"){
						this.$router.push("dashboard");
					}
				}
				if(!this.currently_logging_in_as_user) this.emitter.emit('token_ready');
				this.currently_logging_in_as_user = false;
			},
			/**
			 * error getting user data.
			 * if 401 try to refresh token.
			 * @param  {[type]} error [description]
			 * @return {[type]}       [description]
			 */
			handleGetUserError(error){
				switch(error.response.status){
					case 401: //Unauthorized
						this.refreshToken(); 
					break;
				}
			},
			/**
			 * send request to refresh the current jwt token.
			 * @return {[type]} [description]
			 */
			refreshToken(){
				//eslint-disable-next-line
				axios.post(`${process.env.VUE_APP_API_URL}auth/refresh`)
				.then(response => this.handleRefreshToken(response.data))
				.catch(error => this.handleRefreshError(error));
			},
			/**
			 * save token in store.
			 * @param  {[type]} response [description]
			 * @return {[type]}          [description]
			 */
			handleRefreshToken(response){
				this.$store.dispatch('token',response);
				// reload user data.
				setTimeout(() => this.getUser(),0);
			},
			handleRefreshError(error){
				this.emitter.emit('loader',false);

				switch(error.response.status){
					case 429:
						this.emitter.emit('alert',{
							type: 'warning',
							message: "Too Many Requests. Please try again later."
						});
					break;
					default:
						// remove current token
						localStorage.removeItem('tsa_token');
						// is user currently working on a partner program
						if(this.$route.name.indexOf("onboarding") === -1){
							// couldn't refresh go home.
							this.$router.push({'name': "home"});
						}
					break;
				}
			},
			logout(){
				//eslint-disable-next-line
				axios.post(`${process.env.VUE_APP_API_URL}auth/logout`)
				.then(() => this.handleLogout())
				//eslint-disable-next-line
				.catch(error => console.log(error));
			},
			handleLogout(){
				let redirect = process.env.VUE_APP_COMPANY_URL.replace('{slug}',this.app_slug);
				this.$store.dispatch('logout');
				// handle logout redirect.
				if(this.user.role != "super_admin"){
					window.location.href = redirect;
				}else{
					this.$router.push({name:'home'});
				}				
			},
			/**
			 * show confirm window
			 * save flag to later notify component if user confirmed
			 * @return {[type]} [description]
			 */
			showConfirm(data){
				data.open = true;
				this.confirm = data;
			},
			/**
			 * hide confirm window.
			 * @return {[type]} [description]
			 */
			onConfirmCancel(){
				this.confirm = {
					open: false,
					message: "",
					flag: "",
					data: null
				}
			},
			/**
			 * let component that request confirm that user confirmed.
			 * use event bus.
			 * @return {[type]} [description]
			 */
			onConfirm(){
				this.emitter.emit(this.confirm.flag,this.confirm.data);
				this.onConfirmCancel();
			},
			/**
			 * user was previously trying to onboard.
			 */
			restoreOnboardingSession(token){
				//eslint-disable-next-line
				axios.get(`${process.env.VUE_APP_API_URL}onboarding/restore/${token}`)
				.then(response => this.handleRestoreSession(response.data))
				//eslint-disable-next-line
				.catch(error => console.log(error));	
			},
			/**
			 * save data in store and reload landing data for
			 * @param  {[type]} response [description]
			 * @return {[type]}          [description]
			 */
			handleRestoreSession(response){
				this.$store.dispatch('restoreOnboarding',response);
				const current = this.$route.name;
				const route = `onboarding.${response.session.step}`
				if(current != route){
					// redirect to correct step.
					this.$router.push({name: route});
				}
			},
			/**
			 * super admin wants to log in as a user.
			 * @param  {[type]} user_id [description]
			 * @return {[type]}         [description]
			 */
			logInAsUser(user_id,back_to_admin){
				if(!back_to_admin){
					let token = _.clone(this.jwt_token);
					token.user_id = this.user.id;
					localStorage.setItem("tsa_admin_token", JSON.stringify(token));
				}
				//eslint-disable-next-line
				axios.get(`${process.env.VUE_APP_API_URL}auth/super_admin/login_as_user/${user_id}`)
				.then(response => this.handleLoginAsUser(response.data))
				//eslint-disable-next-line
				.catch(error => console.log(error));
			},
			/**
			 * save toke to store and get user.
			 * @param  {[type]} response [description]
			 * @return {[type]}          [description]
			 */
			handleLoginAsUser(response){
				localStorage.removeItem('tsa_after_login');
				this.$store.dispatch('token',response);
				this.currently_logging_in_as_user = true;
				this.getUser();
			},
			/**
			 * [backToAdmin description]
			 * @return {[type]} [description]
			 */
			backToAdmin(){
				this.back_to_admin = true;
				this.back_to_admin_route = {
					name: 'super_admin.user.edit',
					params: {
						company_id : this.user.company_id,
						user_id: this.user.id
					}
				}
				this.$store.dispatch("loggedInAsUser",false);
				const token = JSON.parse(localStorage.getItem('tsa_admin_token'));
				localStorage.removeItem('tsa_admin_token');
				this.$store.dispatch('token',token);
				this.getUser();
			},
			resetIddleTimer(){
				if(this.iddle_timeout != null) clearTimeout(this.iddle_timeout);
				this.iddle_timeout = setTimeout(() => location.reload(),process.env.VUE_APP_IDDLE_TIME * (1000*60));
			},
			/**
			 * check if user is in Internet Explorer
			 * @return {[type]} [description]
			 */
			isIE() {
				const ua = navigator.userAgent;
				/* MSIE used to detect old browsers and Trident used to newer ones*/
				const is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1 /*|| ua.indexOf("Edge") > -1*/;
				return is_ie; 
			},
			onCloseUpdateProfile(){
				this.update_profile = false;
			},
			onCloseUpdateAgreement(){
				this.update_agreement = false;
			}
		},
		computed:{
			...mapState({
				show_admin_menu: state => state.show_admin_menu,
				user: state => state.user,
				app_slug: state => state.app_slug,
				jwt_token : state => state.token,
				login_as_user: state => state.login_as_user
			})
		},
		watch: {
			//eslint-disable-next-line
			$route (to, from){
				this.emitter.emit('route_change');
				this.resetIddleTimer();
				this.$store.dispatch('currentPage',to.name);
			}
		}
	}
</script>

<style>
    @import "./assets/css/styles.min.css";
</style>