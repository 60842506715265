<template>
	<div class="tsa-box low-padding company-info">
		<div class="tsa-section-title">Company <HelpHint flag="company_details" /></div>
		<div class="tsa-company-action-buttons" v-if="user.role == 'super_admin'">
			<router-link class="tsa-btn tsa-outline" :to="editRoute">Edit</router-link>
			<button class="tsa-btn tsa-default" @click.prevent="toggleStatus">{{ toggleButtonText }}</button>
			<button class="tsa-btn tsa-danger" @click.prevent="confirmDeleteCompany">Delete</button>
		</div>
		<div class="company-details">
			<div class="tsa-data">
				<div class="tsa-data-label">Name:</div>
				<div class="tsa-data-value">{{ company.name }}</div>
			</div>
			<div class="tsa-data">
				<div class="tsa-data-label">Website:</div>
				<div class="tsa-data-value"><a :href="company.url" target="_blank">{{company.url}}</a></div>
			</div>
			<div class="tsa-data">
				<div class="tsa-data-label">Membership Level:</div>
				<div class="tsa-data-value"><a :href="membershipLink" target="_blank">{{ membershipType }}</a></div>
			</div>
			<div class="tsa-data">
				<div class="tsa-data-label">Login Method:</div>
				<div class="tsa-data-value">{{ loginMethod }}</div>
			</div>
			<div class="tsa-data">
				<div class="tsa-data-label">Login Page:</div>
				<div class="tsa-data-value"><a :href="loginPage" target="_blank">{{ loginPage }}</a></div>
			</div>
			<div class="tsa-data">
				<div class="tsa-data-label">Logo:</div>
				<div class="tsa-data-value">
					<div class="tsa-company-logo"
						:style="`background-image: url(${companyLogoUrl});`"
						v-if="hasLogo"></div>
					<div class="tsa-company-logo" v-if="!hasLogo"></div>
				</div>
			</div>
			<div class="tsa-data">
				<div class="tsa-data-label">Outbound process bcc Email:</div>
				<div class="tsa-data-value">
					<a :href="`mailto:${company.communications_email}`">{{ company.communications_email }}</a>
				</div>
			</div>
			<div class="tsa-data">
				<div class="tsa-data-label">Inbound process bcc Email:</div>
				<div class="tsa-data-value">
					<a :href="`mailto:${company.bcc_response_email}`">{{ company.bcc_response_email }}</a>
				</div>
			</div>
			<div class="tsa-data">
				<div class="tsa-data-label">Email Domain:</div>
				<div class="tsa-data-value">
					@{{ company.domain }}
				</div>
			</div>
			<div class="tsa-data">
				<div class="tsa-data-label">Login Page Text:</div>
				<div class="tsa-data-value">
					<div v-html="company.login_page_text" />
				</div>
			</div>
			<div class="tsa-data">
				<div class="tsa-data-label">Show in Public Web:</div>
				<div class="tsa-data-value">
					{{ (company.show_in_public_web)? 'Yes' : 'No' }}
				</div>
			</div>
			<div class="tsa-data">
				<div class="tsa-data-label">Allow Collaboration Feed:</div>
				<div class="tsa-data-value">
					{{ (company.allow_notes)? 'Yes' : 'No' }}
				</div>
			</div>
			<div class="tsa-data">
				<div class="tsa-data-label">&nbsp;</div>
				<div class="tsa-data-value"><br />
					<router-link	
						:to="registrationRoute"
						class="tsa-btn tsa-outline">User Registration</router-link>
					&nbsp;&nbsp;
					<router-link
						v-if="location == 'admin'"
						:to="ssoSetupRoute"
						class="tsa-btn tsa-outline">Sso Setup</router-link>
					<br /><br />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapState} from 'vuex';
	import HelpHint from '@/components/Layout/HelpHint.vue';
	export default {
		name: 'company-info',
		components: {
			HelpHint
		},
		props: {
			company: {
				type: Object,
				default: () => {}
			},
			/**
			 * is is the component current loaded at
			 * super_admin or admin
			 * @type {Object}
			 */
			location: {
				type: String,
				default: "super_admin"
			}
		},
		data(){
			return {
			}
		},
		mounted(){
			this.emitter.on('delete_company',company_id => this.onDeleteCompany(company_id));
		},
		/**
		 * remove bus event listeners before component is destroyed.
		 * @return {[type]} [description]
		 */
		beforeUnmount(){
			this.emitter.off('delete_company');
		},
		methods: {
			/**
			 * send API request to toggle status.
			 * @return {[type]} [description]
			 */
			toggleStatus(){
				this.emitter.emit('loader',true);
				const newStatus = (this.company.status == 'active')? "inactive" : "active";
				//eslint-disable-next-line
				axios.get(`${process.env.VUE_APP_API_URL}super_admin/companies/${this.company.id}/status/${newStatus}`)
				.then(response => this.handleToggleStatus(response.data))
				//eslint-disable-next-line
				.catch(error => console.log(error));
			},
			handleToggleStatus(response){
				this.emitter.emit('loader',false);
				if(response.error != undefined){
					this.emitter.emit('alert',{
						type: 'error',
						message: response.error
					});
					return;
				}
				this.$emit('updated',response);
				this.emitter.emit('alert',`${this.company.name} status changed to <strong>${response.status.ucfirst()}</strong>.`);
			},
			/**
			 * ask user if they want to remove row.
			 * @return {[type]} [description]
			 */
			confirmDeleteCompany(){
				this.emitter.emit('confirm',{
					message: `Are you sure you want to delete ${this.company.name}?`,
					flag: 'delete_company',
					data: this.company.id
				});
			},
			/**
			 * send delete request to API
			 * @return {[type]} [description]
			 */
			onDeleteCompany(company_id){
				if(company_id != this.company.id) return;

				this.emitter.emit('loader',true);
				//eslint-disable-next-line
				axios.get(`${process.env.VUE_APP_API_URL}super_admin/companies/${this.company.id}/delete`)
				.then(response => this.handleDeleteCompany(response.data))
				//eslint-disable-next-line
				.catch(error => console.log(error));
			},
			handleDeleteCompany(response){
				this.emitter.emit('loader',false);
				if(response.error != undefined){
					this.emitter.emit('alert',{
						type: 'error',
						message: response.error
					});
					return;
				}
				this.emitter.emit('alert',response.message);
				this.$router.push({name: 'super_admin.companies'});
			},
		},
		computed: {
			toggleButtonText(){
				if(this.company.status == undefined) return "";
				return (this.company.status == 'active')? "Disable" : "Enable";
			},
			membershipType(){
				if(this.company.membership_type == undefined) return "";
				return this.company.membership_type.ucfirst();
			},
			membershipLink(){
				switch(this.company.membership_type){
					case "premium":
						return "https://www.tsanet.org/premium-membership/";
					case "elite":
						return "https://www.tsanet.org/elite-membership/";
					case "limited":
						return "https://www.tsanet.org/limited-membership/";
					case "basic":
					case "standard":
					default:
						return "https://www.tsanet.org/standard-membership/";
				}
			},
			loginMethod(){
				if(this.company.login_method == undefined) return "";
				let method = "";
				switch(this.company.login_method){
					case "email_link":
						method = "Email Login Link";
					break;
					case "requires_registration":
						method = "Requires Registration";
					break;
					case "sso":
						method = "Single Sign On";
					break;
				}
				return method;
			},
			loginPage(){
				if(this.company.slug == undefined) return "";
				return process.env.VUE_APP_COMPANY_URL.replace('{slug}',this.company.slug);
			},
			hasLogo(){
				if(this.company.logo == undefined || this.company.logo == null) return false;
				return this.company.logo.length > 0;
			},
			/**
			 * api url to load company logo
			 * @return {[type]} [description]
			 */
			companyLogoUrl(){
				return `${process.env.VUE_APP_API_URL}resources/company_logo/${this.company.slug}`;
			},
			/**
			 * super admin registration or admin registration
			 * @return {[type]} [description]
			 */
			registrationRoute(){
				if(this.company.id == undefined) return {};
				if(this.location == "super_admin"){
					return {name: 'super_admin.company.registration',params:{company_id: this.company.id}};
				}else{
					return {name: 'admin.company.registration'};
				}
			},
			ssoSetupRoute(){
				if(this.company.id == undefined) return {};
				
				if(this.location == "admin"){
					return {name: 'admin.company.sso'};
				}
				return {};
			},
			/**
			 * company edit route.
			 * @return {[type]} [description]
			 */
			editRoute(){
				if(this.company.id == undefined) return {};
				return {name:'super_admin.company.edit',params:{company_id: this.company.id}};
			},
			...mapState({
				user: state => state.user
			})
		}
	}
</script>