<template>
	<div class="tsa-contact-document tsa-default">
		<DocumentHeader />
		<div class="uk-child-width-1-2@m uk-grid-small tsa-document-form" uk-grid="masonry: true">
			<div style="z-index: 2;">
				<input type="hidden" name="department" v-model="form_data.department" />
				<!-- custom requirements -->
				<div class="form-section">{{ company.name }} Contact <i class="fas fa-user"></i> 
					<!-- update profile when I submit case. -->
					&nbsp; <span class="tsa-note">Changes will update your profile.</span>
				</div>
				<div :class="formRowClass('first_name')">
					<input type="text" name="first_name" placeholder="Enter Your First Name" v-model="form_data.first_name" />
					<span class="error-block">{{ errors.first_name }}</span>
				</div>
				<div :class="formRowClass('last_name')">
					<input type="text" name="last_name" placeholder="Enter Your Last Name" v-model="form_data.last_name" />
					<span class="error-block">{{ errors.last_name }}</span>
				</div>
				<div :class="formRowClass('email')">
					<input type="email" name="email" placeholder="Enter Your Email" v-model="form_data.email" />
					<span class="error-block">{{ errors.email }}</span>
				</div>
				<div :class="formRowClass('phone_number')">
					<TelephoneFieldTwo
						:phone="form_data.phone_number"
						:defaultCountry="user.phone_country_code"
						@country-changed="countryChanged"
						@phone-changed="onPhoneChanged"
						@onlyNumbers="onOnlyNumbers"
						:formatted="true"
						placeholder="Enter Phone" />
					<span class="error-block">{{ errors.phone_number }}</span>
				</div>
				<div :class="formRowClass('internal_case_number')">
					<input type="text" name="internal_case_number" :placeholder="casePlaceHolder" v-model="form_data.internal_case_number" />
					<span class="error-block">{{ errors.internal_case_number }}</span>
				</div>
				<component :is="whichComponent(requirement)"
					v-for="(requirement, index) in contact_section"
					:key="index"
					:requirement="requirement"
					:error="requirementError(requirement)"
					@change="onRequirementChanged"
					@required="onToggleRequired"
				/>
			</div>
			<div style="z-index: 2;">
				<!-- custom requirements -->
				<div class="form-section">{{ commonContactTitle }}</div>
				<component :is="whichComponent(requirement)"
					v-for="(requirement, index) in common_customer_section"
					:key="index"
					:requirement="requirement"
					:error="requirementError(requirement)"
					:value="getFieldDefault(requirement)"
					@change="onRequirementChanged"
					@required="onToggleRequired"
				/>
			</div>
			<div class="uk-padding-small uk-padding-remove-right">
				<div class="form-section" style="margin-top: 0">Problem Information</div>
				<div :class="formRowClass('problem_summary')">
					<input type="text" name="problem_summary" placeholder="Enter Problem Summary" v-model="form_data.problem_summary" />
					<span class="error-block">{{ errors.problem_summary }}</span>
				</div>
				<div :class="formRowClass('problem_description')">
					<textarea rows="6" name="problem_description" placeholder="Enter description of problem and include product versions, troubleshooting steps and error messages. Do not share any Customer Data such as IP addresses, login IDs or other sensitive information." v-model="form_data.problem_description"></textarea>
					<span class="error-block">{{ errors.problem_description }}</span>
				</div>
				<component :is="whichComponent(requirement)"
					v-for="(requirement, index) in problem_section"
					:key="index"
					:requirement="requirement"
					:model="getModelName(requirement)"
					:error="requirementError(requirement)"
					:form_data="dataChanges"
					@change="onRequirementChanged"
				/>
			</div>
			<div class="uk-padding-small">
				<div class="form-section" style="margin-top: 0">Priority Information</div>
				<div class="tsa-priority" :class="formRowClass('case_priority')">
					<select v-model="form_data.case_priority" :disabled="priority_disabled">
						<option value="">Select Priority</option>
						<option value='low'>Low - P3</option>
						<option value='medium'>Medium - P2</option>
						<option value='high'>High - P1</option>
					</select>
					<button @click.prevent="submitCase" class="tsa-btn">Submit</button>
				</div>
				<div class="priority-notes">
					<HelpHint flag="process_document_priority" />
					<p v-html="priorityInstructions"></p>
				</div>
				<div class="escalation-instructions tsa-box low-padding" v-if="isAdmin">
					<p><strong>PROCESS OWNER: </strong> {{ owner.first_name }} {{ owner.last_name }} <a :href="`mailto:${owner.email}`">{{ owner.email }}</a></p>
					<p><strong>ESCALATION:</strong></p>
					<p v-html="this.document.version.escalation_instructions"></p>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import DocumentHeader from '@/components/ContactDocuments/DocumentHeader.vue';
	import RequirementField from '@/components/ContactDocuments/RequirementField.vue';
	import Email from '@/components/ContactDocuments/Inputs/Email.vue';
	import Hidden from '@/components/ContactDocuments/Inputs/Hidden.vue';
	import Number from '@/components/ContactDocuments/Inputs/Number.vue';
	import Phone from '@/components/ContactDocuments/Inputs/Phone.vue';
	import Select from '@/components/ContactDocuments/Inputs/Select.vue';
	import String from '@/components/ContactDocuments/Inputs/String.vue';
	import TextField from '@/components/ContactDocuments/Inputs/Text.vue';
	import Url from '@/components/ContactDocuments/Inputs/Url.vue';
	import TierSelect from '@/components/ContactDocuments/Inputs/TierSelect.vue';
	import GroupSelect from '@/components/ContactDocuments/Inputs/GroupSelect.vue';
	import DbSelect from '@/components/ContactDocuments/Inputs/DbSelect.vue';
	import Copy from '@/components/ContactDocuments/Inputs/Copy.vue';
	import Timezone from '@/components/ContactDocuments/Inputs/Timezone.vue';
	import {mapState} from 'vuex';
	import HelpHint from '@/components/Layout/HelpHint.vue';
	import TelephoneFieldTwo from '@/components/Form/TelephoneFieldTwo.vue';

	import _ from 'lodash';

	export default {
		name: "default-document",
		components: {
			DocumentHeader,
			Email,
			RequirementField,
			HelpHint,
			Hidden,
			Number,
			Phone,
			Select,
			String,
			TextField,
			Url,
			TierSelect,
			GroupSelect,
			DbSelect,
			Copy,
			Timezone,
			TelephoneFieldTwo
		},
		props: {
			/**
			 * determines whether the user is submitting a sample case or not.
			 * @type {Boolean}
			 */
			sample_submission: {
				type: Boolean,
				default: false
			}
		},
		data(){
			return {
				form_data:{
					first_name: "",
					customer_last_name: "",
					email: "",
					phone_number: "",
					only_numbers: "",
					internal_case_number: "",
					problem_summary: "",
					problem_description: "",
					case_priority: "",
					update_profile: true,
					company_name: ""
				},
				errors: {
				},			
				engineer_country_code: "",
				priority_disabled: false,
				common_customer_section: [],
				contact_section: [],
				problem_section: [],
				dataChanges: {},
				force_required_fields : [],
				owner : {

				},
				updatedFields:[]
			}
		},
		mounted(){
			this.copyUserData();
			if(this.document.id != undefined) this.handleLoadDocument();
			if(this.sample_submission){
				this.form_data.internal_case_number = "Test123";
				this.form_data.problem_summary = "Test Case Submitted – Required Action! DO NOT DELETE - PLEASE RESPOND";
				this.form_data.problem_description = "This is a TSANet Test Case submitted to validate your process for working collaboration requests. You must acknowledge this case by clicking the \"Respond to TSANet\" button in this email.";
				this.form_data.case_priority = "low";
			}
		},
		methods:{
			onToggleRequired(response){
				if(response.required){
					this.force_required_fields.push(response.field_id);
				}else{
					const index = this.force_required_fields.indexOf(response.field_id);
					this.force_required_fields.splice(index,1);
				}
			},
			/**
			 * send case data to api.
			 * @return {[type]} [description]
			 */
			submitCase(){
				let data = _.clone(this.form_data);

				data.collaborate_type = this.$route.params.type;
				data.collaborate_entity = this.$route.params.entity_id;
				data.phone_country_code = this.engineer_country_code;

				data.sample_submission = this.sample_submission;
				data.force_required_fields = this.force_required_fields;

				// clear all errors
				this.errors = {};

				// show loader
				this.emitter.emit('loader',true);

				// eslint-disable-next-line
				axios.post(`${process.env.VUE_APP_API_URL}caller/collaborate/${this.document.id}`,data)
					.then(response => this.submitCaseResponse(response.data))
					.catch(error => this.handleErrors(error.response.data.errors));
			},
			whichComponent(item) {
				if(item.requirement.type == "hidden") {
					return "Hidden";
				}
				if(item.requirement.type == "integer") {
					return "Number";
				}
				if(item.requirement.type == "phone") {
					return "Phone";
				}
				if(item.requirement.type == "select") {
					return "Select";
				}
				if(item.requirement.type == "string") {
					return "String";
				}
				if(item.requirement.type == "text") {
					return "TextField";
				}
				if(item.requirement.type == "url") {
					return "Url";
				}
				if(item.requirement.type == "email") {
					return "Email";
				}
				if(item.requirement.type == "tierselect")
					return "TierSelect";

				if(item.requirement.type == "groupselect")
					return "GroupSelect";

				if(item.requirement.type == "db_select")
					return "DbSelect";

				if(item.requirement.type == "copy")
					return "Copy";

				if(item.requirement.type == "timezone")
					return "Timezone";
				
				return "RequirementField";
					
			},
			getFieldDefault(requirement){
				for(let i = 0; i < this.updatedFields.length;++i){
					if(this.updatedFields[i] == `custom_${requirement.requirement.id}`){
						return this.form_data[`custom_${requirement.requirement.id}`];
					}
				}
				if(this.sample_submission){
					switch(requirement.requirement.label){
						case "Customer Company":
							this.form_data[`custom_${requirement.requirement.id}`] = "Test Company";
							return this.form_data[`custom_${requirement.requirement.id}`];
						case "Customer Name":
							this.form_data[`custom_${requirement.requirement.id}`] = "Test User";
							return this.form_data[`custom_${requirement.requirement.id}`];
						case "Customer Email":
							this.form_data[`custom_${requirement.requirement.id}`] = "user@testcompany.com";
							return this.form_data[`custom_${requirement.requirement.id}`];
						case "Customer Phone":
						case "Customer Phone Including Country Code":
							this.form_data[`custom_${requirement.requirement.id}`] = "+1 913 555 1212";
							return this.form_data[`custom_${requirement.requirement.id}`];
						default:
							return "";
					}
				}
				return "";
			},
			getModelName(item) {
				if(this.form_data != undefined)
					return this.form_data[item.requirement.code_label];
			},
			/**
			 * if success show thank you screen.
			 * @param  {[type]} response [description]
			 * @return {[type]}          [description]
			 */
			submitCaseResponse(response){
				// update local profile data.
				this.$store.commit('setProfileDataFromCase',{
					first_name : this.form_data.first_name,
					last_name : this.form_data.last_name,
					phone_number : this.form_data.phone_number,
					phone_country_code : this.engineer_country_code,
					email: this.form_data.email
				});
				// hide loader
				this.emitter.emit('loader',false);
				// alert message
				this.emitter.emit('alert',response.message);
				// save case details to store.
				this.$store.dispatch('caseDetails',response);
				// let app know case was submitted
				this.$emit('submitted',response);
			},
			/**
			 * handle validation errors.
			 * @param  {[type]} errors [description]
			 * @return {[type]}        [description]
			 */
			handleErrors (errors){
				// hide loader
				this.emitter.emit('loader',false);
				// clear all errors
				this.errors = {};
				for(var e in errors){
					this.errors[e] = errors[e][0];
				}
			},
			/**
			 * 'form-row' add 'has-error' class if field has error
			 * @param  {[type]} field [description]
			 * @return {[type]}       [description]
			 */
			formRowClass(field){
				let string = "form-row";
				if(this.errors[field] != undefined ) string += " has-error";
				return string;
			},
			requirementError(requirement){
				return (this.errors[`custom_${requirement.requirement_id}`] == undefined)? "" : this.errors[`custom_${requirement.requirement_id}`];
			},
			onRequirementChanged(data){
				this.updatedFields.push(`custom_${data.requirement_id}`);
				this.updatedFields = [...new Set(this.updatedFields)];
				this.form_data[`custom_${data.requirement_id}`] = data.data;
				this.dataChanges = _.cloneDeep(this.form_data);
			},
			copyUserData(){
				this.form_data.first_name = this.user.first_name;
				this.form_data.last_name = this.user.last_name;
				this.form_data.email = this.user.email;
				const phoneNumber = (this.user.phone == null)? "" : this.user.phone.replace(/\D/g,'');
				this.form_data.phone_number = phoneNumber;
				this.form_data.company_name = this.company.full_name;
				if(this.document.department != null){
					this.form_data.department = this.document.department.name;
				}
			},
			/**
			 * check companies membership type and see if we need to set a default
			 * priority. if sender or receiver are basic set them to low
			 */
			setDefaultPriority(){
				if(this.company == undefined || this.document.company == undefined){
					this.priority_disabled = true;
					return;
				}
				
				// we are using custom SLA so user can set any priority.
				if(this.priority_messages.custom === true){
					this.priority_disabled = false;
					return;
				}

				if(this.company.membership_type == "basic" || this.document.company.membership_type == "basic" ||
					this.company.membership_type == "standard" || this.document.company.membership_type == "standard"){
					this.form_data.case_priority = "low";
					// disable priority dropdown
					this.priority_disabled = true;
				}else{
					this.priority_disabled = false;
				}
			},
			handleLoadDocument(){
				this.setDefaultPriority();
				// common contact section
				for(let i in this.document.common_customer_section){
					const requirement = this.document.common_customer_section[i].requirement;
					this.form_data[`custom_${requirement.id}`] = "";
				}
				this.common_customer_section = _.cloneDeep(this.document.common_customer_section);
				// 
				for(let i in this.document.problem_section){
					const requirement = this.document.problem_section[i].requirement;
					this.form_data[`custom_${requirement.id}`] = "";
				}
				this.problem_section = _.cloneDeep(this.document.problem_section);
				for(let i in this.document.contact_section){
					const requirement = this.document.contact_section[i].requirement;
					this.form_data[`custom_${requirement.id}`] = "";
				}
				this.contact_section = _.cloneDeep(this.document.contact_section);
				if(this.document.version.manager_users != undefined){
					if(this.document.version.manager_users.length > 0){
						this.owner = this.document.version.manager_users[0];
					}
				}
			},
			countryChanged(data){
				if(data == null) return;
				if(typeof data === 'string'){
					this.engineer_country_code = data;
				}else{
					// object
					this.engineer_country_code = data.iso2;
				}
			},
			onPhoneChanged(number){
				this.form_data.phone_number = number;
			},
			onOnlyNumbers(digits){
				this.form_data.only_numbers = digits;
			}
		},
		computed:{
			commonContactTitle(){
				if(this.document.version.common_customer_section_title != null) return this.document.version.common_customer_section_title;
				
				return (this.document.version.document_type == "ticket")? "Common Customer Contact" : "Additional Information";
			},
			casePlaceHolder(){
				if(this.sample_submission){
					return `Enter your Test Case #`;
				}else{
					return `Enter Your ${this.company.name} Internal Case #`;
				}
			},
			priorityInstructions(){
				const level = this.form_data.case_priority;
				if(level == 0) return "Please choose a priority.";

				if(!this.priority_messages === {} || this.priority_messages.low == undefined) return "";
				/*
				// if sender is basic
				if(this.company.membership_type == "basic"){
					return this.priority_messages.basic.sender;
				}

				// if receiver is basic
				if(this.document.company.membership_type == "basic"){
					return this.priority_messages.basic.receiver;
				}*/

				// both companies are premium
				return this.priority_messages[level];
			},
			isAdmin(){
				return this.user.role != "caller";
			},
			...mapState({
				document: state => state.contact_document,
				company: state => state.company,
				user: state => state.user,
				priority_messages: state => state.priority_messages
			})
		},
		watch:{
			user(){
				this.copyUserData();
			},
			document(){
				this.handleLoadDocument();
			}
		}
	}
</script>