<template>
	<div class="manage-document">
		<Breadcrumb :routes="breadcrumb" />
		<div>
			<div class="tsa-admin-header">
				{{company.name}} <span class="page-name">Process Details</span>
				<button class="tsa-btn" @click="saveDocument">Save</button>
			</div>
			<div uk-grid class="uk-child-width-1-2@m uk-grid-small">
				<div>
					<div :class="formRowClass('status')" class="with-label">
						<label>Status:</label>
						<select v-model="form_data.status">
							<option value='approved'>Approved</option>
							<option value='pending'>Pending</option>
							<option value='rejected'>Rejected</option>
						</select>
						<span class="error-block">{{ errors.status }}</span>
					</div>
					<div :class="formRowClass('title')" class="with-label">
						<label>Process Name:</label>
						<input type="text" placeholder="Enter Title" v-model="form_data.title" />
						<span class="error-block">{{ errors.title }}</span>
					</div>
				</div>
				<div>
					<div :class="formRowClass('support_hours')" class="with-label">
						<label>Support Hours:</label>
						<input type="text" placeholder="Enter Support Hours" v-model="form_data.support_hours" />
						<span class="error-block">{{ errors.support_hours }}</span>
					</div>
					<div :class="formRowClass('product_supported')" class="with-label">
						<label>Products Supported:</label>
						<input type="text" placeholder="Enter Products Supported" v-model="form_data.product_supported" />
						<span class="error-block">{{ errors.product_supported }}</span>
					</div>
				</div>
			</div>
			<div class="tsa-admin-header" style="margin-top: 20px;"><span class="page-name">Admin Note</span></div>
			<div class="form-row"  style="margin-bottom: 20px;">
				<wysiwyg-editor v-model="form_data.admin_note"></wysiwyg-editor>
			</div>
			<InternalNotes
					:process_document="process_document" />
			<div class="tsa-admin-header" style="margin-top:20px;"><span class="page-name">Static HTML</span></div>
			<div :class="formRowClass('document_html')">
				<textarea v-model='form_data.document_html' rows="20"></textarea>
				<span class="error-block">{{ errors.document_html }}</span>
			</div>
		</div>
		<div>
			<div class="tsa-admin-header" style="margin-top:20px;"><span class="page-name">Document Preview</span></div>
			<div class="static-process-document" v-html="form_data.document_html" />
		</div>
	</div>
</template>

<script>
	import Breadcrumb from '@/components/Layout/Breadcrumb.vue';
	import InternalNotes from '@/components/SuperAdmin/Company/DocumentInternalNotes.vue';
	import WysiwygEditor from "@/components/Form/WysiwygEditor.vue";
	import {mapState} from 'vuex';
	export default {
		name: 'super-admin-static-document',
		props: {
			process_document: {
				type: Object,
				default: () => {}
			}
		},
		components: {
			Breadcrumb,
			InternalNotes,
			WysiwygEditor
		},
		data(){
			return {
				company: {},
				mode: "manage",
				errors: {},
				form_data: {
					title: "",
					status: "approved",
					support_hours: "",
					product_supported: "",
					document_html: "",
					document_type: "default",
					admin_note : ""
				},
			}
		},
		mounted(){
			this.initialized();
		},
		updated(){
			this.initialized();
		},
		/**
		* remove bus event listeners before component is destroyed.
		* @return {[type]} [description]
		*/
		beforeUnmount(){
			this.emitter.off('token_ready');
		},
		methods: {
			initialized(){
				if(this.is_initialized) return;
				this.is_initialized = true;
				if(this.process_document.id != undefined) this.cloneDocument();
				this.emitter.on('token_ready', () => this.getFormData());
				if(this.jwt_token.access_token != undefined) this.getFormData();
			},
			/**
			 * get current company data
			 * @return {[type]} [description]
			 */
			getFormData(){
				const company_id = this.$route.params.company_id;
				//eslint-disable-next-line
				axios.get(`${process.env.VUE_APP_API_URL}super_admin/companies/${company_id}/contact_documents/form_data`)
				.then(response => this.handleFormData(response.data))
				//eslint-disable-next-line
				.catch(error => console.log(error));
			},
			handleFormData(response){
				this.company = response.company;
			},
			saveDocument(){
				this.emitter.emit('loader',true);
				const company_id = this.$route.params.company_id,
				document_id = this.$route.params.document_id,
				url = `${process.env.VUE_APP_API_URL}super_admin/companies/${company_id}/contact_documents/${document_id}`
				//eslint-disable-next-line
				axios.post(url,this.form_data)
					.then(response => this.handleSaveDocument(response.data))
					//eslint-disable-next-line
				    .catch(error => this.handleErrors(error.response.data.errors));
			},
			/**
			 * show errors or redirect to group details.
			 * @param  {[type]} response [description]
			 * @return {[type]}          [description]
			 */
			handleSaveDocument(response){
				this.emitter.emit('loader',false);
				if(response.error != undefined){
					this.emitter.emit('alert',{
						type: 'error',
						message: response.error
					});
					return;
				}
				// redirect to details
				this.$router.push({name:"super_admin.company.detail",params:{company_id: this.$route.params.company_id}});
			},
			handleErrors (errors){
				this.emitter.emit('loader',false);
				// clear all errors
				this.errors = {};
				for(var e in errors){
					this.errors[e] = errors[e][0];
				}
			},
			/**
			 * 'form-row' add 'has-error' class if field has error
			 * @param  {[type]} field [description]
			 * @return {[type]}       [description]
			 */
			formRowClass(field){
				let string = "form-row";
				if(this.errors[field] != undefined ) string += " has-error";
				return string;
			},
			cloneDocument(){
				this.form_data.support_hours = this.process_document.version.support_hours;
				this.form_data.status = this.process_document.version.status;
				this.form_data.product_supported = this.process_document.version.product_supported;
				this.form_data.document_html = this.process_document.static_document;
				this.form_data.title = this.process_document.name;
				this.form_data.admin_note = (this.process_document.version.admin_note == null)? "" :  this.process_document.version.admin_note;
			}
		},
		watch: {
			process_document(){
				this.cloneDocument();
			},
		},
		computed: {
			breadcrumb(){
				return [
					{display: "Companies" ,route: {name: "super_admin.companies"}},
					{display: this.company.name, route: {name: "super_admin.company.detail",params: {company_id : this.company.id}}},
					"Process Details"
				];
			},
			...mapState({
				jwt_token: state => state.token
			})
		}
	}
</script>