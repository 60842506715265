<template>
	<div>
		<h1><img :src="require('../../assets/images/msteams_logo.png')" style="height: 25px;" /> Connected to Microsoft Teams!</h1>
		<p><strong>Your inbound process has been connected to Microsoft Teams.</strong> When members collaborate with you they will be invited to Teams and receive a link to a channel for the support case.</p>
		<p>If you have any additional questions contact us at <a href="mailto:connect@tsanet.org">connect@tsanet.org</a></p>
		<p><br><a href="https://www.tsanet.org" class="tsa-btn">Visit TSANet.org</a></p>
	</div>
</template>

<script>
	export default {
		name : 'msteams-connected',
		props: {

		},
		components: {

		},
	}
</script>